import React from 'react';
import { TableCell, TableSortLabel} from '@material-ui/core';
import { stickyColumns, stickyStyle } from '../../common/utility';

export default function TableHeader({ columns, columnRefs, onClickResizeColumn, onRequestSort, order,orderBy }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return(
        <>
            {columns.map(({ dataField, headerName, width, stickyColumn, align='left', isSortable=false}, colIndex) => (
                <TableCell
                    key={`${dataField}_${colIndex + 1}`}
                    align="center"
                    style={{width: width ? width : "auto", textAlign: align, zIndex: stickyColumn? 6: 0 , ...stickyStyle(columns, colIndex, stickyColumn) }}
                    className={`resizeLine ${stickyColumns(stickyColumn)}`}
                    sortDirection={orderBy === dataField ? order : false}
                >
                    {headerName}
                    {isSortable && (<TableSortLabel
                        active={orderBy === dataField}
                        direction={orderBy === dataField ? order : 'asc'}
                        onClick={createSortHandler(dataField)}
                        >
                        {dataField.label}
                        {orderBy === dataField ? (
                            <span></span>
                        ) : null}
                    </TableSortLabel>)}
                    <div
                        onMouseDown={() => onClickResizeColumn(colIndex)}
                        ref={(el) => columnRefs.current[colIndex] = el}
                        className="resizeLine"
                    />
                </TableCell>
            ))}
        </>
    )
}