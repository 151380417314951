import React, { useState } from "react";
import { Table, TableBody, TableHead, makeStyles, TableCell, TextField, Typography, Tooltip, Grid, TableContainer, Paper, TableRow, Button, ButtonGroup, IconButton } from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Edit from '../../assets/EditIcon.svg';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableWrapper: {
        maxHeight: 639
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 190
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    pageNumber: {
        color: '#828282',
    },
    paginationUl: {
        '& ul': {
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-page.Mui-selected': {
                    color: theme.palette.primary.dark,
                    background: 'transparent',
                }
            }
        }
    },
    buttonRoot: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        border: 0,
        margin: `` + theme.spacing(.5) + `px 0 ` + theme.spacing(.5) + `px ` + theme.spacing(.5) + `px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
            position: 'absolute',
            right: -15,
            content: '""',
            top: 0,
            height: 24,
            textDecoration: 'none'
        },
        '&:hover': {
            background: 'none',
            color: '#2B0A3D',
            fontWeight: 600,
            border: 0,
            textDecoration: 'none'
        },
        '&.active': {
            color: '#2B0A3D',
            fontWeight: 600,
            textDecoration: 'none',
            '&:before': {
                backgroundColor: '#2B0A3D',
                left: 0,
                content: '""',
                bottom: -5,
                height: 2,
                position: 'absolute',
                width: '100%',
                textDecoration: 'none'
            }
        }
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
    },
    stickyPagination: {
        position: 'sticky',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        marginTop: theme.spacing(1.5),
        background: '#F3F3F3'
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            maxWidth: 300,
            fontFamily: "sans-serif",
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '5px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '35px'
    },
}));

const AddUser = ({ userData, partyData, saveUser, newUserAdd, setNewUserAdd, setUserData }) => {
    const classes = useStyles();
    const [activeSeletion, setActiveSeletion] = useState(2);
    const [selectStatus, setSelectStatus] = useState([]);
    const [isEdit, setIsEdit] = useState(null);

    const userRoles = [
        { id: "ADM", name: "Admin" },
        { id: "CST", name: "Customer" },
        { id: "MGR", name: "Manager" }
    ];

    const userStatus = [
        { id: "Active", status: "Active" },
        { id: "Inactive", status: "Inactive" }
    ];
    const filteredData = userData.filter(row => {
        return row;
    })

    const ROWS_PER_PAGE = [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: filteredData.length }
    ];
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE[0].value)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0].value);

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
        setActiveSeletion(1)
        setRowsPerPage(value);
        setPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setActiveSeletion(2)
        setPage(newPage);
    }

    const TotalRecords = filteredData.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const validate = (index) => {
        if (activeSeletion === 1) {
            // user clicks on show option recently
            return rowsPerPage > index;
        }
        if (activeSeletion === 2) {
            // user clicks on pagination numbers recently
            return (page === 1 ? 0 : (rowsPerPage * page) - rowsPerPage) <= index && index < (page === 1 ? 25 : (((page * rowsPerPage) - rowsPerPage) + rowsPerPage));
        }
    }

    const handleAddNewUser = (field, value, index) => {
        setNewUserAdd([
            ...newUserAdd.map((userData, i) => {
                if (index === i) userData[field] = value
                return userData
            })
        ])
    }

    const handleRemoveRow = (index) => {
        const data = newUserAdd.filter((_, i) => i !== index)
        setNewUserAdd(data);
    }

    const newresult = newUserAdd.map(userItem => {
        return {
            uid: 0,
            userName: userItem.username,
            userEmail: userItem.useremail,
            password: userItem.password ? userItem.password : "",
            userRole: userItem.userrole.id,
            userStatus: userItem.userstatus.status === 'Active' ? 'Y' : 'N',
            pid: userItem.userrole.id !== "CST" ? "0" : userItem.partyname.pid
        }
    });

    const handleSaveUser = () => {
        const reqObj = [...newresult]
        saveUser(reqObj[0]);
        setNewUserAdd([]);
    }

    const handleEditUser = (index) => {
        setIsEdit(index)
    }

    const handleUpdateUser = (field, value, userid) => {
        setUserData([
            ...userData.map(data => {
                if (data.uid === userid) data[field] = value;
                return data;
            }),
        ]);
    }

    const editresult = userData.map(userItem => {
        return {
            uid: userItem.uid,
            userName: userItem.username,
            userEmail: userItem.useremail,
            password: userItem.password ? userItem.password : "",
            userStatus: selectStatus.status === 'Active' ? 'Y' : 'N'
        }
    });

    const handleEdit = () => {
        const reqObj = [...editresult]
        saveUser(reqObj[isEdit]);
        setIsEdit(null);
    }

    const handleClear = () => {
        setIsEdit(null);
    }

    const handleUpdateStatus = (value, userid) => {
        userData.map(data => {
            if (data.uid === userid)
                setSelectStatus(value);
        })
    }

    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className={classes.tableWrapper}>
                    <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography className={classes.muteText}>User Role</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>User Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>User Email</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Password</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Party Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>User Status</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Action</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newUserAdd.map((row, index) => (
                                <>
                                    {
                                        validate(index) && <TableRow key={row.userId} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                            <TableCell component="th" scope="row" width="100px">
                                                <Autocomplete
                                                    id="role"
                                                    disableClearable
                                                    options={userRoles}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleAddNewUser("userrole", newValue, index)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Select"
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    placeholder="User Name"
                                                    margin="normal"
                                                    onChange={(e) => handleAddNewUser("username", e.target.value, index)}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    placeholder="User Email"
                                                    margin="normal"
                                                    onChange={(e) => handleAddNewUser("useremail", e.target.value, index)}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    placeholder="Password"
                                                    margin="normal"
                                                    onChange={(e) => handleAddNewUser("password", e.target.value, index)}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row" width="300px">
                                                <Autocomplete
                                                    id="party"
                                                    disableClearable
                                                    options={partyData}
                                                    getOptionLabel={(option) => option.party_name}
                                                    getOptionSelected={(option, value) => value.pid === option.pid}
                                                    onChange={(e, newValue) => handleAddNewUser("partyname", newValue, index)}
                                                    disabled={row.userrole.name !== 'Customer' ? true : false}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Select"
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                <Autocomplete
                                                    id="status"
                                                    disableClearable
                                                    options={userStatus}
                                                    getOptionLabel={(option) => option.status}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleAddNewUser("userstatus", newValue, index)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Select"
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                <Tooltip title="Save" arrow>
                                                    <DoneIcon color="primary" fontSize="small" onClick={() => handleSaveUser(index)} />
                                                </Tooltip>
                                                <Tooltip title="Remove" arrow>
                                                    <ClearIcon color="primary" fontSize="small" onClick={() => handleRemoveRow(index)} />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            ))}
                            {userData.map((row, index) => (
                                <>
                                    {
                                        validate(index) && <TableRow key={row.uid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                            <TableCell component="th" scope="row" width="150px">
                                                {row.urole}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {isEdit === index ? <TextField
                                                    placeholder="User Name"
                                                    value={row.username}
                                                    margin="normal"
                                                    onChange={(e) => handleUpdateUser("username", e.target.value, row.uid)}
                                                /> : row.username}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.useremail}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {isEdit === index ?
                                                    <TextField
                                                        placeholder="Password"
                                                        value={row.password}
                                                        margin="normal"
                                                        onChange={(e) => handleUpdateUser("password", e.target.value, row.uid)}
                                                    /> : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {partyData.filter(x => x.pid === row.pid)[0]?.party_name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {isEdit === index ? <Autocomplete
                                                    id="status"
                                                    disableClearable
                                                    value={selectStatus}
                                                    options={userStatus}
                                                    getOptionLabel={(option) => option.status}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleUpdateStatus(newValue, row.uid)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Select"
                                                            margin="normal"
                                                        />
                                                    )}
                                                /> : row.status}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {isEdit === index ?
                                                    <>
                                                        <Tooltip title="Update" arrow>
                                                            <DoneIcon color="primary" fontSize="small" onClick={() => handleEdit(index)} />
                                                        </Tooltip>
                                                        <Tooltip title="Remove" arrow>
                                                            <ClearIcon color="primary" fontSize="small" onClick={() => handleClear(index)} />
                                                        </Tooltip>
                                                    </> :
                                                    <IconButton edge="end" aria-label="add contact" onClick={() => handleEditUser(index)}>
                                                        <img
                                                            src={Edit}
                                                            alt='edit'
                                                            style={{ cursor: 'pointer', paddingRight: '10px' }}
                                                        />
                                                    </IconButton>}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container boxShadow={3} className={classes.stickyPagination} >
                        <Grid item xs={3}>
                            <Typography className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {Pagecount} </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Pagination className={[classes.paginationUl, { root: classes.pageNumber }]} count={Pagecount} showFirstButton showLastButton onChange={handleChangePage} />
                        </Grid>
                        <Grid container item xs={4} justifyContent='center'>
                            <Typography className={classes.paginationFont}>Show</Typography>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                {ROWS_PER_PAGE.map(({ label, value }) => (
                                    <Button
                                        key={value}
                                        className={recordsPerPage === value && 'active'}
                                        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                                        onClick={() => handleChangeRowsPerPage(value)}>
                                        {label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </TableContainer>
            </Paper>
        </React.Fragment>
    );
}

export default AddUser;