export const SUBTITLES = {
    '/purchase-order/summary': 'Summary',
    '/create-purchase-order': 'Create',
    '/shipment/ship-summary': 'Summary',
    '/create-shipment': 'Create',
    '/edit-purchase-order/': 'Edit',
    '/customer': 'Summary',
    '/customer-address/': 'Summary',
    '/work-order/summary/': 'Summary',
    '/edit-work-order/': 'Edit',
    '/edit-shipment/': 'Edit',
    '/customer-work-order/': 'Customer',
    '/party-scan': 'Party Scan',
    '/upload-data': 'Upload Data',
    '/manage-users': 'Manage Users',
    default: ''
}