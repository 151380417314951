import { Grid, Typography, Tooltip, makeStyles, TextField } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import filterIcon from '../assets/filter.png';
import downloadIcon from '../assets/download.png';
import TableView from "./components/TableView";
import SnackbarProvider from 'react-simple-snackbar'
import { WOSummaryContext } from "./components/context/WOSummaryContext";
import { useContext, useState } from "react";
import FilterPopover from "./components/FilterPopover";
import { downloadCustomerWO, downloadWoList } from "../Common/API";
import { saveAs } from "file-saver";
import moment from "moment";
import Cookies from "js-cookie";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    errorMessge: {
        color: '#af5858',
        fontSize: 16,
        border: 'solid 1px #af5858',
        margin: '26px 0',
        padding: 15
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    },
    topItems: {
        gap: 10
    },
    filterIcon: {
        width: "40px",
        cursor: 'pointer'
    }
}));

const WorkOrder = ({ setTitle }) => {
    const { headCells, summaryList, filterWOSummary } = useContext(WOSummaryContext);
    setTitle('Work Order');
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const To = new Date();
    const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
    const [woFilter, setWoFilter] = useState({});
    const dateArray = [{ id: 1, delivery: "Past 3 Months Delivery" }, { id: 2, delivery: "Past 6 Months Delivery" }, { id: 1, delivery: "Past 1 Year Delivery" }]

    const handleClick = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const handleClickDownload = () => {
        woFilter['type'] = "D"
        if (localStorage.getItem('party_type') !== 'CST') {
            woFilter['fromdate'] = From
            woFilter['todate'] = moment(To).format('yyyy-MM-DD')
            woFilter['user'] = localStorage.getItem('userId')
            downloadWoList(woFilter).then((res) => {
                if (res) {
                    const Filename = 'wo-data_' + moment(new Date()).format('yyyy-MM-DD');
                    saveAs(new Blob([res]), `${Filename}.xls`);
                    setWoFilter({})
                }
            }).catch((error) => {

            });
        } else {
            woFilter['fromdate'] = From
            woFilter['todate'] = moment(To).format('yyyy-MM-DD')
            woFilter['party_id'] = Cookies.get('party_id')
            downloadCustomerWO(woFilter).then((res) => {
                if (res) {
                    const Filename = 'wo-data_' + moment(new Date()).format('yyyy-MM-DD');
                    saveAs(new Blob([res]), `${Filename}.xls`);
                    setWoFilter({})
                }
            }).catch((error) => {

            });
        }
    }

    const onChangeDelivery = (data) => {
        if (data === "Past 3 Months Delivery") {
            const threeMonthsAgo = new Date(To);
            // Subtract 3 months from the current date
            woFilter['fromdate'] = moment(threeMonthsAgo.setMonth(To.getMonth() - 3)).format('yyyy-MM-DD');
            woFilter['todate'] = moment(To).format('yyyy-MM-DD');
            woFilter['party_id'] = Cookies.get('party_id');  
            filterWOSummary(woFilter);
        }else if(data === "Past 6 Months Delivery"){
            const sixMonthsAgo = new Date(To);
            // Subtract 3 months from the current date
            woFilter['fromdate'] = moment(sixMonthsAgo.setMonth(To.getMonth() - 6)).format('yyyy-MM-DD');
            woFilter['todate'] = moment(To).format('yyyy-MM-DD');
            woFilter['party_id'] = Cookies.get('party_id');  
            filterWOSummary(woFilter);
        }else if(data === "Past 1 Year Delivery"){
            const yearAgo = new Date(To);
            // Subtract 3 months from the current date
            woFilter['fromdate'] = moment(yearAgo.setMonth(To.getMonth() - 12)).format('yyyy-MM-DD');
            woFilter['todate'] = moment(To).format('yyyy-MM-DD');
            woFilter['party_id'] = Cookies.get('party_id');  
            filterWOSummary(woFilter);
        }
    }

    const renderWorkOrder = () => {
        return (
            <>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Summary</Typography>
                        </Grid>
                        <Grid item xs={8} container className="d-flex align-center">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.topItems}>
                                { Cookies.get("party_type") === "CST" ? <Autocomplete
                                    disableClearable
                                    id="type-select"
                                    options={dateArray}
                                    getOptionLabel={(option) => option.delivery || ""}
                                    getOptionSelected={(option, value) => value.id === option.id}
                                    onChange={(e, newValue) => onChangeDelivery(newValue.delivery)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Search Past Delivery"
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '210px' }}
                                        />
                                    )}
                                /> : null} 
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <Tooltip title="Download" arrow>
                                        <img src={downloadIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClickDownload(e)}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Filter" arrow>
                                        <img src={filterIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClick(e)}
                                        />
                                    </Tooltip>
                                </ErrorBoundary>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <SnackbarProvider>
                            <TableView headCells={headCells} summaryList={summaryList} />
                        </SnackbarProvider>
                    </ErrorBoundary>
                </Grid>
            </>
        )
    }


    return (
        <>
            {renderWorkOrder()}
            {isOpenModal &&
                <FilterPopover
                    isOpenModal={isOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filterWOSummary={filterWOSummary}
                    setWoFilter={setWoFilter}
                    woFilter={woFilter}
                />
            }
        </>
    )
}

export default WorkOrder;