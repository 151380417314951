import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAuth } from "../Auth/context/UserAuthContext";
import { makeStyles, Typography } from '@material-ui/core';
import {
    AppBar,
    Toolbar,
    Grid,
    Menu,
    MenuItem,
    Link as Links,
    Divider,
    IconButton,
    Tooltip,
    Link,
} from '@material-ui/core';
import TitleArrows from '../assets/Title_Arrows.svg';
import profileAvatar from '../assets/profileavatar.svg';
import HomeIcon from "../assets/HomeIcon.svg";
import BackArrow from "../assets/Back_Arrow.svg";
import g4scm_Logo from "../assets/g4scm_logo.png"
import { SUBTITLES } from './constant';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        height: '100vh'
    },
    body: {
        // display: 'flex',
        // flexFlow: 'column',
        height: '100vh',
        overflow: 'auto'
    },
    companyLogo: {
        height: 25
    },
    account: {
        color: '#FFFFFF'
    },
    backArrow: {
        cursor: 'pointer',
        height: '1.5rem'
    },
    toptobottombuttonroot: {
        position: 'fixed',
        top: theme.spacing(1),
        right: theme.spacing(1.5),
        rotate: '90deg',
        borderRadius: '5%',
        backgroundColor: '#70c1d4',
        marginRight: '13px'
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        textAlign: 'left',
        color: '#FFFFFF',
        fontSize: 16,
        width: 800,
        paddingLeft: '15px'
    },
    title1: {
        flexGrow: 0.75,
        textAlign: 'left',
        color: '#FFFFFF',
        fontSize: 16,
        width: 800,
        paddingLeft: '15px'
    },
    titleLink: {
        flexGrow: 1,
        textAlign: 'left',
        color: '#FFFFFF',
        fontSize: 16,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    titleLink1: {
        flexGrow: 0.75,
        textAlign: 'left',
        color: '#FFFFFF',
        fontSize: 16,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    userLoaderText: {
        margin: '1rem 0'
    },
    styledToolbar: {
        backgroundColor: '#0070AD'
    },
    titleArrowIcon: {
        padding: '0 20px',
        marginBottom: 0.5,
        marginRight: 3
    },
    titleArrowIconCM: {
        padding: '0 20px',
        marginBottom: 2.5,
        marginLeft: '-4px'
    },
    blankDiv: {
        width: 33
    },
    settingsIcon: {
        cursor: 'pointer'
    },
    styledNotification: {
        alignItems: 'center',
        paddingRight: 5
    },
    verticalDivider: {
        height: 25,
        marginTop: 3,
        marginRight: 5,
        marginLeft: 5,
        backgroundColor: '#FFFFFF33'
    },
    leftNavWrapper: {
        justifyContent: "flex-start",
        flexWrap: "nowrap",
        alignItems: "center",
        width: "60%"
    },
    rightNavWrapper: {
        marginLeft: 20,
        padding: theme.spacing(1),
        columnGap: 10,
        justifyContent: "flex-end",
        alignItems: "center",
        width: "40%",
        flexWrap: "nowrap"
    },
    projectShortName: {
        color: '#FFFFFF',
        margin: 0
    },
    userActivityPopup: {
        '& .MuiMenu-paper': {
            top: '75px !important',
            borderRadius: 0
        },
        '& ul': {
            padding: 0
        },
        '& .MuiListItem-button:hover': {
            textDecoration: 'none',
            backgroundColor: '#0070ad',
            color: '#ffffff'
        }
    },
    navWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '18px'
    },
    backArrowWrapper: {
        width: '30px',
        display: 'flex'
    },
    logoWrapper: {
        paddingLeft: '30px',
        paddingRight: '30px',
        display: 'flex'
    },
    menu: {
        paddingRight: 0
    },
}));

function Header(props) {
    const classes = useStyles();
    const { logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [currentTaskStep, setCurrentTaskStep] = useState(0);
    const [subTitle, setSubTitle] = useState(SUBTITLES.default);
    const link = location.pathname;
    const poid = link.substring(link.lastIndexOf('/') + 1);
    const pid = link.substring(link.lastIndexOf('/') + 1);
    const workorder = link.substring(link.lastIndexOf('/') + 1);
    const invid = link.substring(link.lastIndexOf('/') + 1);
    const custwoid = link.substring(link.lastIndexOf('/') + 1);

    useEffect(() => {
        setSubTitle(SUBTITLES[location.pathname] || SUBTITLES.default);

        if (location.pathname === `/purchase-order/summary`) {
            setSubTitle('Summary');
        } else if (location.pathname === `/create-purchase-order`) {
            setSubTitle('Create');
        } else if (location.pathname === '/shipment/summary') {
            setSubTitle('Summary');
        } else if (location.pathname === '/create-shipment') {
            setSubTitle('Create');
        } else if (location.pathname === `/edit-purchase-order/${poid}`) {
            setSubTitle('Edit');
        } else if (location.pathname === `/customer`) {
            setSubTitle('Summary');
        } else if (location.pathname === `/customer-address/${pid}`) {
            setSubTitle('Summary');
        } else if (location.pathname === `/work-order/summary`) {
            setSubTitle('Summary');
        } else if (location.pathname === `/edit-work-order/${workorder}`) {
            setSubTitle('Edit');
        } else if (location.pathname === `/edit-shipment/${invid}`) {
            setSubTitle('Edit');
        } else if (location.pathname === `/customer-work-order/${custwoid}`) {
            setSubTitle('Customer');
        } else if (location.pathname === `/party-scan`) {
            setSubTitle('Party Scan');
        } else if (location.pathname === `/upload-data`) {
            setSubTitle('Upload Data');
        } else if (location.pathname === `/manage-users`) {
            setSubTitle('Manage Users');
        }

    }, [location])

    const handleDashboard = () => {
        if (localStorage.getItem('party_type') !== "CST" && localStorage.getItem('party_type') !== "SLS") {
            navigate('/manage-orders');
        } else if(localStorage.getItem('party_type') === "CST") {
            navigate('/customer-dashboard');
        }else if(localStorage.getItem('party_type') === "SLS") {
            navigate('/sales-dashboard');
        }

        setSubTitle('');
    }

    const handleHomeIcon = () => {
        if (localStorage.getItem('party_type') !== "CST" && localStorage.getItem('party_type') !== "SLS") {
            navigate('/manage-orders');
        } else if(localStorage.getItem('party_type') === "CST") {
            navigate('/customer-dashboard');
        }else if(localStorage.getItem('party_type') === "SLS") {
            navigate('/sales-dashboard');
        }
    }

    const backIcon = () => {
        if (currentTaskStep === 0) {
            navigate(-1);
        } else {
            setCurrentTaskStep(currentTaskStep - 1);
        }
    }

    const handleSignOut = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('party_type');
        localStorage.removeItem('party_id');
        localStorage.removeItem('user_name');
        setAnchorEl(null);
        if (localStorage.getItem('otherUserLogin') !== null) {
            navigate('/other-user-login');
            localStorage.removeItem('otherUserLogin');
        } else {
            logOut();
        }
    }

    const handleSaveUser = () => {
        navigate('/manage-users');
    }

    const handleSalesUser = () => {
        navigate('/manage-sales');
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar position="static" color="inherit">
                <Toolbar className={classes.styledToolbar}>
                    <Grid container className={classes.leftNavWrapper}>
                        <div className={classes.backArrowWrapper}>
                            <Tooltip title="Go Back">
                                <img src={BackArrow} alt="go back" onClick={() => backIcon()} className={classes.backArrow} />
                            </Tooltip>
                        </div>
                        <div className={classes.logoWrapper}>
                            <img src={g4scm_Logo} style={{ height: '35px' }} alt="g4pl Logo" className={classes.companyLogo} />
                        </div>
                        <Divider orientation="vertical" className={classes.verticalDivider} />
                        <Grid className={classes.navWrapper}>
                            <div>
                                <Tooltip title="Home">
                                    <Links onClick={handleDashboard} className={classes.titleLink}>
                                        G4SCM
                                    </Links>
                                </Tooltip>
                                <img src={TitleArrows} alt="title_arrows" className={classes.titleArrowIconCM} />
                            </div>
                            <div>
                                <Links className={classes.titleLink}>
                                    {props.title}
                                </Links>
                            </div>
                            {(subTitle) ? (<div><img src={TitleArrows} alt="title_arrows" className={classes.titleArrowIcon} /></div>) : (null)}
                            {subTitle ? (<div className={classes.titleLink}>{subTitle}</div>) : null}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rightNavWrapper}>
                        <>
                            <Tooltip title={localStorage.getItem('user_name')}>
                                <Typography className={classes.projectShortName}>
                                    {localStorage.getItem('user_name')}
                                </Typography>
                            </Tooltip>
                            <Divider orientation="vertical" className={classes.verticalDivider} />
                        </>
                        <>
                            <IconButton>
                                <Tooltip title="HOME">
                                    <img src={HomeIcon} alt="Home" onClick={handleHomeIcon} className={classes.settingsIcon} />
                                </Tooltip>
                            </IconButton>
                            <Divider orientation="vertical" className={classes.verticalDivider} />
                        </>
                        <IconButton size="small" onClick={handleClick}>
                            <img
                                src={profileAvatar}
                                className={classes.divider}
                                alt="profileIcon"
                            />
                        </IconButton>
                        <Menu
                            id="account-menu"
                            anchorEl={anchorEl}
                            open={open}
                            style={{ top: "50px", right: "100px" }}
                            onClose={handleClose}
                        >
                            {localStorage.getItem('party_type') !== "CST" && localStorage.getItem('party_type') !== "SLS" ?
                                <>
                                    <MenuItem>
                                        <Link onClick={() => handleSaveUser()} className={classes.linkLabelMenu}>
                                            Manage Users
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link onClick={() => handleSalesUser()} className={classes.linkLabelMenu}>
                                            Manage Sales
                                        </Link>
                                    </MenuItem>
                                </> : null}
                            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                        </Menu>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>

    )
}

export default Header;
