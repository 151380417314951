import { useNavigate } from "react-router-dom";
import { LightTooltip } from "../../../Common/constant";

const BoxArrowImg = ({headImg, tooltipTitle, redirectLink, type}) => {
    const navigate = useNavigate();
    const handleRedirect = () => navigate(redirectLink);
    const getRedirectTooltip = `Click here to open '${tooltipTitle}' application`
return (
    <LightTooltip title={getRedirectTooltip} onClick={() => handleRedirect()}>
        {type === "PO" ? <img src={headImg} alt="po-Icon" className="img-icon cursor_pointer" width="15px" 
        style={{marginTop: "-23px", marginLeft: "137px"}} /> : type === "WO" ? <img src={headImg} alt="wo-Icon" className="img-icon cursor_pointer" width="15px" 
        style={{marginTop: "-23px", marginLeft: "104px"}} /> : type === "Ship" ? <img src={headImg} alt="ship-Icon" className="img-icon cursor_pointer" width="15px" 
        style={{marginTop: "-23px", marginLeft: "89px"}} /> : type === "vault" ? <img src={headImg} alt="vault-Icon" className="img-icon cursor_pointer" width="15px" 
        style={{marginTop: "-19px", marginLeft: "83px"}} /> : type === "CST" ? <img src={headImg} alt="cst-Icon" className="img-icon cursor_pointer" width="15px" 
        style={{marginTop: "-28px", marginLeft: "104px"}} /> : null}
    </LightTooltip>
)
}

export default BoxArrowImg;