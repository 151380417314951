/** @format */

import React, { createContext, useState, useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { HEAD_CELLS } from '../constants/index';
import { fetchWOCustomerList, fetchWoSummary, fetchWOSalesList } from '../../../Common/API';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

const initialState = {
  loading: true,
  surveyList: [],
  error: null,
  hasError: false,
  headCells: HEAD_CELLS,
  order: 'asc',
  orderBy: '',
  pageId: null,
};

const spinnerStyle = {
  marginLeft: '50%',
  marginTop: '2%',
};

export const WOSummaryContext = createContext(initialState);

export const WOSummaryContextProvider = ({ children }) => {
  const userId = localStorage.getItem('userId');
  const party_id = localStorage.getItem('party_id');
  const { woid } = useParams();
  const To = new Date();
  const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
  const filter = {
    "fromdate": From,
    "todate": moment(To).format('yyyy-MM-DD'),
    "user": userId,
    "wostatus": localStorage.getItem('wostatus') !== undefined ? localStorage.getItem('wostatus') : "",
    "overdue": localStorage.getItem('overdue') !== undefined ? localStorage.getItem('overdue') : "",
    "thisweek": localStorage.getItem('thisweek') !== undefined ? localStorage.getItem('thisweek') : "",
  }

  const filters = {
    "fromdate": From,
    "todate": moment(To).format('yyyy-MM-DD'),
    "party_id": party_id,
    "wostatus": localStorage.getItem('wostatus') !== undefined ? localStorage.getItem('wostatus') : "",
    "origin": localStorage.getItem('origin') !== undefined ? localStorage.getItem('origin') : "",
    "woid": woid !== undefined ? woid : "",
    "wostatusname": localStorage.getItem('wostatusname') !== undefined ? localStorage.getItem('wostatusname') : "",
    "wopendingthismonth": localStorage.getItem('wopendingthismonth') !== undefined ? localStorage.getItem('wopendingthismonth') : "",
  }

  const salesfilters = {
    "fromdate": From,
    "todate": moment(To).format('yyyy-MM-DD'),
    "user": userId,
    "wostatus": localStorage.getItem('wostatus') !== undefined ? localStorage.getItem('wostatus') : "",
    "origin": localStorage.getItem('origin') !== undefined ? localStorage.getItem('origin') : "",
    "woid": woid !== undefined ? woid : "",
    "wostatusname": localStorage.getItem('wostatusname') !== undefined ? localStorage.getItem('wostatusname') : "",
    "wopendingthismonth": localStorage.getItem('wopendingthismonth') !== undefined ? localStorage.getItem('wopendingthismonth') : "",
  }

  const [allWOContextData, setAllWOContextData] = useState(initialState);
  const { summaryList, loading, headCells, orderBy, order } = allWOContextData;

  const partyType = localStorage.getItem('party_type');

  const result =
  partyType !== "CST" && partyType !== "SLS"
    ? filter
    : partyType === "CST"
    ? filters
    : partyType === "SLS"
    ? salesfilters
    : null;

  useEffect(() => {
    filterWOSummary(result);
  }, []);

  const filterWOSummary = (filterData) => {
    if (localStorage.getItem('party_type') !== "CST" && localStorage.getItem('party_type') !== "SLS") {
      fetchWoSummary(filterData)
        .then((data) => {
          if (data.status !== "NO DATA") {
            const summaryData = data.list.map((data, index) => {
              return data;
            });
            setAllWOContextData((prevState) => {
              return {
                ...prevState,
                summaryList: summaryData,
                loading: false,
                error: null,
                hasError: false,
                searchTerm: '',
              }
            });
          } else {
            setAllWOContextData({
              ...allWOContextData,
              summaryList: [],
              loading: false,
              error: null,
              hasError: true,
            });
          }
        })
        .catch((error) => {
          setAllWOContextData({
            ...allWOContextData,
            loading: false,
            error: error,
            hasError: true,
          });
        });
    } else if (localStorage.getItem('party_type') === "CST") {
      fetchWOCustomerList(filterData)
        .then((data) => {
          if (data.status !== "NO DATA") {
            const summaryData = data.list.map((data, index) => {
              return data;
            });
            setAllWOContextData((prevState) => {
              return {
                ...prevState,
                summaryList: summaryData,
                loading: false,
                error: null,
                hasError: false,
                searchTerm: '',
              }
            });
          } else {
            setAllWOContextData((prevState) => {
              return {
                ...prevState,
                summaryList: [],
                loading: false,
                hasError: true,
              }
            });
          }
        })
        .catch((error) => {
          setAllWOContextData({
            ...allWOContextData,
            loading: false,
            error: error,
            hasError: true,
          });
        });
    } else if (localStorage.getItem('party_type') === "SLS") {
      fetchWOSalesList(filterData)
        .then((data) => {
          if (data.status !== "NO DATA") {
            const summaryData = data.list.map((data, index) => {
              return data;
            });
            setAllWOContextData((prevState) => {
              return {
                ...prevState,
                summaryList: summaryData,
                loading: false,
                error: null,
                hasError: false,
                searchTerm: '',
              }
            });
          } else {
            setAllWOContextData((prevState) => {
              return {
                ...prevState,
                summaryList: [],
                loading: false,
                hasError: true,
              }
            });
          }
        })
        .catch((error) => {
          setAllWOContextData({
            ...allWOContextData,
            loading: false,
            error: error,
            hasError: true,
          });
        });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setAllWOContextData({
      ...allWOContextData,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  function descendingComparator(a, b, orderByVal) {
    const orderByArray = orderByVal.split('.');
    if (
      orderByArray &&
      orderByArray.length &&
      b[orderByArray[0]] &&
      b[orderByArray[0]][orderByArray[1]] &&
      a[orderByArray[0]] &&
      a[orderByArray[0]][orderByArray[1]]
    ) {
      if (
        b[orderByArray[0]][orderByArray[1]] <
        a[orderByArray[0]][orderByArray[1]]
      ) {
        return -1;
      }
      return 1;
    } else if (b[orderByVal] && a[orderByVal]) {
      const isNumber = typeof a[orderByVal] === 'number';
      const filedValueB = isNumber
        ? b[orderByVal]
        : b[orderByVal].toUpperCase();
      const filedValueA = isNumber
        ? a[orderByVal]
        : a[orderByVal].toUpperCase();
      if (filedValueB < filedValueA) {
        return -1;
      }
      return 1;
    }
    return 0;
  }

  function getComparator(orderVal, orderByVal) {
    return orderVal === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByVal)
      : (a, b) => -descendingComparator(a, b, orderByVal);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orders = comparator(a[0], b[0]);
      if (orders !== 0) return orders;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const contextValues = {
    ...allWOContextData,
    summaryList,
    headCells,
    filterWOSummary,
    handleRequestSort,
    getComparator,
    stableSort,
  };

  return (
    <WOSummaryContext.Provider value={contextValues}>
      {loading ? (
        <div style={spinnerStyle}>
          {' '}
          <SpinnerCircularFixed
            color='#0070AD'
            enabled={loading}
          />{' '}
        </div>
      ) : (
        children
      )}
    </WOSummaryContext.Provider>
  );
};
