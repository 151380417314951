import { useNavigate } from "react-router-dom";
import { LightTooltip } from "../../../Common/constant";

const BoxImg = ({headImg, tooltipTitle, redirectLink}) => {
    const navigate = useNavigate();
    const handleRedirect = () => navigate(redirectLink);
    const getRedirectTooltip = `Click here to open '${tooltipTitle}' application`

return (
    <LightTooltip title={getRedirectTooltip} onClick={() => handleRedirect()}>
        <img src={headImg} alt="WorkOrder-Icon" className="card-icon cursor_pointer" />
    </LightTooltip>
)
}

export default BoxImg;