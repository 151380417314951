export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'serial_no'
export const IP_ADDRESS = 'ip_address'
export const EMAIL = 'email'
export const USER = 'user_name'
export const DATE = 'date'
export const MATCHES = 'matches'
export const NAME = 'name'
export const ADDRESS = 'address'
export const COUNTRY = 'country'
export const SOURCE = 'source'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'serial_no', width: 80, stickyColumn: 'left'},
    { id: IP_ADDRESS, isSortable: true, selected: true, label: 'IP Address', fieldName: 'ip_address', width: 150},
    { id: EMAIL, isSortable: true, selected: true, label: 'Email', fieldName: 'email', width: 150},
    { id: USER, isSortable: true, selected: true, label: 'User', fieldName: 'user_name', width: 110},
    { id: DATE, isSortable: true, selected: true, label: 'Date', fieldName: 'date', width: 150},
    { id: MATCHES, isSortable: true, selected: true, label: 'Matches', fieldName: 'matches', width: 100},
    { id: NAME, isSortable: true, selected: true, label: 'Name', fieldName: 'name', width: 140},
    { id: ADDRESS, isSortable: true, selected: true, label: 'Address', fieldName: 'address', width: 150 },
    { id: COUNTRY, isSortable: true, selected: true, label: 'Country', fieldName: 'country', width: 120},
    { id: SOURCE, isSortable: true, selected: true, label: 'Source', fieldName: 'source', width: 150},
]
