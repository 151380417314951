/** @format */
import Edit from '../../../../../assets/EditIcon.svg';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback, errorHandler } from '../../../../../Common/Fallback';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const RenderActionButtons = ({ record }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  /**
   * modal close data handling
   */
  const handleModalClose = (confirmState) => {
    setOpenDeleteModal(false);
  };

  /**
   * confirm delete table row
   */
  const confirmDelete = (id) => {
    setOpenDeleteModal(true);
  };

  const renderActionButton = () => {
    return (
      <>
        <Link to={`/edit-shipment/${record.invid}`}>
          <Tooltip
            title='Edit'
            arrow>
            <img
              src={Edit}
              alt='edit'
              style={{ cursor: 'pointer', paddingRight: '10px' }}
            />
          </Tooltip>
        </Link>
        {/* <Tooltip
          title='Delete PO'
          arrow>
          <img
            src={Delete}
            alt='delete'
            onClick={() => confirmDelete(record.poid)}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip
          title='Cancel PO'
          arrow>
          <IconButton style={{background: "none"}}>
            <ClearIcon color='primary' style={{width: "19px", marginTop: "-4px", marginLeft: "-5px"}}/>
          </IconButton>
        </Tooltip> */}
      </>
    );
  };
  return (
    <>
      {renderActionButton()}
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={errorHandler}>
        {openDeleteModal && (
          <DeleteConfirmationModal
            handleModalClose={handleModalClose}
            openDeleteModal={openDeleteModal}
          />
        )}
      </ErrorBoundary>
    </>
  );
};
