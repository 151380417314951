/** @format */
import Edit from '../../../../../assets/EditIcon.svg';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback, errorHandler } from '../../../../../Common/Fallback';
import { Tooltip } from '@material-ui/core';
import PartyScanPopupModal from '../../../PartyScanPopupModal';
import { updateScan } from '../../../../../Common/API';
import moment from 'moment';

export const RenderActionButtons = ({ record, summaryList, amberSnackbar, successSnackbar, filterScanSummary }) => {
  const [openScanModal, setOpenScanModal] = useState(false);
  const [count, setCount] = useState();
  const To = new Date();
  const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
  const filter = {
    "fromDate": From,
    "toDate": moment(To).add(1, 'days').format('yyyy-MM-DD'),
    "user": localStorage.getItem('userId'),
    "type": 'manual'
  }

  const updatePartyScan = (reqData) => {
    updateScan(reqData).then(res => {
      if (res.status === 1) {
        setOpenScanModal(false);
        successSnackbar(res.message)
        filterScanSummary(filter);
      } else {
        amberSnackbar(res.message);
        setOpenScanModal(true);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const handleOpenScanModal = (pid) => {
    const counts = summaryList.find(x => x.pid === pid);
    setCount(counts.matches);
    setOpenScanModal(!openScanModal);
  }

  const renderActionButton = () => {
    return (
      <>
        <Tooltip
          title='Manual Update'
          arrow>
          <img
            src={Edit}
            alt='edit'
            onClick={() => handleOpenScanModal(record.pid)}
            style={{ cursor: 'pointer', paddingRight: '10px' }}
          />
        </Tooltip>
      </>
    );
  };
  return (
    <>
      {renderActionButton()}
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={errorHandler}>
        {openScanModal && (
          <PartyScanPopupModal
            setOpenScanModal={setOpenScanModal}
            openScanModal={openScanModal}
            pid={record.pid}
            count={count}
            setCount={setCount}
            updatePartyScan={updatePartyScan}
          />
        )}
      </ErrorBoundary>
    </>
  );
};
