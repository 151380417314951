import { Grid, Typography } from "@material-ui/core";
import BoxHead from "../../common/BoxHead";
import BoxImg from "../../common/BoxImg";
import { WORK_ORDERS, WORK_ORDER_INFO, REDIRECT_TO_PROFILE, WORK_ORDER_PER_STATUS_TITLE, WORK_ORDER_PIE_TITLE } from "../../../Common/constant";
import { SpinnerCircularFixed } from "spinners-react";
import { ChartComponent, Inject, Category, SeriesDirective, SeriesCollectionDirective, BarSeries, ScrollBar, Zoom } from "@syncfusion/ej2-react-charts";
import { ErrorBoundary } from "react-error-boundary";
import WorkOrderImage from "../../../assets/wo-icon.png";
import { Fallback, errorHandler } from "../../../Common/Fallback";
import { useState } from "react";
import DonutReport from "../../common/DonutReport";
import { fetchWOGraphData } from "../../../Common/API";
import { useNavigate } from "react-router-dom";
import ArrowImg from '../../../assets/redo.png';
import BoxArrowImg from "../../common/BoxArrowImg";

const WorkOrderDash = (props) => {
  const navigate = useNavigate();

  useState(() => {
    fetchWOGraphData(props.filter).then(data => {
      if (data.wocount[0].wocount === "0") {
        props.setLoading({ ...props.loading, noDataMode: true, isLoading: false });
      } else {
        props.setWoStatusGraph(data.wostatus);
        props.setWoCount(data.wocount[0]);
        props.setETA(data.woeta);
        props.setResponse(data.status);
      }
    }).catch(error => {
      props.setLoading({ ...props.loading, noDataMode: true, isLoading: false });
    });
  }, []);

  const woStatusData = Object.entries(
    props.woStatusGraph.reduce((prev, { status, wocount }) => {
      prev[status] = prev[status] ? prev[status] + 1 : wocount;
      return prev;
    }, {})
  )
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => b.count - a.count);

  const graphClick = (data) => {
    const status = props.woStatusGraph.find(x => x.status === data)
    localStorage.removeItem('Overdue');
    localStorage.removeItem('thisweek');
    localStorage.setItem('wostatus', status.id);
    navigate('/work-order/summary');
  }

  const etaData = Object.entries(
    props.eta.reduce((prev, { eta, count }) => {
      prev[eta] = prev[eta] ? prev[eta] + 1 : parseInt(count);
      return prev;
    }, {})
  )
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => b.count - a.count);

  const MaxY = [];
  if (etaData && etaData.length > 0) {
    for (let i = 0; i < etaData.length; i++) {
      MaxY.push(etaData[i].y);
    }
  }

  const Ymaxkey = Math.max(...MaxY);
  let yAxesMaxValkey = 10
  let intervalValue = 10
  if (Ymaxkey !== -1) {
    let roundNearestTo = 5
    if (Ymaxkey > 30) {
      roundNearestTo = 10
    }
    yAxesMaxValkey = Math.ceil(Ymaxkey / roundNearestTo) * roundNearestTo;
    intervalValue = yAxesMaxValkey / 5
  }

  const primaryXAxis = { zoomFactor: 1, valueType: 'Category', majorGridLines: { width: 0 }, majrTickLines: { width: 0 } };

  const zoomsettings = {
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableSelectionZooming: true,
    mode: 'XY',
    enableScrollbar: true
  }

  const load = ({ chart: { zoomModule: { isZoomed } } }) => { isZoomed = true };

  const handlePointClick = (eta) => {
    localStorage.removeItem('wostatus');
    if(eta === "Overdue"){
      localStorage.removeItem('thisweek');
      localStorage.setItem('overdue', eta);
    }else{
      localStorage.removeItem('overdue');
      localStorage.setItem('thisweek', eta);
    }
    navigate('/work-order/summary')
  }

  return (
    <Grid item container xs={12} className="icapBox businessChange">
      <Grid item container xs={12} className="icapboxHead">
        <BoxHead headTitle={WORK_ORDERS} tooltipTitle={WORK_ORDERS} tooltipInfo={WORK_ORDER_INFO} redirectLink={REDIRECT_TO_PROFILE} />
        <BoxArrowImg headImg={ArrowImg} tooltipTitle={WORK_ORDERS} redirectLink={REDIRECT_TO_PROFILE} type="WO" />
      </Grid>
      {props.response !== 'success' ? (
        <div className='loaderWithMessage'>{props.loading.noDataMode ? (<p>{props.loading.nodataMsg}</p>) : (<SpinnerCircularFixed color="#0070AD" enabled={props.loading.isLoading} />)}</div>
      ) : (
        <Grid container item xs={12} spacing={2}>
          <Grid item container xs={3} className="align-center">
            <Grid item xs={6}>
              <BoxImg headImg={WorkOrderImage} tooltipTitle={WORK_ORDERS} redirectLink={REDIRECT_TO_PROFILE} />
            </Grid>
            <Grid item xs={6}>
              <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <Typography className="icapBoxNumberTxt mb-0" gutterBottom variant="h6">{props.woCount.wocount}</Typography>
              </ErrorBoundary>
              <Typography className="icapBoxTxt" variant="h6">Total Work Orders</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={3}>
            <Grid item xs={12}>
              <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <Typography className="icapBoxNumberTxt" variant="h6" style={{ fontSize: '16px' }}>Number of work order per ETA</Typography>
                <ChartComponent
                  primaryXAxis={primaryXAxis}
                  primaryYAxis={{
                    zoomFactor: 1,
                    majorGridLines: { dashArray: [2] },
                    interval: intervalValue,
                    majrTickLines: { width: 0 }
                  }}
                  width={'100%'}
                  height={'50%'}
                  zoomSettings={zoomsettings} load={load}
                  chartArea={{ border: { width: 0 } }}
                  pointClick={(args) => { handlePointClick(args.point.x) }}>
                  <Inject services={[BarSeries, Category, ScrollBar, Zoom]} />
                  <SeriesCollectionDirective>
                    <SeriesDirective dataSource={etaData}
                      columnSpacing={0.1} columnWidth={0.4} width={2}
                      xName='x' yName='y' type='Bar' />
                  </SeriesCollectionDirective>
                </ChartComponent>
              </ErrorBoundary>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
              <DonutReport title={WORK_ORDER_PER_STATUS_TITLE} pieTitle={WORK_ORDER_PIE_TITLE} dataSource={woStatusData} totalCount={props.woCount.wocount} graphClick={graphClick} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default WorkOrderDash;