export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'slno'
//export const WORK_ORDER_NO = 'workorderno'
export const PO_NO = 'pono'
export const BO_NO = 'bno'
export const MAWB_NO = 'mawbno'
export const HAWB_NO = 'hawbno'
export const ORIGIN = 'origin'
export const DESTINATION = 'deldestination'
export const MODE_OF_TRANSPORT = 'modeoftransport'
export const DELIVERY_LOCATION = 'dellocation'
export const WO_DATE = 'workorderdate'
export const CUSTOMER = 'customer'
export const BRAND = 'po_party_brand'
export const ETA = 'eta'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'slno', width: 80, stickyColumn: 'left'},
    { id: PO_NO, isSortable: true, selected: true, label: 'PO Number', fieldName: 'po_no', width: 140},
    { id: BO_NO, isSortable: true, selected: true, label: 'BO Number', fieldName: 'bno', width: 170 },
    { id: MAWB_NO, isSortable: true, selected: true, label: 'MAWB Number', fieldName: 'mawbno', width: 150 },
    { id: HAWB_NO, isSortable: true, selected: true, label: 'HAWB Number', fieldName: 'hawbno', width: 150},
    { id: ORIGIN, isSortable: true, selected: true, label: 'Origin', fieldName: 'origin', width: 100},
    { id: DESTINATION, isSortable: true, selected: true, label: 'Destination', fieldName: 'destination', width: 130},
    { id: MODE_OF_TRANSPORT, isSortable: true, selected: true, label: 'Mode of Transport', fieldName: 'modeOfT', width: 175},
    { id: DELIVERY_LOCATION, isSortable: true, selected: true, label: 'Delivery Location', fieldName: 'delivery_location', width: 170},
    { id: WO_DATE, isSortable: true, selected: true, label: 'Wo Date', fieldName: 'wo_date', width: 130},
    { id: CUSTOMER, isSortable: true, selected: true, label: 'Customer', fieldName: 'customer', width: 130},
    { id: BRAND, isSortable: true, selected: true, label: 'Brand', fieldName: 'brand', width: 130},
    { id: ETA, isSortable: true, selected: true, label: 'ETA', fieldName: 'eta', width: 130},
]
