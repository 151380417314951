import React, { useState } from "react";
import TableOrganizer from "../../../Common/TableOrganizer";
import { DEFAULT_MAX_WIDTH_CELL, DEFAULT_MIN_WIDTH_CELL, ROWS_PER_PAGE_OPTIONS } from "../constants";
import { useSort } from "../../../Common/TableOrganizer/common/hooks/useSort";
import '../TableView/index.css';
import { RenderActionButtons } from "./components/RenderActionButtons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Typography, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    circleRed: {
        fontSize: 10,
        color: theme.palette.primary.danger,
        marginRight: 8,
    },
    circleGreen: {
        fontSize: 10,
        color: theme.palette.success.main,
        marginRight: 8,
    },
    circleOrange: {
        fontSize: 10,
        color: "#FFA500",
        marginRight: 8,
    },
    circle: {
        fontSize: 10,
        color: "#fffff0",
        marginRight: 8,
    }
}))

export function TableView({headCells, summaryList, successSnackbar, amberSnackbar}) {
    const classes = useStyles();
    const { columnOrder, columnOrderBy, handleRequestSort } = useSort();
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0].value)
    const TotalRecords = summaryList.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    }

    const returnPaginationData = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const COLUMNS = []
    headCells.forEach(head => {
        const { id, label, selected, ...rest } = head
        if (selected) {
            const obj = {
                dataField: id,
                headerName: label,
                ...rest
            }
            COLUMNS.push(obj)
        }
    })

    const actionColumn = {
        dataField: "actions",
        headerName: "Actions",
        align: 'center',
        sortable: false,
        width: 116,
        truncate: true,
        cellRenderMethod: (record, dataField, index, isParent, parentRowIndex) => (
            <RenderActionButtons
                record={record}
                dataField={dataField}
                index={index}
                isParent={isParent}
                parentRowIndex={parentRowIndex}
                amberSnackbar={amberSnackbar}
                successSnackbar={successSnackbar}
            />
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    const statusColumn = {
        dataField: "status",
        headerName: "Status",
        align: 'center',
        sortable: false,
        width: 116,
        truncate: true,
        cellRenderMethod: (record) => (
            <>
                <FiberManualRecordIcon className={(record.status === "WO Complete" || record.status === "Delivered")
                    ? classes.circleGreen : (record.status === "Packing" || record.status === "Shipping"
                        || record.status === "In Transit" || record.status === "Out for Delivery") ? classes.circleOrange :
                        (record.eta > moment(new Date).format('yyyy/MM/dd') || record.eta === null || 
                        record.status === "Pending") ? classes.circleRed : classes.circle} />
                <Typography style={{fontFamily: "sans-serif", fontSize: "14px", display: "contents"}}>{record.status}</Typography>
            </>
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    return (
        <>
            <TableOrganizer
                columns={[...COLUMNS, statusColumn, actionColumn]}
                tableData={returnPaginationData(summaryList, recordsPerPage, currentPage)}
                defaultMinWidthForCell={DEFAULT_MIN_WIDTH_CELL}
                defaultMaxWidthForCell={DEFAULT_MAX_WIDTH_CELL}
                tableProps={{ stickyHeader: true, tableContainerHeight: 650 }}
                order={columnOrder} // Order 'ASC' will be having values 'ASC' || 'DESC' || NULL
                orderBy={columnOrderBy}
                handleRequestSort={handleRequestSort}
                pagination={true}
                currentPage={currentPage}
                recordsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                recordsPerPage={recordsPerPage}
                totalPageCount={Pagecount}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                className="summaryViewTable"
            />
        </>
    );
}

export default TableView;
