export const customerModal = {
    party_name: "",
    contact_name: "",
    party_vatno: "",
    party_ein: "",
    password: "",
    contact_email: "",
    contact_phone: "",
    party_city: "",
    party_state: "",
    party_country: "",
    party_addr1: "",
    party_addr2: "",
    party_addr3: "",
    party_addr4: "",
    party_zipcode: "",
    ptype: "",
    partyid: "",
    invid: "",
    user: "",
    party_addr_id: "",
    isactive: "",
    pid: ""
}

export const ExportModal = {
    party_name: "",
    contact_name: "",
    party_vatno: "",
    party_ein: "",
    password: "",
    contact_email: "",
    contact_phone: "",
    party_city: "",
    party_state: "",
    party_country: "",
    party_addr1: "",
    party_addr2: "",
    party_addr3: "",
    party_addr4: "",
    party_zipcode: "",
    user: "",
    party_addr_id: "",
    ptype: "",
    invid: "",
    partyId: ""
}

export const ShippingModal = {
    inv_no: "",
    inv_date: "",
    ship_date: "",
    pid: "",
    user: "",
    isdg: "",
    category: "",
    ship_refno: "",
    invid: ""
}

export const getInvoiceDetail = () => {
    return {
        export: [],
        import: [],
        invoiceData: [],
        conTo: [],
        items: [],
        packing: [],
        ship: [],
        repository: [],
        msg: [],
        shipTrack: []
    }
}