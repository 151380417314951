import { Typography, Box } from "@material-ui/core";
import TableChart from "../../../Common/GoogleCharts/TableChart";

export default function Messages({tableData}) {
    return (
        <>
            <Box>
                <Typography align="left" className="reportTitle">Messages</Typography>
                <TableChart data={tableData} />
            </Box>
        </>
    )
}