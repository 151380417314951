export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'slno'
export const INV_Number = 'inv_no'
export const INV_DATE = 'inv_date'
export const INV_REFNO = 'ship_refno'
export const IMPORTER = 'importer'
export const EXPORTER = 'exporter'
export const IMPSTATE = 'impstate'
export const IMPCITY = 'impcity'
export const EXPCITY = 'expcity'
export const EXPSTATE = 'expstate'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'slno', width: 70, stickyColumn: 'left'},
    { id: INV_Number, isSortable: true, selected: true, label: 'Invoice No', fieldName: 'inv_no', width: 120},
    { id: INV_DATE, isSortable: true, selected: true, label: 'Invoice Date', fieldName: 'inv_date', width: 130},
    { id: INV_REFNO, isSortable: true, selected: true, label: 'Refrence No.', fieldName: 'ship_refno', width: 150 },
    { id: EXPORTER, isSortable: true, selected: true, label: 'Exporter', fieldName: 'exporter', width: 100},
    { id: IMPORTER, isSortable: true, selected: true, label: 'Importer', fieldName: 'importer', width: 90 },
    { id: EXPCITY, isSortable: true, selected: true, label: 'Exporter City', fieldName: 'expcity', width: 120 },
    { id: IMPCITY, isSortable: true, selected: true, label: 'Importer City', fieldName: 'impcity', width: 120 },
    { id: EXPSTATE, isSortable: true, selected: true, label: 'Exporter State', fieldName: 'expstate', width: 130 },
    { id: IMPSTATE, isSortable: true, selected: true, label: 'Importer State', fieldName: 'impstate', width: 120 }, 
]
