import { Paper, Grid, FormControl, Typography, TextField, makeStyles, Checkbox, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, forwardRef } from "react";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { invStatusModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calenderIcon from '../../../../../assets/calendarIcon.svg';

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        marginTop: '13px',
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    cancelButton: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    }
}))

const InvoiceStatus = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const imagePath = `url(${calenderIcon})`;
    const formValidator = useRef(new SimpleReactValidator({}));
    const [, forceUpdate] = useState();
    const [invStatusData, setInvStatusData] = useState([]);
    const [statusDate, setStatusDate] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [etaDate, setETADate] = useState(null);

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 233px/ 12px no-repeat`,
                width: '264px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleChangeInvStatus = (field, value) => {
        if (field === "invStatus") {
            const obj = {
                id: value.status_code,
                status_code: value.status_code,
                invstatus: value.invstatus
            }
            props.setSelectInvStatus(obj);
        } else if (field === "inv_status_date") {
            setStatusDate(value);
        } else if (field === "custView") {
            setIsChecked(value);
        } else if (field === "inv_eta_date") {
            setETADate(value);
        }
        setInvStatusData(values => ({ ...values, [field]: value }));
    }

    const handleSaveInvStatus = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...invStatusModal }
            payload.user = localStorage.getItem('userId');
            payload.invid = props.invid;
            payload.invStatus = props.selectInvStatus.status_code;
            payload.invEtaDate = moment(invStatusData.inv_eta_date).format('yyyy-MM-DD');
            payload.invStatusDate = moment(invStatusData.inv_status_date).format('yyyy-MM-DD');
            payload.comments = invStatusData.comments ? invStatusData.comments : "";
            payload.custView = isChecked ? "Y" : "N";
            props.addInvStatus(payload);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        navigate('/');
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                {localStorage.getItem('party_type') !== 'CST' ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="status">
                                            <span className={classes.mandatoryField}>*</span>
                                            Status
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        id="status-select"
                                        value={props.selectInvStatus}
                                        options={props.statusList}
                                        getOptionLabel={(option) => option.invstatus}
                                        getOptionSelected={(option, value) => value.status_code === option.status_code}
                                        onChange={(e, newValue) => handleChangeInvStatus("invStatus", newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Status"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {formValidator.current.message('Status', props.selectInvStatus, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="date">
                                            <span className={classes.mandatoryField}>*</span>
                                            Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        selected={statusDate}
                                        onChange={(date) => handleChangeInvStatus("inv_status_date", date)}
                                        customInput={<DateCustomInput />}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                    {formValidator.current.message('Date', statusDate, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="comment">
                                            Comment
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="comment"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        multiline
                                        minRows="3"
                                        placeholder="Comment"
                                        onChange={(e) => handleChangeInvStatus("comments", e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} style={{ marginTop: "45px" }}>
                                <FormControl fullWidth>
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="custView">
                                            Customer View
                                        </label>
                                    </Typography>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={(e) => handleChangeInvStatus("custView", e.target.checked)}
                                        color="primary"
                                        style={{ marginTop: '-27px', marginLeft: '-25px', background: "none" }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="eta">
                                            <span className={classes.mandatoryField}>*</span>
                                            ETA
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        selected={etaDate}
                                        onChange={(date) => handleChangeInvStatus("inv_eta_date", date)}
                                        customInput={<DateCustomInput />}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                    {formValidator.current.message('ETA', etaDate, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container wrap="wrap" className={classes.pageFooter}>
                            <Grid item xs={12}>
                                <Button variant="outlined" color="primary"
                                    className={classes.cancelButton}
                                    onClick={() => handleCancel()}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    onClick={() => handleSaveInvStatus()}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </> : <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="status">
                                        Status
                                    </label>
                                </Typography>
                                <TextField
                                    id="status"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Status"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="date">
                                        Date
                                    </label>
                                </Typography>
                                <TextField
                                    id="date"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="eta">
                                        ETA
                                    </label>
                                </Typography>
                                <TextField
                                    id="eta"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="ETA"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                }

            </Paper>
        </React.Fragment>
    )
}

export default InvoiceStatus;