// Layout.js

import React from 'react';
import Header from '../Header';
import { Routes } from 'react-router-dom';
import { useUserAuth } from '../Auth/context/UserAuthContext';

const Layout = ({ children, title }) => {

  const { user, isValidated } = useUserAuth();
  const userAuthenticated = !!user; // Check if user is authenticated

  return (
    <>
      {
        (userAuthenticated && isValidated) ||
          (localStorage.getItem('otherUserLogin') && localStorage.getItem('userId') !== null) ?
          <Header title={title} /> :
          null
      }
      <Routes>{children}</Routes>
    </>
  );
};

export default Layout;
