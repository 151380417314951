import { Grid, ButtonGroup, Tooltip, Button } from "@material-ui/core";
import { NODATA_MSG, dashboardStyle } from "../Common/constant";
import WorkOrderDash from "./components/WorkOrderDash";
import PurchaseOrderDash from "./components/PurchaseOrderDash";
import ShipmentDash from "./components/ShipmentDash";
import { useNavigate } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import '../css/datepicker.styles.css'
import { useState } from "react";
import { fetchPoGraphData, fetchShipmentGraph, fetchWOGraphData } from "../Common/API";
import moment from "moment";
import filterIcon from '../assets/filter.png';
import FilterPopover from "./components/FilterPopover";

const ClientDashboard = ({ setTitle }) => {
    setTitle("Manage Orders");
    localStorage.removeItem('wostatus');
    localStorage.removeItem('overdue');
    localStorage.removeItem('thisweek');
    localStorage.removeItem('wostatusname');
    localStorage.removeItem('wopendingthismonth');
    localStorage.removeItem('origin');
    localStorage.removeItem('category');
    localStorage.removeItem('carrier');
    const classes = dashboardStyle();
    const navigate = useNavigate();
    const [poCtryGraph, setPOCtryGraph] = useState([]);
    const [loading, setLoading] = useState({ isLoading: true, noDataMode: false, nodataMsg: NODATA_MSG });
    const [poCount, setPoCount] = useState([]);
    const [poPending, setPOPending] = useState([]);
    const [response, setResponse] = useState();
    const [woStatusGraph, setWoStatusGraph] = useState([]);
    const [woCount, setWoCount] = useState([]);
    const [eta, setETA] = useState([]);
    const [shipmentCategory, setShipmentCategory] = useState([]);
    const [shipmentCount, setShipmentCount] = useState([]);
    const [carrierData, setCarrierData] = useState([]);
    const [status, setStatus] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const To = new Date();
    const From = new Date(To.getFullYear() - 1, To.getMonth(), To.getDate());
    const filter = {
        "fromDate": moment(From).format('yyyy-MM-DD'),
        "toDate": moment(To).format('yyyy-MM-DD'),
        "user": localStorage.getItem('userId'),
    }

    const updateScreen = () => {
        navigate('/utility-activities');
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const selectFilter = (filter) => {
        fetchPoGraphData(filter).then(data => {
            if (data.status === "success") {
                setPOCtryGraph(data.pocountry);
                setPoCount(data.pocount[0]);
                setPOPending(data.poplanpending[0]);
            } else {
                setLoading({ ...loading, noDataMode: true, isLoading: false });
            }
        }).catch(error => {
            setLoading({ ...loading, noDataMode: true, isLoading: false });
        });

        fetchWOGraphData(filter).then(data => {
            if (data.wocount.wocount !== 0) {
                setWoStatusGraph(data.wostatus);
                setWoCount(data.wocount[0]);
                setETA(data.woeta);
                setResponse(data.status);
            } else {
                setLoading({ ...loading, noDataMode: true, isLoading: false });
            }
        }).catch(error => {
            setLoading({ ...loading, noDataMode: true, isLoading: false });
        });

        fetchShipmentGraph(filter).then(data => {
            if (data.status === "success") {
                setShipmentCategory(data.invcategory);
                setShipmentCount(data.invcount[0]);
                setCarrierData(data.carriercount);
                setStatus(data.status);
            } else {
                setLoading({ ...loading, noDataMode: true, isLoading: false });
            }
        }).catch(error => {
            setLoading({ ...loading, noDataMode: true, isLoading: false });
        })
    }

    const handleClickFilter = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget)
    }

    const renderDashboard = () => {
        return (
            <>
                <Grid container className={classes.dashboard}>
                    <Grid item container xs={12} className={`headingWrap clientUser`}>
                        <Grid item xs={2}>
                            <Tooltip title="Filter" arrow>
                                <img src={filterIcon} alt="filter" width="40px"
                                    onClick={(e) => handleClickFilter(e)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Tooltip>
                        </Grid>

                        <ButtonGroup className="roundedBtn" size="large" variant="outlined">
                            <Button color="primary" onClick={() => updateScreen("Utility")}>Utility Activities</Button>
                            <Button variant="contained" color="primary">Manage Orders</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item container xs={12}>
                        <PurchaseOrderDash poCtryGraph={poCtryGraph} poCount={poCount} setPoCount={setPoCount}
                            setPOCtryGraph={setPOCtryGraph} loading={loading} setLoading={setLoading} poPending={poPending}
                            setPOPending={setPOPending} filter={filter} selectFilter={selectFilter} />
                    </Grid>
                    <Grid item container xs={12}>
                        <WorkOrderDash woStatusGraph={woStatusGraph} loading={loading}
                            setWoStatusGraph={setWoStatusGraph} woCount={woCount} setWoCount={setWoCount}
                            setLoading={setLoading} eta={eta} setETA={setETA} filter={filter}
                            selectFilter={selectFilter} setResponse={setResponse} response={response} />
                    </Grid>
                    <Grid item container xs={12}>
                        <ShipmentDash shipmentCategory={shipmentCategory} setShipmentCategory={setShipmentCategory}
                            shipmentCount={shipmentCount} setShipmentCount={setShipmentCount}
                            loading={loading} setLoading={setLoading} carrierData={carrierData} status={status}
                            setCarrierData={setCarrierData} setStatus={setStatus} filter={filter} />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderDashboard()}
            {isOpenModal && (
                <FilterPopover
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    selectFilter={selectFilter} />
            )}
        </>
    )
}

export default ClientDashboard;