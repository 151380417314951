import React, { useState } from "react";
import { Table, TableBody, TableHead, makeStyles, TableCell, TextField, Typography, Tooltip, Grid, TableContainer, Paper, TableRow, Button, ButtonGroup } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Delete from '../../../../../assets/DeleteIcon.svg';
import DeleteConfirmationModal from "../../../../../Common/DeleteConfirmationModal";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableWrapper: {
        maxHeight: 499
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 190
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    pageNumber: {
        color: '#828282',
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-20px 20px 20px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    paginationUl: {
        '& ul': {
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-page.Mui-selected': {
                    color: theme.palette.primary.dark,
                    background: 'transparent',
                }
            }
        }
    },
    buttonRoot: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        border: 0,
        margin: `` + theme.spacing(.5) + `px 0 ` + theme.spacing(.5) + `px ` + theme.spacing(.5) + `px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
            position: 'absolute',
            right: -15,
            content: '""',
            top: 0,
            height: 24,
            textDecoration: 'none'
        },
        '&:hover': {
            background: 'none',
            color: '#2B0A3D',
            fontWeight: 600,
            border: 0,
            textDecoration: 'none'
        },
        '&.active': {
            color: '#2B0A3D',
            fontWeight: 600,
            textDecoration: 'none',
            '&:before': {
                backgroundColor: '#2B0A3D',
                left: 0,
                content: '""',
                bottom: -5,
                height: 2,
                position: 'absolute',
                width: '100%',
                textDecoration: 'none'
            }
        }
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
    },
    stickyPagination: {
        position: 'sticky',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        marginTop: theme.spacing(1.5),
        background: '#F3F3F3'
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            maxWidth: 220,
            fontFamily: "sans-serif",
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-20px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '35px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}));

const AWBNOs = (props) => {
    const classes = useStyles();
    const [activeSeletion, setActiveSeletion] = useState(2);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [newAWBData, setNewAWBData] = useState([]);
    const [awbnoid, setAWBNoId] = useState();
    const [awbnoData, setAwbnoData] = useState(props.awbList);
    const filteredData = props.awbList.filter(row => {
        return row;
    })

    const ROWS_PER_PAGE = [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: filteredData.length }
    ];
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE[0].value)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0].value);

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
        setActiveSeletion(1)
        setRowsPerPage(value);
        setPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setActiveSeletion(2)
        setPage(newPage);
    }

    const TotalRecords = filteredData.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const validate = (index) => {
        if (activeSeletion === 1) {
            // user clicks on show option recently
            return rowsPerPage > index;
        }
        if (activeSeletion === 2) {
            // user clicks on pagination numbers recently
            return (page === 1 ? 0 : (rowsPerPage * page) - rowsPerPage) <= index && index < (page === 1 ? 25 : (((page * rowsPerPage) - rowsPerPage) + rowsPerPage));
        }
    }

    const handleChangeAWB = (field, value, index) => {
        setNewAWBData([
            ...newAWBData.map((data, i) => {
                if (index === i) data[field] = value;
                return data;
            })
        ])
    }

    const handleUpdateAWB = (field, value, awbid) => {
        setAwbnoData([
            ...awbnoData.map(trackData => {
                if (trackData.trackshipid === awbid) trackData[field] = value;
                return trackData;
            }),
        ]);
    }

    const handleDeleteAWB = (trackshipid) => {
        setOpenDeleteModal(!openDeleteModal);
        setAWBNoId(trackshipid)
    }

    const handleDeleteRow = (index) => {
        const data = newAWBData.filter((_, i) => i !== index)
        setNewAWBData(data);
    }

    const handleAddRow = () => {
        const awbnoRow = {
            mawbno: "",
            hawbno: "",
            bno: ""
        }
        setNewAWBData([...newAWBData, awbnoRow]);
    }

    const handleModalClose = () => {
        setOpenDeleteModal(false);
    }

    const handleDelete = () => {
        props.deleteAWBNo(awbnoid)
        setOpenDeleteModal(false);
    }

    const newresult = newAWBData.map(trackItem => {
        return {
            trackshipid: 0,
            mawbno: trackItem.mawbno,
            hawbno: trackItem.hawbno,
            bno: trackItem.bno,
        }
    })

    const updatedResult = awbnoData.map(trackItem => {
        return {
            trackshipid: trackItem.trackshipid,
            mawbno: trackItem.mawbno,
            hawbno: trackItem.hawbno,
            bno: trackItem.bno,
        }
    })

    const handleSaveAWBNo = () => {
        const reqObj = [...newresult, ...updatedResult]
        props.saveAWBNo(reqObj);
    }

    const handleCancel = () => {

    }

    const renderUploadTable = () => {
        return (
            <React.Fragment>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        onClick={() => handleAddRow()}>
                        + Row
                    </Button>
                </Grid>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer className={classes.tableWrapper}>
                        <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className={classes.muteText}>MAWB/BL #</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>HAWB/HBL #</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>BO #</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Action</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newAWBData.map((row, index) => (
                                    <>
                                        {
                                            validate(index) && <TableRow key={row.trackshipid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        required
                                                        placeholder="AWB No."
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                        onChange={(e) => handleChangeAWB("mawbno", e.target.value, index)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        required
                                                        placeholder="HAWB No"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                        onChange={(e) => handleChangeAWB("hawbno", e.target.value, index)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        required
                                                        placeholder="BO No."
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                        onChange={(e) => handleChangeAWB("bno", e.target.value, index)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <Tooltip title="Delete" arrow>
                                                    <TableCell component="th" scope="row">
                                                        <img
                                                            src={Delete}
                                                            alt='delete'
                                                            style={{ cursor: 'pointer', marginLeft: "21px", marginTop: "21px" }}
                                                            onClick={() => handleDeleteRow(index)}
                                                        />
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                                {awbnoData.map((row, index) => (
                                    <>
                                        {
                                            validate(index) && <TableRow key={row.trackshipid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        placeholder="AWB No."
                                                        variant="outlined"
                                                        margin="normal"
                                                        value={row.mawbno}
                                                        className={classes.inputField}
                                                        onChange={(e) => handleUpdateAWB("mawbno", e.target.value, row.trackshipid)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        placeholder="HAWB No."
                                                        variant="outlined"
                                                        margin="normal"
                                                        value={row.hawbno}
                                                        className={classes.inputField}
                                                        onChange={(e) => handleUpdateAWB("hawbno", e.target.value, row.trackshipid)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        placeholder="BO No."
                                                        variant="outlined"
                                                        margin="normal"
                                                        value={row.bno}
                                                        onChange={(e) => handleUpdateAWB("bno", e.target.value, row.trackshipid)}
                                                        inputProps={{style: {padding: "10.5px 14px"}}}
                                                    />
                                                </TableCell>
                                                <Tooltip title="Delete" arrow>
                                                    <TableCell component="th" scope="row">
                                                        <img
                                                            src={Delete}
                                                            alt='delete'
                                                            style={{ cursor: 'pointer', marginLeft: "21px", marginTop: "21px" }}
                                                            onClick={() => handleDeleteAWB(row.trackshipid)}
                                                        />
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid container boxShadow={3} className={classes.stickyPagination} >
                            <Grid item xs={3}>
                                <Typography className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {Pagecount} </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Pagination className={[classes.paginationUl, { root: classes.pageNumber }]} count={Pagecount} showFirstButton showLastButton onChange={handleChangePage} />
                            </Grid>
                            <Grid container item xs={4} justifyContent='center'>
                                <Typography className={classes.paginationFont}>Show</Typography>
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    {ROWS_PER_PAGE.map(({ label, value }) => (
                                        <Button
                                            key={value}
                                            className={recordsPerPage === value && 'active'}
                                            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                                            onClick={() => handleChangeRowsPerPage(value)}>
                                            {label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </TableContainer>
                </Paper>
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleSaveAWBNo()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderUploadTable()}
            {openDeleteModal && <DeleteConfirmationModal
                handleModalClose={handleModalClose}
                openDeleteModal={openDeleteModal}
                handleDelete={handleDelete}
                type="bill no."
            />}
        </>
    )
}

export default AWBNOs;