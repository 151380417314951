import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { customerModal } from "../../constants";
import Cookies from "js-cookie";
import AddressPopupModal from "../../../common/AddressPopupModal";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    addrBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-20px 20px 20px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
}))

const Consignee = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [openAddrModal, setOpenAddrModal] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(props.consignee === undefined ? true : false);
    const formValidator = useRef(new SimpleReactValidator({}));

    const handleCancel = () => {
        navigate('/');
    }

    const handleChangeConsignee = (field, value) => {
        if (field === "party_country") {
            const obj = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname,
            }
            props.setImpCountry(obj);
        }
        props.setConsignee(values => ({ ...values, [field]: value }));
        setIsBtnDisable(false);
    }

    const handleSaveConsignee = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...customerModal };
            payload.party_name = props.consignee.party_name;
            payload.contact_email = props.consignee.contact_email ? props.consignee.contact_email : '';
            payload.contact_name = props.consignee.contact_name ? props.consignee.contact_name : '';
            payload.contact_phone = props.consignee.contact_phone;
            payload.party_addr1 = props.consignee.party_addr1 ? props.consignee.party_addr1 : '';
            payload.party_addr2 = props.consignee.party_addr2 ? props.consignee.party_addr2 : '';
            payload.party_city = props.consignee.party_city ? props.consignee.party_city : '';
            payload.party_state = props.consignee.party_state ? props.consignee.party_state : '';
            payload.party_zipcode = props.consignee.party_zipcode ? props.consignee.party_zipcode : '';
            payload.party_vatno = props.consignee.party_vatno ? props.consignee.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.ptype = "imp";
            payload.partyid = props.pid;
            payload.party_country = props.impCountry.ctrycode;
            payload.invid = props.invoiceId;
            if (props.addressId > 0) {
                payload.party_addr_id = props.addressId;
            } else {
                payload.party_addr_id = 0;
            }
            props.saveExportAddr(payload);
            setIsBtnDisable(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleOpenAddress = () => {
        setOpenAddrModal(!openAddrModal);
    }

    const renderConsignee = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    {localStorage.getItem('party_type') !== 'CST' ?
                        <>
                            <Grid container item xs={12} justifyContent="flex-end">
                                <Button variant="contained" color="primary"
                                    className={classes.addrBtn}
                                    onClick={() => handleOpenAddress()}>
                                    Select Address
                                </Button>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="party_name">
                                                <span className={classes.mandatoryField}>*</span>
                                                Party Name
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="partyName"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_name}
                                            onChange={(e) => handleChangeConsignee("party_name", e.target.value)}
                                            placeholder="Party Name"
                                        />
                                        {formValidator.current.message('Party Name', props.consignee?.party_name, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="contact_name">
                                                Contact Name
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="contact"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.contact_name}
                                            onChange={(e) => handleChangeConsignee("contact_name", e.target.value)}
                                            placeholder="Contact Name"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="telephone">
                                                <span className={classes.mandatoryField}>*</span>
                                                Telephone
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="telephone"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.contact_phone}
                                            onChange={(e) => handleChangeConsignee("contact_phone", e.target.value)}
                                            placeholder="Telephone"
                                        />
                                        {formValidator.current.message('Telephone', props.consignee?.contact_phone, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="email">
                                                Email
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="Email"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.contact_email}
                                            onChange={(e) => handleChangeConsignee("contact_email", e.target.value)}
                                            placeholder="Email"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="vat_no">
                                                VAT No.
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="VAT"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_vatno}
                                            onChange={(e) => handleChangeConsignee("party_vatno", e.target.value)}
                                            placeholder="VAT No."
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add1">
                                                Address 1
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add1"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_addr1}
                                            onChange={(e) => handleChangeConsignee("party_addr1", e.target.value)}
                                            placeholder="Address 1"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add2">
                                                Address 2
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add2"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_addr2}
                                            onChange={(e) => handleChangeConsignee("party_addr2", e.target.value)}
                                            placeholder="Address 2"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="city">
                                                City
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="city"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_city}
                                            onChange={(e) => handleChangeConsignee("party_city", e.target.value)}
                                            placeholder="City"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="state">
                                                State
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="state"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_state}
                                            onChange={(e) => handleChangeConsignee("party_state", e.target.value)}
                                            placeholder="State"
                                            inputProps={{ maxLength: 3 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="zip">
                                                Zip
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="zip"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignee?.party_zipcode}
                                            onChange={(e) => handleChangeConsignee("party_zipcode", e.target.value)}
                                            placeholder="Zip"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="country">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="country-select"
                                            value={props.impCountry}
                                            options={props.countries}
                                            getOptionLabel={(option) => option.ctrycname}
                                            getOptionSelected={(option, value) => value?.ctrycode === option?.ctrycode}
                                            onChange={(e, newValue) => handleChangeConsignee("party_country", newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {formValidator.current.message('Country', props.impCountry, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container wrap="wrap" className={classes.pageFooter}>
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="primary"
                                        className={classes.cancelButton}
                                        onClick={() => handleCancel()}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" color="primary"
                                        className={classes.applyBtn}
                                        disabled={isBtnDisable}
                                        onClick={() => handleSaveConsignee()}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </> :
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="partyName"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Party Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Contact Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.contact_name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Contact Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="telephone">
                                            Telephone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="telephone"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.contact_phone}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Telephone"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="email">
                                            Email
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Email"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.contact_email}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vat_no">
                                            VAT No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="VAT"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_vatno}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="VAT No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_addr1}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_addr2}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_city}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_state}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="State"
                                        inputProps={{ maxLength: 3 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_zipcode}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Zip"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            Country
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignee?.party_country}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Country"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderConsignee()}
            {openAddrModal && (
                <AddressPopupModal
                    openAddrModal={openAddrModal}
                    setOpenAddrModal={setOpenAddrModal}
                    addressList={props.addressList}
                    setConsignee={props.setConsignee}
                    setConsignedTo=""
                    setCountry={props.setImpCountry}
                    ctryName={props.countries}
                    type="imp"
                />
            )}
        </>
    )
}

export default Consignee;