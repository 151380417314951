import { Dialog, DialogTitle, Paper, Grid, TextField, FormControl, Typography, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import Cookies from "js-cookie";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
    }
}))

const PartyScanPopupModal = ({ setOpenScanModal, openScanModal, pid, updatePartyScan, setCount, count }) => {
    const formValidator = useRef(new SimpleReactValidator({}));
    const [remarks, setRemarks] = useState();
    const classes = useStyles();
    const [, forceUpdate] = useState();

    const handleCancel = () => {
        setOpenScanModal(false);
    }

    const handleUpdateScan = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const reqObj = { 'statuscode': '', 'type': 'manual', "matches": count, 'remarks': remarks, 'party_id': pid, 'user': localStorage.getItem('userId') }
            updatePartyScan(reqObj);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Dialog open={openScanModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9" }}>Update Scan</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="scan_count">
                                        <span className={classes.mandatoryField}>*</span>
                                        Number of Matches
                                    </label>
                                </Typography>
                                <TextField
                                    id="scan_count"
                                    color="primary"
                                    variant="outlined"
                                    value={count}
                                    onChange={(e) => setCount(e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Number of Matches"
                                />
                                {formValidator.current.message('Scan Count', count, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="remarks">
                                    <span className={classes.mandatoryField}>*</span>
                                    Remarks
                                </label>
                            </Typography>
                            <TextField
                                id="desc"
                                color="primary"
                                variant="outlined"
                                multiline
                                minRows="3"
                                placeholder="Remarks"
                                className={classes.inputField}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                            {formValidator.current.message('Scan Count', remarks, 'required', { className: classes.validateStyle })}
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdateScan} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PartyScanPopupModal;