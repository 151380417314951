import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import {Grid, Typography, ButtonGroup, Button} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    sidePadding:{
        padding: `0 ${theme.spacing(3)}px`
      },
      root: {
        boxShadow: 'none'
      },
      buttonRoot: {
        fontSize: 14,
        border: 0,
        margin: ``+theme.spacing(.5)+`px 0 `+theme.spacing(.5)+`px `+theme.spacing(.5)+`px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
          position: 'absolute',
          right: -15,
          content: '""',
          top: 0,
          height: 24,
          textDecoration: 'none'
        },
        '&:hover': {
          background: 'none',
          color: '#2B0A3D',
          fontWeight: 600,
          border: 0,
          textDecoration: 'none'
        },
        '&.active': {
          color: '#2B0A3D',
          fontWeight: 600,
          textDecoration: 'none',
          '&:before': {
            backgroundColor: '#2B0A3D',
            left: 0,
            content: '""',
            bottom: -5,
            height: 2,
            position: 'absolute',
            width: '100%',
            textDecoration: 'none'
          }
        }
      },
    stickyPagination:{
        position: 'sticky',
        background: theme.palette.secondary.main,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        boxShadow: theme.shadows[1],
        zIndex: 99,
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
      },
    pageNum: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        }
      },
      pageNumber: {
        color: '#828282',
      },
      paginationUl:{
        '& ul':{
          justifyContent: 'center',
          '& button':{
            color: theme.palette.primary.main,
            '&.MuiPaginationItem-page.Mui-selected':{
              color: theme.palette.primary.dark,
              background: 'transparent',
            }
          }
        }
      }
    })
)
export const TablePagination = (props) => {
    const classes = useStyles();
    const {pagecount, recordsPerPageOptions, handleChangeRowsPerPage, handleChangePage, recordsPerPage, currentPage} = props

    return (
        <Grid container boxShadow={3} className={[classes.sidePadding, classes.stickyPagination]} >
        <Grid item xs={3}>
          <Typography  className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {pagecount} </Typography>
        </Grid>
        <Grid item xs={5}>
          <Pagination className={[classes.paginationUl, {root: classes.pageNumber}]} count={pagecount}  showFirstButton showLastButton onChange={handleChangePage} />
        </Grid>
        <Grid container item xs={4} justify="flex-end">
          <Typography className={classes.paginationFont}>Show</Typography>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {recordsPerPageOptions.map(({ label, value }) => (
              <Button
                key={value}
                className={recordsPerPage === value && 'active'}
                classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                onClick={() => handleChangeRowsPerPage(value)}>
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    )
}