import { Grid, Button, FormControl, makeStyles, Typography, TextField, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ffrModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import { fecthBlacklistCustomer } from "../../../../../Common/API";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '10px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-20px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-15px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    errorMessge: {
        color: '#af5858',
        fontSize: 16,
        border: 'solid 1px #af5858',
        margin: 15,
        padding: 15
    },
}))

const FreightForwarder = (props) => {
    const classes = useStyles();
    const [fForwarderData, setFForwarderData] = useState(props.ffData);
    const [isNewFF, setIsNewFF] = useState(false);
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [country, setCountry] = useState([]);
    const [partyName, setPartyName] = useState([]);
    const [matches, setMatches] = useState();
    const [isEnable, setIsEnable] = useState(true);
    const formValidator = useRef(new SimpleReactValidator({}));
    const validateParty = useRef(new SimpleReactValidator({}));

    useEffect(() => {
        setPartyName(props.fforwarder.find(x => x.pid === props.ffData.pid));
    }, [])

    const handleSelectFF = () => {
        setIsNewFF(false);
        setFForwarderData(ffrModal);
        setIsEnable(true);
        setMatches();
    }

    const handleChangeFF = (field, value) => {
        if (field === "party_country") {
            let newObject = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setCountry(newObject);
        }
        setFForwarderData(values => ({ ...values, [field]: value }));
    }

    const handleChangePartyName = (value) => {
        const obj = {
            id: value.pid,
            party_name: value.party_name,
            pid: value.pid
        }
        setPartyName(obj);
        props.setPartyId(obj.pid);
        setFForwarderData(props.fforwarder.find(x => x.pid === value.pid))
    }

    const handleAddFF = () => {
        setIsNewFF(true);
        setPartyName([]);
        setFForwarderData(ffrModal);
        setIsEnable(true);
        setMatches();
    }

    const handleSaveFF = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...ffrModal }
            payload.party_name = fForwarderData.party_name;
            payload.contact_email = fForwarderData.contact_email;
            payload.contact_phone = fForwarderData.contact_phone ? fForwarderData.contact_phone : '';
            payload.contact_name = fForwarderData.contact_name ? fForwarderData.contact_name : '';
            payload.party_addr1 = fForwarderData.party_addr1 ? fForwarderData.party_addr1 : '';
            payload.party_addr2 = fForwarderData.party_addr2 ? fForwarderData.party_addr2 : '';
            payload.party_city = fForwarderData.party_city ? fForwarderData.party_city : '';
            payload.party_state = fForwarderData.party_state ? fForwarderData.party_state : '';
            payload.party_zipcode = fForwarderData.party_zipcode ? fForwarderData.party_zipcode : '';
            payload.party_ein = fForwarderData.party_ein ? fForwarderData.party_ein : '';
            payload.party_vatno = fForwarderData.party_vatno ? fForwarderData.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.password = fForwarderData.password;
            payload.party_country = country.ctrycode;
            payload.woid = props.woid;
            props.addFreightForwarder(payload);
            setIsEnable(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleUpdateFF = () => {
        const isValid = validateParty.current.allValid();
        if (isValid) {
            const payload = { ...ffrModal }
            payload.party_name = partyName.party_name ? partyName.party_name : fForwarderData.party_name;
            payload.contact_email = fForwarderData.contact_email;
            payload.contact_phone = fForwarderData.contact_phone ? fForwarderData.contact_phone : '';
            payload.contact_name = fForwarderData.contact_name ? fForwarderData.contact_name : '';
            payload.party_addr1 = fForwarderData.party_addr1 ? fForwarderData.party_addr1 : '';
            payload.party_addr2 = fForwarderData.party_addr2 ? fForwarderData.party_addr2 : '';
            payload.party_city = fForwarderData.party_city ? fForwarderData.party_city : '';
            payload.party_state = fForwarderData.party_state ? fForwarderData.party_state : '';
            payload.party_zipcode = fForwarderData.party_zipcode ? fForwarderData.party_zipcode : '';
            payload.party_ein = fForwarderData.party_ein ? fForwarderData.party_ein : '';
            payload.party_vatno = fForwarderData.party_vatno ? fForwarderData.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.password = fForwarderData.password;
            payload.party_country = country.ctrycode;
            payload.ffpid = partyName.pid ? partyName.pid : fForwarderData.pid;
            payload.woid = props.woid;
            props.ffrUpdate(payload);
            setIsEnable(false);
        } else {
            validateParty.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        navigate('/');
    }

    const handleCheckBlackList = () => {
        const payload = {
            "search": {
                "securekey": "dMvMAaml7DdsJN7Y0MHcgIrqhB9d5qOb7fAnq",
                "filters": {
                    "fuzzylogic": "false",
                    "dplguruforensic": "false",
                    "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
                    "exclusionwords": "pvt",
                    "redflag": "military"
                },
                "itemstosearch": [
                    {
                        "name": fForwarderData.party_name,
                        "address": fForwarderData.party_addr1 + ' ' + fForwarderData.party_addr2,
                        "country_code": country.ctrycode ? country.ctrycode : fForwarderData.party_country
                    }
                ]
            }
        }

        fecthBlacklistCustomer(payload).then(data => {
            setMatches(data.summary.matches);
            const req = { "party_id": props.partyid ? props.partyid : fForwarderData.pid, 'type': 'api', 'matches': data.summary.matches, 'remarks': '', 'user': localStorage.getItem('userId'), 'statuscode': data.status.statuscode }
            props.addScanParty(req);
        }).catch(error => {

        })
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        disabled={isEnable}
                        onClick={() => handleCheckBlackList()}>
                        Scan Blacklist Customer
                    </Button>
                    {isNewFF ? <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        onClick={() => handleSelectFF()}>
                        Select FF
                    </Button> : <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        onClick={() => handleAddFF()}>
                        + FF
                    </Button>}
                </Grid>
                {isNewFF ? (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="party_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Party Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="party_name"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_name}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_name", e.target.value)}
                                    placeholder="Party Name"
                                />
                                {formValidator.current.message('Party Name', fForwarderData.party_name, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact_name">
                                        Contact Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="contact"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.contact_name}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("contact_name", e.target.value)}
                                    placeholder="Contact Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="vat_no">
                                        VAT No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="VAT"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_vatno}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_vatno", e.target.value)}
                                    placeholder="VAT No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ein_no">
                                        EIN No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="EIN"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_ein}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_ein", e.target.value)}
                                    placeholder="EIN No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        <span className={classes.mandatoryField}>*</span>
                                        Email
                                    </label>
                                </Typography>
                                <TextField
                                    id="Email"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.contact_email}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("contact_email", e.target.value)}
                                    placeholder="Email"
                                />
                                {formValidator.current.message('Email', fForwarderData.contact_email, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="password">
                                        <span className={classes.mandatoryField}>*</span>
                                        Password
                                    </label>
                                </Typography>
                                <TextField
                                    id="Password"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.password}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("password", e.target.value)}
                                    placeholder="Password"
                                />
                                {formValidator.current.message('Password', fForwarderData.password, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add1">
                                        Address 1
                                    </label>
                                </Typography>
                                <TextField
                                    id="add1"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_addr1}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_addr1", e.target.value)}
                                    placeholder="Address 1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add2">
                                        Address 2
                                    </label>
                                </Typography>
                                <TextField
                                    id="add2"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_addr2}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_addr2", e.target.value)}
                                    placeholder="Address 2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="city">
                                        City
                                    </label>
                                </Typography>
                                <TextField
                                    id="city"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_city}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_city", e.target.value)}
                                    placeholder="City"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="state">
                                        State
                                    </label>
                                </Typography>
                                <TextField
                                    id="state"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_state}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_state", e.target.value)}
                                    placeholder="State"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="zip">
                                        Zip
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_zipcode}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("party_zipcode", e.target.value)}
                                    placeholder="ZIP"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="country">
                                        <span className={classes.mandatoryField}>*</span>
                                        Country
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    value={country}
                                    id="country-select"
                                    options={props.countries}
                                    getOptionLabel={(option) => option.ctrycname || ""}
                                    getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                    onChange={(e, newValue) => handleChangeFF("party_country", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {formValidator.current.message('Country', country, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="telephone">
                                        Telephone
                                    </label>
                                </Typography>
                                <TextField
                                    id="telephone"
                                    color="primary"
                                    variant="outlined"
                                    value={props.ffData.contact_phone}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeFF("contact_phone", e.target.value)}
                                    placeholder="Telephone"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            {matches ? (
                                <p className={classes.errorMessge}>
                                    No of Matches : {matches}, Type : Api
                                </p>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="party_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Party Name
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    value={partyName}
                                    id="party-name-select"
                                    options={props.fforwarder}
                                    getOptionLabel={(option) => option.party_name || ""}
                                    getOptionSelected={(option, value) => value?.pid === option?.pid}
                                    onChange={(e, newValue) => handleChangePartyName(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Party Name"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {validateParty.current.message('Party Name', partyName, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact_name">
                                        Contact Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="contact"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.contact_name}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="Contact Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="vat_no">
                                        VAT No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="VAT"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_vatno}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="VAT No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ein_no">
                                        EIN No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="EIN"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_ein}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="EIN No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        <span className={classes.mandatoryField}>*</span>
                                        Email
                                    </label>
                                </Typography>
                                <TextField
                                    id="Email"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.contact_email}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="Email"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="password">
                                        <span className={classes.mandatoryField}>*</span>
                                        Password
                                    </label>
                                </Typography>
                                <TextField
                                    id="Password"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.password}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="Password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add1">
                                        Address 1
                                    </label>
                                </Typography>
                                <TextField
                                    id="add1"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_addr1}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="Address 1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add2">
                                        Address 2
                                    </label>
                                </Typography>
                                <TextField
                                    id="add2"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_addr2}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="Address 2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="city">
                                        City
                                    </label>
                                </Typography>
                                <TextField
                                    id="city"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_city}
                                    className={classes.inputField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    placeholder="City"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="state">
                                        State
                                    </label>
                                </Typography>
                                <TextField
                                    id="state"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_state}
                                    className={classes.inputField}
                                    placeholder="State"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="zip">
                                        Zip
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    value={fForwarderData.party_zipcode}
                                    className={classes.inputField}
                                    placeholder="Zip"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="country">
                                        <span className={classes.mandatoryField}>*</span>
                                        Country
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    value={props.countries.find(x => x.ctrycode === fForwarderData.party_country)?.ctrycname}
                                    className={classes.inputField}
                                    placeholder="Country"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="telephone">
                                        Telephone
                                    </label>
                                </Typography>
                                <TextField
                                    id="telephone"
                                    color="primary"
                                    variant="outlined"
                                    value={props.ffData.contact_phone}
                                    className={classes.inputField}
                                    placeholder="Telephone"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            {matches ? (
                                <p className={classes.errorMessge}>
                                    No of Matches : {matches}, Last Scan Date : {props.ffData?.scandate}, Type : Api
                                </p>
                            ) : (props.ffData?.scandate === null) ? <p className={classes.errorMessge}>No scan found</p> :
                                props.ffData?.scandate !== undefined ? <p className={classes.errorMessge}>Last Scan Date : {props.ffData?.scandate}</p> : null}
                        </Grid>
                    </Grid>
                )}
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        {isNewFF ? (
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                onClick={() => handleSaveFF()}>
                                Save
                            </Button>
                        ) : <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleUpdateFF()}>
                            Update
                        </Button>}

                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default FreightForwarder;