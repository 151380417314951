import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useUserAuth } from "./context/UserAuthContext";
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from "./Style";
import g4scm_Logo from '../assets/g4scm_logo.png';
import google from '../assets/google-plus.png';
import twitter from '../assets/twitter.png';
import facebbok from '../assets/facebook.png';
import { Typography, Card, CardContent, TextField, InputAdornment } from "@material-ui/core";
import { FacebookAuthProvider, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { validateLoginEmail } from "../Common/API";
import Cookies from "js-cookie";
import { SpinnerCircularFixed } from "spinners-react";

const Login = () => {
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const { logIn, user, googleSignIn, isValidated, loginError, loginLoading, infoMsg, initialError, initialLoading } = useUserAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await logIn(email);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      const googlelog = await googleSignIn();
      validateLoginEmail(googlelog.user.email).then(data => {
        if (data.status === 'success') {
          Cookies.set('userId', data.userid);
          Cookies.set("party_type", data.usertype);
          Cookies.set("party_id", data.customerid);
          if (data.usertype !== 'CST') {
            navigate('/manage-orders');
          } else {
            navigate('/customer-dashboard');
          }
        } else {
          navigate("/");
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFacebookSignIn = async (e) => {
    e.preventDefault();
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(provider);
      validateLoginEmail(result.user.email).then(data => {
        if (data.status === 'success') {
          Cookies.set('userId', data.userid);
          Cookies.set("party_type", data.usertype);
          Cookies.set("party_id", data.customerid);
          if (data.usertype !== 'CST') {
            navigate('/manage-orders');
          } else {
            navigate('/customer-dashboard');
          }
        } else {
          navigate("/");
        }
      });
      console.log('Facebook Login Successful:', result.user);
    } catch (error) {
      console.error('Facebook Login Error:', error);
    }
  };

  const handleTwitterSignIn = async (e) => {
    e.preventDefault();
    const provider = new TwitterAuthProvider();
    try {
      const result = await signInWithPopup(provider);
      validateLoginEmail(result.user.email).then(data => {
        if (data.status === 'success') {
          Cookies.set('userId', data.userid);
          Cookies.set("party_type", data.usertype);
          Cookies.set("party_id", data.customerid);
          if (data.usertype !== 'CST') {
            navigate('/manage-orders');
          } else {
            navigate('/customer-dashboard');
          }
        } else {
          navigate("/");
        }
      });
      console.log('Twitter Login Successful:', result.user);
    } catch (error) {
      console.error('Twitter Login Error:', error);
    }
  };

  return (
    <>
      <div className={classes.screen}>
        {initialLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {initialError !== '' ? (
              <div className='error-msg'>{initialError}</div>
            ) : (
              <>
                {/* We are checking user because for a split second, we will not have user */}
                {user ? (
                  // so instead of seeing form, I am using this please wait msg
                  <SpinnerCircularFixed />
                ) : (
                  // for a split second we will see this form
                  <Card variant="none" className={classes.card}>
                    <CardContent className={classes.content}>
                      <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                        Welcome To
                        <br />
                        <img src={g4scm_Logo} style={{ height: '40px', marginTop: '10px' }} alt="g4pl Logo" />
                      </Typography>
                      <form onSubmit={handleLogin}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          className={classes.button}
                        >
                          Sign In
                        </Button>
                        {loginError !== '' && (
                          <div style={{ color: 'red', marginTop: 4 }}>{loginError}</div>
                        )}

                        {/* show info msg */}
                        {infoMsg !== '' && (
                          <div style={{ color: 'green', marginTop: 4 }}>{infoMsg}</div>
                        )}
                        <Typography className={classes.paragraph}>
                          Don't have an account ? <Link to='/' className={classes.link}>Contact G4SCM</Link>
                        </Typography>
                        <div className={classes.dividerContainer}>
                          <div className={classes.dividerLine}></div>
                          <div className={classes.dividerText}>OR</div>
                          <div className={classes.dividerLine}></div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>Continue with social media</Typography>
                          <img src={facebbok} style={{ height: '44px', cursor: 'pointer' }} alt="facebook Logo" onClick={handleFacebookSignIn} />
                          <img src={google} style={{ height: '40px', marginLeft: '10px', cursor: 'pointer' }} alt="google Logo"
                            onClick={handleGoogleSignIn} />
                          <img src={twitter} style={{ height: '40px', marginLeft: '12px', cursor: 'pointer' }} alt="twitter Logo"
                            onClick={handleTwitterSignIn} />
                        </div>
                      </form>
                    </CardContent>
                    {/* <div className={classes.content2}>
                      <div className={classes.content3} />
                      <Typography variant="h7">
                        We help companies save money and avoid costly errors in their global logistics operations by
                        implementing innovative logistics solutions with immediate, measurable results.
                        We assist either on a project basis or as a result of the ongoing outsourced resources relationship.
                      </Typography>
                    </div> */}
                  </Card>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Login;
