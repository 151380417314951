import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { errorHandler, Fallback } from "../Common/Fallback";
import { POSummaryContext } from "./components/context/POSummaryContext";
import filterIcon from '../assets/filter.png';
import { useState } from "react";
import downloadIcon from '../assets/download.png';
import { downloadPOList } from "../Common/API";
import { saveAs } from "file-saver";
import moment from "moment";
import FilterPopover from "./components/FilterPopover";
import { useContext } from "react";
import TableView from './components/TableView';
import addPo from '../assets/add.png';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    },
    topItems: {
        gap: 10
    },
    filterIcon: {
        width: "40px",
        cursor: 'pointer'
    }
}));

const PurchaseOrder = ({ setTitle }) => {
    const classes = useStyles();
    setTitle('Purchase Order');
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const To = new Date();
    const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
    const [expFilter, setExportFilter] = useState({
        "fromdate": moment(From).format('yyyy-MM-DD'),
        "todate": moment(To).format('yyyy-MM-DD'),
        "user": localStorage.getItem('userId'),
        "type": 'D'
    });
    const { headCells, summaryList, filterPOSummary } = useContext(POSummaryContext);

    const handleClickDownload = () => {
        expFilter['type'] = "D"
        downloadPOList(expFilter).then((res) => {
            if (res) {
                const Filename = 'po-data_' + moment(new Date()).format('yyyy-MM-DD');
                saveAs(new Blob([res]), `${Filename}.xls`);
            }
        }).catch((error) => {

        });
    }

    const handleClick = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const handleClickCreate = () => {
        navigate('/create-purchase-order');
    }

    const renderSummary = () => {
        return (
            <>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Summary</Typography>
                        </Grid>
                        <Grid item xs={8} container className="d-flex align-center">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className={classes.topItems}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <Tooltip title="Create PO" arrow>
                                        <img src={addPo} alt="filter" className={classes.filterIcon}
                                            onClick={() => handleClickCreate()}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Download" arrow>
                                        <img src={downloadIcon} alt="filter" className={classes.filterIcon}
                                            onClick={() => handleClickDownload()}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Filter" arrow>
                                        <img src={filterIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClick(e)}
                                        />
                                    </Tooltip>
                                </ErrorBoundary>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <TableView summaryList={summaryList} headCells={headCells} />
                    </ErrorBoundary>
                </Grid>
            </>
        );
    }

    return (
        <>
            {renderSummary()}
            {isOpenModal &&
                <FilterPopover
                    isOpenModal={isOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filterPOSummary={filterPOSummary}
                    setExportFilter={setExportFilter}
                />
            }
        </>
    )
}

export default PurchaseOrder;