import { Dialog, Grid, Typography, FormControl, DialogActions, makeStyles, DialogTitle, DialogContent, TextField, Paper, Button } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import { useState, useRef } from "react";
import { cartonModal } from "../../../../../../../Common/constant";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    }
}))

const CartonPopupModal = ({ isOpenCartonModal, setIsOpenCartonModal, containerData, saveCartonsFF, woid }) => {
    const formValidator = useRef(new SimpleReactValidator({}));
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [cartonData, setCartonData] = useState([]);

    const handleChangeCartons = (field, value) => {
        setCartonData(values => ({ ...values, [field]: value }));
    }

    const handleAddCarton = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = {...cartonModal}
            payload.container_no = cartonData.container_no;
            payload.prod_qty = containerData.prod_code;
            payload.receiptno = containerData.receiptno;
            payload.dim_cube = cartonData.dim_cube ? cartonData.dim_cube : "";
            payload.dim_height = cartonData.dim_height ? cartonData.dim_height : cartonData.dim_height;
            payload.dim_length = cartonData.dim_length ? cartonData.dim_length : "";
            payload.dim_uom = cartonData.dim_uom ? cartonData.dim_uom : ""; 
            payload.dim_width = cartonData.dim_width ? cartonData.dim_width : "";
            payload.package_type = cartonData.package_type ? cartonData.package_type : "Carton"; 
            payload.pkg_nos = cartonData.pkg_nos ? cartonData.pkg_nos : ""; 
            payload.weight_gross = cartonData.weight_gross ? cartonData.weight_gross : "";
            payload.weight_uom = cartonData.weight_uom ? cartonData.weight_uom : "KG";
            payload.weight_net = cartonData.weight_net ? cartonData.weight_net : "";
            payload.user = localStorage.getItem('userId');
            payload.woitemid = containerData.woitemid;
            payload.woid = woid;
            payload.prod_qty = cartonData.prod_qty;
            saveCartonsFF(payload);
            setIsOpenCartonModal(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }       
    }

    const handleCancel = () => {
        setIsOpenCartonModal(false);
    }

    return (
        <Dialog open={isOpenCartonModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif !important' }}>Add Carton</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                    <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="item_code">
                                        Item Code
                                    </label>
                                </Typography>
                                <TextField
                                    id="item_code"
                                    color="primary"
                                    variant="outlined"
                                    disabled={true}
                                    value={containerData?.prod_code}
                                    className={classes.inputField}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="rct_no">
                                        Receipt No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="rct_no"
                                    color="primary"
                                    variant="outlined"
                                    disabled={true}
                                    value={containerData?.receiptno}
                                    className={classes.inputField}
                                />
                            </FormControl>
                        </Grid>
                    <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="package_type">
                                        Package Type
                                    </label>
                                </Typography>
                                <TextField
                                    id="package_type"
                                    color="primary"
                                    variant="outlined"
                                    value={cartonData.package_type ? cartonData.package_type : "Carton"}
                                    onChange={(e) => handleChangeCartons('package_type', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Contact Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="container_no">
                                    <span className={classes.mandatoryField}>*</span>
                                        Container No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="container_no"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('container_no', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Container No."
                                />
                                {formValidator.current.message('Container no.', cartonData.container_no, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="prod_qty">
                                    <span className={classes.mandatoryField}>*</span>
                                        Quantity
                                    </label>
                                </Typography>
                                <TextField
                                    id="prod_qty"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('prod_qty', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Quantity"
                                />
                                {formValidator.current.message('Quantity', cartonData.prod_qty, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="pkg_nos">
                                        No. of Packages
                                    </label>
                                </Typography>
                                <TextField
                                    id="pkg_nos"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('pkg_nos', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="No. of Packages"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dim_uom">
                                        Dimension UOM
                                    </label>
                                </Typography>
                                <TextField
                                    id="dim_uom"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('dim_uom', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Dimension UOM"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="length">
                                        Length
                                    </label>
                                </Typography>
                                <TextField
                                    id="length"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('dim_length', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Length"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="width">
                                        Width
                                    </label>
                                </Typography>
                                <TextField
                                    id="width"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('dim_width', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Width"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="height">
                                        Height
                                    </label>
                                </Typography>
                                <TextField
                                    id="height"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('dim_height', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Height"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="wt_uom">
                                        Weight UOM
                                    </label>
                                </Typography>
                                <TextField
                                    id="wt_uom"
                                    color="primary"
                                    variant="outlined"
                                    value={cartonData.weight_uom ? cartonData.weight_uom : "KG"}
                                    onChange={(e) => handleChangeCartons('weight_uom', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Weight UOM"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="gross_wt">
                                        Gross Weight
                                    </label>
                                </Typography>
                                <TextField
                                    id="gross_wt"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('weight_gross', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Gross Weight"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="weight_net">
                                        Net Weight
                                    </label>
                                </Typography>
                                <TextField
                                    id="weight_net"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('weight_net', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Net Weight"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="cube">
                                        Cube
                                    </label>
                                </Typography>
                                <TextField
                                    id="cube"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeCartons('dim_cube', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Cube"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddCarton} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CartonPopupModal;