import { Typography, Grid, Box, Paper, Tab, Tabs, AppBar, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { errorHandler, Fallback } from "../../../Common/Fallback";
import { fetchCustomer, fetchCountry, saveCustomerAddr, saveComInv, fetchCustomerDetails, fetchInvoiceDetails, saveFileUpload, saveShipmentDetails, deleteFile, saveShipmentMessage, fetchFedexRates, validateGenarateLabel, saveInvItem, fetchInvoiceAddress, generateLabelPdf, saveInvoiceStatus, fetchInvStatusList, fecthBlacklistCustomer, fetchTrackShipment, deleteShipment, updateScan } from '../../../Common/API';
import ShipmentDetail from "./components/ShipmentDetail";
import Exporter from "./components/Exporter";
import Consignee from "./components/Consignee";
import ConsignedTo from "./components/ConsignedTo";
import FileUpload from "./components/FileUpload";
import Shipping from "./components/Shipping";
import { useSnackbar } from 'react-simple-snackbar';
import { OPTIONS, OPTIONS3, purchaseOrderStyle } from "../../../Common/constant";
import PackingList from "./components/PackingList";
import ProductDetails from "./components/ProductDetails";
import ChooseShipment from "./components/ChooseShipment";
import Cookies from "js-cookie";
import { getInvoiceDetail } from "./constants";
import Messages from "./components/Messages";
import { useParams } from "react-router-dom";
import InvoiceStatus from "./components/InvoiceStatus";
import sIcon from '../../../assets/sIcon.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labeledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box p={index === 3 ? 0 : 3}>
                    <Typography component="div">
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    )
}

const CreateShipment = ({ setTitle }) => {
    setTitle('Shipments')
    const classes = purchaseOrderStyle();
    const userId = localStorage.getItem('userId');
    const { invid } = useParams();
    const [customers, setCustomers] = useState([]);
    const [countries, setCountries] = useState([]);
    const [invoiceId, setInvoiceId] = useState();
    const [comInvDetail, setComInvDetail] = useState(getInvoiceDetail);
    const [successSnackbar] = useSnackbar(OPTIONS);
    const [amberSnackbar] = useSnackbar(OPTIONS3);
    const [consignedTo, setConsignedTo] = useState([]);
    const [fedexRates, setFedexRates] = useState({
        isLoading: false,
        ratesList: []
    });
    const [shipping, setShipping] = useState([]);
    const [partyName, setPartyName] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [selectInvStatus, setSelectInvStatus] = useState([]);
    const [exporterData, setExporterData] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [consignee, setConsignee] = useState([]);
    const [partyId, setPartyId] = useState([]);
    const [shipmentMsg, setShipmentMsg] = useState([]);
    const [expCountry, setExpCountry] = useState([]);
    const [impCountry, setImpCountry] = useState([]);
    const [conCountry, setConCountry] = useState([]);
    const [category, setCategory] = useState([]);
    const [isDangGood, setIsDangGood] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [updateItems, setUpdateItems] = useState([]);
    const [itemList, setItemList] = useState([{
        id: 0,
        prod_qty: "",
        prod_name: "",
        prod_eccn: "",
        prod_hts: "",
        prod_htc: "",
        prod_origin_country: "",
        prod_unit_price: "",
        prod_qty_uom: "",
        prod_net_wt: "",
        prod_packages: "",
        prod_code: "",
        batterymaterialtype: "",
        batterypackingtype: "",
        alcrecipienttype: "",
        total: ""
    }]);

    const dangerousGoods = [{ id: "A", name: "Alcohol" },
    { id: "B", name: "Battery" }, { id: "D", name: "Dry Ice", }]

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        fetchCustomerList();
        fetchCountryList();
        if (invid) {
            (async () => {
                await fetchInvoiceDetails(invid).then((res) => {
                    setIsDangGood(res.invoice.isdg === "Y" ? true : false);
                    setCategory(dangerousGoods.find(x => x.id === res.invoice.dgcategory))
                    setShipping(res.invoice);
                    setPartyName(res.invoice.party);
                    getAddress(res.invoice.pid);
                    setConsignee(res.importer[0]);
                    setConsignedTo(res.fforwarder[0]);
                    setImpCountry(res.importer[0]?.country);
                    setConCountry(res.fforwarder[0]?.country);
                    setUpdateItems(res.items)
                    if (res.exporter[0] !== undefined) {
                        setExporterData(res.exporter[0]);
                        //setExpCountry(countries.find(x => x.ctrycode === res.exporter[0].party_country));
                    } else {
                        customerDetails(res.invoice.pid);
                    }
                    setComInvDetail((prevState) => {
                        return {
                            ...prevState,
                            export: res.exporter[0],
                            import: res.importer[0],
                            invoiceData: res.invoice,
                            packing: res.packing,
                            ship: res.shipping[0],
                            repository: res.repo,
                            msg: res.messages,
                            shipTrack: res.trackship
                        }
                    });
                });
            })();
        }
        fetchInvStatusList().then(data => {
            setStatusList(data);
        }).catch(error => {
            amberSnackbar(error);
        });
    }, []);

    const getAddress = (pid) => {
        if (localStorage.getItem('party_type') !== "CST") {
            fetchInvoiceAddress(pid, "imp", localStorage.getItem('userId')).then(data => {
                if (data.partyaddr.length !== 0) {
                    setAddressList(data.partyaddr);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        } else {
            fetchInvoiceAddress(Cookies.get('party_id'), "imp", localStorage.getItem('userId')).then(data => {
                if (data.partyaddr.length !== 0) {
                    setAddressList(data.partyaddr);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        }
    }

    const shipmentTrack = () => {
        if (invid) {
            fetchTrackShipment(userId, invid).then(data => {
                if (data.status === "ERROR") {
                    amberSnackbar(data.message)
                } else {
                    setShipmentMsg(data);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        } else {
            fetchTrackShipment(userId, invoiceId).then(data => {
                if (data.status === "ERROR") {
                    amberSnackbar(data.message)
                } else {
                    setShipmentMsg(data);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        }
    }

    const fetchCountryList = () => {
        fetchCountry().then(data => {
            setCountries(data)
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const fetchCustomerList = () => {
        fetchCustomer().then(data => {
            setCustomers(data)
        }).catch(error => {
            amberSnackbar(error);
        });
    }

    const saveExportAddr = (reqObj) => {
        saveCustomerAddr(reqObj).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                refreshInvoice(invoiceId || invid);
            } else {
                amberSnackbar(res.message)
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const addComInv = (reqObj) => {
        saveComInv(reqObj).then(res => {
            if (res.status === 1) {
                setInvoiceId(res.invid);
                successSnackbar(res.message);
                refreshInvoice(res.invid || invid);
            } else {
                amberSnackbar(res.message);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const customerDetails = (pid) => {
        fetchCustomerDetails(userId, pid).then(res => {
            if (res) {
                setExporterData(res[0]);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const saveFile = (documentUpload, discription) => {
        const formData = new FormData();
        formData.append('myfile', documentUpload);
        formData.append('repo_desc', (discription !== undefined || discription !== '') ? discription : '');
        formData.append('invid', invoiceId || invid);
        formData.append('user', userId);
        saveFileUpload(formData).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                refreshInvoice(invoiceId || invid);
            } else {
                amberSnackbar(res.message);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const refreshInvoice = (id) => {
        (async () => {
            await fetchInvoiceDetails(id).then((res) => {
                setComInvDetail((prevState) => {
                    return {
                        ...prevState,
                        export: res.exporter[0],
                        import: res.importer[0],
                        invoiceData: res.invoice,
                        conTo: res.fforwarder[0],
                        items: res.items,
                        packing: res.packing,
                        ship: res.shipping[0],
                        repository: res.repo,
                        msg: res.messages,
                        shipTrack: res.trackship
                    }
                });
            });
        })();
    }

    const addShipDetails = (reqObj) => {
        saveShipmentDetails(reqObj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                refreshInvoice(invoiceId || invid);
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const handleFileDelete = (repoid, invid) => {
        deleteFile(repoid, invid, userId).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                refreshInvoice(invoiceId || invid);
            } else {
                amberSnackbar(res.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const addMessage = (reqObj) => {
        saveShipmentMessage(reqObj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                refreshInvoice(invoiceId || invid);
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const generateFedexRates = () => {
        if (invid) {
            setFedexRates((prevState) => {
                return { ...prevState, ratesList: [], isLoading: true }
            })
            fetchFedexRates(invid, userId).then(data => {
                if (data.result.length > 0) {
                    setFedexRates((prevState) => {
                        return { ...prevState, ratesList: data.result, isLoading: false }
                    })
                    successSnackbar(data.message);
                } else {
                    amberSnackbar(data.fedmsg[0]);
                    setFedexRates((prevState) => {
                        return { ...prevState, ratesList: [], isLoading: false }
                    })
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        } else {
            fetchFedexRates(invoiceId, userId).then(data => {
                if (data.result.length > 0) {
                    setFedexRates(data.result);
                    successSnackbar(data.message);
                } else {
                    amberSnackbar(data.fedmsg[0]);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        }
    }

    const validateLabel = (inv_id, service) => {
        (async () => {
            validateGenarateLabel(inv_id, service, userId).then((res) => {
                if (res.status === "SUCCESS") {
                    successSnackbar(res.status + " shipment created.");
                    refreshInvoice(invoiceId || invid);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        })();
    }

    const addInvItem = (objArr) => {
        if (invid) {
            saveInvItem(objArr, userId, invid).then((res) => {
                if (res.status === 1) {
                    successSnackbar(res.message);
                    refreshInvoice(invid);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        } else {
            saveInvItem(objArr, userId, (invoiceId)).then((res) => {
                if (res.status === 1) {
                    successSnackbar(res.message);
                    refreshInvoice(invoiceId);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        }
    }

    const handleGenerateLabel = () => {
        if (invid) {
            generateLabelPdf(userId, invid).then(res => {
                if (res.status === "SUCCESS") {
                    successSnackbar(res.message);
                    refreshInvoice(invid);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        } else {
            generateLabelPdf(userId, invoiceId).then(res => {
                if (res.status === "SUCCESS") {
                    successSnackbar(res.message);
                    refreshInvoice(invoiceId);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        }

    }

    const addInvStatus = (reqObj) => {
        saveInvoiceStatus(reqObj).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
            } else {
                amberSnackbar(res.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        });
    }

    const shipemntDelete = () => {
        if (invid) {
            deleteShipment(invid, userId).then(res => {
                if (res.status === "SUCCESS") {
                    refreshInvoice(invoiceId || invid);
                    successSnackbar(res.status);
                } else {
                    amberSnackbar(res.status);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        } else {
            deleteShipment(invoiceId, userId).then(res => {
                if (res.status === "SUCCESS") {
                    refreshInvoice(invoiceId || invid);
                    successSnackbar(res.status);
                } else {
                    amberSnackbar(res.status);
                }
            }).catch(error => {
                amberSnackbar(error);
            });
        }
    }

    const addScanParty = (reqobj) => {
        updateScan(reqobj).then(data => {
          if (data.status === 1) {
            successSnackbar(data.message);
          } else {
            amberSnackbar(data.message);
          }
        }).catch(error => {
          amberSnackbar(error);
        })
      }

    return (
        <>
            <Grid container item xs={12}>
                {invid ? <Grid item xs={2}>
                    <Box ml={3} me={2}>
                        <Paper elevation={10} className={classes.leftPaper}>
                            <Grid item>
                                <img src={sIcon} className={classes.profileIcon} />
                            </Grid>
                            <Grid item container xs={12} spacing={4} className={classes.topP}>
                                <Grid item xs={12}>
                                    <Typography className={classes.disabled}>Shipment Ref No.</Typography>
                                    <Typography>{comInvDetail.invoiceData.ship_refno}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.disabled}>Party Name</Typography>
                                    <Typography>{comInvDetail.import?.party_name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.disabled}>From Country</Typography>
                                    <Typography>{comInvDetail.export?.party_country}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.disabled}>To Country</Typography>
                                    <Typography>{comInvDetail.import?.party_country}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Box ml={3}>
                                <Button data-testid="label" fullWidth variant="contained" color="primary"
                                    onClick={() => handleGenerateLabel()} style={{ textTransform: "none" }} >
                                    Generate Invoice
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid> : null}
                <Grid item xs={invid ? 10 : 12}>
                    <Box ml={2} mr={3}>
                        <Paper elevation={10} className={classes.paper}>
                            <AppBar position="static" color="default" className={classes.topPadding}>
                                <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable"
                                    scrollButtons="auto">
                                    <Tab label="Shipments" style={{ textTransform: "none" }} />
                                    {invid ? <Tab label="Invoice Status" style={{ textTransform: "none" }} /> : null}
                                    <Tab label="Exporter/Shipper" style={{ textTransform: "none" }} />
                                    <Tab label="Consignee/Importer" style={{ textTransform: "none" }} />
                                    <Tab label="Consigned To" style={{ textTransform: "none" }} />
                                    <Tab label="Packing List" style={{ textTransform: "none" }} />
                                    <Tab label="Product Details" style={{ textTransform: "none" }} />
                                    <Tab label="Select Shipment" style={{ textTransform: "none" }} />
                                    <Tab label="Shipment Details" style={{ textTransform: "none" }} />
                                    <Tab label="Repository" style={{ textTransform: "none" }} />
                                    <Tab label="Message" style={{ textTransform: "none" }} />
                                </Tabs>
                            </AppBar>
                            <Box className={classes.topP}>
                                <TabPanel value={tabIndex} index={0}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <Shipping customers={customers} countries={countries} userId={userId} shipping={shipping}
                                            fetchCustomerList={fetchCustomerList} addComInv={addComInv} setShipping={setShipping}
                                            customerDetails={customerDetails} amberSnackbar={amberSnackbar} partyName={partyName}
                                            successSnackbar={successSnackbar} setPartyName={setPartyName} invid={invid}
                                            getAddress={getAddress} setPartyId={setPartyId} dangerousGoods={dangerousGoods}
                                            setCategory={setCategory} category={category} isDangGood={isDangGood}
                                            setIsDangGood={setIsDangGood} />
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    {invid ? <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <InvoiceStatus invid={invid} addInvStatus={addInvStatus} statusList={statusList}
                                            setSelectInvStatus={setSelectInvStatus} selectInvStatus={selectInvStatus} />
                                    </ErrorBoundary> : null}
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 2 : 1}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <Exporter countries={countries} saveExportAddr={saveExportAddr}
                                            exporterData={exporterData} pid={comInvDetail.invoiceData?.pid} invoiceId={invid}
                                            addrId={comInvDetail.export?.party_addr_id} setExporterData={setExporterData}
                                            expCountry={expCountry} setExpCountry={setExpCountry} addScanParty={addScanParty} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Shipment section first...</p>
                                            ) : <Exporter countries={countries} saveExportAddr={saveExportAddr} exporterData={exporterData}
                                                pid={comInvDetail.invoiceData?.pid} invoiceId={invoiceId} addrId={0} addScanParty={addScanParty}
                                                setExporterData={setExporterData} expCountry={expCountry} setExpCountry={setExpCountry} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 3 : 2}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <Consignee countries={countries} addressList={addressList} pid={comInvDetail.invoiceData.pid}
                                            addressId={comInvDetail.import?.party_addr_id} saveExportAddr={saveExportAddr} invoiceId={invid}
                                            setAddressList={setAddressList} consignee={consignee} setConsignee={setConsignee}
                                            setImpCountry={setImpCountry} impCountry={impCountry} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Exporter/Shipper section first...</p>
                                            ) : <Consignee countries={countries} addressId={comInvDetail.import?.party_addr_id}
                                                saveExportAddr={saveExportAddr} invoiceId={invoiceId} addressList={addressList}
                                                setAddressList={setAddressList} consignee={consignee} setConsignee={setConsignee}
                                                pid={partyId} setImpCountry={setImpCountry} impCountry={impCountry} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 4 : 3}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <ConsignedTo countries={countries} saveExportAddr={saveExportAddr} invoiceId={invid}
                                            addressId={comInvDetail.import?.party_addr_id} asConsignee={comInvDetail.import}
                                            amberSnackbar={amberSnackbar} pid={comInvDetail.invoiceData.pid} consignedTo={consignedTo}
                                            setConsignedTo={setConsignedTo} conCountry={conCountry} setConCountry={setConCountry} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Consignee/Importer section first...</p>
                                            ) : <ConsignedTo countries={countries} saveExportAddr={saveExportAddr} invoiceId={invoiceId}
                                                addressId={comInvDetail.import?.party_addr_id} asConsignee={comInvDetail.import}
                                                amberSnackbar={amberSnackbar} consignedTo={consignedTo} pid={comInvDetail.invoiceData.pid}
                                                setConsignedTo={setConsignedTo} conCountry={conCountry} setConCountry={setConCountry}
                                            />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 5 : 4}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <PackingList packingList={comInvDetail.packing} refreshInvoice={refreshInvoice}
                                            successSnackbar={successSnackbar} amberSnackbar={amberSnackbar} invoiceId={invid} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Consigned To section first...</p>
                                            ) : <PackingList packingList={comInvDetail.packing} refreshInvoice={refreshInvoice}
                                                successSnackbar={successSnackbar} amberSnackbar={amberSnackbar} invoiceId={invoiceId} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 6 : 5}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <ProductDetails countries={countries} dgCategory={comInvDetail.invoiceData.dgcategory}
                                            packing={comInvDetail.packing} addInvItem={addInvItem} amberSnackbar={amberSnackbar}
                                            invoiceId={invid} setItemList={setItemList} itemList={itemList} updateItems={updateItems}
                                            setUpdateItems={setUpdateItems} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Consigned To section first...</p>
                                            ) : <ProductDetails countries={countries} dgCategory={comInvDetail.invoiceData.dgcategory}
                                                packing={comInvDetail.packing} addInvItem={addInvItem} amberSnackbar={amberSnackbar}
                                                invoiceId={invoiceId} setItemList={setItemList} itemList={itemList} updateItems={updateItems}
                                                setUpdateItems={setUpdateItems} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 7 : 6}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <ChooseShipment generateFedexRates={generateFedexRates} fedexRates={fedexRates.ratesList}
                                            validateLabel={validateLabel} invoiceId={invid} shipmentTrack={shipmentTrack}
                                            trackShip={comInvDetail.shipTrack} isLoading={fedexRates.isLoading} shipmentMsg={shipmentMsg}
                                            shipemntDelete={shipemntDelete} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Shipment and Consignee section first...</p>
                                            ) : <ChooseShipment generateFedexRates={generateFedexRates} fedexRates={fedexRates.ratesList}
                                                validateLabel={validateLabel} invoiceId={invoiceId} shipmentTrack={shipmentTrack}
                                                trackShip={comInvDetail.shipTrack} isLoading={fedexRates.isLoading} shipmentMsg={shipmentMsg}
                                                shipemntDelete={shipemntDelete} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 8 : 7}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <ShipmentDetail invoiceId={invid} addShipDetails={addShipDetails} shipment={comInvDetail.ship} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Shipment section first...</p>
                                            ) : <ShipmentDetail invoiceId={invoiceId} addShipDetails={addShipDetails} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 9 : 8}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <FileUpload saveFile={saveFile} repository={comInvDetail.repository}
                                            amberSnackbar={amberSnackbar} handleFileDelete={handleFileDelete} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Shipment section first...</p>
                                            ) : <FileUpload saveFile={saveFile} repository={comInvDetail.repository}
                                                amberSnackbar={amberSnackbar} handleFileDelete={handleFileDelete} />}
                                    </ErrorBoundary>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={invid ? 10 : 9}>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        {invid ? <Messages addMessage={addMessage} invoiceId={invid} messageList={comInvDetail.msg} />
                                            : invoiceId === undefined ? (
                                                <p style={{ padding: '30px', fontFamily: 'sans-serif' }}>Please save Shipment section first...</p>
                                            ) : <Messages addMessage={addMessage} invoiceId={invoiceId} messageList={comInvDetail.msg} />}
                                    </ErrorBoundary>
                                </TabPanel>
                            </Box>
                        </Paper>
                    </Box>
                </Grid >
            </Grid>
            {invid ? null : <Grid container wrap="wrap" className={classes.pageFooter}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary"
                        className={classes.applyBtn}
                        disabled={comInvDetail.shipTrack.length > 0 ? false : true}
                        onClick={() => handleGenerateLabel()}>
                        Generate Invoice
                    </Button>
                </Grid>
            </Grid>}
        </>
    )

}

export default CreateShipment;