import { Box, makeStyles, TextField, Tooltip, withStyles } from '@material-ui/core';
import { forwardRef } from 'react';
import calenderIcon from '../../assets/calendarIcon.svg';

const useStyles = makeStyles(theme => ({
  inputField: {
    "&::placeholder": {
      fontSize: '12px',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: '400',
      letterSpacing: '0px',
      color: '#828282',
      opacity: 1
    },
    marginTop: '13px',
  },
}))

const imagePath = `url(${calenderIcon})`;

export const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <TextField value={value}
    variant="outlined"
    style={{
      background: `${imagePath} 290px/ 12px no-repeat`,
      width: '327px'
    }}
    onClick={onClick}
    onChange={onChange}
    className={useStyles().inputField}
    ref={ref} placeholder="yyyy-MM-dd" />
));

const ubuntuFont = 'sans-serif';
export const OPTIONS = {
  position: 'top-center',
  style: {
    width: 350,
    height: 90,
    backgroundColor: '#FFFFFF',
    color: '#2B0A3D',
    fontFamily: ubuntuFont,
    fontSize: 16,
    marginTop: 60,
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
    borderLeft: '32px solid #3ED128'
  },
  closeStyle: {
    color: '#0070AD',
    fontSize: 12,
    position: 'top',
    padding: 0
  }
};

export const OPTIONS2 = {
  position: 'top-center',
  style: {
    width: 700,
    height: 90,
    backgroundColor: '#FFFFFF',
    color: '#2B0A3D',
    fontFamily: ubuntuFont,
    fontSize: 16,
    marginTop: 60,
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
    borderLeft: '32px solid #3ED128'
  },
  closeStyle: {
    color: '#0070AD',
    fontSize: 12,
    position: 'top',
    padding: 0
  }
};

export const OPTIONS3 = {
  position: 'top-center',
  style: {
    width: 340,
    height: 90,
    backgroundColor: '#FFFFFF',
    color: '#2B0A3D',
    fontFamily: ubuntuFont,
    fontSize: 16,
    marginTop: 60,
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
    borderLeft: '32px solid #F21B3F'
  },
  closeStyle: {
    color: '#0070AD',
    fontSize: 12,
    position: 'top',
    padding: 0
  }
};

export const customerModal = {
  party_name: "",
  contact_name: "",
  party_vatno: "",
  party_ein: "",
  password: "",
  contact_email: "",
  contact_phone: "",
  party_city: "",
  party_state: "",
  party_country: "",
  party_addr1: "",
  party_addr2: "",
  party_addr3: "",
  party_addr4: "",
  party_zipcode: "",
  ptype: "",
  partyid: "",
  invid: "",
  user: "",
  party_addr_id: "",
  woid: ""
}

export const ffrModal = {
  party_name: "",
  contact_name: "",
  party_vatno: "",
  party_ein: "",
  password: "",
  contact_email: "",
  contact_phone: "",
  party_city: "",
  party_state: "",
  party_country: "",
  party_addr1: "",
  party_addr2: "",
  party_addr3: "",
  party_addr4: "",
  party_zipcode: "",
  user: "",
  woid: "",
  ffpid: ""
}

export const agentModal = {
  party_name: "",
  contact_name: "",
  party_vatno: "",
  party_ein: "",
  password: "",
  contact_email: "",
  contact_phone: "",
  party_city: "",
  party_state: "",
  party_country: "",
  party_addr1: "",
  party_addr2: "",
  party_addr3: "",
  party_addr4: "",
  party_zipcode: "",
  user: "",
  woid: "",
  agentpid: ""
}

export const packingModal = {
  container_no: "",
  package_type: "",
  dim_length: "",
  dim_width: "",
  dim_height: "",
  weight_gross: "",
  user: "",
  dim_uom: "",
  weight_uom: "",
  invid: "",
  packid: ""
}

export const poListModal = {
  po_date: "",
  po_party_name: "",
  po_party_country: "",
  po_party_state: "",
  po_party_city: "",
  po_party_addr1: "",
  po_party_addr2: "",
  po_party_brand: "",
  currency: "",
  po_party_zip: "",
  user: "",
  poid: "",
  cust_id: "",
  po_number: "",
  po_contact_name: "",
  po_contact_email: "",
  po_pcid: 0
}


export const POItemModal = {
  prod_code: "",
  prod_name: "",
  prod_qty: "",
  prod_qty_uom: "",
  prod_unit_price: "",
  user: "",
  po_item_id: ""
}

export const DeliveryPlanModal = {
  ItemDetails: [],
  address: "",
  address2: "",
  city: "",
  contact: "",
  country: "",
  currency: "",
  email: "",
  modeoft: "",
  party: "",
  party_id: "",
  phone: "",
  po_date: "",
  ponumber: "",
  vatno: "",
  zip: "",
  state: "",
  user: "",
  poid: "",
  paid: "",
}

export const blackListModal = {
  itemstosearch: []
}

export const shipmentDetailsModal = {
  mawbno: "",
  ship_date: "",
  po_number: "",
  pay_terms: "",
  billof_landing: "",
  ship_purpose: "",
  incoterms: "",
  hmatdesc: "",
  broker_name: "",
  broker_contact: "",
  broker_phone: "",
  invshipid: "",
  invid: "",
  user: ""
}

export const messageModal = {
  message: "",
  subject: "",
  user: "",
  invid: "",
  customer_view: "",
  woid: ""
}

export const receiptModal = {
  prod_qty: "",
  prod_uom: "",
  receiptdate: "",
  receiptno: "",
  woid: "",
  user: "",
  woitemid: ""
}

export const contactModal = {
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  user: "",
  pid: "",
  paid: ""
}

export const cartonModal = {
  container_no: "",
  dim_cube: "",
  dim_height: "",
  dim_length: "",
  dim_uom: "",
  dim_width: "",
  package_type: "",
  pkg_nos: "",
  prod_qty: "",
  receiptno: "",
  user: "",
  weight_gross: "",
  weight_net: "",
  weight_uom: "",
  woid: "",
  woitemid: ""
}

export const woStatusModal = {
  comments: "",
  custView: "",
  oldwostatus: "",
  user: "",
  woid: "",
  woEtaDate: "",
  woStatus: "",
  woStatusDate: ""
}

export const invStatusModal = {
  invStatus: "",
  invStatusDate: "",
  custView: "",
  comments: "",
  invEtaDate: "",
  user: "",
  invid: ""
}

export const deliveryLocation = {
  party_name: "",
  party_vatno: "",
  party_email: "",
  party_contact: "",
  party_city: "",
  party_state: "",
  party_country: "",
  party_addr1: "",
  party_addr2: "",
  party_zipcode: "",
  party_telephone: "",
  user: "",
  paid: "",
  woid: "",
  pid: "",
  modeoft: ""
}

export const deliveryLocationContact = {
  party_contact: "",
  woid: "",
  user: ""
}

export const poContact = {
  party_contact: "",
  poid: "",
  user: ""
}

export const NODATA_MSG = "Oops! Something went wrong there is no data available"
export const RECENT_DELIVERY = 'Past deliveries (30 days)'
export const PLANNED_DELIVERY_CTRY = 'Delivery planned within 30 days'
export const PLANNED_DELIVERY_STATUS = 'Delivery planned within 30 days'
export const REDIRECT_TO_PROFILE = "/work-order/summary"
export const REDIRECT_TO_PO = "/purchase-order/summary"
export const REDIRECT_TO_SHIPMENT = "/shipment/summary"
export const SHIPMENTS = "Shipments"
export const MESSAGES = "Messages"
export const PURCHASE_ORDERS = "Purchase Orders"
export const WORK_ORDERS = "Work Orders"
export const VAULT = "File Vault"
export const REDIRECT_TO_VAULT = "/file-vault"
export const VAULT_INFO = "Vault allow us to upload, share, rename, create and delete file of any size."
export const SHIPMENT_INFO = "Shipment allow us to select and generate fedex label and ship from source to destination country."
export const MSG_INFO = "Notification for all the orders which allow us track to track the order."
export const PURCHASE_ORDER_INFO = "Purchase order allow us track the payment and create delivery plan."
export const WORK_ORDER_INFO = "Work Order allow us to track all the customer orders according to status and ETA date."
export const WORK_ORDER_PIE_TITLE = "Total Work Orders"
export const SHIPMENT_PIE_TITLE = "Total Shipments"
export const PO_PIE_TITLE = "Total Purchase Order"
export const PO_PER_COUNTRY_TITLE = "Number of purchase order per country"
export const WORK_ORDER_PER_STATUS_TITLE = "Number of work orders per status"
export const SIPMENT_PER_TRANSPORT_TITLE = "Number of shipments per carrier"
export const RECENT_SHIPMENT_TITLE = "Number of recent deliveries per country"
export const PLANNED_SHIPMENT_TITLE = "Number of planned deliveries per country"
export const LEGEND_CATEGORIES_COLORS_IMPACT = ["#4701A7", "#7E39BA", "#FF5770", "#6D64CC", "#80B8D6", "#01D1D0", "#CB2980", "#04acab", "#390284", "#f34da6", "#873cee"];
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 11,
  }
}))(Tooltip);

const headingStle = (theme, fontSize, weight) => {
  return {
    fontWeight: weight,
    fontSize: theme.typography.fontSize + fontSize,
    color: theme.palette.primary.dark,
  }
}

export const dashboardStyle = makeStyles(theme => ({
  saveBtn: {
    borderRadius: '30px',
    textTransform: 'none',
    cursor: 'pointer',
    margin: '39px 20px 5px -110px',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#0070AD',
        textDecoration: 'none',
        boxShadow: 'none',
        color: '#fff'
    }
},
clearBtn: {
  borderRadius: '30px',
  textTransform: 'none',
  cursor: 'pointer',
  margin: '39px 20px 5px -348px',
  boxShadow: 'none',
  '&:hover': {
      backgroundColor: '#0070AD',
      textDecoration: 'none',
      boxShadow: 'none',
      color: '#fff'
  }
},
  inputField: {
    "&::placeholder": {
      fontSize: '12px',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: '400',
      letterSpacing: '0px',
      color: '#828282',
      opacity: 1
    },
    marginTop: '13px',
  },
  dashboard: {
    padding: '0 2%',
    background: theme.palette.common.white,
    '& .headingWrap': {
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(3)}px 0`,
      '& h6': headingStle(theme, 4, 700),
      '& .roundedBtn': {
        '& > button': {
          borderRadius: '50px 0 0 50px',
          textTransform: 'none',
          boxShadow: "none",
          '& + button': {
            borderRadius: '0 50px 50px 0',
            '&:hover': { backgroundColor: theme.palette.primary.main }
          }
        },
      }
    },
    '& .icapBox': {
      border: '1.5px solid #C4C4C4',
      marginBottom: `${theme.spacing(2.5)}px`,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      height: '320px',
      alignItems: 'flex-start',
      '&:hover': { border: `1.5px solid ${theme.palette.primary.main}`, },
      '& >.MuiGrid-item': {
        alignItems: 'center',
      },
      '& .card-icon': { width: '90%', maxWidth: '125px' },
      '& .icapboxHead': {
        '& h6': { ...headingStle(theme, 2, 600), cursor: 'pointer' },
        justifyContent: 'space-between'
      },
      '& .cursor_pointer': {
        cursor: 'pointer'
      },
      '& .icapBoxBoldTxt': headingStle(theme, 2, 700),
      '& .icapBoxNumberTxt': headingStle(theme, 14, 700),
      '& .icapBoxTxt': headingStle(theme, 2, 400),
      '&.stackHolders': {
        '& .slideBox': {
          paddingTop: `${theme.spacing(1)}px`,
          '& .slideWrap': {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            '& .slideBar': {
              height: '20px',
              minWidth: '2px',
              marginRight: `${theme.spacing(1)}px`,
              '&.red': { background: '#FF304D' },
              '&.yellow': { background: '#FFBA08' },
              '&.green': { background: '#95E616' },
            },
            '& p': { whiteSpace: 'nowrap' },
            '& + hr': { borderBottom: 0 }
          }
        }
      },
      '&.personas, &.changeImpact, &.businessChange': { '& [id*="_chart_legend_g"]': { display: 'none' } },
      '& .cstmLegend': {
        '&.multiRowLegend': {
          '& ul': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            '& li': {
              maxWidth: 'calc(50% - 20px)',
              '&:nth-child(even)': { marginLeft: '20px' },
              '&:nth-child(7n)': { border: 0 }
            }
          }
        },
        '& .icapBoxBoldTxt': { borderBottom: '1px solid #0000001f', paddingBottom: 10 },
        '& li': {
          borderBottom: '1px solid #C4C4C4',
          padding: '8px 0',
          font: '12px',
          color: '#767676',
          justifyContent: 'space-between',
          cursor: 'pointer',
          '& .ellipsis': { paddingRight: '14px' },
          '&:last-child': { border: 0 },
          '& .legendColor': {
            width: '15px', height: '15px', float: 'left', marginRight: '5px'
          },
        },
        '& .ejCstmPagination': {
          '& button span': {
            fontWeight: 'bold',
            fontSize: '14px'
          }
        },
      },
      '& .loaderWithMessage': {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
      '& .colorIndcatorBox': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px'
      },
    }
  },
  headstyle: {
    '& h6': headingStle(theme, 4, 700),
  },
}));

export const BCPProgressColors = {
  Overdue: "#DB6A52",
  ThisWeek: "#A9DB52",
  Total: '#AED6F1',
  Week: '#73C6B6'
}

export const BCPcolorAnalysis = [
  {
    Status: {
      Name: "Overdue",
      color: "#DB6A52"
    }
  },
  {
    Status: {
      Name: "This Week",
      color: "#A9DB52"
    }
  }
]
export const DelPlancolorAnalysis = [
  {
    Status: {
      Name: "Total",
      color: "#AED6F1"
    }
  },
  {
    Status: {
      Name: "This Week",
      color: "#73C6B6"
    }
  }
]

export const Overdue = "Overdue"
export const ThisWeek = "This Week"
export const Total = "Total"
export const Week = "This Week"

export const RenderProgressStake = (data, total, ProgressColors, numberSign) => {
  return Object.keys(data).map((objKey, indx) => (
    <Box className="boxStyle" key={new Date().getTime() * indx} style={{ height: '100%', textAlign: 'center', width: `${((100 * data[objKey]) / total)}%`, backgroundColor: ProgressColors[objKey] }}>
      {((100 * data[objKey]) / total) !== 0 && <span style={{ padding: "5px 5px", color: "#2B0A3D", font: "bold 13px" }}>{`${data[objKey]} ${numberSign ? numberSign : ''}`}</span>}
    </Box>
  ));
};

export const workOrderStyle = makeStyles(theme => ({
  paper: {
    background: "none",
    width: "100%",
    margin: theme.spacing(5, 'auto')
  },
  topP: {
    padding: theme.spacing(1.25)
  },
  topPadding: {
    padding: theme.spacing(1.25)
  },
  leftPaper: {
    background: "none",
    width: "100%",
    margin: theme.spacing(5, 'auto'),
    padding: theme.spacing(2)
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  profileIcon: {
    width: "8rem",
    height: "auto",
    display: "block",
    margin: "1rem auto",
    color: '#0070ad'
  }
}))

export const purchaseOrderStyle = makeStyles(theme => ({
  applyBtn: {
      fontFamily: 'sans-serif',
      borderRadius: '30px',
      textTransform: 'none',
      cursor: 'pointer',
      margin: '5px 20px 5px 0',
      boxShadow: 'none',
      '&:hover': {
          backgroundColor: '#0070AD',
          textDecoration: 'none',
          boxShadow: 'none',
          color: '#fff'
      }
  },
  cancelButton: {
      borderRadius: '30px',
      textTransform: 'none',
      cursor: 'pointer',
      margin: '5px 20px 5px 0',
      '&:hover': {
          backgroundColor: '#FFFFFF',
          textDecoration: 'none'
      }
  },
  pageFooter: {
      background: "#F3F3F3",
      padding: '15px',
      textAlign: 'right',
      position: "fixed",
      right: 0,
      bottom: 0,
  },
  paper: {
    background: "none",
    width: "100%",
    margin: theme.spacing(5, 'auto')
  },
  topP: {
    padding: theme.spacing(1.25)
  },
  topPadding: {
    padding: theme.spacing(1.25)
  },
  leftPaper: {
    background: "none",
    width: "100%",
    margin: theme.spacing(5, 'auto'),
    padding: theme.spacing(2)
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  profileIcon: {
    width: "8rem",
    height: "auto",
    display: "block",
    margin: "1rem auto",
    color: '#0070ad'
  }
}));

export const getDefaultWoInfo = () => {
  return {
    woList: [],
    deliveryLocation: [],
    freightForward: [],
    itemDetails: [],
    trackShipmentDate: [],
    trackShipment: [],
    container: [],
    repository: [],
    message: [],
    partyFFR: [],
    countries: [],
    ffList: [],
    woda: [],
    contacts: []
  }
}
