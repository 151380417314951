import { Paper, Grid, FormControl, Typography, TextField, makeStyles, Button, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import CreateIcon from '../../../../../assets/CreateActive.svg';
import DeleteIcon from '../../../../../assets/DeleteIcon.svg';
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}))

const ItemDetails = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [isDisable, setIsDisable] = useState(true);

    const validateUpdate = useRef(new SimpleReactValidator({}));
    const formValidator = useRef(new SimpleReactValidator({}));

    const handleAddItems = () => {
        props.setItemList([
            ...props.itemList,
            {
                id: props.itemList.length + 1,
                prod_code: "",
                prod_name: "",
                prod_qty: "",
                prod_qty_uom: "",
                prod_unit_price: "",
                total: "",
            }
        ])
    }

    const handleDeleteItems = (index) => {
        const data = props.itemList.filter((_, i) => i !== index)
        props.setItemList(data);
    }

    const handleDeletePOItems = (itemId) => {
        props.poItemDelete(itemId)
    }

    const handleChangeEditItem = (field, value, po_item_id) => {
        setIsDisable(false);
        props.setItemEditList([
            ...props.itemEditList.map(data => {
                if (data.po_item_id === po_item_id) data[field] = value;
                return data;
            }),
        ]);
    }

    const handleChangeItem = (field, value, idx) => {
        setIsDisable(false);
        props.setItemList([
            ...props.itemList.map((itemData, i) => {
                if (idx === i) itemData[field] = value
                return itemData;
            })
        ])
    }

    const handleCancel = () => {
        navigate('/');
    }

    const result = props.itemList.map(data => {
        return {
            poitemid: 0,
            poid: props.poid ? props.poid : props.selectPoid,
            prod_code: data.prod_code,
            prod_name: data.prod_name,
            prod_qty: data.prod_qty,
            prod_qty_uom: data.prod_qty_uom,
            prod_unit_price: data.prod_unit_price
        }
    })

    const secondResult = props.itemEditList.map(itemData => {
        return {
            prod_code: itemData.prod_code,
            poitemid: itemData.po_item_id,
            prod_name: itemData.prod_name,
            prod_qty: itemData.prod_qty,
            prod_qty_uom: itemData.prod_qty_uom,
            prod_unit_price: itemData.prod_unit_price,
            poid: props.poid,
        }
    })

    const handleUpdateItem = () => {
        const isValid = validateUpdate.current.allValid();
        if (isValid) {
            const newResult = [...result, ...secondResult]
            const itemObj = { ItemDetails: newResult, 'user': localStorage.getItem('userId') }
            props.editPOItem(itemObj);
            setIsDisable(true);
            props.setItemEditList([])
        } else {
            validateUpdate.current.showMessages();
            forceUpdate(1);
        }
    }
    
    const handleSaveItem = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const newResult = [...result, ...secondResult]
            const itemObj = { ItemDetails: newResult, 'user': localStorage.getItem('userId') }
            props.addPOItems(itemObj);
            setIsDisable(true);
            props.setItemList([]);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                {props.poid && (
                    props.itemEditList.map((row, index) => {
                        const { prod_code, prod_name, prod_qty, prod_unit_price, po_item_id, prod_qty_uom } = row;
                        return (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="prodCode">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Product Code
                                                </label>
                                            </Typography>
                                            <TextField
                                                id="prodCode"
                                                disabled={true}
                                                value={prod_code}
                                                color="primary"
                                                variant="outlined"
                                                className={classes.inputField}
                                                placeholder="Product Code"
                                            />
                                            {validateUpdate.current.message('Product Code', prod_code, 'required', { className: classes.validateStyle })}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="prodDesc">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Product Description
                                                </label>
                                            </Typography>
                                            <TextField
                                                id="prodDesc"
                                                name='prod_name'
                                                value={prod_name}
                                                color="primary"
                                                variant="outlined"
                                                className={classes.inputField}
                                                onChange={(e) => handleChangeEditItem("prod_name", e.target.value, po_item_id)}
                                                placeholder="Product Description"
                                            />
                                            {validateUpdate.current.message('Product Description', prod_name, 'required', { className: classes.validateStyle })}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="qty">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Quantity
                                                </label>
                                            </Typography>
                                            <TextField
                                                id="qty"
                                                value={prod_qty}
                                                color="primary"
                                                variant="outlined"
                                                className={classes.inputField}
                                                onChange={(e) => handleChangeEditItem("prod_qty", e.target.value, po_item_id)}
                                                placeholder="Quantity"
                                            />
                                            {validateUpdate.current.message('Quantity', prod_qty, 'required', { className: classes.validateStyle })}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="party_name">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    UOM
                                                </label>
                                            </Typography>
                                            <Autocomplete
                                                id="uom-select"
                                                disableClearable
                                                value={row}
                                                options={props.uomData}
                                                getOptionLabel={(option) => option.uom_desc}
                                                getOptionSelected={(option, value) => value.uom_code === option.uom_code}
                                                onChange={(e, newValue) => handleChangeEditItem("prod_qty_uom", newValue.uom_code, po_item_id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        placeholder="Select UOM"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                    />
                                                )}
                                            />
                                            {validateUpdate.current.message('UOM', prod_qty_uom, 'required', { className: classes.validateStyle })}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="unitPrice">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Unit Price
                                                </label>
                                            </Typography>
                                            <TextField
                                                id="unitPrice"
                                                value={prod_unit_price}
                                                color="primary"
                                                variant="outlined"
                                                className={classes.inputField}
                                                onChange={(e) => handleChangeEditItem("prod_unit_price", e.target.value, po_item_id)}
                                                placeholder="Unit Price"
                                            />
                                            {validateUpdate.current.message('Unit Price', prod_unit_price, 'required', { className: classes.validateStyle })}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="total">
                                                    Total
                                                </label>
                                            </Typography>
                                            <TextField
                                                id="total"
                                                value={prod_qty * prod_unit_price}
                                                disabled={true}
                                                color="primary"
                                                variant="outlined"
                                                className={classes.inputField}
                                                placeholder="Total"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <IconButton edge="end" aria-label="add items" onClick={() => handleAddItems()}>
                                            <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="add items" onClick={() => handleDeletePOItems(po_item_id)}>
                                            <img src={DeleteIcon} alt="add" style={{ marginTop: "30px" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })
                )}
                {props.itemList.map((row, index) => {
                    const { prod_code, prod_name, prod_qty, prod_qty_uom, prod_unit_price, uom } = row;
                    return (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodCode">
                                                <span className={classes.mandatoryField}>*</span>
                                                Product Code
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodCode"
                                            value={prod_code}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_code", e.target.value, index)}
                                            placeholder="Product Code"
                                        />
                                        {formValidator.current.message('Product Code', prod_code, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodDesc">
                                                <span className={classes.mandatoryField}>*</span>
                                                Product Description
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodDesc"
                                            value={prod_name}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_name", e.target.value, index)}
                                            placeholder="Product Description"
                                        />
                                        {formValidator.current.message('Product Description', prod_name, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="qty">
                                                <span className={classes.mandatoryField}>*</span>
                                                Quantity
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="qty"
                                            value={prod_qty}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_qty", e.target.value, index)}
                                            placeholder="Quantity"
                                        />
                                        {formValidator.current.message('Quantity', prod_qty, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="party_name">
                                                <span className={classes.mandatoryField}>*</span>
                                                UOM
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="uom-select"
                                            value={props.prodUOM}
                                            options={props.uomData}
                                            getOptionLabel={(option) => option.uom_desc}
                                            getOptionSelected={(option, value) => value.uom_code === option.uom_code}
                                            onChange={(e, newValue) => handleChangeItem("prod_qty_uom", newValue.uom_code, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Select UOM"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {formValidator.current.message('UOM', prod_qty_uom, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="unitPrice">
                                                <span className={classes.mandatoryField}>*</span>
                                                Unit Price
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="unitPrice"
                                            value={prod_unit_price}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_unit_price", e.target.value, index)}
                                            placeholder="Unit Price"
                                        />
                                        {formValidator.current.message('Unit Price', prod_unit_price, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="total">
                                                Total
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="total"
                                            value={prod_qty * prod_unit_price}
                                            disabled={true}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            placeholder="Total"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton edge="end" aria-label="add items" onClick={() => handleAddItems()}>
                                        <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                    </IconButton>
                                    {row.id !== 0 && (
                                        <IconButton edge="end" aria-label="add items" onClick={() => handleDeleteItems(index)}>
                                            <img src={DeleteIcon} alt="add" style={{ marginTop: "30px" }} />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
                {props.poid ?
                    <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                disabled={isDisable}
                                onClick={() => handleUpdateItem()}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                    : <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                disabled={isDisable}
                                onClick={() => handleSaveItem()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>}
            </Paper>
        </React.Fragment>
    )
}

export default ItemDetails;