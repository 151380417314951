import { makeStyles } from "@material-ui/core";

export const tableStyle = makeStyles(theme => ({
    errorMessge: {
        color: '#af5858',
        fontSize:16,
        border: 'solid 1px #af5858',
        margin:15,
        padding:15
    },
    table: {
        minWidth: 650,
        tableLayout: 'fixed',
        '& thead > tr th':{
            background: 'white',
            color: theme.palette.primary.main,
            borderTop: `none`,
            borderButton: `none`,
            textAlign:'left',
            fontWeight: 'bold',
            '& .MuiTableSortLabel-icon':{opacity: 0.8},
            '& .MuiTableSortLabel-iconDirectionAsc':{
                '& path':{d: 'path("M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z")'},
            },
            '& .MuiTableSortLabel-iconDirectionDesc':{
                '& path':{d: 'path("M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z")'},
            }
        },
        '& tr:nth-child(even)': {
            background: theme.palette.grey[100],
            '&.parentCell':{background: 'transparent'},
            '& > .stickyColumn': { background: theme.palette.grey[100] }
        },
        '& th, td': {
            font: `${theme.typography.fontSize}px ${theme.typography.h1.fontFamily}`,
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
            '&.truncate': {
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
            },
            '&.emptyCell': { width: 'auto' }
        },
        '& th': {
            color: theme.palette.primary.lightDark,
            fontweight: 'bold',
            borderLeft: `1px solid ${theme.palette.grey[300]} !important`,
            borderTop: `none`,
            borderButton: `none`,
            '&.stickyLeft': { zIndex: '5' },
            '& .resizeLine': {
                position: 'absolute',
                height: '100%',
                width: '4px',
                top: 0,
                right: '-2px',
                cursor: 'col-resize',
                zIndex: 1,
                '&:hover': {
                    backgroundColor: '#0AA1DD'
                },
                '&:active': {
                    backgroundColor: '#0AA1DD'
                }
            }
        },
        '& td': {
            color: theme.palette.primary.dark,
            border:'none',
            textAlign:'left',
            overflowWrap: 'break-word',
            '& .expandBtn': {
                color: theme.palette.primary.main,
            }
        },
        '& td > table thead tr th': {
            color: theme.palette.primary.dark,
            backgroundColor:'red',
            border:'none',
            textAlign:'left',
            overflowWrap: 'break-word',
            '& .expandBtn': {
                color: theme.palette.primary.main,
            }
        },
        '& .stickyColumn': {
            position: 'sticky',
            background: '#fff',
            zIndex: 5,
            '& .resizeLine': { display: 'none' },
            '&.stickyLeft': {
                left: 0,
                boxShadow: '1px 0px 3px 0px rgba(0, 0, 0, 0.1)'
            },
            '&.stickyRight': {
                right: 0,
                boxShadow: '-1px 0px 3px 0px rgba(0, 0, 0, 0.1)'
            },
        },
        '& .MuiAutocomplete-inputRoot':{
            padding: 2,
        },
        '& input.MuiOutlinedInput-input, .MuiOutlinedInput-multiline':{
            padding: 10,
        },
    },
    customizeAutoComplete: {
        '& .MuiAutocomplete-inputRoot': {
            display: 'flex',
            flexWrap: 'inherit',
            minWidth: '200px',
        },
        '&.selectedValue': {
            '& .MuiAutocomplete-inputRoot': {
                padding: `0 ${theme.spacing(3)}px 0 0`,
                '&> div':{overflow: 'hidden'}
            },
            '& .Mui-focused .multiSelectBox': {
                display: 'inline-grid',
            },
            '& input': {
                minWidth: 0,
            }
        }
    },
    customButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    showElipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginTop: 7,
        marginBottom:8,
        fontSize: 14,
        color: '#2B0A3D',
        border: 'none'
    }
}));