import { Grid, makeStyles, Typography, TextField, Paper, FormControl, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { fetchCustomer, fetchDataByVaultName, fetchVaultList, fetchVaultUserData } from "../Common/API";
import Cookies from "js-cookie";
import { Autocomplete } from "@material-ui/lab";
import SimpleReactValidator from "simple-react-validator";
import FileManager from "./components/FileManager";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    saveBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '35px 20px 5px 17px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}));

const FileVault = ({setTitle}) => {
    const classes = useStyles();
    setTitle('File Vault');
    const [customers, setCustomers] = useState([]);
    const [listVault, setListVault] = useState([]);
    const [, forceUpdate] = useState();
    const [vaultName, setVaultName] = useState([]);
    const [isOpenVault, setIsOpenVault] = useState(false);
    const formValidator = useRef(new SimpleReactValidator({}));

    useEffect(() => {
        fetchCustomer().then(data => {
            setCustomers(data);
        }).catch(error => {
            //amberSnackbar(error);
        });
        if(localStorage.getItem('party_type') !== "ADM"){
            fetchVaultUserData(localStorage.getItem('userId')).then(res => {
                console.log(res);
            });
        }  
    }, []);

    const handleChangeParty = (value) => {
        fetchVaultList(value.pid, localStorage.getItem('userId'))
            .then(data => {
                setListVault(data);
            }).catch(error => {
                //amberSnackbar(error);
            });
    }

    const handleOpenVault = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            fetchDataByVaultName(vaultName).then(data => {
                return data;
            }).catch(error => {

            });
            setIsOpenVault(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const renderVault = () => {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>File Vault</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <Paper component="form" className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        id="party-name-select"
                                        options={customers}
                                        getOptionLabel={(option) => option.party_name}
                                        getOptionSelected={(option, value) => value.pid === option.pid}
                                        onChange={(e, newValue) => handleChangeParty(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Party Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vault_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Vault Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        id="vault-select"
                                        options={listVault ? listVault : []}
                                        getOptionLabel={(option) => option.vaultname}
                                        getOptionSelected={(option, value) => value.vaultid === option.vaultid}
                                        onChange={(e, newValue) => setVaultName(newValue.vaultname)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Vault Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" size="medium"
                                    onClick={handleOpenVault} className={classes.saveBtn}>
                                    Open
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderVault()}
            {isOpenVault && <FileManager />}
        </>
    )

}

export default FileVault;