import { makeStyles, Dialog, DialogContent, DialogTitle, Grid, Typography, FormControl, TextField, Button, DialogActions, Paper } from "@material-ui/core";
import Cookies from "js-cookie";
import { useRef } from "react";
import { useState } from "react"
import SimpleReactValidator from "simple-react-validator";
import { packingModal } from "../../../../../../../Common/constant";
import { Autocomplete } from "@material-ui/lab";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    }
}))

const AddPackingModal = (props) => {
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [weightUom, setWtUOM] = useState([]);
    const [uomDim, setDimUOM] = useState([]);
    const formValidator = useRef(new SimpleReactValidator({}));

    useEffect(() => {
        setWtUOM(props.wtUOM.find(x => x.name === props.packingData.weight_uom))
        setDimUOM(props.dimUOM.find(x => x.name === props.packingData.dim_uom))
    }, []);

    const handleCancel = () => {
        props.setIsOpenDialog(false);
    }

    const handleSave = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...packingModal }
            if (props.packId > 0) {
                payload.container_no = props.packingData.container_no;
                payload.package_type = props.packingData.package_type;
                payload.dim_length = props.packingData.dim_length ? props.packingData.dim_length : "";
                payload.dim_height = props.packingData.dim_height ? props.packingData.dim_height : "";
                payload.dim_width = props.packingData.dim_width ? props.packingData.dim_width : "";
                payload.weight_gross = props.packingData.weight_gross ? props.packingData.weight_gross : "";
                payload.dim_uom = uomDim.name;
                payload.packid = props.packId;
                payload.weight_uom = weightUom.name;
                payload.invid = props.invid
                payload.user = localStorage.getItem('userId');
                props.savePackingList(payload);
            } else {
                if (props.data?.find(x => x.container_no === props.packingData.container_no)) {
                    props.amberSnackbar('Duplicate packing number')
                } else {
                    payload.container_no = props.packingData.container_no;
                    payload.package_type = props.packingData.package_type;
                    payload.dim_length = props.packingData.dim_length ? props.packingData.dim_length : "";
                    payload.dim_height = props.packingData.dim_height ? props.packingData.dim_height : "";
                    payload.dim_width = props.packingData.dim_width ? props.packingData.dim_width : "";
                    payload.weight_gross = props.packingData.weight_gross ? props.packingData.weight_gross : "";
                    payload.dim_uom = uomDim.name;
                    payload.weight_uom = weightUom.name;
                    payload.user = localStorage.getItem('userId');
                    payload.invid = props.invid
                    props.savePackingList(payload);
                }
            }
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleChangePacking = (field, value) => {
        if (field === "weight_uom") {
            const obj = {
                id: value.id,
                name: value.name
            }
            setWtUOM(obj);
        } else if (field === "dim_uom") {
            const obj = {
                id: value.id,
                name: value.name
            }
            setDimUOM(obj);
        }
        props.setPackingData(values => ({ ...values, [field]: value }));
    }

    return (
        <Dialog open={props.isOpenDialog} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif' }}>Add Packing</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="packingno">
                                        <span className={classes.mandatoryField}>*</span>
                                        Packing No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="packingno"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.container_no}
                                    onChange={(e) => handleChangePacking("container_no", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Packing No."
                                />
                                {formValidator.current.message('Packing No.', props.packingData.container_no, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="packingType">
                                        <span className={classes.mandatoryField}>*</span>
                                        Packing Type
                                    </label>
                                </Typography>
                                <TextField
                                    id="packingType"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.package_type}
                                    onChange={(e) => handleChangePacking("package_type", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Packing Type"
                                />
                                {formValidator.current.message('Packing Type', props.packingData.package_type, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="Length">
                                        Length
                                    </label>
                                </Typography>
                                <TextField
                                    id="length"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.dim_length}
                                    onChange={(e) => handleChangePacking("dim_length", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Length"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="width">
                                        Width
                                    </label>
                                </Typography>
                                <TextField
                                    id="width"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.dim_width}
                                    onChange={(e) => handleChangePacking("dim_width", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Width"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        Height
                                    </label>
                                </Typography>
                                <TextField
                                    id="height"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.dim_height}
                                    onChange={(e) => handleChangePacking("dim_height", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Height"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dimUOM">
                                        Dim UOM
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="uom-select"
                                    value={uomDim}
                                    options={props.dimUOM}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => value.id === option.id}
                                    onChange={(e, newValue) => handleChangePacking("dim_uom", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Dim UOM"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="weight">
                                        Weight
                                    </label>
                                </Typography>
                                <TextField
                                    id="weight"
                                    color="primary"
                                    variant="outlined"
                                    value={props.packingData.weight_gross}
                                    onChange={(e) => handleChangePacking("weight_gross", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Weight"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="wtUOM">
                                        Weight UOM
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="uom-select"
                                    value={weightUom}
                                    options={props.wtUOM}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => value?.id === option?.id}
                                    onChange={(e, newValue) => handleChangePacking("weight_uom", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Weight UOM"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddPackingModal