import React, { useState } from "react";
import TableOrganizer from "../../Common/TableOrganizer";
import { DEFAULT_MAX_WIDTH_CELL, DEFAULT_MIN_WIDTH_CELL, ROWS_PER_PAGE_OPTIONS } from "../Constants";
import { useSort } from "../../Common/TableOrganizer/common/hooks/useSort";
import '../TableView/index.css';
import { RenderActionButtons } from "./components/RenderActionButtons";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export function TableView({ addPartyScan, successSnackbar, amberSnackbar, headCells, 
    summaryList, customerSummary, countries }) {
    const navigate = useNavigate();
    const { columnOrder, columnOrderBy, handleRequestSort } = useSort();
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0].value)
    const TotalRecords = summaryList.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    }

    const returnPaginationData = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const handleChangeAddress = (id) => {
        navigate(`/customer-address/${id}`);
    }

    const handleChangeContact = (id) => {
        Cookies.set('screen', 'customer');
        navigate(`/contact-details/${id}`);
    }

    const COLUMNS = []
    headCells.forEach(head => {
        const { id, label, selected, ...rest } = head
        if (selected) {
            const obj = {
                dataField: id,
                headerName: label,
                ...rest
            }
            COLUMNS.push(obj)
        }
    })

    const actionColumn = {
        dataField: "actions",
        headerName: "Actions",
        align: 'center',
        sortable: false,
        width: 120,
        truncate: true,
        cellRenderMethod: (record, dataField, index, isParent, parentRowIndex) => (
            <RenderActionButtons
                record={record}
                dataField={dataField}
                index={index}
                isParent={isParent}
                parentRowIndex={parentRowIndex}
                summaryList={summaryList}
                amberSnackbar={amberSnackbar}
                successSnackbar={successSnackbar}
                customerSummary={customerSummary}
                addPartyScan={addPartyScan}
                countries={countries}
            />
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    const locationColumn = {
        dataField: "View Location",
        headerName: "View Location",
        align: 'center',
        sortable: false,
        width: 120,
        truncate: true,
        cellRenderMethod: (record) => (
            <Button
                variant="text" style={{ textTransform: "none" }}
                onClick={() => handleChangeAddress(record.pid)}>
                View Location
            </Button>
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    const contactColumn = {
        dataField: "View Contact",
        headerName: "View Contact",
        align: 'center',
        sortable: false,
        width: 120,
        truncate: true,
        cellRenderMethod: (record) => (
            <Button
                variant="text" style={{ textTransform: "none" }}
                onClick={() => handleChangeContact(record.pid)}>
                View Contact
            </Button>
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    return (
        <TableOrganizer
            columns={[...COLUMNS, locationColumn, contactColumn, actionColumn]}
            tableData={returnPaginationData(summaryList, recordsPerPage, currentPage)}
            defaultMinWidthForCell={DEFAULT_MIN_WIDTH_CELL}
            defaultMaxWidthForCell={DEFAULT_MAX_WIDTH_CELL}
            tableProps={{ stickyHeader: true, tableContainerHeight: 630 }}
            order={columnOrder} // Order 'ASC' will be having values 'ASC' || 'DESC' || NULL
            orderBy={columnOrderBy}
            handleRequestSort={handleRequestSort}
            pagination={true}
            currentPage={currentPage}
            recordsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            recordsPerPage={recordsPerPage}
            totalPageCount={Pagecount}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            className="summaryViewTable"
        />
    );
}

export default TableView;
