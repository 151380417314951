import { Grid, Typography } from "@material-ui/core";
import { LightTooltip } from "../../../Common/constant";
import Notification from "../../../assets/Notification_Icon.svg";
import { useNavigate } from "react-router-dom";

const BoxHead = ({headTitle, redirectLink, tooltipInfo, tooltipTitle}) => {
    const navigate = useNavigate();
    const handleRedirect = ()=> navigate(redirectLink);
    const getRedirectTooltip = `Click here to open '${tooltipTitle}' application`;
    return (
        <Grid item container xs={12} className="icapboxHead">
            <LightTooltip title={getRedirectTooltip} onClick={()=>handleRedirect()}>
                <Typography variant="h6">{headTitle}</Typography>
            </LightTooltip>

            <LightTooltip title={tooltipInfo} placement="bottom-end">
                <img className='cursor_pointer' src={Notification} alt="info for Survey" />
            </LightTooltip>
        </Grid>
    )
}

export default BoxHead;