import { Dialog, DialogTitle, DialogContent, Grid, ButtonGroup, Button, Tooltip, DialogActions, Paper, TableContainer, TableHead, TableRow, Table, TableCell, Typography, TableBody, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import { SpinnerCircularFixed } from "spinners-react";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableWrapper: {
        maxHeight: 499
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 190
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    pageNumber: {
        color: '#828282',
    },
    paginationUl: {
        '& ul': {
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-page.Mui-selected': {
                    color: theme.palette.primary.dark,
                    background: 'transparent',
                }
            }
        }
    },
    buttonRoot: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        border: 0,
        margin: `` + theme.spacing(.5) + `px 0 ` + theme.spacing(.5) + `px ` + theme.spacing(.5) + `px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
            position: 'absolute',
            right: -15,
            content: '""',
            top: 0,
            height: 24,
            textDecoration: 'none'
        },
        '&:hover': {
            background: 'none',
            color: '#2B0A3D',
            fontWeight: 600,
            border: 0,
            textDecoration: 'none'
        },
        '&.active': {
            color: '#2B0A3D',
            fontWeight: 600,
            textDecoration: 'none',
            '&:before': {
                backgroundColor: '#2B0A3D',
                left: 0,
                content: '""',
                bottom: -5,
                height: 2,
                position: 'absolute',
                width: '100%',
                textDecoration: 'none'
            }
        }
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
    },
    stickyPagination: {
        position: 'sticky',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        marginTop: theme.spacing(1.5),
        background: '#F3F3F3'
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            maxWidth: 220,
            fontFamily: "sans-serif",
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    },
}));

const ScanPartyPopupModal = ({ isPopupOpen, setIsPopupOpen, scanDetails, isLoading, matches }) => {
    const classes = useStyles();
    const [activeSeletion, setActiveSeletion] = useState(2);
    const filteredData = scanDetails.filter(row => {
        return row;
    });

    const modifiedData = filteredData.map(row => {
        const modifiedRow = {};
        for (const key in row) {
            if (row.hasOwnProperty(key)) {
                const newKey = key.replace(/[\s/]/g, ''); // Remove spaces and slashes
                modifiedRow[newKey] = row[key];
            }
        }
        return modifiedRow;
    });

    const ROWS_PER_PAGE = [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: filteredData.length }
    ];
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE[0].value)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0].value);

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
        setActiveSeletion(1)
        setRowsPerPage(value);
        setPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setActiveSeletion(2)
        setPage(newPage);
    }

    const handleCancel = () => {
        setIsPopupOpen(false);
    }

    const TotalRecords = filteredData.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const validate = (index) => {
        if (activeSeletion === 1) {
            // user clicks on show option recently
            return rowsPerPage > index;
        }
        if (activeSeletion === 2) {
            // user clicks on pagination numbers recently
            return (page === 1 ? 0 : (rowsPerPage * page) - rowsPerPage) <= index && index < (page === 1 ? 25 : (((page * rowsPerPage) - rowsPerPage) + rowsPerPage));
        }
    }

    console.log(matches)

    return (
        <Dialog open={isPopupOpen} maxWidth="xl">
            <DialogTitle style={{ color: "#1381B9" }}>Scan Details</DialogTitle>
            <DialogContent dividers>
                {isLoading ? <SpinnerCircularFixed enabled={isLoading} /> :
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Typography style={{textAlign: "center", marginBottom: '8px'}}>Number of Matches : {matches}</Typography>
                        <TableContainer className={classes.tableWrapper}>
                            <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Sl No.</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Source</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Name</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Alternative Names</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Country</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Address</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Classification of the entity</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.muteText}>Federal Register Notice</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modifiedData.map((row, index) => (
                                        <>
                                            {
                                                validate(index) && <TableRow key={row.sorepoid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <Tooltip title={row.Source !== null ? row.Source : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.Source}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={(row.name !== null ? row.name : "")} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.Name}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.Alternativenames !== null ? row.Alternativenames : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.Alternativenames}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.Country !== null ? row.Country : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.Country}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.Address !== null ? row.Address : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.Address}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.ClassificationOfTheEntity !== null ? row.ClassificationOfTheEntity : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.ClassificationOfTheEntity}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.FederalRegisterNotice !== null ? row.federalregisternotice : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.FederalRegisterNotice}
                                                        </TableCell>
                                                    </Tooltip>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                            <Grid container boxShadow={3} className={classes.stickyPagination} >
                                <Grid item xs={3}>
                                    <Typography className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {Pagecount} </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Pagination className={[classes.paginationUl, { root: classes.pageNumber }]} count={Pagecount} showFirstButton showLastButton onChange={handleChangePage} />
                                </Grid>
                                <Grid container item xs={4} justifyContent='center'>
                                    <Typography className={classes.paginationFont}>Show</Typography>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        {ROWS_PER_PAGE.map(({ label, value }) => (
                                            <Button
                                                key={value}
                                                className={recordsPerPage === value && 'active'}
                                                classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                                                onClick={() => handleChangeRowsPerPage(value)}>
                                                {label}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </TableContainer>
                    </Paper>}

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScanPartyPopupModal;