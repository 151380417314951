import { createContext, useEffect } from "react";
import { fecthBlacklistCustomer, updateScan } from "../../../Common/API";
import { SpinnerCircularFixed } from "spinners-react";
import { HEAD_CELLS } from '../constants/index';
import { useState } from "react";
import Cookies from "js-cookie";

const initialState = {
    loading: false,
    surveyList: [],
    error: null,
    hasError: false,
    headCells: HEAD_CELLS,
    order: 'asc',
    orderBy: '',
    pageId: null,
    noOfMatches: ''
};

const spinnerStyle = {
    marginLeft: '50%',
    marginTop: '2%',
};

export const SearchPartyContext = createContext(initialState);

export const SearchPartyContextProvider = ({ children }) => {

    const [allSearchPartyData, setAllSearchPartyData] = useState(initialState);
    const { summaryList, loading, headCells, orderBy, order, noOfMatches } = allSearchPartyData;

    const searchParty = (payload, partyname, address) => {
        fecthBlacklistCustomer(payload).then(data => {
            if (data.summary.matches !== "0") {
                updateScan(partyname, address, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                })
                const summaryData = data.records.map((data, index) => {
                    return data;
                });
                setAllSearchPartyData((prevState) => {
                    return {
                        ...prevState,
                        summaryList: summaryData,
                        noOfMatches: data.summary.matches,
                        loading: false,
                        error: null,
                        hasError: false,
                        searchTerm: '',
                    }
                });
            } else {
                updateScan(partyname, address, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                })
                setAllSearchPartyData((prevState) => {
                    return {
                        ...prevState,
                        summaryList: [],
                        loading: false,
                        hasError: true,
                    }
                });
            }
        })
            .catch((error) => {
                setAllSearchPartyData({
                    ...allSearchPartyData,
                    loading: false,
                    error: error,
                    hasError: true,
                });
            });
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setAllSearchPartyData({
            ...allSearchPartyData,
            order: isAsc ? 'desc' : 'asc',
            orderBy: property,
        });
    };

    function descendingComparator(a, b, orderByVal) {
        const orderByArray = orderByVal.split('.');
        if (
            orderByArray &&
            orderByArray.length &&
            b[orderByArray[0]] &&
            b[orderByArray[0]][orderByArray[1]] &&
            a[orderByArray[0]] &&
            a[orderByArray[0]][orderByArray[1]]
        ) {
            if (
                b[orderByArray[0]][orderByArray[1]] <
                a[orderByArray[0]][orderByArray[1]]
            ) {
                return -1;
            }
            return 1;
        } else if (b[orderByVal] && a[orderByVal]) {
            const isNumber = typeof a[orderByVal] === 'number';
            const filedValueB = isNumber
                ? b[orderByVal]
                : b[orderByVal].toUpperCase();
            const filedValueA = isNumber
                ? a[orderByVal]
                : a[orderByVal].toUpperCase();
            if (filedValueB < filedValueA) {
                return -1;
            }
            return 1;
        }
        return 0;
    }

    function getComparator(orderVal, orderByVal) {
        return orderVal === 'desc'
            ? (a, b) => descendingComparator(a, b, orderByVal)
            : (a, b) => -descendingComparator(a, b, orderByVal);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const orders = comparator(a[0], b[0]);
          if (orders !== 0) return orders;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

      const contextValues = {
        ...allSearchPartyData,
        summaryList,
        headCells,
        noOfMatches,
        searchParty,
        handleRequestSort,
        getComparator,
        stableSort,
      };
    
      return (
        <SearchPartyContext.Provider value={contextValues}>
          {loading ? (
            <div style={spinnerStyle}>
              {' '}
              <SpinnerCircularFixed
                color='#0070AD'
                enabled={loading}
              />{' '}
            </div>
          ) : (
            children
          )}
        </SearchPartyContext.Provider>
      );
}