import { CircularProgress, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    loadingGridRoot: {
        minHeight: '40vh',
        alignItems: 'end',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const PageLoader = () => {
const classes = useStyles();
return (
    <Grid item xs={12} className={{root: classes.loadingGridRoot}}>
        <CircularProgress color="inherit" />
    </Grid>
)
}

export default PageLoader;