import { SpinnerCircularFixed } from "spinners-react";
import { PURCHASE_ORDERS, PURCHASE_ORDER_INFO, REDIRECT_TO_PO, Total, Week, PO_PER_COUNTRY_TITLE, PO_PIE_TITLE, RenderProgressStake, BCPProgressColors } from "../../../Common/constant";
import { Grid, Typography, Box, Divider, makeStyles } from "@material-ui/core";
import BoxImg from "../../common/BoxImg";
import PurchaseOrderImage from '../../../assets/po-icon.png';
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from '../../../Common/Fallback';
import BoxHead from "../../common/BoxHead";
import DonutReport from "../../common/DonutReport";
import { useEffect } from "react";
import { fetchPoGraphData } from "../../../Common/API";
import { useNavigate } from "react-router-dom";
import ArrowImg from '../../../assets/redo.png';
import BoxArrowImg from "../../common/BoxArrowImg";

const useStyles = makeStyles(theme => ({
  parentDiv: {
    height: '28px',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    '& .boxStyle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
}))

const PurchaseOrderDash = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPoGraphData(props.filter).then(data => {
      if (data.status === "success") {
        props.setPOCtryGraph(data.pocountry);
        props.setPoCount(data.pocount[0]);
        props.setPOPending(data.poplanpending[0]);
      } else {
        props.setLoading({ ...props.loading, noDataMode: true, isLoading: false });
      }
    }).catch(error => {
      props.setLoading({ ...props.loading, noDataMode: true, isLoading: false });
    })
  }, [])

  const ProgressBarDelplan = [{
    "id": 1,
    "status": "Total",
    data: {
      "id": 3,
      "category_name": "Overdue",
      "counts": 10
    }
  }, {
    "id": 2,
    "status": "This Week",
    data: {
      "id": 4,
      "category_name": "This Week",
      "counts": 5
    }
  }]

  const DelPlanArray = [];
  ProgressBarDelplan.length && ProgressBarDelplan.forEach(bcp => {
    DelPlanArray.push(bcp.data)
  })

  const DEV_LEGEND_DELPLAN = [Total, Week];
  const devplanValue = DEV_LEGEND_DELPLAN.map(() => 0);

  if (ProgressBarDelplan && ProgressBarDelplan.length) {
    ProgressBarDelplan.forEach(({ status }) => {
      if (status === "Total") {
        devplanValue[0] += parseInt(props.poPending.pocount);
      } else if (status === "This Week") {
        devplanValue[1] += 0;
      } else {
        devplanValue[0] += 1;
      }
    });
  }

  const devPlanData = { Total: devplanValue[0], Week: devplanValue[1] };
  const devPlantotal = devplanValue[0] + devplanValue[1]

  const poCtryData = Object.entries(
    props.poCtryGraph.reduce((prev, { pocountry, pocount }) => {
      prev[pocountry] = prev[pocountry] ? prev[pocountry] + 1 : parseInt(pocount);
      return prev;
    }, {})
  )
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => b.count - a.count);

  const graphClick = (origin) => {
    localStorage.removeItem('wostatus');
    localStorage.setItem('origin', origin);
    navigate('/purchase-order/summary');
  }

  const handleClickBar = () => {
    localStorage.removeItem('origin');
    localStorage.setItem('wostatus', 'Pending');
    navigate('/work-order/summary');
  }

  return (
    <Grid item container xs={12} className="icapBox businessChange">
      <Grid item container xs={12} className="icapboxHead">
        <BoxHead headTitle={PURCHASE_ORDERS} tooltipTitle={PURCHASE_ORDERS} tooltipInfo={PURCHASE_ORDER_INFO} redirectLink={REDIRECT_TO_PO} />
        <BoxArrowImg headImg={ArrowImg} tooltipTitle={PURCHASE_ORDERS} redirectLink={REDIRECT_TO_PO} type="PO" />
      </Grid>
      {!props.poCtryGraph.length ? (
        <div className='loaderWithMessage'>{props.loading.noDataMode ? (<p>{props.loading.nodataMsg}</p>) : (<SpinnerCircularFixed color="#0070AD" enabled={props.loading.isLoading} />)}</div>
      ) : (
        <Grid container item xs={12} spacing={2}>
          <Grid item container xs={3} className="align-center">
            <Grid item xs={6}>
              <BoxImg headImg={PurchaseOrderImage} tooltipTitle={PURCHASE_ORDERS} redirectLink={REDIRECT_TO_PO} />
            </Grid>
            <Grid item xs={6}>
              <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <Typography className="icapBoxNumberTxt mb-0" gutterBottom variant="h6">{props.poCount.pocount}</Typography>
              </ErrorBoundary>
              <Typography className="icapBoxTxt" variant="h6">Total Purchase Orders</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={3}>
            <Grid item xs={12}>
              <Grid item container justifyContent='space-between' xs={12}>
                <Typography className="icapBoxBoldTxt" variant="h6">Total Delivery Plan Pending</Typography>
                <Typography className="icapBoxBoldTxt" variant="h6"> {props.poPending.pocount} </Typography>
              </Grid>
              <Divider style={{ marginTop: "10px", marginBottom: "19px" }} />
            </Grid>
            <Grid item xs={12} className="progressbarContent">
              <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <Box className={classes.parentDiv} onClick={() => handleClickBar()}>{RenderProgressStake(devPlanData, devPlantotal, BCPProgressColors)}</Box>
              </ErrorBoundary>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
              <DonutReport title={PO_PER_COUNTRY_TITLE} pieTitle={PO_PIE_TITLE} dataSource={poCtryData} totalCount={props.poCount.pocount} graphClick={graphClick} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default PurchaseOrderDash;