import { Box, Grid, Paper, Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import wIcon from '../../../assets/w-icon.png';
import { getDefaultWoInfo, workOrderStyle } from '../../../Common/constant';
import { useEffect } from 'react';
import { fetchCustomerWoDetails, saveWOMessage } from '../../../Common/API';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback, errorHandler } from '../../../Common/Fallback';
import LoadDetails from './components/LoadDetails';
import ReceiptAtFF from './components/ReceiptAtFF';
import AWBNumber from './components/AWBNumber';
import ItemDetails from './components/ItemDetails';
import WorkOrderView from './components/WorkOrderView';
import CustomerFF from './components/CustomerFF';
import CustomerDL from './components/CustomerDL';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labeledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box p={index === 3 ? 0 : 3}>
                    <Typography component="div">
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    )
}

const CustomerWorkOrder = () => {
    const classes = workOrderStyle();
    const { custwoid } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [woInfo, setWoInfo] = useState(getDefaultWoInfo);

    useEffect(() => {
        refreshWO();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const refreshWO = () => {
        (async () => {
            await fetchCustomerWoDetails(custwoid).then((res) => {
                setWoInfo((prevState) => {
                    return {
                        ...prevState,
                        woList: res.order[0],
                        deliveryLocation: res.dellocation[0],
                        trackShipment: res.tracking,
                        container: res.containers,
                        trackShipmentDate: res.trackdates,
                        freightForward: res.forwarding,
                        message: res.msgdetails,
                        repository: res.repodetails,
                        partyFFR: res.partyffr[0],
                        itemDetails: res.items
                    }
                });
            }).catch(error => {
                //amberSnackbar(error);
            })
        })();
    }

    const addMessage = (reqObj) => {
        saveWOMessage(reqObj).then(data => {
            if (data.status === 1) {
                //successSnackbar(data.message);
                refreshWO();
            } else {
                //amberSnackbar(data.message);
            }
        }).catch(error => {
            //amberSnackbar(error);
        })
    }

    return (
        <Grid container item xs={12}>
            <Grid item xs={2}>
                <Box ml={3} me={2}>
                    <Paper elevation={10} className={classes.leftPaper}>
                        <Grid item>
                            <img src={wIcon} className={classes.profileIcon} />
                        </Grid>
                        <Grid item container xs={12} spacing={4} className={classes.topP}>
                            <Grid item xs={12}>
                                <Typography className={classes.disabled}>Work Order No.</Typography>
                                <Typography>{woInfo.woList?.workorderno}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.disabled}>Date</Typography>
                                <Typography>{woInfo.woList?.workorderdate}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.disabled}>Origin</Typography>
                                <Typography>{woInfo.woList?.Origin}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.disabled}>Destination</Typography>
                                <Typography>{woInfo.woList?.deldestination}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Box ml={2} mr={3}>
                    <Paper elevation={10} className={classes.paper}>
                        <AppBar position="static" color="default" className={classes.topPadding}>
                            <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable"
                                scrollButtons="auto">
                                <Tab label="Work Order" style={{ textTransform: "none" }} />
                                <Tab label="Delivery Location" style={{ textTransform: "none" }} />
                                <Tab label="Frieght Forwarder" style={{ textTransform: "none" }} />
                                <Tab label="Item Details" style={{ textTransform: "none" }} />  
                                <Tab label="AWB Nos" style={{ textTransform: "none" }} />
                                <Tab label="Receipt @FF" style={{ textTransform: "none" }} />
                                <Tab label="Loading Details" style={{ textTransform: "none" }} />           
                            </Tabs>
                        </AppBar>
                        <Box className={classes.topP}>
                            <TabPanel value={tabIndex} index={0}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <WorkOrderView woList={woInfo.woList} trackData={woInfo.trackShipmentDate}
                                    fileList={woInfo.repository} addMessage={addMessage} messageList={woInfo.message} 
                                    custwoid={custwoid} />
                                </ErrorBoundary>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <CustomerDL locationData={woInfo.deliveryLocation} modeofTrans={woInfo.woList?.modeoftransport} />
                                </ErrorBoundary>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <CustomerFF ffData={woInfo.partyFFR} />
                                </ErrorBoundary>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <ItemDetails itemData={woInfo.itemDetails} />
                                </ErrorBoundary>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={4}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <AWBNumber awbData={woInfo.trackShipment} />
                                </ErrorBoundary>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={5}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <ReceiptAtFF ffData={woInfo.freightForward} />
                                </ErrorBoundary>
                            </TabPanel> 
                            <TabPanel value={tabIndex} index={6}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <LoadDetails loadData={woInfo.container} />
                                </ErrorBoundary>
                            </TabPanel>
                        </Box>
                    </Paper>
                </Box>
            </Grid >
        </Grid>

    );
}

export default CustomerWorkOrder;