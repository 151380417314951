export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'slno'
export const PO_NO = 'po_number'
export const PO_DATE = 'po_date'
export const CUSTOMER = 'customer'
export const ORIGIN = 'Origin'
export const BRAND = 'po_party_brand'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'slno', width: 1, stickyColumn: 'left'},
    { id: PO_NO, isSortable: true, selected: true, label: 'Po No', fieldName: 'po_number', width: 30},
    { id: PO_DATE, isSortable: true, selected: true, label: 'Po Date', fieldName: 'po_date', width: 30},
    { id: CUSTOMER, isSortable: true, selected: true, label: 'Customer', fieldName: 'customer', width: 30},
    { id: ORIGIN, isSortable: true, selected: true, label: 'Origin', fieldName: 'origin', width: 20 },
    { id: BRAND, isSortable: true, selected: true, label: 'Brand', fieldName: 'brand', width: 20},
]
