/** @format */

import React, { useRef, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { Box, Typography, Button, FormControl, TextField } from '@material-ui/core';
import UnionIcon from '../../../../../assets/Union.svg';
import SimpleReactValidator from 'simple-react-validator';

const ubuntuFont = 'Ubuntu, sans-serif';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '20px',
  },
  styledBox: {
    columnGap: 12,
  },
  selectButton: {
    fontFamily: ubuntuFont,
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'capitalize',
    borderRadius: 16,
  },
  dividerBottom: {
    margin: '20px 0px',
    backgroundColor: '#E0E0E0',
  },
  arrow: {
    color: '#ccc',
  },
  tooltip: {
    fontFamily: ubuntuFont,
    backgroundColor: '#fff',
    color: 'black',
    border: '1px solid #ccc',
  },
  tooltext: {
    fontFamily: ubuntuFont,
    padding: '10px',
    fontSize: '15px',
  },
  styledFont: {
    fontFamily: ubuntuFont,
    color: '#3b3838',
  },
  accordianExpandedTitle: {
    fontSize: '12px',
    lineHeight: '13px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: '600',
    fontFamily: 'sans-serif',
    letterSpacing: '0px',
    color: '#2B0A3D',
    opacity: 1
  },
  inputField: {
    marginTop: '13px',
    width: "600px"
  },
  mandatoryField: {
    color: '#FF304C',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'padding-box',
    paddingRight: '2px'
  },
  validateStyle: {
    color: '#f44336',
    fontSize: '12px',
    fontWeight: '100',
    marginTop: 20
  }
}));

function BootstrapTooltip(props) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={classes.tooltip}
      {...props}
    />
  );
}

const DeleteConfirmationModal = ({ openDeleteModal, setOpenDeleteModal, workOrderDelete }) => {
  const classes = useStyles();
  const [reason, setReason] = useState();
  const [, forceUpdate] = useState();
  const formValidator = useRef(new SimpleReactValidator({}));

  const handleModalClose = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = () => {
    const isValid = formValidator.current.allValid();
        if (isValid) {
          workOrderDelete(reason);
        }else{
          formValidator.current.showMessages();
            forceUpdate(1);
        }
  }

  if (!openDeleteModal) return null;

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openDeleteModal}
        closeAfterTransition>
        <Fade in={openDeleteModal}>
          <div className={classes.paper}>
            <h4 id='transition-modal-title' style={{fontFamily: 'sans-serif', marginBottom: '24px', color: '#1381B9'}}>Confirmation</h4>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <Typography className={classes.accordianExpandedTitle}>
                  <label htmlFor="reason">
                    <span className={classes.mandatoryField}>*</span>
                    Reason
                  </label>
                </Typography>
                <TextField
                  id="reason"
                  color="primary"
                  variant="outlined"
                  className={classes.inputField}
                  multiline
                  minRows="3"
                  placeholder="Reason"
                  onChange={(e) => setReason(e.target.value)}
                />
                {formValidator.current.message('Reason', reason, 'required', { className: classes.validateStyle })}
              </FormControl>
            </Grid>
            {/* <Typography className={classes.styledFont}>
              Are you sure you want to delete this purchase order? This action
              cannot be undone.{' '}
              <BootstrapTooltip
                placement='bottom-start'
                title={
                  <React.Fragment>
                    <Typography className={classes.tooltext}>
                      Deleting this purchase order will also delete the deliveryplan
                      associated with it.
                    </Typography>
                  </React.Fragment>
                }>
                <img
                  src={UnionIcon}
                  alt='warning'
                />
              </BootstrapTooltip>
            </Typography> */}
            <Divider
              className={classes.dividerBottom}
              orientation='horizontal'
            />
            <Box
              display='flex'
              flexDirection='row'
              p={1}
              m={1}
              bgcolor='background.paper'
              justifyContent='flex-end'
              alignItems='center'
              className={classes.styledBox}>
              <Box>
                <Button
                  color='primary'
                  variant='outlined'
                  size='small'
                  className={classes.selectButton}
                  onClick={() => handleModalClose()}>
                  No, Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  className={classes.selectButton}
                  onClick={() => handleDelete()}>
                  {' '}
                  Yes, Confirm{' '}
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default DeleteConfirmationModal;
