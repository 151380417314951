import { Grid, FormControl, Typography, TextField, makeStyles, Button, Paper } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-44px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
}))

const CustomerDL = ({locationData, modeofTrans}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="party_name">
                                    Location
                                </label>
                            </Typography>
                            <TextField
                                id="party"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Party Name"
                                value={locationData?.party_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="contact_name">
                                    Contact Name
                                </label>
                            </Typography>
                            <TextField
                                id="contact"
                                color="primary"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                className={classes.inputField}
                                placeholder="Contact Name"
                                value={locationData?.party_contact}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="telephone">
                                    Telephone
                                </label>
                            </Typography>
                            <TextField
                                id="telephone"
                                color="primary"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                className={classes.inputField}
                                placeholder="Telephone"
                                value={locationData?.party_telephone}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="email">
                                    <span className={classes.mandatoryField}>*</span>
                                    Email
                                </label>
                            </Typography>
                            <TextField
                                id="Email"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Email"
                                value={locationData?.party_email}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="vat_no">
                                    VAT No.
                                </label>
                            </Typography>
                            <TextField
                                id="VAT"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="VAT No."
                                value={locationData?.party_vatno}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="add1">
                                    Address 1
                                </label>
                            </Typography>
                            <TextField
                                id="add1"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Address 1"
                                value={locationData?.party_addr1}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="add2">
                                    Address 2
                                </label>
                            </Typography>
                            <TextField
                                id="add2"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Address 2"
                                value={locationData?.party_addr2}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="city">
                                    City
                                </label>
                            </Typography>
                            <TextField
                                id="city"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="City"
                                value={locationData?.party_city}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="state">
                                    State
                                </label>
                            </Typography>
                            <TextField
                                id="state"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="State"
                                value={locationData?.party_state}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="zip">
                                    Zip
                                </label>
                            </Typography>
                            <TextField
                                id="zip"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Zip"
                                value={locationData?.party_zipcode}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="country">
                                    Country
                                </label>
                            </Typography>
                            <TextField
                                id="country"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Country"
                                value={locationData?.party_country}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="modeOft">
                                    Mode of Transport
                                </label>
                            </Typography>
                            <TextField
                                id="modeOft"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                placeholder="Mode of Transport"
                                value={modeofTrans}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default CustomerDL;