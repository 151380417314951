import Chart from "react-google-charts";

export default function PieChart({ data, graphClick }) {

    const options = {
        pieHole: 0.6,
        is3D: false,
    };

    const handleRowClick = (e) => {
        const chart = e.chartWrapper.getChart();
        const selection = chart.getSelection();
        const clickData = e.chartWrapper.Bea.cache;
        if (selection.length > 0) {
            const selectedSlice = selection[0].row;
            const selectedRowValue = clickData[selectedSlice][0].Me;
            graphClick(selectedRowValue);
        }
    };
    return (
        <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
            chartEvents={[
                {
                    eventName: "select",
                    callback: handleRowClick,
                },
            ]}
        />
    )
}