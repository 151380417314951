export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'pid'
export const PARTY_NAME = 'party_name'
export const PARTY_TYPE = 'party_type'
export const ADDRESS = 'party_addr1'
export const CITY = 'party_city'
export const STATE = 'party_state'
export const COUNTRY = 'party_country'
export const LAST_SCAN_DATE = 'scan_time'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'slno', width: 80, stickyColumn: 'left'},
    { id: PARTY_NAME, isSortable: true, selected: true, label: 'Party Name', fieldName: 'party_name', width: 180},
    { id: PARTY_TYPE, isSortable: true, selected: true, label: 'Party Type', fieldName: 'party_type', width: 140},
    { id: ADDRESS, isSortable: true, selected: true, label: 'Address', fieldName: 'address', width: 170 },
    { id: CITY, isSortable: true, selected: true, label: 'City', fieldName: 'city', width: 150 },
    { id: STATE, isSortable: true, selected: true, label: 'State', fieldName: 'staet', width: 150},
    { id: COUNTRY, isSortable: true, selected: true, label: 'Country', fieldName: 'country', width: 100},
    { id: LAST_SCAN_DATE, isSortable: true, selected: true, label: 'Last Scan Date', fieldName: 'scan_date', width: 150}
]
