/** @format */

import React from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import { Divider, makeStyles } from '@material-ui/core';
import { Box, Typography, Button } from '@material-ui/core';
import UnionIcon from '../../../../assets/Union.svg';

const ubuntuFont = 'Ubuntu, sans-serif';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '20px',
  },
  styledBox: {
    columnGap: 12,
  },
  selectButton: {
    fontFamily: ubuntuFont,
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'capitalize',
    borderRadius: 16,
  },
  dividerBottom: {
    margin: '20px 0px',
    backgroundColor: '#E0E0E0',
  },
  arrow: {
    color: '#ccc',
  },
  tooltip: {
    fontFamily: ubuntuFont,
    backgroundColor: '#fff',
    color: 'black',
    border: '1px solid #ccc',
  },
  tooltext: {
    fontFamily: ubuntuFont,
    padding: '10px',
    fontSize: '15px',
  },
  styledFont: {
    fontFamily: ubuntuFont,
    color: '#3b3838',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={classes.tooltip}
      {...props}
    />
  );
}

const DeleteConfirmationModal = ({ handleModalClose, openDeleteModal }) => {
  const classes = useStyles();

  if (!openDeleteModal) return null;

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openDeleteModal}
        closeAfterTransition>
        <Fade in={openDeleteModal}>
          <div className={classes.paper}>
            <h4 id='transition-modal-title'>Confirmation</h4>
            <Typography className={classes.styledFont}>
              Are you sure you want to delete this purchase order? This action
              cannot be undone.{' '}
              <BootstrapTooltip
                placement='bottom-start'
                title={
                  <React.Fragment>
                    <Typography className={classes.tooltext}>
                      Deleting this purchase order will also delete the deliveryplan
                      associated with it.
                    </Typography>
                  </React.Fragment>
                }>
                <img
                  src={UnionIcon}
                  alt='warning'
                />
              </BootstrapTooltip>
            </Typography>
            <Divider
              className={classes.dividerBottom}
              orientation='horizontal'
            />
            <Box
              display='flex'
              flexDirection='row'
              p={1}
              m={1}
              bgcolor='background.paper'
              justifyContent='flex-end'
              alignItems='center'
              className={classes.styledBox}>
              <Box>
                <Button
                  color='primary'
                  variant='outlined'
                  size='small'
                  className={classes.selectButton}
                  onClick={() => handleModalClose()}>
                  No, Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  className={classes.selectButton}
                  onClick={() => handleModalClose(true)}>
                  {' '}
                  Yes, Confirm{' '}
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default DeleteConfirmationModal;
