import { Grid, Button, FormControl, makeStyles, Typography, TextField, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Cookies from "js-cookie";
import { fecthBlacklistCustomer } from "../../../../../Common/API";
import { agentModal } from "../../../../../Common/constant";
import { useNavigate } from "react-router-dom";
import ScanPartyPopupModal from "../../../../../Common/ScanPartyPopupModal";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '10px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-44px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-44px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    errorMessge: {
        color: '#af5858',
        fontSize: 16,
        border: 'solid 1px #af5858',
        margin: '26px 0',
        padding: 15
    },
}))

const DeliveryAgent = (props) => {
    const classes = useStyles();
    const [agentData, setAgentData] = useState(props.delagent);
    const [, forceUpdate] = useState();
    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const [isEnable, setIsEnable] = useState([]);
    const [isButton, setIsButton] = useState(true);
    const [isNewAgent, setIsNewAgent] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [matchData, setMatchData] = useState([]);
    const [noOfMatch, setNoOfMatch] = useState("");
    const [partyName, setPartyName] = useState(props.delagent);
    const formValidator = useRef(new SimpleReactValidator({}));
    const validateParty = useRef(new SimpleReactValidator({}));

    const handleChangeFF = (field, value) => {
        setIsButton(false);
        if (field === "party_country") {
            let newObject = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setCountry(newObject);
        }
        setAgentData(values => ({ ...values, [field]: value }));
    }

    const handleSaveAgent = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...agentModal }
            payload.party_name = agentData.party_name;
            payload.contact_email = agentData.contact_email;
            payload.contact_phone = agentData.contact_phone ? agentData.contact_phone : '';
            payload.contact_name = agentData.contact_name ? agentData.contact_name : '';
            payload.party_addr1 = agentData.party_addr1 ? agentData.party_addr1 : '';
            payload.party_addr2 = agentData.party_addr2 ? agentData.party_addr2 : '';
            payload.party_city = agentData.party_city ? agentData.party_city : '';
            payload.party_state = agentData.party_state ? agentData.party_state : '';
            payload.party_zipcode = agentData.party_zipcode ? agentData.party_zipcode : '';
            payload.party_ein = agentData.party_ein ? agentData.party_ein : '';
            payload.party_vatno = agentData.party_vatno ? agentData.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.password = agentData.password;
            payload.party_country = country.ctrycode;
            payload.woid = props.woid;
            props.addDeliveryAgent(payload);
            setIsEnable(false);
            setIsButton(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        navigate('/');
    }

    const handleSelectAgent = () => {
        setIsNewAgent(false);
        setAgentData(agentModal);
        setIsEnable(true);
        setMatchData();
    }

    const handleAddAgent = () => {
        setIsNewAgent(true);
        setPartyName([]);
        setAgentData(agentModal);
        setIsEnable(true);
        setMatchData();
    }

    const handleUpdateAgent = () => {
        const isValid = validateParty.current.allValid();
        if (isValid) {
            const payload = { ...agentModal }
            payload.party_name = partyName.party_name ? partyName.party_name : agentData.party_name;
            payload.contact_email = agentData.contact_email;
            payload.contact_phone = agentData.contact_phone ? agentData.contact_phone : '';
            payload.contact_name = agentData.contact_name ? agentData.contact_name : '';
            payload.party_addr1 = agentData.party_addr1 ? agentData.party_addr1 : '';
            payload.party_addr2 = agentData.party_addr2 ? agentData.party_addr2 : '';
            payload.party_city = agentData.party_city ? agentData.party_city : '';
            payload.party_state = agentData.party_state ? agentData.party_state : '';
            payload.party_zipcode = agentData.party_zipcode ? agentData.party_zipcode : '';
            payload.party_ein = agentData.party_ein ? agentData.party_ein : '';
            payload.party_vatno = agentData.party_vatno ? agentData.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.password = agentData.password;
            payload.party_country = country.ctrycode;
            payload.agentpid = partyName.pid ? partyName.pid : agentData.pid;
            payload.woid = props.woid;
            props.updateAgent(payload);
            setIsEnable(false);
            setIsButton(true);
        } else {
            validateParty.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleChangePartyName = (value) => {
        setIsButton(false);
        const obj = {
            id: value.pid,
            party_name: value.party_name,
            pid: value.pid
        }
        setPartyName(obj);
        props.setPartyId(obj.pid)
        setAgentData(props.agent.find(x => x.pid === value.pid))
    }

    const getScanDetails = () => {
        const payload = {
            "search": {
                "securekey": "dMvMAaml7DdsJN7Y0MHcgIrqhB9d5qOb7fAnq",
                "filters": {
                    "fuzzylogic": "false",
                    "dplguruforensic": "false",
                    "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
                    "exclusionwords": "pvt",
                    "redflag": "military"
                },
                "itemstosearch": [
                    {
                        "name": agentData.party_name,
                        "address": "",
                        "country_code": ""
                    }
                ]
            }
        }

        fecthBlacklistCustomer(payload).then(data => {
            setNoOfMatch(data.summary.matches)
            if (data.summary.matches === "0") {
                setMatchData([]);
                setIsLoading(false);
            } else {
                setMatchData(data.records);
                setIsLoading(false);
            }
            const req = { "party_id": props.partyid ? props.partyid : agentData.pid, 'type': 'api', 'matches': data.summary.matches, 'remarks': '', 'user': localStorage.getItem('userId'), statuscode: data.status.statuscode }
            //props.addScanParty(req);
        }).catch(error => {

        })
    }

    const handleCheckBlackList = () => {
        setIsPopupOpen(!isPopupOpen);
        getScanDetails();    
    }

    const renderDeliveryAgent = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Button variant="contained" color="primary"
                            className={classes.selectBtn}
                            onClick={() => handleCheckBlackList()}>
                            Scan Blacklist Customer
                        </Button>
                        {isNewAgent ? <Button variant="contained" color="primary"
                            className={classes.selectBtn}
                            onClick={() => handleSelectAgent()}>
                            Select Delivery Agent
                        </Button> : <Button variant="contained" color="primary"
                            className={classes.selectBtn}
                            onClick={() => handleAddAgent()}>
                            + Delivery Agent
                        </Button>}
                    </Grid>
                    {isNewAgent ? (
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="party_name"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_name}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_name", e.target.value)}
                                        placeholder="Party Name"
                                    />
                                    {formValidator.current.message('Party Name', agentData.party_name, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Contact Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.contact_name}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("contact_name", e.target.value)}
                                        placeholder="Contact Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vat_no">
                                            VAT No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="VAT"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_vatno}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_vatno", e.target.value)}
                                        placeholder="VAT No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="ein_no">
                                            EIN No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="EIN"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_ein}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_ein", e.target.value)}
                                        placeholder="EIN No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="email">
                                            <span className={classes.mandatoryField}>*</span>
                                            Email
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Email"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.contact_email}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("contact_email", e.target.value)}
                                        placeholder="Email"
                                    />
                                    {formValidator.current.message('Email', agentData.contact_email, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="password">
                                            <span className={classes.mandatoryField}>*</span>
                                            Password
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Password"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.password}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("password", e.target.value)}
                                        placeholder="Password"
                                    />
                                    {formValidator.current.message('Password', agentData.password, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_addr1}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_addr1", e.target.value)}
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_addr2}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_addr2", e.target.value)}
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_city}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_city", e.target.value)}
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_state}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_state", e.target.value)}
                                        placeholder="State"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData.party_zipcode}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("party_zipcode", e.target.value)}
                                        placeholder="ZIP"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            <span className={classes.mandatoryField}>*</span>
                                            Country
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        value={country}
                                        id="country-select"
                                        options={props.countries}
                                        getOptionLabel={(option) => option.ctrycname}
                                        getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                        onChange={(e, newValue) => handleChangeFF("party_country", newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Country"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {formValidator.current.message('Country', country, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="telephone">
                                            Telephone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="telephone"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeFF("contact_phone", e.target.value)}
                                        placeholder="Telephone"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        value={partyName}
                                        id="party-name-select"
                                        options={props.agent}
                                        getOptionLabel={(option) => option.party_name || ""}
                                        getOptionSelected={(option, value) => value?.pid === option?.pid}
                                        onChange={(e, newValue) => handleChangePartyName(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Party Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {validateParty.current.message('Party Name', partyName, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Contact Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.contact_name}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Contact Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vat_no">
                                            VAT No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="VAT"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_vatno}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="VAT No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="ein_no">
                                            EIN No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="EIN"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_ein}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="EIN No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="email">
                                            <span className={classes.mandatoryField}>*</span>
                                            Email
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Email"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.contact_email}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="password">
                                            <span className={classes.mandatoryField}>*</span>
                                            Password
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Password"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.password}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_addr1}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_addr2}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_city}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_state}
                                        className={classes.inputField}
                                        placeholder="State"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_zipcode}
                                        className={classes.inputField}
                                        placeholder="Zip"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            <span className={classes.mandatoryField}>*</span>
                                            Country
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={agentData?.party_country}
                                        className={classes.inputField}
                                        placeholder="Country"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="telephone">
                                            Telephone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="telephone"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        placeholder="Telephone"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            {isNewAgent ? (
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    disabled={isButton}
                                    onClick={() => handleSaveAgent()}>
                                    Save
                                </Button>
                            ) : <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                disabled={isButton}
                                onClick={() => handleUpdateAgent()}>
                                Update
                            </Button>}
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }
    
    return (
        <>
            {renderDeliveryAgent()}
            {isPopupOpen && (
                <ScanPartyPopupModal
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                    scanDetails={matchData}
                    isLoading={isLoading}
                    matches={noOfMatch} />
            )}
        </>
    )
}

export default DeliveryAgent;