import { Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import TableView from './components/TableView';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback, errorHandler } from '../Common/Fallback';
import { PartyScanSummaryContext } from './components/context/PartyScanSummaryContext';
import filterIcon from '../assets/filter.png';
import { useContext } from 'react';
import FilterPopover from '../Common/FilterPopover';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    },
    topItems: {
        gap: 10
    },
    filterIcon: {
        width: "40px",
        cursor: 'pointer'
    }
}));

const PartyScan = ({ setTitle }) => {
    setTitle('Admin Activities');
    const classes = useStyles();
    const { headCells, summaryList, filterScanSummary, amberSnackbar, successSnackbar } = useContext(PartyScanSummaryContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const handleClickFilter = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget)
    }

    const renderSummary = () => {
        return (
            <>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Summary</Typography>
                        </Grid>
                        <Grid item xs={8} container className="d-flex align-center">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className={classes.topItems}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <Tooltip title="Filter" arrow>
                                        <img src={filterIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClickFilter(e)}
                                        />
                                    </Tooltip>
                                </ErrorBoundary>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <TableView summaryList={summaryList} headCells={headCells} filterScanSummary={filterScanSummary}
                            amberSnackbar={amberSnackbar} successSnackbar={successSnackbar} />
                    </ErrorBoundary>
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderSummary()}
            {isOpenModal &&
                <FilterPopover
                    isOpenModal={isOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filterScanSummary={filterScanSummary}
                />
            }
        </>
    )
}

export default PartyScan;