import { Grid, FormControl, Typography, TextField, makeStyles, Button, Paper, IconButton, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { deliveryLocation, deliveryLocationContact } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import CreateIcon from '../../../../../assets/CreateActive.svg';
import AddContactPopupModal from "../../../../../Common/AddContactPopupModal";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '32px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-41px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-44px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
}))

const DeliveryLocation = (props) => {
    const classes = useStyles();
    const [selectLocation, setSelectLocation] = useState(props.deliveryLocation);
    const [selectCountry, setSelectCountry] = useState([])
    const [locationData, setLocationData] = useState(props.deliveryLocation);
    const formValidator = useRef(new SimpleReactValidator({}));
    const [, forceUpdate] = useState();
    const [isOpenContactModal, setIsOpenContactModal] = useState(false);
    const [contacts, setContacts] = useState(props.wocontacts);
    const [isModeoft, setIsModeoft] = useState(false);

    useEffect(() => {
        setSelectCountry(props.countries.find(x => x.ctrycode === props.deliveryLocation.party_country));
    }, [])

    const handleChangeLocation = (field, value) => {
        if (field === "party_name") {
            const obj = {
                id: value.paid,
                paid: value.paid,
                party_name: value.party_name
            }
            setSelectLocation(obj);
            props.contactNameList("", value.paid);
        } else if (field === "party_country") {
            const newObj = {
                id: value.paid,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setSelectCountry(newObj);
        } else if (field === "modeoft") {
            setIsModeoft(true);
        } else if (field === "party_contact") {
            setContacts(value)
        }
        setLocationData(values => ({ ...values, [field]: value }));
    }

    const handleUpdateLocation = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...deliveryLocation }
            payload.party_email = locationData.party_email ? locationData.party_email : "";
            payload.party_addr1 = locationData.party_addr1 ? locationData.party_addr1 : "";
            payload.party_addr2 = locationData.party_addr2 ? locationData.party_addr2 : "";
            payload.party_city = locationData.party_city ? locationData.party_city : "";
            payload.party_country = selectCountry.ctrycode;
            payload.party_name = selectLocation.party_name;
            if (isModeoft) {
                payload.paid = selectLocation.paid;
            } else {
                payload.paid = 0;
            }
            payload.user = localStorage.getItem('userId');
            payload.party_state = locationData.party_state ? locationData.party_state : "";
            payload.party_zipcode = locationData.party_zipcode ? locationData.party_zipcode : "";
            payload.party_telephone = locationData.party_telephone ? locationData.party_telephone : "";
            payload.party_vatno = locationData.party_vatno ? locationData.party_vatno : "";
            payload.modeoft = locationData.modeoft;
            payload.woid = props.woid;
            payload.pid = locationData.pid;
            props.addDeliveryLocation(payload);
        } else {
            props.formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleAddContact = () => {
        setIsOpenContactModal(!isOpenContactModal);
    }

    const handleSaveContact = () => {
        const payload = { ...deliveryLocationContact }
        payload.woid = props.woid;
        payload.user = localStorage.getItem('userId');
        payload.party_contact = contacts;
        props.saveUpdateContact(payload);
    }

    const renderLocation = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="party_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Location
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="party-name-select"
                                    value={selectLocation}
                                    options={props.location}
                                    getOptionLabel={(option) => option.party_name}
                                    getOptionSelected={(option, value) => value.paid === option.paid}
                                    onChange={(e, newValue) => handleChangeLocation("party_name", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Location"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {props.formValidator.current.message('Location', props.deliveryLocation?.party_name, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="vat_no">
                                        VAT No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="VAT"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_vatno}
                                    onChange={(e) => handleChangeLocation("party_vatno", e.target.value)}
                                    placeholder="VAT No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add1">
                                        Address 1
                                    </label>
                                </Typography>
                                <TextField
                                    id="add1"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_addr1}
                                    onChange={(e) => handleChangeLocation("party_addr1", e.target.value)}
                                    placeholder="Address 1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add2">
                                        Address 2
                                    </label>
                                </Typography>
                                <TextField
                                    id="add2"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_addr2}
                                    onChange={(e) => handleChangeLocation("party_addr2", e.target.value)}
                                    placeholder="Address 2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="city">
                                        City
                                    </label>
                                </Typography>
                                <TextField
                                    id="city"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_city}
                                    onChange={(e) => handleChangeLocation("party_city", e.target.value)}
                                    placeholder="City"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="state">
                                        State
                                    </label>
                                </Typography>
                                <TextField
                                    id="state"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_state}
                                    onChange={(e) => handleChangeLocation("party_state", e.target.value)}
                                    placeholder="State"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="zip">
                                        Zip
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.party_zipcode}
                                    onChange={(e) => handleChangeLocation("party_zipcode", e.target.value)}
                                    placeholder="Zip"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="country">
                                        <span className={classes.mandatoryField}>*</span>
                                        Country
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="country-select"
                                    value={selectCountry}
                                    options={props.countries}
                                    getOptionLabel={(option) => option.ctrycname}
                                    getOptionSelected={(option, value) => value?.ctrycode === option?.ctrycode}
                                    onChange={(e, newValue) => handleChangeLocation("party_country", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="modeOft">
                                        <span className={classes.mandatoryField}>*</span>
                                        Mode of Transport
                                    </label>
                                </Typography>
                                <TextField
                                    id="modeOft"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    value={locationData.modeoft}
                                    onChange={(e) => handleChangeLocation("modeoft", e.target.value)}
                                    placeholder="Mode of Transport"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                onClick={() => handleUpdateLocation()}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}><hr /></Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact_name">
                                        Contact Name
                                    </label>
                                </Typography>
                                <Autocomplete
                                    limitTags={1}
                                    id="party-contact-select"
                                    disableClearable
                                    multiple
                                    value={contacts}
                                    options={props.contactName}
                                    getOptionLabel={(option) => option.contact_name}
                                    getOptionSelected={(option, value) => value.pa_pcid === option.pa_pcid}
                                    onChange={(e, newValue) => handleChangeLocation("party_contact", newValue)}
                                    renderOption={(option, { selected }) => (
                                        <Grid container item justifyContent="space-between">
                                            <Grid>
                                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#0070AD' }} checked={selected} />
                                                {option.contact_name}
                                            </Grid>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Contact Name"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton edge="end" aria-label="add contact" onClick={() => handleAddContact()}>
                                <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                onClick={() => handleSaveContact()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }

    return (
        <>
            {renderLocation()}
            {isOpenContactModal && (
                <AddContactPopupModal
                    isOpenContactModal={isOpenContactModal}
                    setIsOpenContactModal={setIsOpenContactModal}
                    paid={selectLocation.paid}
                    saveContact={props.addContact}
                    pid={0} />
            )}
        </>
    )
}

export default DeliveryLocation;