export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'id'
export const SOURCE = 'Source'
export const NAME = 'Name'
export const ALTERNATIVE_NAMES = 'Alternativenames'
export const COUNTRY = 'Country'
export const ADDRESS = 'Address'
export const STARTDATE = 'StartDate'
export const DPLSTATUS = 'DPLStatus'
export const CLASS_OF_THE_ENTITY = 'ClassificationOfTheEntity'
export const FEDERAL_REGISTER_NOTICE = 'FederalRegisterNotice'

export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'id', width: 60, stickyColumn: 'left'},
    { id: SOURCE, isSortable: true, selected: true, label: 'Source', fieldName: 'Source', width: 150},
    { id: NAME, isSortable: true, selected: true, label: 'Name', fieldName: 'Name', width: 140},
    { id: ALTERNATIVE_NAMES, isSortable: true, selected: true, label: 'Alternative Names', fieldName: 'Alternativenames', width: 140 },
    { id: COUNTRY, isSortable: true, selected: true, label: 'Country', fieldName: 'Country', width: 100},
    { id: ADDRESS, isSortable: true, selected: true, label: 'Address', fieldName: 'Address', width: 100 },
    { id: STARTDATE, isSortable: true, selected: true, label: 'Start Date', fieldName: 'StartDate', width: 100 },
    { id: DPLSTATUS, isSortable: true, selected: true, label: 'DPL Status', fieldName: 'DPLStatus', width: 100 },
    { id: CLASS_OF_THE_ENTITY, isSortable: true, selected: true, label: 'Classification of the entity', fieldName: 'ClassificationOfTheEntity', width: 165},
    { id: FEDERAL_REGISTER_NOTICE, isSortable: true, selected: true, label: 'Federal register notice', fieldName: 'FederalRegisterNotice', width: 150}
]
