import { Button, Grid, Typography, FormControl, TextField, Paper, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import UploadTable from "./components/UploadTable";
import { downLoadShipmentFiles } from "../../../../../Common/API";
import { saveAs } from "file-saver";

const useStyles = makeStyles(theme => ({
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    applyBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '50px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    saveBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '50px 20px 5px 17px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
}))

const FileUpload = (props) => {
    const classes = useStyles();
    const [documentUpload, setDocumentUpload] = useState([]);
    const [description, setDescription] = useState('');

    const handleFileUpload = event => {
        const file = event.target.files[0]
        setDocumentUpload(file);
    }

    const handleSaveFile = () => {
        props.saveFile(documentUpload, description);
    }

    const downloadFiles = (fileId, file) => {
        downLoadShipmentFiles(fileId, localStorage.getItem("userId"), "S").then(data => {
            const fileType = file.substr(file.lastIndexOf('.') + 1);
            saveAs(new Blob([data]), file.replace('.', '').replace(fileType, '') + "." + fileType);
        }).catch(error => {
            props.amberSnackbar(error);
        });
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                {localStorage.getItem('party_type') !== 'CST' ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="desc">
                                            Description
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="desc"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        multiline
                                        minRows="3"
                                        placeholder="Description"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" component="label" className={classes.applyBtn}>
                                    Browse
                                    <input hidden multiple type="file" onChange={handleFileUpload} />
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={documentUpload.name}
                                    disabled={true}
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    style={{ marginTop: '42px', marginLeft: '-181px' }}
                                />
                                <Button variant="contained" color="primary" size="medium" onClick={handleSaveFile} className={classes.saveBtn}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item spacing={12} style={{ marginTop: "40px" }}>
                            {props.repository.length !== 0 && (
                                <UploadTable repository={props.repository} downloadFiles={downloadFiles}
                                    handleDelete={props.handleFileDelete} />
                            )}
                        </Grid>
                    </>
                    : <Grid item spacing={12} style={{ marginTop: "40px" }}>
                        <UploadTable repository={props.repository} downloadFiles={downloadFiles}
                            handleDelete={props.handleFileDelete} />
                    </Grid>}
            </Paper>
        </React.Fragment>
    )
}

export default FileUpload;