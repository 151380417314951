import { Typography, Box } from "@material-ui/core";
import { PLANNED_DELIVERY_STATUS } from "../../../Common/constant";
import PieChart from "../../../Common/GoogleCharts/PieChart";
import { useNavigate } from "react-router-dom";

export default function PlannedDeliverStatus({ status }) {
    const navigate = useNavigate();
    const graphClick = (wostatusname) => {
        localStorage.removeItem('wostatusname');
        localStorage.removeItem('origin');
        localStorage.removeItem('wopendingthismonth');
        localStorage.removeItem('wostatus');
        const wopendingthismonth = 1
        localStorage.setItem('wostatusname', wostatusname);
        localStorage.setItem('wopendingthismonth', wopendingthismonth);
        navigate({ pathname: '/work-order/summary', data: { wostatusname, wopendingthismonth } });
    }
    const handleClick = () => {
        localStorage.removeItem('wostatusname');
        localStorage.removeItem('origin');
        localStorage.removeItem('wopendingthismonth');
        localStorage.removeItem('wostatus');
        navigate({ pathname: '/work-order/summary' })
    }
    return (
        <>
            <Box>
                <span style={{ cursor: 'pointer' }} onClick={() => handleClick()}>
                    <Typography align="left" className="reportTitle">{PLANNED_DELIVERY_STATUS}</Typography>
                </span>
                <PieChart data={status} graphClick={graphClick} />
            </Box>
        </>
    )
}