import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { errorHandler, Fallback } from "../Common/Fallback";
import { fetchContactDetails } from "../Common/API";
import ContactTable from "./components/ContactTable";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    }
}));

const ContactDetails = ({ setTitle }) => {
    setTitle("Contact Details")
    const { id } = useParams();
    const classes = useStyles();
    const [contactList, setContactList] = useState([]);

    useEffect(() => {
        if (Cookies.get('screen') === "location") {
            fetchContactDetails(id, "", localStorage.getItem('userId')).then(data => {
                if (data.status === 'NO DATA') {
                    setContactList([]);
                } else {
                    setContactList(data.list);
                }
            }).catch(error => {

            })
        }else{
            fetchContactDetails("", id, localStorage.getItem('userId')).then(data => {
                if (data.status === 'NO DATA') {
                    setContactList([]);
                } else {
                    setContactList(data.list);
                }
            }).catch(error => {

            })
        }
    }, [])

    return (
        <>
            <Grid container style={{ marginBottom: '48px' }}>
                <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                    <Grid item xs={4} className="d-flex align-center">
                        <Typography variant="h6" className={classes.title}>Summary</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <ContactTable contactList={contactList} />
            </ErrorBoundary>
        </>
    );
}

export default ContactDetails;