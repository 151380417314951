import { Card, CardContent, makeStyles, Typography, CardActions } from "@material-ui/core";
import fedexFirst from "../../../../../../../assets/FedexLabelIcons/fedexLogo_first.png";
import priorityOvernight from "../../../../../../../assets/FedexLabelIcons/fedexLogo_Povernight.png";
import standardOvernight from "../../../../../../../assets/FedexLabelIcons/fedexLogo_standard.png";
import fedex2DayAM from "../../../../../../../assets/FedexLabelIcons/fedexLogo_2dayam.png";
import fedex2Day from "../../../../../../../assets/FedexLabelIcons/fedexLogo_2day.png";
import fedexExpressSaver from "../../../../../../../assets/FedexLabelIcons/fedexLogo_expressSaver.png";
import internationalFirst from "../../../../../../../assets/FedexLabelIcons/internationalFirst.png";
import internationalEconomyFreight from "../../../../../../../assets/FedexLabelIcons/internationalEconomyFr.png";
import internationalPremium from "../../../../../../../assets/FedexLabelIcons/internationalPremium.png";
import imternationalPriorityFreight from "../../../../../../../assets/FedexLabelIcons/internationalPriorityFreight.png";
import internationalPriority from "../../../../../../../assets/FedexLabelIcons/internationalPriority.png";
import internationalPriorityExpress from "../../../../../../../assets/FedexLabelIcons/internationalPriorityExpress.png";
import internationalEconomy from "../../../../../../../assets/FedexLabelIcons/internationalEconomy.png";
import internationalGround from "../../../../../../../assets/FedexLabelIcons/internationalGround.png";
import internationalNextFlight from "../../../../../../../assets/FedexLabelIcons/internationalNextFlight.png";
import internationalConnectPlus from "../../../../../../../assets/FedexLabelIcons/internationalConnectPlus.png";
import fedexGround from "../../../../../../../assets/FedexLabelIcons/fedexLogo_ground.png";
import React from "react";

const useStyles = makeStyles(theme => ({
    fedexImg: {
        width: "7rem",
        height: "7rem",
        borderRadius: "9999px",
        border: "2px solid #fff",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
    }
}))

const FedexRates = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.ratesFedex.Service === 'FIRST_OVERNIGHT' ?
                <img src={fedexFirst} alt="First Overnight" className={classes.fedexImg} />
                : props.ratesFedex.Service === 'PRIORITY_OVERNIGHT' ?
                    <img src={priorityOvernight} alt="Priority Overnight" className={classes.fedexImg} />
                    : props.ratesFedex.Service === 'STANDARD_OVERNIGHT' ?
                        <img src={standardOvernight} alt="Standard Overnight" className={classes.fedexImg} />
                        : props.ratesFedex.Service === 'FEDEX_2_DAY_AM' ?
                            <img src={fedex2DayAM} alt="Fedex 2 Day AM" className={classes.fedexImg} />
                            : props.ratesFedex.Service === 'FEDEX_2_DAY' ?
                                <img src={fedex2Day} alt="Fedex 2 Day" className={classes.fedexImg} />
                                : props.ratesFedex.Service === 'FEDEX_EXPRESS_SAVER' ?
                                    <img src={fedexExpressSaver} alt="Fedex Express Saver" className={classes.fedexImg} />
                                    : props.ratesFedex.Service === 'FEDEX_GROUND' ?
                                        <img src={fedexGround} alt="Fedex Ground" className={classes.fedexImg} />
                                        : props.ratesFedex.Service === 'INTERNATIONAL_FIRST' ?
                                            <img src={internationalFirst} alt="International First" className={classes.fedexImg} />
                                            : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_ECONOMY_FREIGHT' ?
                                                <img src={internationalEconomyFreight} alt="International Economy Freight" className={classes.fedexImg} />
                                                : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_PREMIUM' ?
                                                    <img src={internationalPremium} alt="International Premium" className={classes.fedexImg} />
                                                    : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_PRIORITY_FREIGHT' ?
                                                        <img src={imternationalPriorityFreight} alt="International Priority Freight" className={classes.fedexImg} />
                                                        : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_PRIORITY' ?
                                                            <img src={internationalPriority} alt="International Priority" className={classes.fedexImg} />
                                                            : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS' ?
                                                                <img src={internationalPriorityExpress} alt="International Priority Express" className={classes.fedexImg} />
                                                                : props.ratesFedex.Service === 'INTERNATIONAL_ECONOMY' ?
                                                                    <img src={internationalEconomy} alt="International Economy" className={classes.fedexImg} />
                                                                    : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_GROUND' ?
                                                                        <img src={internationalGround} alt="International Ground" className={classes.fedexImg} />
                                                                        : props.ratesFedex.Service === 'FEDEX_INTERNATIONA_NEXT_FLIGHT' ?
                                                                            <img src={internationalNextFlight} alt="International Next Flight" className={classes.fedexImg} />
                                                                            : props.ratesFedex.Service === 'FEDEX_INTERNATIONAL_CONNECT_PLUS' ?
                                                                                <img src={internationalConnectPlus} alt="International Connect Plus" className={classes.fedexImg} />
                                                                                : ""
            }
        </React.Fragment>
    );
}

export default FedexRates;