import { ErrorBoundary } from "react-error-boundary";
import { errorHandler, Fallback } from "../Common/Fallback";
import SnackbarProvider from 'react-simple-snackbar'
import { useContext, useEffect, useState } from "react";
import { downloadShipment, fetchCustomer, fetchShipmentStatus } from "../Common/API";
import { ShipmentSummaryContext } from "./components/context/ShipmentSummaryContext";
import FilterPopover from "./components/FilterPopover";
import { Grid, Typography, Tooltip, makeStyles } from "@material-ui/core";
import filterIcon from '../assets/filter.png';
import downloadIcon from '../assets/download.png';
import TableView from './components/TableView';
import FileSaver from "file-saver";
import moment from "moment";
import Cookies from "js-cookie";
import addShip from '../assets/add.png';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    },
    topItems: {
        gap: 10
    },
    filterIcon: {
        width: "40px",
        cursor: 'pointer'
    }
}));

const Shipment = ({ setTitle }) => {
    const { headCells, summaryList, filterShipmentSummary } = useContext(ShipmentSummaryContext);
    setTitle('Shipment');
    const navigate = useNavigate();
    const classes = useStyles();
    const [status, setStatus] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const To = new Date();
    const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
    const [expFilter, setExportFilter] = useState({
        "fromdate": moment(From).format('yyyy-MM-DD'),
        "todate": moment(To).format('yyyy-MM-DD'),
        "user": localStorage.getItem('userId'),
        "type": 'D'
    });

    useEffect(() => {
        fetchShipmentStatus().then((data) => {
            setStatus(data);
        }).catch((error) => {
            //amberSnackbar(error);
        });
        fetchCustomer().then((data) => {
            setCustomerList(data);
        }).catch((error) => {
            //amberSnackbar(error);
        });
    }, []);

    const handleClickDownload = () => {
        expFilter['type'] = 'D'
        downloadShipment(expFilter).then(data => {
            const blob = new Blob([data], { type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64' });
            const Filename = 'ship-data_' + moment(new Date()).format('yyyy-MM-DD');
            FileSaver.saveAs(blob, `${Filename}.xls`);
        }).catch(error => {
            //amberSnackbar(error);
        })
    }

    const handleClick = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const handleClickCreate = () => {
        navigate('/create-shipment');
    }

    const renderShipment = () => {
        return (
            <>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Summary</Typography>
                        </Grid>
                        <Grid item xs={8} container className="d-flex align-center">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className={classes.topItems}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    {Cookies.get("party_type") !== 'CST' ? <Tooltip title="Create Shipment" arrow>
                                        <img src={addShip} alt="filter" className={classes.filterIcon}
                                            onClick={() => handleClickCreate()}
                                        />
                                    </Tooltip> : null}
                                    <Tooltip title="Download" arrow>
                                        <img src={downloadIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClickDownload(e)}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Filter" arrow>
                                        <img src={filterIcon} alt="filter" className={classes.filterIcon}
                                            onClick={(e) => handleClick(e)}
                                        />
                                    </Tooltip>
                                </ErrorBoundary>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <SnackbarProvider>
                            <TableView headCells={headCells} summaryList={summaryList} />
                        </SnackbarProvider>
                    </ErrorBoundary>
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderShipment()}
            {isOpenModal &&
                <FilterPopover
                    isOpenModal={isOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filterShipmentSummary={filterShipmentSummary}
                    customerList={customerList}
                    status={status}
                    setExportFilter={setExportFilter}
                />
            }
        </>
    )
}

export default Shipment;