/** @format */

import React, { createContext, useState, useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { HEAD_CELLS } from '../constants/index';
import {
  fetchPoSummary,
} from '../../../Common/API';
import moment from 'moment/moment';

const initialState = {
  loading: true,
  surveyList: [],
  error: null,
  hasError: false,
  headCells: HEAD_CELLS,
  order: 'asc',
  orderBy: '',
  pageId: null,
};

const spinnerStyle = {
  marginLeft: '50%',
  marginTop: '2%',
};

export const POSummaryContext = createContext(initialState);

export const POSummaryContextProvider = ({ children }) => {
  const userId = localStorage.getItem('userId');
  const To = new Date();
  const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
  const filter = {
    "fromDate": From,
    "toDate": moment(To).format('yyyy-MM-DD'),
    "user": userId,
    "origin": localStorage.getItem('origin') !== undefined ? localStorage.getItem('origin') : ""
  }
  const [allPOContextData, setAllPOContextData] = useState(initialState);
  const { summaryList, loading, headCells, orderBy, order } = allPOContextData;

  useEffect(() => {
    filterPOSummary(filter);
  }, []);

  const filterPOSummary = (filter) => {
    fetchPoSummary(filter)
      .then((data) => {
        if (data.status !== "NO DATA") {
          const summaryData = data.list.map((data, index) => {
            return data;
          });
          setAllPOContextData((prevState) => {
            return {
              ...prevState,
              summaryList: summaryData,
              loading: false,
              error: null,
              hasError: false,
              searchTerm: '',
            }
          });
        } else {
          setAllPOContextData((prevState) => {
            return {
              ...prevState,
              summaryList: [],
              loading: false,
              hasError: true,
            }
          });
        }
      })
      .catch((error) => {
        setAllPOContextData({
          ...allPOContextData,
          loading: false,
          error: error,
          hasError: true,
        });
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setAllPOContextData({
      ...allPOContextData,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  function descendingComparator(a, b, orderByVal) {
    const orderByArray = orderByVal.split('.');
    if (
      orderByArray &&
      orderByArray.length &&
      b[orderByArray[0]] &&
      b[orderByArray[0]][orderByArray[1]] &&
      a[orderByArray[0]] &&
      a[orderByArray[0]][orderByArray[1]]
    ) {
      if (
        b[orderByArray[0]][orderByArray[1]] <
        a[orderByArray[0]][orderByArray[1]]
      ) {
        return -1;
      }
      return 1;
    } else if (b[orderByVal] && a[orderByVal]) {
      const isNumber = typeof a[orderByVal] === 'number';
      const filedValueB = isNumber
        ? b[orderByVal]
        : b[orderByVal].toUpperCase();
      const filedValueA = isNumber
        ? a[orderByVal]
        : a[orderByVal].toUpperCase();
      if (filedValueB < filedValueA) {
        return -1;
      }
      return 1;
    }
    return 0;
  }

  function getComparator(orderVal, orderByVal) {
    return orderVal === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByVal)
      : (a, b) => -descendingComparator(a, b, orderByVal);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orders = comparator(a[0], b[0]);
      if (orders !== 0) return orders;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const contextValues = {
    ...allPOContextData,
    summaryList,
    headCells,
    filterPOSummary,
    handleRequestSort,
    getComparator,
    stableSort,
  };

  return (
    <POSummaryContext.Provider value={contextValues}>
      {loading ? (
        <div style={spinnerStyle}>
          {' '}
          <SpinnerCircularFixed
            color='#0070AD'
            enabled={loading}
          />{' '}
        </div>
      ) : (
        children
      )}
    </POSummaryContext.Provider>
  );
};
