export const stickyColumns = (stickyColumn) => {
    let returnClass = '';
    if (stickyColumn && stickyColumn === 'right') returnClass = 'stickyColumn stickyRight'
    if (stickyColumn && stickyColumn === 'left') returnClass = 'stickyColumn stickyLeft'
    return returnClass;
}

export const addActionDependencyPropeties = (records) => {
    return records.map((record) => {
        return { ...record, isEditing: false }
    });
}

export const handleMultiSelectOption = (records, dataField, newValues) => {
    const result = records[dataField].map((record) => {
        return { ...record, isSelected: isMatched(newValues, record) }
    });
    return result;
}

const isMatched = (newValues, record) => {
    return newValues.some((newValue) => newValue.label === record.label && newValue.value === record.value)
}

export const handleOptionSelected = (record, dataField, newValue) => {
    return record[dataField].map(field => {
        if (field.value === newValue.value) {
            return { ...field, isSelected: true }
        } else {
            return { ...field, isSelected: false }
        }
    })
}

export const handleRadioSelectOption = (record, dataField, newValue) => {
    return record[dataField].map(field => {
        if (field.isSelected === newValue) {
            return { ...field, isSelected: false }
        } else {
            return { ...field, isSelected: true }
        }
    })
}

const passTotalColumnWidthLeft = (columns, colIndex) => {
    let totalWidth = 0;
    for (let i = 0; i < colIndex; i++) {
        totalWidth += columns[i].width
    }
    return totalWidth;
}
const passTotalColumnWidthRight = (columns, colIndex) => {
    let totalWidth = 0;
    for (let i = colIndex; i < columns.length - 1; i++) {
        totalWidth += columns[i].width
    }
    return totalWidth;
}
export const stickyStyle = (columns, colIndex, stickyColumn) => {
    let stickyStyle = '';
    if (stickyColumn === 'left' && columns[colIndex - 1]) {
        stickyStyle = { left: passTotalColumnWidthLeft(columns, colIndex) }
    }
    if (stickyColumn === 'right' && columns[colIndex + 1]) {
        stickyStyle = { right: passTotalColumnWidthRight(columns, colIndex) }
    }
    return stickyStyle;
}

export const camelCaseToTitleCase = text => {
    return text.toLowerCase().replace(/(?:^|\s|\/|-)\w/g, function (match) {
        return match.toUpperCase();
    });
};

export const lowerContainsForSearch = (str1, str2) => str1 && str2 && str1.toLowerCase().indexOf(str2.toLowerCase()) !== -1;

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
    if (array) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    } else {
        return [];
    }
};

export const getFormattedDate = (durationString) => {
    const durationParts = durationString.split(' ');
    let years = 0, months = 0, days = 0;
    for (let i = 0; i < durationParts.length; i += 2) {
        const value = durationParts[i];
        const unit = durationParts[i + 1];
        if (unit === 'years') {
            years = value;
        } else if (unit === 'months') {
            months = value;
        } else if (unit === 'days') {
            days = value;
        }
    }
    return `${years} Y/${months} M/${days} D`;
}
