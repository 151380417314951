import React, { useState } from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { stickyColumns, stickyStyle } from '../../common/utility';
import { ExpanderButton } from '../../components/ExpanderButton'
import { CONTENT_TYPES } from '../../common/constants';
import { tableStyle } from '../../common/style';

export default function TableRecord(props) {
  const { columns, row, index, isParent, parentRowIndex } = props
  const customeStyle = tableStyle();

  const [open, setOpen] = useState(false);

  const cellContentGenerator = (row, index, col) => {
    if (col['dataField'] === 'expander' && isParent) {
      return <ExpanderButton open={open} setOpen={setOpen} column={col} row={row} />
    }
    if (col.contentType === CONTENT_TYPES.SELECT || col.contentType === CONTENT_TYPES.RADIO) {
      const options = row[col.dataField]
      if (options !== undefined) {
        const selectedValue = options.find(option => option.isSelected === true)
        return selectedValue.label ?? ''
      }
    }
    if (col.contentType === CONTENT_TYPES.MULTI_SELECT) {
      const options = row[col.dataField]
      if (options !== undefined) {
        const selectedOptions = [];
        options.forEach(option => {
          if (option.isSelected === true) {
            selectedOptions.push(option.label);
          }
        });
        return selectedOptions.join(',');
      }
      return "";
    }
    const renderContent = () => {
      if (!row[col.dataField]) return '--'
      return row[col.dataField]
    }

    if (col.dataField === 'mawbno') {
      return (
        <div onClick={(e) => handleMawbNumberClick(e, row['mawbno'])} style={{ cursor: 'pointer', textDecoration: 'none' }}>
          {row['mawbno']}
        </div>
      );
    } else {
      return col.cellRenderMethod ? col.cellRenderMethod(row, col.dataField, index, isParent, parentRowIndex) : renderContent();
    }
  }

  const handleMawbNumberClick = (e, mawbNumber) => {
    e.preventDefault(); // Prevent default action
    // Open in a new tab
    if(row['modeoftransport'].toLowerCase() === 'air'){
      window.open(`https://connect.track-trace.com/for/sasa/aircargo/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'sea'){
      window.open(`https://connect.track-trace.com/for/sasa/billoflading/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'fedex'){
      window.open(`https://connect.track-trace.com/for/sasa/fedex/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'ocean'){
      window.open(`https://connect.track-trace.com/for/sasa/billoflading/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'ups'){
      window.open(`https://connect.track-trace.com/for/sasa/ups/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'container'){
      window.open(`https://connect.track-trace.com/for/sasa/container/${mawbNumber}`, '_blank');
    }else if(row['modeoftransport'].toLowerCase() === 'dhl'){
      window.open(`https://connect.track-trace.com/for/sasa/dhl/${mawbNumber}`, '_blank');
    }else {

    }
  }

  return (
    <>
      <TableRow key={row.id}>
        {columns.map((col, indx) => (
          <Tooltip title={col.dataField !== 'actions' ? row[col.dataField] : ""} arrow>
            <TableCell
              key={`${row.id}-${col.dataField}`}
              align="center"
              className={`${stickyColumns(col.stickyColumn)} ${col.truncate ? 'truncate' : ''}`}
              classes={{
                body: col.truncate ? 'truncate' : ''
              }}
              style={{ width: col.width ? col.width : "auto", textAlign: col.align ? col.align : 'left', ...stickyStyle(columns, indx, col.stickyColumn) }}
            >
              <div className={customeStyle.showElipsis}>{cellContentGenerator(row, index, col)}</div>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </>
  )
}