import { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  GoogleAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail
} from "firebase/auth";
import { auth } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { validateLoginEmail } from "../../Common/API";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user] = useAuthState(auth);
  const [isValidated, setIsValidated] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const [infoMsg, setInfoMsg] = useState('');

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState('');

  function logOut() {
    auth.signOut().then(() => {
      setIsValidated(false);
      console.log('successfully logged out');
      navigate('/');
    }).catch((err) => {
      console.log(err);
    })
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function logIn(email) {
    setLoginLoading(true);
    validateLoginEmail(email).then(data => {
      if (data.status === 'success') {
        sendSignInLinkToEmail(auth, email, {
          // this is the URL that we will redirect back to after clicking on the link in mailbox
          url: "https://g4scm.com", //"https://g4scm.com", //"https://www.stage.g4scm.com", //"http://localhost:3000",
          handleCodeInApp: true,
        }).then(() => {
          localStorage.setItem('email', email);
          setLoginLoading(false);
          setLoginError('');
          setInfoMsg('We have sent you an email with a link to sign in');
        }).catch(err => {
          setLoginLoading(false);
          setLoginError(err.message);
        })
      } else {
        alert(data.message);
      }
    });
  }

  const validateEmail = async () => {
    try {
      validateLoginEmail(user.email).then(data => {
        if (data.status === 'success') {
          // Clear previous user's data
        localStorage.removeItem('otherUserLogin');
        localStorage.removeItem('userId');
        localStorage.removeItem('party_type');
        localStorage.removeItem('party_id');
        localStorage.removeItem('user_name');

        // Set new user's data
        localStorage.setItem('userId', data.userid);
        localStorage.setItem('party_type', data.usertype);
        localStorage.setItem('party_id', data.customerid);
        localStorage.setItem('user_name', data.username);
          if (data.usertype !== 'CST' && data.usertype !== 'SLS') {
            navigate('/manage-orders');
          } else if(data.usertype === 'CST') {
            navigate('/customer-dashboard');
          } else if(data.usertype === 'SLS') {
            navigate('/sales-dashboard');
          }
        }
      });
    } catch (error) {
    }
  }


  useEffect(() => {
    if (user && !isValidated) {
      // user is already signed in
      validateEmail(user.email);
      setIsValidated(true);
    } else {
      // user is not signed in but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem('email');
        if (!email) {
          email = window.prompt('Please provide your email');
        }
        // after that we will complete the login process
        setInitialLoading(true);
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // we can get the user from result.user but no need in this case
            validateEmail(result.user.email);
            localStorage.removeItem('email');
            setInitialLoading(false);
            setInitialError('');
          }).catch((err) => {
            setInitialLoading(false);
            setInitialError(err.message);
            navigate('/');
          })
      }
      else {
        console.log('enter email and sign in');
      }
    }
  }, [user, search, navigate]);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, logOut, googleSignIn, loginError, loginLoading, infoMsg, initialError, initialLoading, isValidated }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
