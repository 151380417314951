import { useState } from "react";

export const useSort = () =>{
    const [columnSortData, setColumnSortData] = useState({columnOrder:null,columnOrderBy:null});
    const {columnOrder,columnOrderBy} = columnSortData;
    const handleRequestSort = (event, property) => {
        const isAsc = columnOrderBy === property && columnOrder === 'desc';
        setColumnSortData((prevState) => {
            return  {
                ...prevState,
                columnOrder: isAsc ? 'asc' : 'desc',
                columnOrderBy: property
            }
        });
    };

    return {columnOrder,columnOrderBy,handleRequestSort}
}