import React, { useEffect, useRef } from 'react';
import { Grid, TableHead } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHeader from './components/TableHeader';
import TableRecord from './components/TableRecord';
import { tableStyle } from './common/style';
import { TablePagination } from './components/TablePagination';
import { getComparator, stableSort } from './common/utility';

export default function TableOrganizer(props) {

  const { 
    columns,
    tableData,
    defaultMinWidthForCell,
    defaultMaxWidthForCell,
    tableProps,
    order,
    orderBy,
    handleRequestSort,
    childColumns,
    parentRowIndex,
    pagination,
    totalPageCount,
    recordsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    recordsPerPageOptions,
    className,
  } = props
  
  const { stickyHeader, tableContainerHeight } = tableProps;
  const classes = tableStyle(); 
  const columnRefs = useRef([]);

  const isResizing = useRef(-1);

  const openMouseMove = () => {
    document.onmousemove = handleOnMouseMove;
    document.onmouseup = handleOnMouseUp;
  }
  const stopMouseMove = () => {
    document.onmousemove = null;
    document.onmouseup = null;
  }
  
  useEffect(() => {
    openMouseMove()
    return () => {
      stopMouseMove()
    };
  }, []);
  
  if(!columns || !columns.length) return <p className={classes.errorMessge}>No columns to display.</p>

  if(!tableData || !tableData.length) return <p className={classes.errorMessge}>No data to display.</p>
  

  
  const setCursorDocument = (isResizing) => {
    document.body.style.cursor = isResizing ? "col-resize" : "auto";
  };

  const adjustWidthColumn = (index, width) => {
    const minWidth = columns[index]?.minWidth ?? defaultMinWidthForCell;
    const maxWidth = columnRefs[index]?.maxWidth ?? defaultMaxWidthForCell;
    const newWidth =
      width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

    columnRefs.current[index].parentElement.style.width = newWidth + "px";
  };

  const handleOnMouseMove = (e) => {
    // && every(columnRefs, 'current'
    if (isResizing.current >= 0) {
      const newWidth =
        e.clientX -
        columnRefs.current[
          isResizing.current
        ].parentElement?.getBoundingClientRect().left;
      adjustWidthColumn(isResizing.current, newWidth);
    }
  };

  const handleOnMouseUp = () => {
    isResizing.current = -1;
    setCursorDocument(false);
  };

  const onClickResizeColumn = (index) => {
    if(!document.onmousemove) openMouseMove();
    isResizing.current = index;
    setCursorDocument(true);
  };

  const headerContent = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader
            columns={columns}
            columnRefs={columnRefs}
            onClickResizeColumn={onClickResizeColumn}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
        </TableRow>
      </TableHead>
    )
  }

  return (
    <Grid>
      <TableContainer component={Paper} style={{ height: `${tableContainerHeight}px` }}>

        <Table className={`${classes.table} ${className}`} aria-label="simple table" stickyHeader={stickyHeader}>

          {/* {Below header will render only once for parent component} */}
          {headerContent()}
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy)).map((row, index) => {
              const isParent = Boolean(row.children && row.children.length);
              return (
                <TableRecord
                  key={"TableRows" + index}
                  row={row}
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  getComparator={getComparator}
                  stableSort={stableSort}
                  index={index}
                  expandedFlag={false}
                  childColumns={childColumns}
                  parentRowIndex={parentRowIndex}
                  isParent={isParent}
                  maxlength={30}
                />
              )
            })}
          </TableBody>
          
        </Table>
      </TableContainer>
      {pagination && <TablePagination 
        pagecount={totalPageCount} 
        pagination={pagination} 
        recordsPerPageOptions={recordsPerPageOptions}
        recordsPerPage={recordsPerPage}
        currentPage={currentPage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        />
      }
      
    </Grid>
  );
}