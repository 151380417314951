import { Paper, Checkbox, FormControl, Grid, Typography, TextField, makeStyles, Button } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { messageModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import WOMessageTable from "./components/WOMessageTable";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: "15px",
        marginBottom: '-35px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    }
}))

const WOMessages = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [messageData, setMessageData] = useState([]);
    const [isBtnDisable, setIsBtnDisable] = useState(true);
    const [isChecked, setIsChecked] = useState(true);
    const formValidator = useRef(new SimpleReactValidator({}));

    const handleChangeMessage = (field, value) => {
        setIsBtnDisable(false);
        if(field === "customer_view"){
            setIsChecked(value);
        }
        setMessageData(values => ({ ...values, [field]: value }));
    }

    const handleCancel = () => {
        navigate('/');
    }

    const handleSaveMessage = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = {...messageModal}
            payload.message = messageData.message;
            payload.subject = messageData.subject;
            payload.customer_view = isChecked === true ? "Y" : "N";
            payload.invid = props.invoiceId;
            payload.user = localStorage.getItem('userId');
            payload.woid = props.woid;
            props.addMessage(payload);
            setIsBtnDisable(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="subject">
                                    <span className={classes.mandatoryField}>*</span>
                                    Subject
                                </label>
                            </Typography>
                            <TextField
                                id="subject"
                                color="primary"
                                variant="outlined"
                                value={messageData.subject}
                                className={classes.inputField}
                                onChange={(e) => handleChangeMessage("subject", e.target.value)}
                                placeholder="Subject"
                            />
                            {formValidator.current.message('Subject', messageData.subject, 'required', { className: classes.validateStyle })}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="message">
                                    <span className={classes.mandatoryField}>*</span>
                                    Message
                                </label>
                            </Typography>
                            <TextField
                                id="message"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                value={messageData.message}
                                multiline
                                minRows="3"
                                placeholder="Message"
                                onChange={(e) => handleChangeMessage("message", e.target.value)}
                            />
                            {formValidator.current.message('Message', messageData.message, 'required', { className: classes.validateStyle })}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ marginTop: "45px" }}>
                        <FormControl fullWidth>
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="custView">
                                    Customer View
                                </label>
                            </Typography>
                            <Checkbox
                                checked={isChecked}
                                onChange={(e) => handleChangeMessage("customer_view", e.target.checked)}
                                color="primary"
                                style={{ marginTop: '-27px', marginLeft: '-50px', background: "none" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item spacing={12} style={{marginTop: "40px", marginBottom: "20px"}}>
                    {props.messageList.length !== 0 && (
                        <WOMessageTable msgList={props.messageList} />
                    )}   
                </Grid>  
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            disabled={isBtnDisable}
                            onClick={() => handleSaveMessage()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default WOMessages;