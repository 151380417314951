import { Dialog, Grid, Typography, FormControl, DialogActions, makeStyles, DialogTitle, DialogContent, TextField, Paper, Button } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import { useState, useRef } from "react";
import { cartonModal } from "../../../../../../../Common/constant";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    }
}))

const PalletsPopupModal = ({ isOpenPalletModal, setIsOpenPalletModal, palletList, woid, savePalletsFF }) => {
    const formValidator = useRef(new SimpleReactValidator({}));
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [palletData, setPalletData] = useState([]);
    
    const handleChangePallets = (field, value) => {
        setPalletData(values => ({ ...values, [field]: value }));
    }

    const handleAddPallets = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = {...cartonModal}
            payload.prod_qty = palletList.prod_code;
            payload.receiptno = palletList.receiptno;
            payload.dim_height = palletData.dim_height ? palletData.dim_height : "";
            payload.dim_length = palletData.dim_length ? palletData.dim_length : "";
            payload.dim_uom = palletData.dim_uom ? palletData.dim_uom : "CM"; 
            payload.dim_width = palletData.dim_width ? palletData.dim_width : "";
            payload.package_type = palletData.package_type ? palletData.package_type : "Carton"; 
            payload.pkg_nos = palletData.pkg_nos ? palletData.pkg_nos : "";
            payload.prod_qty = palletData.prod_qty;
            payload.user = localStorage.getItem('userId');
            payload.woitemid = palletList.woitemid;
            payload.woid = woid;
            savePalletsFF(payload);
            setIsOpenPalletModal(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }       
    }

    const handleCancel = () => {
        setIsOpenPalletModal(false);
    }

    return (
        <Dialog open={isOpenPalletModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif !important' }}>Add Pallet</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                    <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="item_code">
                                        Item Code
                                    </label>
                                </Typography>
                                <TextField
                                    id="item_code"
                                    color="primary"
                                    variant="outlined"
                                    disabled={true}
                                    value={palletList?.prod_code}
                                    className={classes.inputField}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="rct_no">
                                        Receipt No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="rct_no"
                                    color="primary"
                                    variant="outlined"
                                    disabled={true}
                                    value={palletList?.receiptno}
                                    className={classes.inputField}
                                />
                            </FormControl>
                        </Grid>
                    <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="package_type">
                                        Package Type
                                    </label>
                                </Typography>
                                <TextField
                                    id="package_type"
                                    color="primary"
                                    variant="outlined"
                                    value={palletData.package_type ? palletData.package_type : "Pallet"}
                                    onChange={(e) => handleChangePallets('package_type', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Contact Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="pallet_no">
                                        No. of Pallets
                                    </label>
                                </Typography>
                                <TextField
                                    id="pallet_no"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangePallets('prod_qty', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="No. of Pallets"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="length">
                                        Length
                                    </label>
                                </Typography>
                                <TextField
                                    id="length"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangePallets('dim_length', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Length"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="width">
                                        Width
                                    </label>
                                </Typography>
                                <TextField
                                    id="width"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangePallets('dim_width', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Width"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="height">
                                        Height
                                    </label>
                                </Typography>
                                <TextField
                                    id="height"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangePallets('dim_height', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Height"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dim_uom">
                                        Dimension UOM
                                    </label>
                                </Typography>
                                <TextField
                                    id="dim_uom"
                                    color="primary"
                                    variant="outlined"
                                    value={palletData.dim_uom ? palletData.dim_uom : "CM"}
                                    onChange={(e) => handleChangePallets('dim_uom', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Weight UOM"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="cartons">
                                        No. of Cartons
                                    </label>
                                </Typography>
                                <TextField
                                    id="cartons"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangePallets('pkg_nos', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="No. of Cartons"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddPallets} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PalletsPopupModal;