import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useNavigate } from "react-router-dom";
import AddressPopupModal from "../../../common/AddressPopupModal";
import { useState } from "react";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { customerModal } from "../../constants";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { fetchInvoiceAddress } from "../../../../../Common/API";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    addrBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-38px 20px 20px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
}))

const ConsignedTo = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [addressList, setAddressList] = useState([]);
    const [openAddrModal, setOpenAddrModal] = useState(false);

    const formValidator = useRef(new SimpleReactValidator({}));

    useEffect(() => {
        if (localStorage.getItem('party_type') !== "CST") {
            fetchInvoiceAddress(props.pid, "con", localStorage.getItem('userId')).then(data => {
                if (data.partyaddr.length !== 0) {
                    setAddressList(data.partyaddr);
                }
            }).catch(error => {
                props.amberSnackbar(error);
            });
        } else {
            fetchInvoiceAddress(Cookies.get('party_id'), "con", localStorage.getItem('userId')).then(data => {
                if (data.partyaddr.length !== 0) {
                    setAddressList(data.partyaddr);
                }
            }).catch(error => {
                props.amberSnackbar(error);
            });
        }
    }, []);

    const handleCancel = () => {
        navigate('/');
    }

    const handleOpenAddress = () => {
        setOpenAddrModal(!openAddrModal);
    }

    const handleChangeConsignedTo = (field, value) => {
        props.setConsignedTo(values => ({ ...values, [field]: value }));
    }

    const handleCountry = (newValue) => {
        let newObj = {
            id: newValue.ctrycode,
            ctrycode: newValue.ctrycode,
            ctrycname: newValue.ctrycname
        }
        props.setConCountry(newObj);
    }

    const handleSaveConsigneeTo = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...customerModal }
            payload.party_name = props.consignedTo.party_name;
            payload.contact_email = props.consignedTo.contact_email ? props.consignedTo.contact_email : '';
            payload.contact_name = props.consignedTo.contact_name ? props.consignedTo.contact_name : '';
            payload.contact_phone = props.consignedTo.contact_phone;
            payload.party_addr1 = props.consignedTo.party_addr1 ? props.consignedTo.party_addr1 : '';
            payload.party_addr2 = props.consignedTo.party_addr2 ? props.consignedTo.party_addr2 : '';
            payload.party_city = props.consignedTo.party_city ? props.consignedTo.party_city : '';
            payload.party_state = props.consignedTo.party_state ? props.consignedTo.party_state : '';
            payload.party_zipcode = props.consignedTo.party_zipcode ? props.consignedTo.party_zipcode : '';
            payload.party_vatno = props.consignedTo.party_vatno ? props.consignedTo.party_vatno : '';
            payload.user = localStorage.getItem('userId');
            payload.ptype = "con";
            payload.partyid = props.pid;
            payload.party_country = props.conCountry.ctrycode;
            payload.invid = props.invoiceId;
            if (props.addressId > 0) {
                payload.party_addr_id = props.addressId;
            } else {
                payload.party_addr_id = 0;
            }
            props.saveExportAddr(payload);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleSameAsConsignee = (value) => {
        if (value === true) {
            props.setConsignedTo(props.asConsignee)
            props.setConCountry(props.countries.find(x => x.ctrycode === props.asConsignee.party_country));
        } else {
            props.setConsignedTo(customerModal);
            props.setConCountry([]);
        }
    }

    const renderConsignedTo = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    {localStorage.getItem('party_type') !== 'CST' ?
                        <>
                            <Grid container item xs={10} justifyContent="flex-end">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="asConsignee">
                                        Same as consignee
                                    </label>
                                </Typography>
                                <Checkbox
                                    onChange={(e) => handleSameAsConsignee(e.target.checked)}
                                    color="primary"
                                    style={{ marginTop: '-15px' }}
                                />
                            </Grid>
                            <Grid container item xs={12} justifyContent="flex-end">
                                <Button variant="contained" color="primary"
                                    className={classes.addrBtn}
                                    onClick={() => handleOpenAddress()}>
                                    Select Address
                                </Button>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="party_name">
                                                <span className={classes.mandatoryField}>*</span>
                                                Party Name
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="partyName"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_name}
                                            onChange={(e) => handleChangeConsignedTo("party_name", e.target.value)}
                                            placeholder="Party Name"
                                        />
                                        {formValidator.current.message('Party Name', props.consignedTo?.party_name, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="contact_name">
                                                Contact Name
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="contact"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.contact_name}
                                            onChange={(e) => handleChangeConsignedTo("contact_name", e.target.value)}
                                            placeholder="Contact Name"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="telephone">
                                                <span className={classes.mandatoryField}>*</span>
                                                Telephone
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="telephone"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.contact_phone}
                                            onChange={(e) => handleChangeConsignedTo("contact_phone", e.target.value)}
                                            placeholder="Telephone"
                                        />
                                        {formValidator.current.message('Telephone', props.consignedTo?.contact_phone, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="email">
                                                Email
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="Email"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.contact_email}
                                            onChange={(e) => handleChangeConsignedTo("contact_email", e.target.value)}
                                            placeholder="Email"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="vat_no">
                                                VAT No.
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="VAT"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_vatno}
                                            onChange={(e) => handleChangeConsignedTo("party_vatno", e.target.value)}
                                            placeholder="VAT No."
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add1">
                                                Address 1
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add1"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_addr1}
                                            onChange={(e) => handleChangeConsignedTo("party_addr1", e.target.value)}
                                            placeholder="Address 1"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add2">
                                                Address 2
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add2"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_addr2}
                                            onChange={(e) => handleChangeConsignedTo("party_addr2", e.target.value)}
                                            placeholder="Address 2"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="city">
                                                City
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="city"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_city}
                                            onChange={(e) => handleChangeConsignedTo("party_city", e.target.value)}
                                            placeholder="City"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="state">
                                                State
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="state"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_state}
                                            onChange={(e) => handleChangeConsignedTo("party_state", e.target.value)}
                                            placeholder="State"
                                            inputProps={{ maxLength: 3 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="zip">
                                                Zip
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="zip"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.consignedTo?.party_zipcode}
                                            onChange={(e) => handleChangeConsignedTo("party_zipcode", e.target.value)}
                                            placeholder="Zip"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="country">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            id="country-select"
                                            disableClearable
                                            value={props.conCountry}
                                            options={props.countries}
                                            getOptionLabel={(option) => option.ctrycname}
                                            getOptionSelected={(option, value) => value?.ctrycode === option?.ctrycode}
                                            onChange={(e, newValue) => handleCountry(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {formValidator.current.message('Country', props.conCountry, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container wrap="wrap" className={classes.pageFooter}>
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="primary"
                                        className={classes.cancelButton}
                                        onClick={() => handleCancel()}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" color="primary"
                                        className={classes.applyBtn}
                                        onClick={() => handleSaveConsigneeTo()}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </> :
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="partyName"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Party Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Contact Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.contact_name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Contact Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="telephone">
                                            Telephone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="telephone"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.contact_phone}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Telephone"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="email">
                                            Email
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Email"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.contact_email}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vat_no">
                                            VAT No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="VAT"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_vatno}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="VAT No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_addr1}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_addr2}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_city}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_state}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="State"
                                        inputProps={{ maxLength: 3 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_zipcode}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Zip"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            Country
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="country"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        value={props.consignedTo?.party_zipcode}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        placeholder="Country"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>}
                </Paper>
            </React.Fragment>
        )
    }

    return (
        <>
            {renderConsignedTo()}
            {openAddrModal && (
                <AddressPopupModal
                    openAddrModal={openAddrModal}
                    setOpenAddrModal={setOpenAddrModal}
                    addressList={addressList}
                    setConsignee=""
                    setConsignedTo={props.setConsignedTo}
                    setCountry={props.setConCountry}
                    ctryName={props.countries}
                    type="conTo"
                />
            )}
        </>
    )

}

export default ConsignedTo;