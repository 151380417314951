import { makeStyles } from "@material-ui/core";
import backgroundImg from '../assets/background.jpg';

export const useStyles = makeStyles(theme => ({
  screen: {
    backgroundImage: `url(${backgroundImg})`,
    //background: '#E1EBEE',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  button: {
    background: '#1381B9',
    padding: '16px 20px',
    borderRadius: '5px',
    display: 'flex',
    border: '1px solid #fff',
    fontSize: '14px',
    width: '100%',
    color: '#fff',
    fontWeight: 700,
    marginTop: '10px',
    justifyContent: 'center',
    cursor: 'pointer'
  },

  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0'
  },
  
  dividerLine: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#ccc'
  },
  
  dividerText: {
    margin: '0 16px',
    color: '#888'
  },

  link: {
    textDecoration: 'none',
    color: '#1381B9'
  },

  paragraph: {
    marginTop: '8px',
    textAlign: 'center'
  },

  divider: {
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center'
  },

  card: {
    display: 'flex',
    maxWidth: 600,
    minWidth: 300, 
    margin: 'auto',
    borderRadius: 10,
  },

  content: {
    flex: 1,
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  content2: {
    flex: 1, // Adjust this value to increase the width
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.9, // Adjust opacity for background image
    borderRadius: '0 10px 10px 0', // Rounded right border
    padding: '32px',
    position: 'relative',
  },

  content3: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 'inherit'
  },
}))