// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyB99oEa-P2o1wjHCc_a6fpogXarub0kNGk",
//   authDomain: "fir-authentication-c1923.firebaseapp.com",
//   projectId: "fir-authentication-c1923",
//   storageBucket: "fir-authentication-c1923.appspot.com",
//   messagingSenderId: "760033346903",
//   appId: "1:760033346903:web:06bec0bbb983c36b5d2a12",
//   measurementId: "G-C0W25KHXFW"
// };

const firebaseConfig = {
  apiKey: "AIzaSyB-Xp0neeTSsoh4og01fiNhjpO4-ysN988",
  authDomain: "g4scmauth-3bf68.firebaseapp.com",
  projectId: "g4scmauth-3bf68",
  storageBucket: "g4scmauth-3bf68.appspot.com",
  messagingSenderId: "90651233388",
  appId: "1:90651233388:web:0bc410bf5ef36b4ae31c49",
  measurementId: "G-G852VTT197"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;