import { Table, Dialog, DialogActions, DialogTitle, makeStyles, DialogContent, Button, Tooltip, TableContainer, TableHead, TableRow, TableCell, Typography, TableBody, Radio, FormControlLabel, Paper } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 142
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            fontFamily: 'sans-serif',
            maxWidth: 220,
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    }
}));

const AddressPopupModal = ({ openAddrModal, setOpenAddrModal, addressList, setConsignee, setConsignedTo, setCountry, ctryName, type }) => {
    const classes = useStyles();
    const [selectedAddr, setSelectedAddr] = useState([]);

    const filteredData = addressList.filter(row => {
        return row;
    })

    const handleSelect = () => {
        if(type === "imp"){
            setConsignee(selectedAddr);
        }else{
            setConsignedTo(selectedAddr);
        }    
        setCountry(ctryName.find(x => x.ctrycode === selectedAddr.party_country)); 
        setOpenAddrModal(false);
    }

    const handleCancel = () => {
        setOpenAddrModal(false);
    }

    const handleSelectAddr = (idx) => {
        const dataAddr = filteredData.map((data, i) => {
            if (i === idx) {
                return data;
            }
        });
        setSelectedAddr(dataAddr[idx]);
    }

    return (
        <>
            <Dialog open={openAddrModal} maxWidth="md">
                <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif !important' }}>Select Address</DialogTitle>
                <DialogContent dividers>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer className={classes.tableWrapper}>
                            <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Actions</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Name</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Contact Name</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Telephone</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Email</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>EIN</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Vat No.</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Address 1</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Address 2</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>City</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>State</Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCellActions}`}>
                                            <Typography className={classes.muteText}>Country</Typography>
                                        </TableCell>         
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.map((row, index) => (
                                        <>
                                            {
                                                <TableRow key={row.index} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                    <TableCell>
                                                        <input type="radio"
                                                            onClick={() => handleSelectAddr(index)}
                                                        />
                                                    </TableCell>
                                                    <Tooltip title={row.party_name !== null ? row.party_name : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_name}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.contact_name !== null ? row.contact_name : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.contact_name}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.contact_phone !== null ? row.contact_phone : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.contact_phone}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.contact_email !== null ? row.contact_email : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.contact_email}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_ein !== null ? row.party_ein : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_ein}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_vatno !== null ? row.party_vatno : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_vatno}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_addr1 !== null ? row.party_addr1 : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_addr1}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_addr2 !== null ? row.party_addr2 : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_addr2}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_city !== null ? row.party_city : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_city}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_state !== null ? row.party_state : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_state}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={row.party_country !== null ? row.party_country : ""} arrow>
                                                        <TableCell component="th" scope="row">
                                                            {row.party_country}
                                                        </TableCell>
                                                    </Tooltip>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSelect} color="primary">Select</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddressPopupModal;