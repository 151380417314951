import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button, withStyles, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { customerModal } from "../../../../../Common/constant";
import { fecthBlacklistCustomer, updateScan } from "../../../../../Common/API";
import AddContactPopupModal from "../../../../../Common/AddContactPopupModal";
import ScanPartyPopupModal from "../../../../../Common/ScanPartyPopupModal";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-41px 20px 10px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    errorMessge: {
        color: '#af5858',
        fontSize: 16,
        border: 'solid 1px #af5858',
        margin: '26px 0',
        padding: 15
    },
}))

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFED8',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 11,
        padding: 8,
        arrow: {
            color: 'FFFED8'
        }
    }
}))(Tooltip)

const CustomerDetails = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [country, setCountry] = useState([]);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [partyName, setPartyName] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [isEnable, setIsEnable] = useState(true);
    const [isOpenContactModal, setIsOpenContactModal] = useState(false);
    const [isSaved, setIsSaved] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [noOfMatch, setNoOfMatch] = useState("");
    const formValidator = useRef(new SimpleReactValidator({}));
    const validateParty = useRef(new SimpleReactValidator({}));

    const handleChangeCustomer = (field, value) => {
        if (field === "party_country") {
            let newObject = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setCountry(newObject);
        }
        setIsEnable(false);
        setCustomerData(values => ({ ...values, [field]: value }));
    }

    const handleSelectCustomer = () => {
        setIsNewCustomer(false);
        setCustomerData(customerModal);
        setMatchData([]);
        setIsEnable(true);
    }

    const handleSavecustomer = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...customerModal }
            payload.party_name = customerData.party_name;
            payload.contact_email = email;
            payload.contact_phone = customerData.contact_phone ? customerData.contact_phone : '';
            payload.contact_name = customerData.contact_name ? customerData.contact_name : '';
            payload.party_addr1 = customerData.party_addr1 ? customerData.party_addr1 : '';
            payload.party_addr2 = customerData.party_addr2 ? customerData.party_addr2 : '';
            payload.party_city = customerData.party_city ? customerData.party_city : '';
            payload.party_state = customerData.party_state ? customerData.party_state : '';
            payload.party_zipcode = customerData.party_zipcode ? customerData.party_zipcode : '';
            payload.party_ein = customerData.party_ein ? customerData.party_ein : '';
            payload.party_vatno = customerData.party_vatno ? customerData.party_vatno : '';
            payload.user = props.userId;
            payload.password = password;
            payload.party_country = country.ctrycode;
            props.saveCustomerDetail(payload);
            setCustomerData([]);
            setIsEnable(true);
            setIsSaved(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }
    const handleCancel = () => {
        navigate('/')
    }

    const handleChangePartyName = (field, value) => {
        setIsEnable(false);
        const obj = {
            id: value.pid,
            party_name: value.party_name,
            pid: value.pid
        }
        setPartyName(obj);
        props.contactNameList(value.pid);
        setCustomerData(props.customers.find(x => x.pid === value.pid))
        props.setCustomerId(value.pid);
    }

    const handleAddCustomer = () => {
        setIsNewCustomer(true);
        setPartyName([]);
        setCustomerData(customerModal);
        setIsEnable(true);
        setMatchData([])
    }

    const handleAddContact = () => {
        setIsOpenContactModal(!isOpenContactModal);
    }

    const handleCheckBlackList = () => {
        setIsPopupOpen(!isPopupOpen);
        getScanDetails();
    }

    const getScanDetails = () => {
        const payload = {
            "search": {
                "securekey": `${process.env.REACT_APP_DPL_GURU_KEY}`,
                "filters": {
                    "fuzzylogic": "false",
                    "dplguruforensic": "false",
                    "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
                    "exclusionwords": "pvt",
                    "redflag": "military"
                },
                "itemstosearch": [
                    {
                        "name": customerData.party_name,
                        "address": customerData.party_addr1 + ' ' + customerData.party_addr2,
                        "country_code": country.ctrycode
                    }
                ]
            }
        }

        fecthBlacklistCustomer(payload).then(data => {
            setNoOfMatch(data.summary.matches)
            if (data.summary.matches === "0") {
                setMatchData([]);
                setIsLoading(false);
                updateScan(customerData.party_name ? customerData.party_name : "", customerData.party_addr1 + ' ' + customerData.party_addr2, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                });
            } else {
                updateScan(customerData.party_name ? customerData.party_name : "", customerData.party_addr1 + ' ' + customerData.party_addr2, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                });
                setMatchData(data.records);
                setIsLoading(false);
            }
        }).catch(error => {
            alert(error);
        })
    }

    const renderCustomerDetail = () => {
        return (
            <React.Fragment>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        disabled={isEnable}
                        onClick={() => handleCheckBlackList()}>
                        Scan Blacklist Customer
                    </Button>
                    {isNewCustomer ?
                        <>
                            <Button variant="contained" color="primary"
                                className={classes.selectBtn}
                                disabled={isSaved}
                                onClick={() => handleAddContact()}>
                                + Contact
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.selectBtn}
                                onClick={() => handleSelectCustomer()}>
                                Select Customer
                            </Button>
                        </> :
                        <Button variant="contained" color="primary"
                            className={classes.selectBtn}
                            onClick={() => handleAddCustomer()}>
                            + Customer
                        </Button>
                    }
                </Grid>
                {isNewCustomer ? (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="party_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Party Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="party_name"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_name}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_name", e.target.value)}
                                    placeholder="Party Name"
                                />
                                {formValidator.current.message('Party Name', customerData.party_name, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="vat_no">
                                        VAT No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="VAT"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_vatno}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_vatno", e.target.value)}
                                    placeholder="VAT No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ein_no">
                                        EIN No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="EIN"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_ein}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_ein", e.target.value)}
                                    placeholder="EIN No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        <span className={classes.mandatoryField}>*</span>
                                        Email
                                    </label>
                                </Typography>
                                <TextField
                                    id="Email"
                                    color="primary"
                                    variant="outlined"
                                    value={email}
                                    className={classes.inputField}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                />
                                {formValidator.current.message('Email', email, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="password">
                                        <span className={classes.mandatoryField}>*</span>
                                        Password
                                    </label>
                                </Typography>
                                <TextField
                                    id="Password"
                                    color="primary"
                                    variant="outlined"
                                    value={password}
                                    className={classes.inputField}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                />
                                {formValidator.current.message('Password', password, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add1">
                                        Address 1
                                    </label>
                                </Typography>
                                <TextField
                                    id="add1"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_addr1}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_addr1", e.target.value)}
                                    placeholder="Address 1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add2">
                                        Address 2
                                    </label>
                                </Typography>
                                <TextField
                                    id="add2"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_addr2}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_addr2", e.target.value)}
                                    placeholder="Address 2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="city">
                                        City
                                    </label>
                                </Typography>
                                <TextField
                                    id="city"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_city}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_city", e.target.value)}
                                    placeholder="City"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="state">
                                        State
                                    </label>
                                </Typography>
                                <TextField
                                    id="state"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_state}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_state", e.target.value)}
                                    placeholder="State"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="zip">
                                        Zip
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    value={customerData.party_zipcode}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeCustomer("party_zipcode", e.target.value)}
                                    placeholder="ZIP"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="country">
                                        <span className={classes.mandatoryField}>*</span>
                                        Country
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="country-select"
                                    value={country}
                                    options={props.countries}
                                    getOptionLabel={(option) => option.ctrycname || ""}
                                    getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                    onChange={(e, newValue) => handleChangeCustomer("party_country", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {formValidator.current.message('Country', country, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        id="party-name-select"
                                        value={partyName}
                                        options={props.customers}
                                        getOptionLabel={(option) => option.party_name || ""}
                                        getOptionSelected={(option, value) => value.pid === option.pid}
                                        onChange={(e, newValue) => handleChangePartyName("party_name", newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Party Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {validateParty.current.message('Party Name', partyName, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Contact Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.contact_name}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Contact Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="vat_no">
                                            VAT No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="VAT"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_vatno}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="VAT No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="ein_no">
                                            EIN No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="EIN"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_ein}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="EIN No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="email">
                                            <span className={classes.mandatoryField}>*</span>
                                            Email
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Email"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.contact_email}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="password">
                                            <span className={classes.mandatoryField}>*</span>
                                            Password
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="Password"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.password}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_addr1}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_addr2}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_city}
                                        className={classes.inputField}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_state}
                                        className={classes.inputField}
                                        placeholder="State"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.party_zipcode}
                                        className={classes.inputField}
                                        placeholder="Zip"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            <span className={classes.mandatoryField}>*</span>
                                            Country
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={props.countries.find(x => x.ctrycode === customerData.party_country)?.ctrycname}
                                        className={classes.inputField}
                                        placeholder="Country"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="telephone">
                                            Telephone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="telephone"
                                        color="primary"
                                        variant="outlined"
                                        value={customerData.contact_phone}
                                        className={classes.inputField}
                                        placeholder="Telephone"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>
                )}
            </React.Fragment>
        )
    }

    return (
        <>
            <Paper component="form" className={classes.paper}>
                {renderCustomerDetail()}
                {isNewCustomer ? (
                    <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                disabled={customerData.length === 0 ? true : false}
                                onClick={() => handleSavecustomer()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : null}
                {isOpenContactModal && (
                    <AddContactPopupModal
                        isOpenContactModal={isOpenContactModal}
                        setIsOpenContactModal={setIsOpenContactModal}
                        saveContact={props.addContact}
                        pid={props.customerId}
                        paid={0} />
                )}
                {isPopupOpen && (
                    <ScanPartyPopupModal
                        isPopupOpen={isPopupOpen}
                        setIsPopupOpen={setIsPopupOpen}
                        scanDetails={matchData}
                        isLoading={isLoading}
                        matches={noOfMatch} />
                )}
            </Paper>
        </>
    )
}

export default CustomerDetails;