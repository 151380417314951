import { Dialog, Grid, Typography, FormControl, DialogActions, makeStyles, IconButton, DialogTitle, DialogContent, TextField, Paper, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SimpleReactValidator from "simple-react-validator";
import { customerModal } from "../../../Shipment/components/CreateShipment/constants";
import { useState, useRef } from "react";
import Cookies from "js-cookie";
import CreateIcon from '../../../assets/CreateActive.svg';
import AddContactPopupModal from "../../../Common/AddContactPopupModal";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    }
}))

const AddCustomerMasterModal = ({ openCustomerModal, setOpenCustomerModal, selectedCustomer, setSelectedCustomer,
    country, setCountry, countries, addCustomer, pid, addContact }) => {
    const formValidator = useRef(new SimpleReactValidator({}));
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [isChecked, setIsChecked] = useState(selectedCustomer?.isactive === "Y" ? true : false);

    const handleChangeCustomer = (field, value) => {
        if (field === "party_country") {
            let newObject = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setCountry(newObject);
        }
        setSelectedCustomer(values => ({ ...values, [field]: value }));
    }

    const handleSave = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...customerModal };
            payload.party_name = selectedCustomer.party_name;
            payload.contact_email = selectedCustomer.contact_email;
            payload.contact_name = selectedCustomer.contact_name ? selectedCustomer.contact_name : '';
            payload.contact_phone = selectedCustomer.contact_phone;
            payload.party_addr1 = selectedCustomer.party_addr1 ? selectedCustomer.party_addr1 : '';
            payload.party_addr2 = selectedCustomer.party_addr2 ? selectedCustomer.party_addr2 : '';
            payload.party_city = selectedCustomer.party_city ? selectedCustomer.party_city : '';
            payload.party_state = selectedCustomer.party_state;
            payload.party_zipcode = selectedCustomer.party_zipcode;
            payload.party_ein = selectedCustomer.party_ein ? selectedCustomer.party_ein : '';
            payload.party_vatno = selectedCustomer.party_vatno ? selectedCustomer.party_vatno : '';
            payload.party_addr3 = '';
            payload.party_addr4 = '';
            payload.user = localStorage.getItem('userId');
            payload.password = '';
            payload.party_country = country.ctrycode;
            payload.isactive = isChecked === true ? "Y" : "N";
            payload.pid = pid;
            addCustomer(payload);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        setOpenCustomerModal(false);
    }

    return (
        <Dialog open={openCustomerModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif' }}>Edit Customer</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="party_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Party Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="partyName"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_name}
                                    onChange={(e) => handleChangeCustomer("party_name", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Party Name"
                                />
                                {formValidator.current.message('Party Name', selectedCustomer.party_name, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact_name">
                                        Contact Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="contact"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.contact_name}
                                    onChange={(e) => handleChangeCustomer('contact_name', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Contact Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="vat_no">
                                        VAT No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="VAT"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_vatno}
                                    onChange={(e) => handleChangeCustomer('party_vatno', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="VAT No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ein">
                                        EIN No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="ein"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_ein}
                                    onChange={(e) => handleChangeCustomer('party_ein', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="EIN No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        <span className={classes.mandatoryField}>*</span>
                                        Email
                                    </label>
                                </Typography>
                                <TextField
                                    id="Email"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.contact_email}
                                    onChange={(e) => handleChangeCustomer("contact_email", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Email"
                                />
                                {formValidator.current.message('Email', selectedCustomer.contact_email, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add1">
                                        Address 1
                                    </label>
                                </Typography>
                                <TextField
                                    id="add1"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_addr1}
                                    onChange={(e) => handleChangeCustomer('party_addr1', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Address 1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="add2">
                                        Address 2
                                    </label>
                                </Typography>
                                <TextField
                                    id="add2"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_addr2}
                                    onChange={(e) => handleChangeCustomer('party_addr2', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Address 2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="city">
                                        City
                                    </label>
                                </Typography>
                                <TextField
                                    id="city"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_city}
                                    onChange={(e) => handleChangeCustomer('party_city', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="City"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="state">
                                        <span className={classes.mandatoryField}>*</span>
                                        State
                                    </label>
                                </Typography>
                                <TextField
                                    id="state"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_state}
                                    onChange={(e) => handleChangeCustomer("party_state", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="State"
                                />
                                {formValidator.current.message('State', selectedCustomer.party_state, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="zip">
                                        <span className={classes.mandatoryField}>*</span>
                                        Zip
                                    </label>
                                </Typography>
                                <TextField
                                    id="zip"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.party_zipcode}
                                    onChange={(e) => handleChangeCustomer("party_zipcode", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Zip"
                                />
                                {formValidator.current.message('Zip', selectedCustomer.party_zipcode, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="telephone">
                                        <span className={classes.mandatoryField}>*</span>
                                        Telephone
                                    </label>
                                </Typography>
                                <TextField
                                    id="telephone"
                                    color="primary"
                                    variant="outlined"
                                    value={selectedCustomer.contact_phone}
                                    onChange={(e) => handleChangeCustomer("contact_phone", e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Telephone"
                                />
                                {formValidator.current.message('Telephone', selectedCustomer.contact_phone, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="country">
                                        <span className={classes.mandatoryField}>*</span>
                                        Country
                                    </label>
                                </Typography>
                                <Autocomplete
                                    disableClearable
                                    id="country-select"
                                    value={country}
                                    options={countries}
                                    getOptionLabel={(option) => option.ctrycname}
                                    getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                    onChange={(e, newValue) => handleChangeCustomer("party_country", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {formValidator.current.message('Country', country, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="isactive">
                                        Is Active
                                    </label>
                                </Typography>
                                <Checkbox
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    checked={isChecked}
                                    color="primary"
                                    style={{ marginTop: '-26px', marginLeft: '-66px' }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCustomerMasterModal;