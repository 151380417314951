/** @format */

import React, { createContext, useState, useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { HEAD_CELLS } from '../constants/index';
import Cookies from 'js-cookie';
import { fetchPartyScanList } from '../../../Common/API';
import { useSnackbar } from "react-simple-snackbar";
import { OPTIONS, OPTIONS3 } from "../../../Common/constant";
import moment from 'moment';

const initialState = {
  loading: true,
  surveyList: [],
  error: null,
  hasError: false,
  headCells: HEAD_CELLS,
  order: 'asc',
  orderBy: '',
  pageId: null,
};

const spinnerStyle = {
  marginLeft: '50%',
  marginTop: '2%',
};

export const PartyScanSummaryContext = createContext(initialState);

export const PartyScanSummaryContextProvider = ({ children }) => {
  const userId = localStorage.getItem('userId');
  const [successSnackbar] = useSnackbar(OPTIONS);
  const [amberSnackbar] = useSnackbar(OPTIONS3);
  const [allPartyContextData, setAllPartyContextData] = useState(initialState);
  const { summaryList, loading, headCells, orderBy, order } = allPartyContextData;
  const To = new Date();
  const From = moment(new Date(To.getFullYear() - 1, To.getMonth(), To.getDate())).format('yyyy-MM-DD');
  const filter = {
    "fromDate": From,
    "toDate": moment(To).add(1, 'days').format('yyyy-MM-DD'),
    "user": userId,
    "type": 'manual'
  }

  useEffect(() => {
    filterScanSummary(filter);
  }, [])

  const filterScanSummary = (filter) => {
    fetchPartyScanList(filter)
      .then((data) => {
        if (data.status !== "NO DATA") {
          const summaryData = data.list.map((data, index) => {
            return data;
          });
          setAllPartyContextData((prevState) => {
            return {
              ...prevState,
              summaryList: summaryData,
              loading: false,
              error: null,
              hasError: false,
              searchTerm: '',
            }
          });
        } else {
          setAllPartyContextData((prevState) => {
            return {
              ...prevState,
              summaryList: [],
              loading: false,
              hasError: true,
            }
          });
        }
      })
      .catch((error) => {
        setAllPartyContextData({
          ...allPartyContextData,
          loading: false,
          error: error,
          hasError: true,
        });
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setAllPartyContextData({
      ...allPartyContextData,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  function descendingComparator(a, b, orderByVal) {
    const orderByArray = orderByVal.split('.');
    if (
      orderByArray &&
      orderByArray.length &&
      b[orderByArray[0]] &&
      b[orderByArray[0]][orderByArray[1]] &&
      a[orderByArray[0]] &&
      a[orderByArray[0]][orderByArray[1]]
    ) {
      if (
        b[orderByArray[0]][orderByArray[1]] <
        a[orderByArray[0]][orderByArray[1]]
      ) {
        return -1;
      }
      return 1;
    } else if (b[orderByVal] && a[orderByVal]) {
      const isNumber = typeof a[orderByVal] === 'number';
      const filedValueB = isNumber
        ? b[orderByVal]
        : b[orderByVal].toUpperCase();
      const filedValueA = isNumber
        ? a[orderByVal]
        : a[orderByVal].toUpperCase();
      if (filedValueB < filedValueA) {
        return -1;
      }
      return 1;
    }
    return 0;
  }

  function getComparator(orderVal, orderByVal) {
    return orderVal === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByVal)
      : (a, b) => -descendingComparator(a, b, orderByVal);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orders = comparator(a[0], b[0]);
      if (orders !== 0) return orders;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const contextValues = {
    ...allPartyContextData,
    summaryList,
    amberSnackbar,
    successSnackbar,
    headCells,
    filterScanSummary,
    handleRequestSort,
    getComparator,
    stableSort,
  };

  return (
    <PartyScanSummaryContext.Provider value={contextValues}>
      {loading ? (
        <div style={spinnerStyle}>
          {' '}
          <SpinnerCircularFixed
            color='#0070AD'
            enabled={loading}
          />{' '}
        </div>
      ) : (
        children
      )}
    </PartyScanSummaryContext.Provider>
  );
};
