import React, { useState } from "react";
import TableOrganizer from "../../../Common/TableOrganizer";
import { DEFAULT_MAX_WIDTH_CELL, DEFAULT_MIN_WIDTH_CELL, ROWS_PER_PAGE_OPTIONS } from "../constants";
import { useSort } from "../../../Common/TableOrganizer/common/hooks/useSort";
import '../TableView/index.css';
import { RenderActionButtons } from "./components/RenderActionButtons";
import { Typography } from "@material-ui/core";
import redFlag from '../../../assets/redflag.png';
import greenFlag from '../../../assets/greenflag.png';
import yellowFlag from '../../../assets/yellowflag.png';

export function TableView({ headCells, summaryList, filterScanSummary, amberSnackbar, successSnackbar }) {
    const { columnOrder, columnOrderBy, handleRequestSort } = useSort();
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0].value)
    const TotalRecords = summaryList.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    }

    const returnPaginationData = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const COLUMNS = []
    headCells.forEach(head => {
        const { id, label, selected, ...rest } = head
        if (selected) {
            const obj = {
                dataField: id,
                headerName: label,
                ...rest
            }
            COLUMNS.push(obj)
        }
    })

    const actionColumn = {
        dataField: "actions",
        headerName: "Actions",
        align: 'center',
        sortable: false,
        width: 116,
        truncate: true,
        cellRenderMethod: (record) => (
            <RenderActionButtons
                record={record}
                amberSnackbar={amberSnackbar}
                successSnackbar={successSnackbar}
                filterScanSummary={filterScanSummary}
                summaryList={summaryList}
            />
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    const statusColumn = {
        dataField: "noOfMatches",
        headerName: "No. of Matches",
        align: 'center',
        sortable: false,
        width: 116,
        truncate: true,
        cellRenderMethod: (record) => (
            <>
                {record.matches > 0 ? <img src={redFlag} alt='red' width='18px' /> : record.matches === null ? <img src={yellowFlag} alt='yellow' width='18px' /> : <img src={greenFlag} alt='green' width='18px' />}
                <Typography style={{fontSize: "14px", display: "contents"}}>{record.matches}</Typography>
            </>
        ),
        stickyColumn: 'right',
        isNonEditable: true
    }

    return (
        <>
            <TableOrganizer
                columns={[...COLUMNS, statusColumn, actionColumn]}
                tableData={returnPaginationData(summaryList, recordsPerPage, currentPage)}
                defaultMinWidthForCell={DEFAULT_MIN_WIDTH_CELL}
                defaultMaxWidthForCell={DEFAULT_MAX_WIDTH_CELL}
                tableProps={{ stickyHeader: true, tableContainerHeight: 650 }}
                order={columnOrder} // Order 'ASC' will be having values 'ASC' || 'DESC' || NULL
                orderBy={columnOrderBy}
                handleRequestSort={handleRequestSort}
                pagination={true}
                currentPage={currentPage}
                recordsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                recordsPerPage={recordsPerPage}
                totalPageCount={Pagecount}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                className="summaryViewTable"
            />
        </>
    );
}

export default TableView;
