import { Typography, Box } from "@material-ui/core";
import { PLANNED_DELIVERY_CTRY } from "../../../Common/constant";
import PieChart from "../../../Common/GoogleCharts/PieChart";
import { useNavigate } from "react-router-dom";

export default function PlannedDeliveryCountry({ plannedData }) {
    const navigate = useNavigate();
    const graphClick = (origin) => {
        localStorage.removeItem('wostatusname');
        localStorage.removeItem('origin');
        localStorage.removeItem('wopendingthismonth');
        localStorage.removeItem('wostatus');
        const wopendingthismonth = 1
        localStorage.setItem('wopendingthismonth', wopendingthismonth)
        localStorage.setItem('origin', origin);
        navigate({ pathname: '/work-order/summary', data: { origin, wopendingthismonth } });
    }
    const handleClick = () => {
        localStorage.removeItem('wostatusname');
        localStorage.removeItem('origin');
        localStorage.removeItem('wopendingthismonth');
        localStorage.removeItem('wostatus');
        navigate({ pathname: '/work-order/summary' })
    }
    return (
        <>
            <Box>
                <span style={{ cursor: 'pointer' }} onClick={() => handleClick()}>
                    <Typography align="left" className="reportTitle">{PLANNED_DELIVERY_CTRY}</Typography>
                </span>
                <PieChart data={plannedData} graphClick={graphClick} />
            </Box>
        </>
    )
}