import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from "./Style";
import LockIcon from '@material-ui/icons/Lock';
import g4scm_Logo from '../assets/g4scm_logo.png';
import { Typography, Card, CardContent, TextField, InputAdornment } from "@material-ui/core";
import { createLogin } from "../Common/API";
import { useSnackbar } from "react-simple-snackbar";
import { OPTIONS3 } from "../Common/constant";

const OtherUserLogin = () => {
  const classes = useStyles();
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [amberSnackbar] = useSnackbar(OPTIONS3);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await createLogin(email, password).then((res) => {
        localStorage.setItem('otherUserLogin', true);
        localStorage.setItem('userId', res.user);
        localStorage.setItem('party_type', res.party_type);
        localStorage.setItem('party_id', res.party_id);
        localStorage.setItem('user_name', res.user_name);
        localStorage.setItem('otherUser', true);
        if (res.status === "success") {
          if (res.party_type !== 'CST' && res.party_type !== 'SLS') {
            navigate('/manage-orders');
          } else if(res.party_type === 'CST') {
            navigate('/customer-dashboard');
          } else if(res.party_type === 'SLS') {
            navigate('/sales-dashboard');
          }
        } else {
          amberSnackbar(res.message);
        }
      });
    } catch (err) {
      console.log("err");
      console.log(err.message)
    }
  };

  return (
    <>
      <div className={classes.screen}>
        <Card variant="none" className={classes.card}>
          <CardContent className={classes.content}>
            <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
              Welcome To
              <br />
              <img src={g4scm_Logo} style={{ height: '40px', marginTop: '10px' }} alt="g4pl Logo" />
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
                label="Username"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                className={classes.button}
              >
                Sign In
              </Button>
              <Typography className={classes.paragraph}>
                Don't have an account ? <Link to='/' className={classes.link}>Contact G4SCM</Link>
              </Typography>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default OtherUserLogin;
