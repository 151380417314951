export const DEFAULT_MIN_WIDTH_CELL = 70;
export const DEFAULT_MAX_WIDTH_CELL = 800;
export const ROWS_PER_PAGE_OPTIONS = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
]

export const SL_NO = 'pid'
export const CUSTOMER_NAME = 'party_name'
export const CONTACT_PERSON = 'contact_name'
export const EMAIL = 'contact_email'
export const PHONE = 'contact_phone'
export const CITY = 'party_city'
export const STATE = 'party_state'
export const COUNTRY = 'party_country'
export const IS_ACTIVE = 'isactive'
export const MATCHES = 'matches'
export const SCAN_DATE = 'scandate'
export const TYPE = 'scantype'


export const HEAD_CELLS = [
    { id: SL_NO, isSortable: true, selected: true, label: 'Sl No', fieldName: 'pid', width: 80, stickyColumn: 'left'},
    { id: CUSTOMER_NAME, isSortable: true, selected: true, label: 'Customer Name', fieldName: 'party_name', width: 165},
    { id: CONTACT_PERSON, isSortable: true, selected: true, label: 'Contact Person', fieldName: 'contact_name', width: 160},
    { id: EMAIL, isSortable: true, selected: true, label: 'Email', fieldName: 'contact_email', width: 170 },
    { id: PHONE, isSortable: true, selected: true, label: 'Phone', fieldName: 'contact_phone', width: 150 },
    { id: CITY, isSortable: true, selected: true, label: 'City', fieldName: 'party_city', width: 120},
    { id: STATE, isSortable: true, selected: true, label: 'State', fieldName: 'party_state', width: 100},
    { id: COUNTRY, isSortable: true, selected: true, label: 'Country', fieldName: 'party_country', width: 100},
    { id: IS_ACTIVE, isSortable: true, selected: true, label: 'Is Active', fieldName: 'isactive', width: 130},
    { id: MATCHES, isSortable: true, selected: true, label: 'No of Matches', fieldName: 'matches', width: 143},
    { id: SCAN_DATE, isSortable: true, selected: true, label: 'Last Scan Date', fieldName: 'scandate', width: 150},
    { id: TYPE, isSortable: true, selected: true, label: 'Scan Type', fieldName: 'scantype', width: 120}
]
