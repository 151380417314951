import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Dialog, DialogTitle, Button, DialogContent, Paper, Grid, DialogActions, TextField, Typography, FormControl, makeStyles } from "@material-ui/core";
import Cookies from "js-cookie";
import { contactModal } from "../constant";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
    }
}))

const AddContactPopupModal = ({ isOpenContactModal, setIsOpenContactModal, saveContact, pid, paid }) => {
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [contactData, setContactData] = useState([]);
    const formValidator = useRef(new SimpleReactValidator({}));

    const handleChangeContact = (field, value) => {
        setContactData(values => ({ ...values, [field]: value }))
    }

    const handleCancel = () => {
        setIsOpenContactModal(false);
    }

    const handleAddContact = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...contactModal }
            payload.contact_email = contactData.contact_email;
            payload.contact_name = contactData.contact_name;
            payload.contact_phone = contactData.contact_phone ? contactData.contact_phone : "";
            payload.user = localStorage.getItem('userId');
            payload.pid = pid;
            payload.paid = paid
            saveContact(payload)
            setIsOpenContactModal(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Dialog open={isOpenContactModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif !important' }}>Add Contact</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Contact Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="contact_name"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeContact('contact_name', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Contact Name"
                                />
                                {formValidator.current.message('Contact Name', contactData.contact_name, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="email">
                                        <span className={classes.mandatoryField}>*</span>
                                        Email
                                    </label>
                                </Typography>
                                <TextField
                                    id="email"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeContact('contact_email', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Email"
                                />
                                {formValidator.current.message('Email', contactData.contact_email, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="phone">
                                        Telephone
                                    </label>
                                </Typography>
                                <TextField
                                    id="phone"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeContact('contact_phone', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Telephone"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddContact} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddContactPopupModal;