import { Paper, Table, TableBody, makeStyles, TableRow, Typography, TableCell, TableHead, TableContainer, Tooltip, Grid, Button, ButtonGroup } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, {useState} from "react";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableWrapper: {
        maxHeight: 499
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 190
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    pageNumber: {
        color: '#828282',
    },
    paginationUl: {
        '& ul': {
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-page.Mui-selected': {
                    color: theme.palette.primary.dark,
                    background: 'transparent',
                }
            }
        }
    },
    buttonRoot: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        border: 0,
        margin: `` + theme.spacing(.5) + `px 0 ` + theme.spacing(.5) + `px ` + theme.spacing(.5) + `px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
            position: 'absolute',
            right: -15,
            content: '""',
            top: 0,
            height: 24,
            textDecoration: 'none'
        },
        '&:hover': {
            background: 'none',
            color: '#2B0A3D',
            fontWeight: 600,
            border: 0,
            textDecoration: 'none'
        },
        '&.active': {
            color: '#2B0A3D',
            fontWeight: 600,
            textDecoration: 'none',
            '&:before': {
                backgroundColor: '#2B0A3D',
                left: 0,
                content: '""',
                bottom: -5,
                height: 2,
                position: 'absolute',
                width: '100%',
                textDecoration: 'none'
            }
        }
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
    },
    stickyPagination: {
        position: 'sticky',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        marginTop: theme.spacing(1.5),
        background: '#F3F3F3'
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            maxWidth: 220,
            fontFamily: "sans-serif",
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    },
}));

const TrackShipment = (props) => {
    const classes = useStyles();
    const [activeSeletion, setActiveSeletion] = useState(2);
    const filteredData = props.trackList.filter(row => {
        return row;
    })

    const ROWS_PER_PAGE = [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: filteredData.length }
    ];
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE[0].value)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0].value);

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
        setActiveSeletion(1)
        setRowsPerPage(value);
        setPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setActiveSeletion(2)
        setPage(newPage);
    }

    const TotalRecords = filteredData.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const validate = (index) => {
        if (activeSeletion === 1) {
            // user clicks on show option recently
            return rowsPerPage > index;
        }
        if (activeSeletion === 2) {
            // user clicks on pagination numbers recently
            return (page === 1 ? 0 : (rowsPerPage * page) - rowsPerPage) <= index && index < (page === 1 ? 25 : (((page * rowsPerPage) - rowsPerPage) + rowsPerPage));
        }
    }

    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className={classes.tableWrapper}>
                    <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography className={classes.muteText}>Sl No.</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Tracking Type</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Master Tracking No.</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Packing Type</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.muteText}>Box Tracking No.</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <>
                                    {
                                        validate(index) && <TableRow key={row.sorepoid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <Tooltip title={row.user_name !== null ? row.user_name : ""} arrow>
                                                <TableCell component="th" scope="row">
                                                    {row.carrier}
                                                </TableCell>
                                            </Tooltip>
                                            <Tooltip title={row.subject !== null ? row.subject : ""} arrow>
                                                <TableCell component="th" scope="row">
                                                    {row.ship_trackno}
                                                </TableCell>
                                            </Tooltip>
                                            <Tooltip title={row.cdate !== null ? row.cdate : ""} arrow>
                                                <TableCell component="th" scope="row">
                                                    {row.rec_type}
                                                </TableCell>
                                            </Tooltip>
                                            <Tooltip title={row.message !== null ? row.message : ""} arrow>
                                                <TableCell component="th" scope="row">
                                                    {row.box_trackno}
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                    }
                                </>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container boxShadow={3} className={classes.stickyPagination} >
                        <Grid item xs={3}>
                            <Typography className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {Pagecount} </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Pagination className={[classes.paginationUl, { root: classes.pageNumber }]} count={Pagecount} showFirstButton showLastButton onChange={handleChangePage} />
                        </Grid>
                        <Grid container item xs={4} justifyContent='center'>
                            <Typography className={classes.paginationFont}>Show</Typography>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                {ROWS_PER_PAGE.map(({ label, value }) => (
                                    <Button
                                        key={value}
                                        className={recordsPerPage === value && 'active'}
                                        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                                        onClick={() => handleChangeRowsPerPage(value)}>
                                        {label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </TableContainer>
            </Paper>
        </React.Fragment>
    );
}

export default TrackShipment;