import { makeStyles } from "@material-ui/core";

export const dashHeaderStyle = makeStyles(theme => ({
    headBox: {
        '& .headerBox': {
            borderBottom: '1px solid #E0E0E0',
            '& .titleFirst': {
                fontSize: theme.spacing(4) - 4,
                color: theme.palette.primary.dark,
                padding: `${theme.spacing(1.2)}px 24px`
            }
        },
        background: 'white',
        '& .sidePadding': {
            padding: '24px'
        },
        '& .headerPadding': {
            padding: '0px 24px 0px 24px'
        },
        '& .viewToggle': {
            margin: '0',
            padding: 0
        },
        '& .MuiDivider-vertical': { height: '70%', margin: `0 ${theme.spacing(1)}px` },
        '& .viewColumns, ': {
            '& .MuiDivider-vertical': { height: '70%', margin: `0 ${theme.spacing(1)}px` },
            '& .viewColumns, ': {
                padding: 0
            },
            '& .h-100': { height: '100%' },
            '& .title': {
                fontSize: theme.spacing(4) - 4,
                color: theme.palette.primary.dark,
                padding: `${theme.spacing(1.2)}px 0`
            },
            '& .verticalDivider': {
                height: 30,
                padding: '0 1px',
                margin: '0 10px'
            }
        }
    }
}))

export const reportStyle = makeStyles((theme) => ({
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    header: {
        borderBottom: '1px solid #E0E0E0'
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`
    },
    dashReportsWrap: {
        background: 'white',
        '& .spinnerStyle': {
            display: "flex",
            alignItems: "center",
            height: '100%',
            justifyContent: 'center',
        },
        '& .loadWithMessage': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingTop: 150
        },
        '& .reportTitle': {
            fontSize: theme.typography.fontSize * 1.15,
            fontWeight: theme.typography.fontWeightBold,
            padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(3)}px`,
        },
        '& .reportTitle1': {
            fontSize: theme.typography.fontSize * 1.15,
            fontWeight: theme.typography.fontWeightBold,
            padding: '24px 0px 0px 35px'
        },
        '& .reportTitle2': {
            fontSize: theme.typography.fontSize * 1.15,
            fontWeight: theme.typography.fontWeightBold,
            padding: '24px 0px 0px 35px'
        },
        '& .reportTitle3': {
            fontSize: theme.typography.fontSize * 1.15,
            fontWeight: theme.typography.fontWeightBold,
            padding: '24px 0px 0px 35px'
        },
        '& .spacpadding': {
            padding: '1px 20px 0px 10px'
        },
        '& .box': {
            position: 'relative',
            overflow: 'hidden',
            border: `1px solid ${theme.palette.grey[500]}`,
            height: '435px',
            '& .grid-spacing': {
                paddingTop: 35
            },
            '& .grid-spacingLeft': {
                paddingLeft: 8
            },
            '& .cstmLegend': {
                position: 'relative',
                paddingTop: '10px',
                '& .li': {
                    padding: '8px',
                    paddingLeft: 0,
                    width: 'auto',
                    maxWidth: 200,
                    "@media (max-width: 1600px)": {
                        maxWidth: 120
                    },
                    float: 'left',
                    font: '14px sans-serif',
                    color: '#767676',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '& .listWrap': { textTransform: 'capitalize' },
                    '& p': { fontSize: 13, marginTop: '-4px' },
                    '&:last-child': { border: 0 },
                    '& .legendColor': {
                        width: '11px', height: '11px', float: 'left', marginRight: '8px'
                    },
                    '& .disabled': {
                        color: '#D3D3D3',
                        '& .legendColor': {
                            backgrounf: '#D3D3D3!important'
                        }
                    }
                },
                '& .ejCstmPagination': {
                    position: 'absolute',
                    right: '24px',
                    bottom: '-3px',
                    '& button span': {
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }
                }
            },
            '& .multiDonut': {
                overflow: 'scroll',
                '& .MuiGrid-item': {
                    minWidth: '140px',
                    "@media (max-width: 1600px)": {
                        mindWidth: '120px'
                    }
                },
                '& .pieNumber, .pieTitle': {
                    top: '-5px',
                    fontSize: theme.typography.fontSize * 1.2,
                    color: '#2B0A3D'
                }
            },
            '& .vertical': {
                position: 'absolute',
                top: '40%',
                left: '2%',
                width: '100px',
                height: '100px',
                transform: 'rotate(90deg)',
                fontSize: '14px',
                fontFamily: 'sans-serif',
                color: '#828282',
                webkitTransform: 'rotate(-90deg)' 
            }
        }
    },
}))