import { Grid, Typography } from "@material-ui/core";
import BoxHead from "../../common/BoxHead";
import { SHIPMENT_INFO, SHIPMENTS, REDIRECT_TO_SHIPMENT, NODATA_MSG, SIPMENT_PER_TRANSPORT_TITLE, LEGEND_CATEGORIES_COLORS_IMPACT, SHIPMENT_PIE_TITLE } from "../../../Common/constant";
import ShipmentImage from "../../../assets/shipment-icon.png";
import BoxImg from "../../common/BoxImg";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../../../Common/Fallback";
import { SpinnerCircularFixed } from "spinners-react";
import DonutReport from "../../common/DonutReport";
import { ChartComponent, Inject, Category, BarSeries, Zoom, ScrollBar, SeriesDirective, SeriesCollectionDirective } from "@syncfusion/ej2-react-charts";
import { useEffect } from "react";
import { fetchShipmentGraph } from "../../../Common/API";
import { useNavigate } from "react-router-dom";
import BoxArrowImg from "../../common/BoxArrowImg";
import ArrowImg from '../../../assets/redo.png';

const ShipmentDash = (props) => {
  const [loading, setLoading] = useState({ isLoading: true, noDataMode: false, nodataMsg: NODATA_MSG });
  const navigate = useNavigate();

  useEffect(() => {
    fetchShipmentGraph(props.filter).then(data => {
      if (data.invcount[0].invcount === "0") {
        props.setLoading({ ...loading, noDataMode: true, isLoading: false });
      } else {
        props.setShipmentCategory(data.invcategory);
        props.setShipmentCount(data.invcount[0]);
        props.setCarrierData(data.carriercount);
        props.setStatus(data.status);
      }
    }).catch(error => {
      setLoading({ ...loading, noDataMode: true, isLoading: false });
    })
  }, []);

  const categoryData = Object.entries(
    props.shipmentCategory.reduce((prev, { category, invcount }) => {
      prev[category] = prev[category] ? prev[category] + 1 : parseInt(invcount);
      return prev;
    }, {})
  )
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => b.count - a.count);

  const MaxY = [];
  if (categoryData && categoryData.length > 0) {
    for (let i = 0; i < categoryData.length; i++) {
      MaxY.push(categoryData[i].y);
    }
  }

  const Ymaxkey = Math.max(...MaxY);
  let yAxesMaxValkey = 10
  let intervalValue = 10
  if (Ymaxkey !== -1) {
    let roundNearestTo = 5
    if (Ymaxkey > 30) {
      roundNearestTo = 10
    }
    yAxesMaxValkey = Math.ceil(Ymaxkey / roundNearestTo) * roundNearestTo;
    intervalValue = yAxesMaxValkey / 5
  }

  const primaryXAxis = { zoomFactor: 1, valueType: 'Category', majorGridLines: { width: 0 }, majrTickLines: { width: 0 } };

  const zoomsettings = {
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableSelectionZooming: true,
    mode: 'XY',
    enableScrollbar: true
  }

  const load = ({ chart: { zoomModule: { isZoomed } } }) => { isZoomed = true };

  const carrierGraphData = Object.entries(
    props.carrierData.reduce((prev, { carrier, shipcount }) => {
      prev[carrier] = prev[carrier] ? prev[carrier] + 1 : shipcount;
      return prev;
    }, {})
  )
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => b.count - a.count);

  const graphClick = (carrier) => {
    localStorage.removeItem('category');
    localStorage.setItem('carrier', carrier);
    navigate('/shipment/summary');
  }

  const handlePointClick = (args) => {
    const category = args.point.x === "Normal" ? "N" : args.point.x === "Battery" ? "B" : args.point.x === "Alcohol" ? "A" : args.point.x === "Dry Ice" ? "D" : null;
    localStorage.removeItem('carrier');
    localStorage.setItem('category', category);
    navigate('/shipment/summary');
  }

  return (
    <Grid item container xs={12} className="icapBox businessChange">
      <Grid item container xs={12} className="icapboxHead">
        <BoxHead headTitle={SHIPMENTS} tooltipTitle={SHIPMENTS} tooltipInfo={SHIPMENT_INFO} redirectLink={REDIRECT_TO_SHIPMENT} />
        <BoxArrowImg headImg={ArrowImg} tooltipTitle={SHIPMENTS} redirectLink={REDIRECT_TO_SHIPMENT} type="Ship" />
      </Grid>
      {props.status !== "success" ? (
        <div className='loaderWithMessage'>{props.loading.noDataMode ? (<p>{props.loading.nodataMsg}</p>) : (<SpinnerCircularFixed color="#0070AD" enabled={props.loading.isLoading} />)}</div>
      ) : (
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={3} className="align-center">
              <Grid item xs={6}>
                <BoxImg headImg={ShipmentImage} tooltipTitle={SHIPMENTS} redirectLink={REDIRECT_TO_SHIPMENT} />
              </Grid>
              <Grid item xs={6}>
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                  <Typography className="icapBoxNumberTxt mb-0" gutterBottom variant="h6">{props.shipmentCount.invcount}</Typography>
                </ErrorBoundary>
                <Typography className="icapBoxTxt" variant="h6">Total Shipments</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={3}>
              <Grid item xs={12}>
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                  <Typography className="icapBoxNumberTxt" variant="h6" style={{ fontSize: '16px' }}>Number of shipments per category</Typography>
                  <ChartComponent
                    primaryXAxis={primaryXAxis}
                    primaryYAxis={{
                      zoomFactor: 1,
                      majorGridLines: { dashArray: [2] },
                      interval: intervalValue,
                      majrTickLines: { width: 0 }
                    }}
                    width={'100%'}
                    height={'50%'}
                    zoomSettings={zoomsettings} load={load}
                    chartArea={{ border: { width: 0 } }}
                    pointClick={(args) => { handlePointClick(args) }}>
                    <Inject services={[BarSeries, Category, ScrollBar, Zoom]} />
                    <SeriesCollectionDirective>
                      <SeriesDirective dataSource={categoryData} 
                      columnSpacing={0.1} columnWidth={0.4} width={2} 
                      xName='x' yName='y' type='Bar' />
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </ErrorBoundary>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <DonutReport title={SIPMENT_PER_TRANSPORT_TITLE} pieTitle={SHIPMENT_PIE_TITLE} dataSource={carrierGraphData} totalCount={props.shipmentCount.invcount} graphClick={graphClick} />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ShipmentDash;