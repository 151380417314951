import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import createRoot from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt+QHFqVk5rXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlljTn5Qd0RmUHtedXc=;Mgo+DSMBPh8sVXJ1S0d+X1lPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpTcUVgWH5beXBUTmM=;ORg4AjUWIQA/Gnt2VFhhQlJMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkJjWn5fdX1RRWJf;MTY0ODI1NUAzMjMxMmUzMTJlMzMzOEtZeFZDczY4Vk0wa1VCVHBwdC9SdnlvMFRxaElXeXFyZlR5SXFmKytDb2s9;MTY0ODI1NkAzMjMxMmUzMTJlMzMzOG4rVWVzekp4L29hY3NUYkRtdnpUN0N5bmVEUFlJUkpqa3lVais3QWliMmc9;NRAiBiAaIQQuGjN/V0d+XU9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckVkWXxfcXFcQ2BaUg==;MTY0ODI1OEAzMjMxMmUzMTJlMzMzOEIrTzFZZDVkSmFBdytJWnRzQ1lVN0NwbWhvUk1QaUR2UEViWkRjOU94VEk9;MTY0ODI1OUAzMjMxMmUzMTJlMzMzOEdSS21QWVVxQzA3SEtjdmhSWC9YUnVxZ2RydkpEWWxTQ3RyNk9WYjZ5Y3c9;Mgo+DSMBMAY9C3t2VFhhQlJMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkJjWn5fdX1QQWRf;MTY0ODI2MUAzMjMxMmUzMTJlMzMzOGlYc1Y1STh0cSs1NXZYUkNvRDIzR3pwZjRBWmZ5anFZM256am1aUTlmYUU9;MTY0ODI2MkAzMjMxMmUzMTJlMzMzOFlkUDNVSEpoancvQ3J4RmEvWVNpeFVaby9PWE93ZkZhcVVzMkV2TjVyU3M9;MTY0ODI2M0AzMjMxMmUzMTJlMzMzOEIrTzFZZDVkSmFBdytJWnRzQ1lVN0NwbWhvUk1QaUR2UEViWkRjOU94VEk9');

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // OVERRIDING FLEXBASIS to fix issue created by chrome version 92  (ticket #1211 for details)
        body: {
          '& .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12': {
            flexBasis: 'auto'
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1381B9',
      dark: '#2B0A3D',
      lightDark: '#828282',
      danger: '#ff0000',
    },
    secondary: {
      main: '#fff'
    },
  },
  typography: {
    h1: {
      fontFamily: 'sans-serif'
    },
    h2: {
      fontFamily: 'sans-serif'
    },
    h3: {
      fontFamily: 'sans-serif'
    },
    h4: {
      fontFamily: 'sans-serif'
    },
    h5: {
      fontFamily: 'sans-serif'
    },
    h6: {
      fontFamily: 'sans-serif'
    },
    subtitle1: {
      fontFamily: 'sans-serif'
    },
    body1: {
      fontFamily: 'sans-serif'
    },
    body2: {
      fontFamily: 'sans-serif'
    },
    caption: {
      fontFamily: 'sans-serif'
    }
  },
});

createRoot.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </ThemeProvider>
  ,
  document.getElementById('root')
);
