import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button } from "@material-ui/core";
import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../css/datepicker.styles.css'
import { shipmentDetailsModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import moment from "moment";
import calenderIcon from '../../../../../assets/calendarIcon.svg';

const useStyles = makeStyles(theme => ({
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    }
}))

const ShipmentDetail = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const imagePath = `url(${calenderIcon})`;
    const [shipmentDetails, setShipmentDetails] = useState([]);
    const [shipmentDate, setShipmentDate] = useState();
    const [isBtnDiable, setIsbtnDisable] = useState(true);

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 214px/ 12px no-repeat`,
                width: '248px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleCancel = () => {
        navigate('/');
    }

    const handleChangeShipment = (field, value) => {
        setIsbtnDisable(false);
        if (field === "ship_date") {
            setShipmentDate(value);
        }
        setShipmentDetails(values => ({ ...values, [field]: value }))
    }

    const handleSaveShip = () => {
        const payload = { ...shipmentDetailsModal }
        payload.mawbno = shipmentDetails.mawbno ? shipmentDetails.mawbno : "";
        payload.ship_date = shipmentDate ? moment(shipmentDate).format('yyyy-MM-DD') : null;
        payload.billof_landing = shipmentDetails.billof_landing ? shipmentDetails.billof_landing : "";
        payload.broker_contact = shipmentDetails.broker_contact ? shipmentDetails.broker_contact : "";
        payload.broker_name = shipmentDetails.broker_name ? shipmentDetails.broker_name : "";
        payload.broker_phone = shipmentDetails.broker_phone ? shipmentDetails.broker_phone : "";
        payload.hmatdesc = shipmentDetails.hmatdesc ? shipmentDetails.hmatdesc : "";
        payload.incoterms = shipmentDetails.incoterms ? shipmentDetails.incoterms : "";
        payload.pay_terms = shipmentDetails.pay_terms ? shipmentDetails.pay_terms : "";
        payload.po_number = shipmentDetails.po_number ? shipmentDetails.po_number : "";
        payload.ship_purpose = shipmentDetails.ship_purpose ? shipmentDetails.ship_purpose : "";
        payload.invshipid = 0;
        payload.invid = props.invoiceId;
        payload.user = localStorage.getItem('userId');
        props.addShipDetails(payload);
        setIsbtnDisable(true);
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                {localStorage.getItem('party_type') !== 'CST' ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="awb">
                                            AWB No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="awb"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("mawbno", e.target.value)}
                                        placeholder="AWB No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="shipDate">
                                            Shipment Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        selected={shipmentDate}
                                        dateFormat='yyyy-MM-dd'
                                        onChange={
                                            (date) => handleChangeShipment("ship_date", date)
                                        }
                                        style={{ width: '160px' }}
                                        customInput={<DateCustomInput />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="pono">
                                            PO No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="pono"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("po_number", e.target.value)}
                                        placeholder="PO No."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="payTerms">
                                            Payment Terms
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="payTerms"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("pay_terms", e.target.value)}
                                        placeholder="Payment Terms"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="bill">
                                            Bill of Landing
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="bill"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("billof_landing", e.target.value)}
                                        placeholder="Bill of Landing"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="ship">
                                            Purpose of Shipment
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="ship"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("ship_purpose", e.target.value)}
                                        placeholder="Purpose of Shipment"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="incoterms">
                                            Incoterms
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="incoterms"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("incoterms", e.target.value)}
                                        placeholder="Incoterms"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="material">
                                            Hazardous Material
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="material"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("hmatdesc", e.target.value)}
                                        placeholder="Hazardous Material"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="broker">
                                            Broker Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="broker"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("broker_name", e.target.value)}
                                        placeholder="Broker Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="phone">
                                            Broker Phone
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="phone"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("broker_phone", e.target.value)}
                                        placeholder="Broker Phone"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact">
                                            Broker Contact
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="contact"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeShipment("broker_contact", e.target.value)}
                                        placeholder="Broker Contact"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container wrap="wrap" className={classes.pageFooter}>
                            <Grid item xs={12}>
                                <Button variant="outlined" color="primary"
                                    className={classes.cancelButton}
                                    onClick={() => handleCancel()}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    disabled={isBtnDiable}
                                    onClick={() => handleSaveShip()}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    : <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="awb">
                                        AWB No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="awb"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="AWB No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="shipDate">
                                        Shipment Date
                                    </label>
                                </Typography>
                                <TextField
                                    id="date"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Shipment Date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="pono">
                                        PO No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="pono"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="PO No."
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="payTerms">
                                        Payment Terms
                                    </label>
                                </Typography>
                                <TextField
                                    id="payTerms"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Payment Terms"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="bill">
                                        Bill of Landing
                                    </label>
                                </Typography>
                                <TextField
                                    id="bill"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Bill of Landing"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ship">
                                        Purpose of Shipment
                                    </label>
                                </Typography>
                                <TextField
                                    id="ship"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Purpose of Shipment"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="incoterms">
                                        Incoterms
                                    </label>
                                </Typography>
                                <TextField
                                    id="incoterms"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Incoterms"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="material">
                                        Hazardous Material
                                    </label>
                                </Typography>
                                <TextField
                                    id="material"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Hazardous Material"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="broker">
                                        Broker Name
                                    </label>
                                </Typography>
                                <TextField
                                    id="broker"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Broker Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="phone">
                                        Broker Phone
                                    </label>
                                </Typography>
                                <TextField
                                    id="phone"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Broker Phone"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="contact">
                                        Broker Contact
                                    </label>
                                </Typography>
                                <TextField
                                    id="contact"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Broker Contact"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </Paper>
        </React.Fragment>
    )
}

export default ShipmentDetail;