import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar, ContextMenu } from "@syncfusion/ej2-react-filemanager";
import './index.css';

const FileManager = () => {
    return (
        <div>
            <div className="control-section">
                <FileManagerComponent height={550} id="filemanager"
                    allowDragAndDrop={true}
                    ajaxSettings={{
                        url: process.env.REACT_APP_VAULT_URL + 'AmazonS3FileOperations',
                        getImageUrl: process.env.REACT_APP_VAULT_URL + 'AmazonS3GetImage',
                        uploadUrl: process.env.REACT_APP_VAULT_URL + 'AmazonS3Upload',
                        downloadUrl: process.env.REACT_APP_VAULT_URL + 'AmazonS3Download'
                    }} toolbarSettings={{ items: ['NewFolder', 'Upload', 'SortBy', 'Cut', 'Copy', 'Paste', 'Delete', 'Refresh', 'Download', 'Rename', 'Selection', 'View', 'Details'] }} contextMenuSettings={{
                        layout: ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', '|', 'Details', '|', 'SelectAll']
                    }} view={"Details"}>
                    <Inject services={[NavigationPane, DetailsView, Toolbar, ContextMenu]} />
                </FileManagerComponent>
            </div>
        </div>
    );
}

export default FileManager;