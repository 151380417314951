export const Fallback = ({ error }) => {
    return (
      <div role="alert">
        <h2 style={{ textAlign: 'center', fontSize: '18px' }}>Sorry, something went wrong. Please try again later!</h2>
        {/* <pre style={{ textAlign: 'center' }}>{error.message}</pre>  Might be needed in future */}
      </div>
    );
  };
  
  export const errorHandler = (error, errorInfo) => {
    console.log('Logging', error, errorInfo); //Logging error in console
  };
  