import axios from 'axios';

const appUrl = process.env.REACT_APP_URL;
const FileUrl = process.env.REACT_APP_VAULT_URL;
const dplUrl = process.env.REACT_APP_DPL_GURU_API;

export const createLogin = (email, pwd) => {
    const data = { 'username': email, 'password': pwd }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/auth/login`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchPoSummary = (filter) => {
    const data = filter
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/polist`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCustomer = () => {
    return axios({
        method: 'GET',
        url: `${appUrl}/api/customers/getcustomers`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchSalesCustomer = (user_id) => {
    const data = {'uid': user_id}
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getsalescustomer`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCountry = () => {
    return axios({
        method: 'GET',
        url: `${appUrl}/api/common/getcountries`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveCustomer = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/addcustomer`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchUserList = (userId) => {
    const data = { 'user': userId }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/auth/getusers`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchOriginList = (pid) => {
    const data = { 'party_id': pid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getcustomershiporigin`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchPOList = (pid) => {
    const data = { 'party_id': pid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getcustomerpos`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const downloadPOList = (filter) => {
    const data = filter
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/polist`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveCustomerAddr = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addcominvaddr`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveComInv = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addcominv`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCustomerDetails = (userid, id) => {
    const data = { 'user': userid, 'party_id': id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getcustomerdetails`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchInvoiceDetails = (id) => {
    return axios({
        method: 'GET',
        url: `${appUrl}/api/exports/cominvdetails?cominv=${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveFileUpload = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addsorepo`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchShipmentList = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/cominvlist`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchShipmentStatus = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/common/getinvstatus`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchUOMDetails = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/common/getuoms`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCustomerAddress = (id) => {
    const data = { 'party_id': id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getcustomeraddress`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchPOEditData = (id) => {
    return axios({
        method: 'GET',
        url: `${appUrl}/api/imports/podetails?po=${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchAvailableItemQty = (poid, user) => {
    const data = { 'poid': poid, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/getwoavailableitems`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveInvoicePacking = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addinvpacking`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updatePurchaseOrder = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatepo`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deletePoItem = (id, user) => {
    const data = { 'poitemid': id, "user": user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletepoitem`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updatePoItems = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatepoitem`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const savePurchaseOrder = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addpo`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const savePOItem = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addpoitems`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveDeliveryPlan = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addwo`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateCustomer = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/editcustomer`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWoSummary = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wolist`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchInvoiceAddress = (partyid, ptype, user) => {
    const data = { "partyid": partyid, "ptype": ptype, "user": user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/cominvaddrdetails`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveShipmentDetails = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addinvshipdet`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const downLoadShipmentFiles = (repoid, user, type) => {
    const data = { "repoid": repoid, "user": user, "rtype": type }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/common/fetchrepo`,
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteFile = (fileid, id, user) => {
    const data = { 'sorepoid': fileid, 'invid': id, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/deletesorepo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveShipmentMessage = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addsomsg`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWoDetails = (id) => {
    return axios({
        method: 'GET',
        url: `${appUrl}/api/imports/wodetails?wo=${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchFedexRates = (id, user) => {
    const data = { 'invid': id, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/fedex/get_shipping_rates`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const validateGenarateLabel = (id, service, user, type) => {
    const data = { 'invid': id, 'service': service, 'user': user, 'type': type }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/fedex/validate_create_shipment`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWOStatus = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/common/getwostatus`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveWOMessage = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addwomsg`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWOGraphData = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wodash`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchShipmentGraph = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/cominvdash`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchPoGraphData = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/podash`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveWoRepository = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addworepo`,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteWORepository = (fileid, id, user) => {
    const data = { 'worepoid': fileid, 'woid': id, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deleterepo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteWOLoadEvent = (cntrId, user, id) => {
    const data = { "trackctnrid": cntrId, "user": user, "woid": id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletecntr`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteFFReciept = (trackffid, user, id) => {
    const data = { "trackffid": trackffid, "user": user, "woid": id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deleteffreceipt`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveRcvdFFReceipt = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/woffreceipt`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveFFCartons = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wocntrload`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveWoBillNo = (billNo, user, woid) => {
    const data = { 'billno': billNo, 'user': user, 'woid': woid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wobillno`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deletebillNo = (trackid, user, id) => {
    const data = { "trackshipid": trackid, "user": user, "woid": id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletebillno`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveTrackShipmentDate = (trackdata) => {
    const data = { trackActivity: trackdata }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/trackwo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteTrackShipDate = (tractactid, user, id) => {
    const data = { "trackactid": tractactid, "user": user, "woid": id }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletetrackwo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveWOStatus = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wostatus`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateDelPlan = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatewo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveInvItem = (itemObj, user, invid) => {
    const data = { 'ItemDetails': itemObj, 'user': user, 'invid': invid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/addinvitems`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchVaultUserData = (userId) => {
    return axios({
        method: 'GET',
        url: `${FileUrl}GetUserByUserId?userId=${userId}`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchDataByVaultName = (vaultName) => {
    return axios({
        method: 'GET',
        url: `${FileUrl}GetDataByVaultName?name=${vaultName}`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchFreightForward = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/customers/getfreightforwarders`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchVaultList = (pid, user) => {
    const data = { party_id: pid, user: user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/vault/listvault`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteWorkOrder = (id, user, reason, type) => {
    const data = { 'woid': id, "user": user, 'message': reason, "otype": type }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletewo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteAllPo = (poid, user, reason, type) => {
    const data = { "poid": poid, "user": user, "message": reason, "otype": type }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/deletepo`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveFF = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/addfreightforwarders`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveDeliveryAgent = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/adddeliveryagent`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateFFR = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/woffr`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateDelAgent = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wodagent`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchDestination = (pid) => {
    const data = { 'party_id': pid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getcustomershipdest`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const downloadWoList = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wolist`,
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCustomerWoDetails = (id) => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/imports/wocustdetails?wo=${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const downloadCustomerWO = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wolistcustomer`,
        headers: {
            'content-type': 'blob',
        },
        responseType: 'arraybuffer',
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWOCustomerList = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wolistcustomer`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchWOSalesList = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/wolistsales`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const generateLabelPdf = (userId, invid) => {
    const data = { "user": userId, "invid": invid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/invoicefed/generate_invoice`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveInvoiceStatus = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/invstatus`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const downloadShipment = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/exports/cominvlist`,
        headers: {
            'content-type': 'blob',
        },
        responseType: 'arraybuffer',
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchInvStatusList = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/common/getinvstatus`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fecthBlacklistCustomer = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${dplUrl}/consultas/bst/bstapi.php`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchTrackShipment = (userId, invid) => {
    const data = { "user": userId, "invid": invid }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/fedex/get_tracking_info`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const deleteShipment = (invid, user) => {
    const data = { 'invid': invid, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/fedex/delete_shipment`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchPartyScanList = (fromdate, todate, user) => {
    const data = {fromdate: fromdate, todate: todate, user: user}
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/scanlist`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateScan = (partyname, address, record, match, partyid, user, status) => {
    const data = {name: partyname, address: address, data: record, matches: match, party_id: partyid, user: user, statuscode: status}
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/addscan`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchDeliveryAgent = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/customers/getdeliveryagents`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveContacts = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/addcontact`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchContactList = (pid, paid, user) => {
    const data = { "pid": pid, "paid": paid, "user": user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/getcontactname`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchContactDetails = (paid, pid, user) => {
    const data = { 'paid': paid, 'pid': pid, 'user': user }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/contactnamelist`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const updateLocationContact = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatepartyaddresscontact`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveUpdateWoContact = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatewocontact`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const saveUpdatePoContact = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/updatepocontact`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchCustomerGraph = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/customerdash`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchSalesGraph = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/salesdash`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const trackLoginUser = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/tracklogin`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const validateLoginEmail = (email) => {
    const data = { 'useremail': email }
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/imports/validateuseremail`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const addUserDetails = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/auth/adduser`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchAllUsers = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/auth/getallusers`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const addSalesDetails = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${appUrl}/api/auth/addsales`,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const fetchSalesUsers = () => {
    return axios({
        method: 'POST',
        url: `${appUrl}/api/auth/getsalesuser`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}



