import { Typography, Box } from "@material-ui/core";
import searchIcon from '../../../assets/searchIcon.png';
import { LightTooltip } from "../../../Common/constant";
import { useNavigate } from "react-router-dom";

export default function CustomerSearch() {
    const navigate = useNavigate();

    const handleRedirect = () => navigate('/search-customer');
    const getRedirectTooltip = 'Click here to open'

    return (
        <>
            <Box>
                <Typography align="left" className="reportTitle">
                    <span style={{cursor: 'pointer'}} onClick={handleRedirect}>
                        Search Customer
                    </span>
                </Typography>
                <LightTooltip title={getRedirectTooltip} onClick={() => handleRedirect()}>
                    <img src={searchIcon} alt="search-Icon" width={'25%'} style={{ marginLeft: '36%', marginTop: '11%', cursor: 'pointer' }} />
                </LightTooltip>
            </Box>
        </>
    )
}