import { Paper, Grid, FormControl, Typography, TextField, makeStyles, Button, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import CreateIcon from '../../../../../assets/CreateActive.svg';
import DeleteIcon from '../../../../../assets/DeleteIcon.svg';
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        marginTop: '30px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}))

const ProductDetails = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const formValidator = useRef(new SimpleReactValidator({}));
    const alcoholType = [{ Id: "C", name: "CONSUMER" }, { Id: "L", name: "LICENSEE" }]
    const batteryMaterial = [{ Id: 1, name: "LITHIUM ION" }, { Id: 2, name: "LITHIUM METAL" }, { Id: 3, name: "UNKNOWN" }]
    const batteryPacking = [{ Id: 1, name: "CONTAINED IN EQUIPMENT" }, { Id: 2, name: "PACKED WITH EQUIPMENT" }, { Id: 3, name: "UNKNOWN" }]
    const handleAddItems = () => {
        props.setItemList([
            ...props.itemList,
            {
                id: props.itemList.length + 1,
                prod_qty: "",
                prod_name: "",
                prod_eccn: "",
                prod_htc: "",
                prod_hts: "",
                prod_origin_country: "",
                prod_unit_price: "",
                prod_qty_uom: "",
                prod_net_wt: "",
                prod_packages: "",
                prod_code: "",
                batterymaterialtype: "",
                batterypackingtype: "",
                alcrecipienttype: "",
                total: ""
            }
        ])
    }

    const handleDeleteItems = (index) => {
        const data = props.itemList.filter((_, i) => i !== index)
        props.setItemList(data);
    }

    const handleCancel = () => {
        navigate('/')
    }

    const handleChangeItem = (field, idx, value) => {
        props.setItemList([
            ...props.itemList.map((data, i) => {
                if (idx === i) data[field] = value;
                return data;
            })
        ])
    }

    const handleSaveItems = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {

            const objArr = [...props.itemList, ...props.updateItems]
            const weightById = new Map();

            props.packing.forEach(obj => {
                const { container_no, weight_gross } = obj;
                const weightNum = Number(weight_gross); // convert weight to a number
                if (weightById.has(container_no)) {
                    weightById.set(container_no, weightById.get(container_no) + weightNum);
                } else {
                    weightById.set(container_no, weightNum);
                }
            });

            const packingWeights = Array.from(weightById, ([container_no, weight_gross]) => ({ container_no, weight_gross: weight_gross.toFixed(3) }));

            const prodWtById = new Map();

            objArr.forEach(obj => {
                const { prod_packages, prod_net_wt } = obj;
                const weightNum = Number(prod_net_wt); // convert weight to a number
                if (prodWtById.has(prod_packages)) {
                    prodWtById.set(prod_packages, prodWtById.get(prod_packages) + weightNum);
                } else {
                    prodWtById.set(prod_packages, weightNum);
                }
            });

            const prodWeights = Array.from(prodWtById, ([prod_packages, prod_net_wt]) => ({ prod_packages, prod_net_wt: prod_net_wt.toFixed(3) }));

            prodWeights.map(obj => {
                const { prod_packages, prod_net_wt } = obj;
                const container = packingWeights.find(obj => obj.container_no === prod_packages);
                const totalWt = container.weight_gross;
                if (Number(prod_net_wt) > Number(totalWt)) {
                    props.amberSnackbar(`Total weight for container ${container.container_no} exceeded`);
                } else {
                    props.addInvItem(objArr)
                }
            });
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                {props.invoiceId && (props.updateItems.map((row, index) => {
                    const { prod_eccn, prod_htc, prod_net_wt, prod_code, prod_name, prod_qty, prod_unit_price } = row;
                    return (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="qty">
                                                <span className={classes.mandatoryField}>*</span>
                                                Quantity
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="qty"
                                            color="primary"
                                            name="prod_qty"
                                            value={prod_qty}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_qty", index, e.target.value)}
                                            placeholder="Quantity"
                                        />
                                        {formValidator.current.message('Quantity', prod_qty, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="packingNo">
                                                <span className={classes.mandatoryField}>*</span>
                                                Packing No.
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="package"
                                            value={row}
                                            options={props.packing}
                                            getOptionLabel={(option) => option.container_no ? option.container_no : option.prod_packages}
                                            getOptionSelected={(option, value) => value.container_no === option.container_no}
                                            onChange={(e, newValue) => handleChangeItem("prod_packages", index, newValue.container_no)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Package No."
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {/* {formValidator.current.message('Package No.', "", 'required', { className: classes.validateStyle })} */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="netWt">
                                                <span className={classes.mandatoryField}>*</span>
                                                Net Weight
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="netWt"
                                            name="prod_net_wt"
                                            value={prod_net_wt}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_net_wt", index, e.target.value)}
                                            placeholder="Net Weight"
                                        />
                                        {formValidator.current.message('Net Weight', prod_net_wt, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodCode">
                                                Product Code
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodCode"
                                            color="primary"
                                            name="prod_code"
                                            value={prod_code}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_code", index, e.target.value)}
                                            placeholder="Product Code"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodDesc">
                                                Product Description
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodDesc"
                                            color="primary"
                                            value={prod_name}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_name", index, e.target.value)}
                                            placeholder="Product Description"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="eccn/lic">
                                                <span className={classes.mandatoryField}>*</span>
                                                ECCN/LIC
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="eccn/lic"
                                            color="primary"
                                            value={prod_eccn}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_eccn", index, e.target.value)}
                                            placeholder="eccn/lic"
                                        />
                                        {formValidator.current.message('ECCN/LIC', prod_eccn, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="hts">
                                                <span className={classes.mandatoryField}>*</span>
                                                HTS
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="hts"
                                            color="primary"
                                            variant="outlined"
                                            value={prod_htc}
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_hts", index, e.target.value)}
                                            placeholder="hts"
                                        />
                                        {formValidator.current.message('HTS', prod_htc, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="unitPrice">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country of Origin
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="country"
                                            value={row}
                                            options={props.countries}
                                            getOptionLabel={(option) => option.ctrycname}
                                            getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                            onChange={(e, newValue) => handleChangeItem("prod_origin_country", index, newValue.ctrycode)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Select Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {/* {formValidator.current.message('Country', "", 'required', { className: classes.validateStyle })} */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="unitPrice">
                                                <span className={classes.mandatoryField}>*</span>
                                                Unit Price
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="unitPrice"
                                            color="primary"
                                            value={prod_unit_price}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_unit_price", index, e.target.value)}
                                            placeholder="Unit Price"
                                        />
                                        {formValidator.current.message('Unit Price', prod_unit_price, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                {props.dgCategory === "A" ? (
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="alcohol">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Alcohol Receipient
                                                </label>
                                            </Typography>
                                            <Autocomplete
                                                id="alcohol"
                                                disableClearable
                                                value={row}
                                                options={alcoholType}
                                                getOptionLabel={(option) => option.name ? option.name : option.alcrecipienttype}
                                                getOptionSelected={(option, value) => value.id === option.id}
                                                onChange={(e, newValue) => handleChangeItem("alcrecipienttype", index, newValue.name)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        placeholder="Alcohol"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                    />
                                                )}
                                            />
                                            {/* {formValidator.current.message('Alcohol Receipient', "", 'required', { className: classes.validateStyle })} */}
                                        </FormControl>
                                    </Grid>
                                ) : props.dgCategory === "B" ? (
                                    <>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <Typography className={classes.accordianExpandedTitle}>
                                                    <label htmlFor="packing">
                                                        <span className={classes.mandatoryField}>*</span>
                                                        Battery Packing
                                                    </label>
                                                </Typography>
                                                <Autocomplete
                                                    id="packing"
                                                    disableClearable
                                                    value={row}
                                                    options={batteryPacking}
                                                    getOptionLabel={(option) => option.name ? option.name : option.batterypackingtype}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleChangeItem("batterypackingtype", index, newValue.name)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Battery Packing"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={classes.inputField}
                                                        />
                                                    )}
                                                />
                                                {/* {formValidator.current.message('Battery Packing', "", 'required', { className: classes.validateStyle })} */}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <Typography className={classes.accordianExpandedTitle}>
                                                    <label htmlFor="material">
                                                        <span className={classes.mandatoryField}>*</span>
                                                        Battery Material
                                                    </label>
                                                </Typography>
                                                <Autocomplete
                                                    id="material"
                                                    disableClearable
                                                    value={row}
                                                    options={batteryMaterial}
                                                    getOptionLabel={(option) => option.name ? option.name : option.batterymaterialtype}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleChangeItem("batterymaterialtype", index, newValue.name)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Battery Material"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={classes.inputField}
                                                        />
                                                    )}
                                                />
                                                {/* {formValidator.current.message('Battery Material', "", 'required', { className: classes.validateStyle })} */}
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid item xs={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="total">
                                                Total
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="total"
                                            disabled={true}
                                            color="primary"
                                            value={prod_qty * prod_unit_price}
                                            variant="outlined"
                                            className={classes.inputField}
                                            placeholder="Total"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton edge="end" aria-label="add items" onClick={() => handleAddItems()}>
                                        <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="add items" onClick={() => handleDeleteItems(index)}>
                                        <img src={DeleteIcon} alt="add" style={{ marginTop: "30px" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </>
                    )
                }))}
                {props.updateItems.length === 0 && (props.itemList.map((row, index) => {
                    const { prod_eccn, prod_hts, prod_net_wt, prod_code, prod_name, prod_qty, prod_unit_price } = row;
                    return (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="qty">
                                                <span className={classes.mandatoryField}>*</span>
                                                Quantity
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="qty"
                                            color="primary"
                                            name="prod_qty"
                                            value={prod_qty}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_qty", index, e.target.value)}
                                            placeholder="Quantity"
                                        />
                                        {formValidator.current.message('Quantity', prod_qty, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="packingNo">
                                                <span className={classes.mandatoryField}>*</span>
                                                Packing No.
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="package"
                                            options={props.packing}
                                            getOptionLabel={(option) => option.container_no}
                                            getOptionSelected={(option, value) => value.container_no === option.container_no}
                                            onChange={(e, newValue) => handleChangeItem("prod_packages", index, newValue.container_no)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Package No."
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {/* {formValidator.current.message('Package No.', "", 'required', { className: classes.validateStyle })} */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="netWt">
                                                <span className={classes.mandatoryField}>*</span>
                                                Net Weight
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="netWt"
                                            name="prod_net_wt"
                                            value={prod_net_wt}
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_net_wt", index, e.target.value)}
                                            placeholder="Net Weight"
                                        />
                                        {formValidator.current.message('Net Weight', prod_net_wt, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodCode">
                                                Product Code
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodCode"
                                            color="primary"
                                            name="prod_code"
                                            value={prod_code}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_code", index, e.target.value)}
                                            placeholder="Product Code"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="prodDesc">
                                                Product Description
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="prodDesc"
                                            color="primary"
                                            value={prod_name}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_name", index, e.target.value)}
                                            placeholder="Product Description"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="eccn/lic">
                                                <span className={classes.mandatoryField}>*</span>
                                                ECCN/LIC
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="eccn/lic"
                                            color="primary"
                                            value={prod_eccn}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_eccn", index, e.target.value)}
                                            placeholder="eccn/lic"
                                        />
                                        {formValidator.current.message('ECCN/LIC', prod_eccn, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="hts">
                                                <span className={classes.mandatoryField}>*</span>
                                                HTS
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="hts"
                                            color="primary"
                                            variant="outlined"
                                            value={prod_hts}
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_hts", index, e.target.value)}
                                            placeholder="hts"
                                        />
                                        {formValidator.current.message('HTS', prod_hts, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="unitPrice">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country of Origin
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="country"
                                            options={props.countries}
                                            getOptionLabel={(option) => option.ctrycname}
                                            getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                                            onChange={(e, newValue) => handleChangeItem("prod_origin_country", index, newValue.ctrycode)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Select Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {/* {formValidator.current.message('Country', "", 'required', { className: classes.validateStyle })} */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="unitPrice">
                                                <span className={classes.mandatoryField}>*</span>
                                                Unit Price
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="unitPrice"
                                            color="primary"
                                            value={prod_unit_price}
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItem("prod_unit_price", index, e.target.value)}
                                            placeholder="Unit Price"
                                        />
                                        {formValidator.current.message('Unit Price', prod_unit_price, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                {props.dgCategory === "A" ? (
                                    <Grid item xs={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <Typography className={classes.accordianExpandedTitle}>
                                                <label htmlFor="alcohol">
                                                    <span className={classes.mandatoryField}>*</span>
                                                    Alcohol Receipient
                                                </label>
                                            </Typography>
                                            <Autocomplete
                                                id="alcohol"
                                                disableClearable
                                                options={alcoholType}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => value.id === option.id}
                                                onChange={(e, newValue) => handleChangeItem("alcrecipienttype", index, newValue.name)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        placeholder="Alcohol"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                    />
                                                )}
                                            />
                                            {/* {formValidator.current.message('Alcohol Receipient', "", 'required', { className: classes.validateStyle })} */}
                                        </FormControl>
                                    </Grid>
                                ) : props.dgCategory === "B" ? (
                                    <>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <Typography className={classes.accordianExpandedTitle}>
                                                    <label htmlFor="packing">
                                                        <span className={classes.mandatoryField}>*</span>
                                                        Battery Packing
                                                    </label>
                                                </Typography>
                                                <Autocomplete
                                                    id="packing"
                                                    disableClearable
                                                    options={batteryPacking}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleChangeItem("batterypackingtype", index, newValue.name)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Battery Packing"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={classes.inputField}
                                                        />
                                                    )}
                                                />
                                                {/* {formValidator.current.message('Battery Packing', "", 'required', { className: classes.validateStyle })} */}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <Typography className={classes.accordianExpandedTitle}>
                                                    <label htmlFor="material">
                                                        <span className={classes.mandatoryField}>*</span>
                                                        Battery Material
                                                    </label>
                                                </Typography>
                                                <Autocomplete
                                                    id="material"
                                                    disableClearable
                                                    options={batteryMaterial}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    onChange={(e, newValue) => handleChangeItem("batterymaterialtype", index, newValue.name)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Battery Material"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={classes.inputField}
                                                        />
                                                    )}
                                                />
                                                {/* {formValidator.current.message('Battery Material', "", 'required', { className: classes.validateStyle })} */}
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid item xs={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="total">
                                                Total
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="total"
                                            disabled={true}
                                            color="primary"
                                            value={prod_qty * prod_unit_price}
                                            variant="outlined"
                                            className={classes.inputField}
                                            placeholder="Total"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton edge="end" aria-label="add items" onClick={() => handleAddItems()}>
                                        <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                    </IconButton>
                                    {row.id !== 0 && (
                                        <IconButton edge="end" aria-label="add items" onClick={() => handleDeleteItems(index)}>
                                            <img src={DeleteIcon} alt="add" style={{ marginTop: "30px" }} />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )
                }))}
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleSaveItems()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default ProductDetails;