import { Grid, FormControl, Typography, TextField, Checkbox, Paper, makeStyles, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calenderIcon from '../../../../../assets/calendarIcon.svg';
import { forwardRef } from 'react';
import moment from "moment";
import { woStatusModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        marginTop: '13px',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-44px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}))

const WorkOrderStatus = (props) => {
    const classes = useStyles();
    const [woStatusData, setWoStatusData] = useState(props.woStatusList);
    const [woDate, setWoDate] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [woETADate, setWoETADate] = useState(null);
    const [, forceUpdate] = useState();
    const imagePath = `url(${calenderIcon})`;

    const DateInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 222px/ 12px no-repeat`,
                width: '262px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleChangeWoStatus = (field, value) => {
        if (field === "wostatus") {
            const obj = {
                id: value.status_code,
                status_code: value.status_code,
                wostatus: value.wostatus
            }
            props.setSelectWOStatus(obj);
        }else if(field === "statusDate"){
            setWoDate(value);
        }else if(field === "custView"){
            setIsChecked(value);
        }else if(field === "etaDate"){
            setWoETADate(value);
        }
        setWoStatusData(values => ({ ...values, [field]: value }));
    }

    const handleSaveWOStatus = () => {
        const isValid = props.formValidator.current.allValid();
        if (isValid) {
            const payload = {...woStatusModal}
            payload.user = localStorage.getItem('userId');
            payload.woid = props.woid;
            payload.woStatus = props.selectWOStatus.status_code;
            payload.woEtaDate = moment(woStatusData.etaDate).format('yyyy-MM-DD');
            payload.woStatusDate = moment(woStatusData.statusDate).format('yyyy-MM-DD');
            payload.comments = woStatusData.comments ? woStatusData.comments : "";
            payload.custView = isChecked ? "Y" : "N";
            payload.oldwostatus = props.woStatusList.wo_status;
            props.addWOStatus(payload)
        }else{
            props.formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {

    }

    return (
        <React.Fragment>
            <Paper component="form" className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="status">
                                    <span className={classes.mandatoryField}>*</span>
                                    Status
                                </label>
                            </Typography>
                            <Autocomplete
                                disableClearable
                                id="status-select"
                                value={props.selectWOStatus}
                                options={props.woStatus}
                                getOptionLabel={(option) => option.wostatus}
                                getOptionSelected={(option, value) => value.status_code === option.status_code}
                                onChange={(e, newValue) => handleChangeWoStatus("wostatus", newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        placeholder="Status"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.inputField}
                                    />
                                )}
                            />
                            {props.formValidator.current.message('Status', props.selectWOStatus, 'required', { className: classes.validateStyle })}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="date">
                                    <span className={classes.mandatoryField}>*</span>
                                    Date
                                </label>
                            </Typography>
                            <DatePicker
                                selected={woDate}
                                value={woDate === null ? moment(props.woStatusList.wo_status_date).format("yyyy-MM-DD") : woDate}
                                onChange={(date) => handleChangeWoStatus("statusDate", date)}
                                customInput={<DateInput />}
                                dateFormat='yyyy-MM-dd'
                            />
                            {props.formValidator.current.message('Date', woDate === null ? props.woStatusList.wo_status_date : woDate, 'required', { className: classes.validateStyle })}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="comment">
                                    Comment
                                </label>
                            </Typography>
                            <TextField
                                id="comment"
                                color="primary"
                                variant="outlined"
                                className={classes.inputField}
                                multiline
                                minRows="3"
                                placeholder="Comment"
                                onChange={(e) => handleChangeWoStatus("comments", e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ marginTop: "45px" }}>
                        <FormControl fullWidth>
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="custView">
                                    Customer View
                                </label>
                            </Typography>
                            <Checkbox
                                checked={isChecked}
                                onChange={(e) => handleChangeWoStatus("custView", e.target.checked)}
                                color="primary"
                                style={{ marginTop: '-27px', marginLeft: '-25px', background: "none" }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="eta">
                                    <span className={classes.mandatoryField}>*</span>
                                    ETA
                                </label>
                            </Typography>
                            <DatePicker
                                selected={woETADate}
                                value={woETADate === null ? moment(props.woStatusList.wo_eta_date).format("yyyy-MM-DD") : woETADate}
                                onChange={(date) => handleChangeWoStatus("etaDate", date)}
                                customInput={<DateInput />}
                                dateFormat='yyyy-MM-dd'
                            />
                            {props.formValidator.current.message('ETA', woETADate === null ? props.woStatusList.wo_eta_date : woETADate, 'required', { className: classes.validateStyle })}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleSaveWOStatus()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default WorkOrderStatus;