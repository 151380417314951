import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchCustomerAddress } from "../Common/API";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import AddressTable from './components/AddressTable';
import { errorHandler, Fallback } from "../Common/Fallback";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
        fontFamily: 'sans-serif'
    }
}));

const CustomerAddress = ({ setTitle }) => {
    setTitle("Customer Address")
    const {id} = useParams();
    const classes = useStyles();
    const [addressList, setAddressList] = useState([]);

    useEffect(() => {
        fetchCustomerAddress(id).then(data => {
            setAddressList(data);
        }).catch(error => {

        })
    }, [])

    return (
        <>
            <Grid container style={{ marginBottom: '48px' }}>
                <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                    <Grid item xs={4} className="d-flex align-center">
                        <Typography variant="h6" className={classes.title}>Summary</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                <AddressTable addressList={addressList} />
            </ErrorBoundary>
        </>
    );
}

export default CustomerAddress;