import { Grid, makeStyles, Paper, TextField, Typography, FormControl, IconButton, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import DeleteIcon from '../../../../../assets/DeleteIcon.svg';
import { useNavigate } from "react-router-dom";
import { DeliveryPlanModal, deliveryLocationContact } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import SimpleReactValidator from "simple-react-validator";
import CreateIcon from '../../../../../assets/CreateActive.svg';
import AddContactPopupModal from "../../../../../Common/AddContactPopupModal";
import DeliveryPlanTable from "./components/DeliveryPlanTable";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    saveBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '32px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '20px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-58px 20px 25px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}))

const DeliveryPlan = (props) => {
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const [modeoft, setModeoft] = useState([]);
    const [isNewDelPlan, setIsNewDelPlan] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [isSaved, setIsSaved] = useState(true);
    const [contact, setContact] = useState([]);
    const [isOpenContactModal, setIsOpenContactModal] = useState(false);

    const formValidator = useRef(new SimpleReactValidator({}));
    const validateSelect = useRef(new SimpleReactValidator({}));

    const handleCancel = () => {
        navigate('/');
    }

    const handleChangeLocation = (value) => {
        setIsDisable(false);
        const location = props.addrData.filter(x => x.paid === value.paid)[0];
        props.setDelPlanList(location);
        props.contactNameList("", value.paid);
    }

    const handleChangeDeliveryPlan = (field, value) => {
        setIsDisable(false);
        if (field === "country") {
            const obj = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            setCountry(obj)
        }
        props.setDelPlanList(values => ({ ...values, [field]: value }));
    }

    const handleAddDelPlan = () => {
        setIsNewDelPlan(true);
        props.setDelPlanList(DeliveryPlanModal);
    }

    const handleSelectDelPlan = () => {
        setIsNewDelPlan(false);
        props.setDelPlanList(DeliveryPlanModal);
    }

    const handleSaveDelPlan = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...DeliveryPlanModal }
            payload.ItemDetails = props.balanceQty === undefined ? [] : props.balanceQty;
            payload.party = props.delPlanData.party_name;
            payload.contact = props.delPlanData.party_contact === undefined ? "" : props.delPlanData.party_contact || contact ? contact : "";
            payload.address = props.delPlanData.party_addr1 ? props.delPlanData.party_addr1 : "";
            payload.address2 = props.delPlanData.party_addr2 ? props.delPlanData.party_addr2 : "";
            payload.city = props.delPlanData.party_city ? props.delPlanData.party_city : "";
            payload.state = props.delPlanData.party_state ? props.delPlanData.party_state : "";
            payload.country = props.delPlanData.party_country ? props.delPlanData.party_country : country.ctrycode;
            payload.phone = props.delPlanData.party_telephone ? props.delPlanData.party_telephone : "";
            payload.vatno = props.delPlanData.party_vatno ? props.delPlanData.party_vatno : "";
            payload.modeoft = props.delPlanData.modeoft ? props.delPlanData.modeoft : modeoft;
            payload.email = props.delPlanData.email;
            payload.zip = props.delPlanData.party_zipcode ? props.delPlanData.party_zipcode : "";
            payload.po_date = props.po_date;
            payload.ponumber = props.po_no;
            payload.currency = props.currency;
            payload.party_id = props.partyId;
            payload.user = localStorage.getItem("userId");
            payload.paid = props.delPlanData.paid ? props.delPlanData.paid : 0;
            payload.poid = props.poid;
            props.addDeliveryPlan(payload);
            setIsDisable(true);
            setIsSaved(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }
    const handleDeleteItemQty = (idx) => {
        const data = props.balanceQty.filter((_, i) => i !== idx)
        props.setBalanceQty(data);
    }

    const handleChangeItemQty = (field, value, idx) => {
        setIsDisable(false);
        props.setBalanceQty([
            ...props.balanceQty.map((data, i) => {
                const item = props.itemEditList.find(x => x.po_item_id === data.po_item_id);
                if (idx === i) data[field] = value;
                props.balanceQty[idx]['prod_qty_uom'] = item.prod_qty_uom;
                props.balanceQty[idx]['index'] = idx;
                return data;
            })
        ])
    }

    const handleAddContact = () => {
        setIsOpenContactModal(!isOpenContactModal);
    }

    // const handleSaveContacts = () => {
    //     const payload = {...deliveryLocationContact}
    //     payload.party_contact = contact ? contact : [];
    //     payload.user = localStorage.getItem('userId'); 
    //     props.saveMultipleContact(payload);
    // }

    const renderDeliveryPlan = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    {isNewDelPlan ? (
                        <>
                            <Grid container item xs={12} justifyContent="flex-end">
                                {/* <Button variant="contained" color="primary"
                                    className={classes.selectBtn}
                                    disabled={isSaved}
                                    onClick={() => handleAddContact()}>
                                    + Contact
                                </Button> */}
                                <Button variant="contained" color="primary"
                                    className={classes.selectBtn}
                                    onClick={() => handleSelectDelPlan()}>
                                    Select Delivery Plan
                                </Button>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="party_name">
                                                <span className={classes.mandatoryField}>*</span>
                                                Location
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="contact"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.delPlanData.party_name}
                                            onChange={(e) => handleChangeDeliveryPlan("party_name", e.target.value)}
                                            placeholder="Location"
                                        />
                                        {formValidator.current.message('Location', props.delPlanData.party_name, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="vat_no">
                                                VAT No.
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="VAT"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_vatno", e.target.value)}
                                            placeholder="VAT No."
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add1">
                                                Address 1
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add1"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_addr1", e.target.value)}
                                            placeholder="Address 1"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add2">
                                                Address 2
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add2"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_addr2", e.target.value)}
                                            placeholder="Address 2"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="city">
                                                City
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="city"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_city", e.target.value)}
                                            placeholder="City"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="state">
                                                State
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="state"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_state", e.target.value)}
                                            placeholder="State"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="zip">
                                                Zip
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="zip"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeDeliveryPlan("party_zipcode", e.target.value)}
                                            placeholder="ZIP"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="country">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="country-select"
                                            value={country}
                                            options={props.countries}
                                            getOptionLabel={(option) => option.ctrycname}
                                            getOptionSelected={(option, value) => value?.ctrycode === option?.ctrycode}
                                            onChange={(e, newValue) => handleChangeDeliveryPlan("country", newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {formValidator.current.message('Country', country, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="modeOft">
                                                <span className={classes.mandatoryField}>*</span>
                                                Mode of Transport
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="modeOft"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={props.delPlanData.modeoft}
                                            onChange={(e) => handleChangeDeliveryPlan("modeoft", e.target.value)}
                                            placeholder="Mode of Transport"
                                        />
                                        {formValidator.current.message('Mode of transport', props.delPlanData.modeoft, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}><hr /></Grid>
                            </Grid>
                        </>
                    ) :
                        <>
                            <Grid container item xs={12} justifyContent="flex-end">
                                <Button variant="contained" color="primary"
                                    className={classes.selectBtn}
                                    onClick={() => handleAddDelPlan()}>
                                    + Delivery Plan
                                </Button>
                            </Grid>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="contact_name">
                                                Contact Name
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            limitTags={2}
                                            id="party-contact-select"
                                            disableClearable
                                            multiple
                                            options={props.contactName}
                                            getOptionLabel={(option) => option.contact_name}
                                            getOptionSelected={(option, value) => value.pa_pcid === option.pa_pcid}
                                            onChange={(e, newValue) => setContact(newValue)}
                                            renderOption={(option, { selected }) => (
                                                <Grid container item justifyContent="space-between">
                                                    <Grid>
                                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#0070AD' }} checked={selected} />
                                                        {option.contact_name}
                                                    </Grid>
                                                </Grid>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Contact Name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton edge="end" aria-label="add contact" onClick={() => handleAddContact()}>
                                        <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button variant="contained" color="primary"
                                        className={classes.saveBtn}
                                        disabled={isSaved}
                                        onClick={() => handleSaveContacts()}>
                                        Save
                                    </Button>
                                </Grid> */}
                                {/* <Grid item xs={12}><hr /></Grid> */}
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="party_name">
                                                <span className={classes.mandatoryField}>*</span>
                                                Location
                                            </label>
                                        </Typography>
                                        <Autocomplete
                                            disableClearable
                                            id="party-name-select"
                                            options={props.addrData}
                                            getOptionLabel={(option) => option.party_name}
                                            getOptionSelected={(option, value) => value.paid === option.paid}
                                            onChange={(e, newValue) => handleChangeLocation(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Location"
                                                    variant="outlined"
                                                    margin="normal"
                                                    className={classes.inputField}
                                                />
                                            )}
                                        />
                                        {validateSelect.current.message('Location', props.delPlanData.party_name, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="vat_no">
                                                VAT No.
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="VAT"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_vatno}
                                            placeholder="VAT No."
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add1">
                                                Address 1
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add1"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_addr1}
                                            placeholder="Address 1"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="add2">
                                                Address 2
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="add2"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_addr2}
                                            placeholder="Address 2"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="city">
                                                City
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="city"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_city}
                                            placeholder="City"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="state">
                                                State
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="state"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_state}
                                            placeholder="State"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="zip">
                                                Zip
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="zip"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_zipcode}
                                            placeholder="Zip"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="country">
                                                <span className={classes.mandatoryField}>*</span>
                                                Country
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="zip"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            className={classes.inputField}
                                            value={props.delPlanData.party_country}
                                            placeholder="Country"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="modeOft">
                                                <span className={classes.mandatoryField}>*</span>
                                                Mode of Transport
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="modeOft"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.inputField}
                                            value={modeoft}
                                            placeholder="Mode of Transport"
                                            onChange={(e) => setModeoft(e.target.value)}
                                        />
                                        {validateSelect.current.message('Mode of transport', modeoft, 'required', { className: classes.validateStyle })}
                                    </FormControl>
                                </Grid>
                                {props.balanceQty !== undefined ? <Grid item xs={12}><hr /></Grid> : null}
                            </Grid>
                        </>}
                    {props.balanceQty?.map((row, idx) => (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="itemcode">
                                                <span className={classes.mandatoryField}>*</span>
                                                Item Code
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="itemcode"
                                            color="primary"
                                            variant="outlined"
                                            disabled={true}
                                            value={row?.prod_code}
                                            className={classes.inputField}
                                            placeholder="Item Code"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <Typography className={classes.accordianExpandedTitle}>
                                            <label htmlFor="itemqty">
                                                <span className={classes.mandatoryField}>*</span>
                                                Item Quantity
                                            </label>
                                        </Typography>
                                        <TextField
                                            id="itemqty"
                                            color="primary"
                                            variant="outlined"
                                            value={row?.prod_qty}
                                            className={classes.inputField}
                                            onChange={(e) => handleChangeItemQty("prod_qty", e.target.value, idx)}
                                            placeholder="Item Quantity"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton edge="end" aria-label="add items" onClick={() => handleDeleteItemQty(idx)}>
                                        <img src={DeleteIcon} alt="add" style={{ marginTop: "30px" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Paper>
            </React.Fragment>
        )
    }

    return (
        <>
            <Paper component="form" className={classes.paper}>
                {renderDeliveryPlan()}
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            disabled={isDisable}
                            onClick={() => handleSaveDelPlan()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <DeliveryPlanTable
                    workOrder={props.workOrder} />
                {isOpenContactModal && (
                    <AddContactPopupModal
                        isOpenContactModal={isOpenContactModal}
                        setIsOpenContactModal={setIsOpenContactModal}
                        saveContact={props.addContact}
                        paid={props.delPlanData.paid ? props.delPlanData.paid : props.partyPaid}
                        pid={0} />
                )}

            </Paper>
        </>
    )
}

export default DeliveryPlan;