import { Grid, Box, Typography, Tooltip } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import { dashHeaderStyle, reportStyle } from "./common/styles";
import filterIcon from '../assets/filter.png';
import RecentDelivery from './components/RecentDelivery';
import PlannedDeliveryCountry from './components/PlannedDeliveryCountry';
import PlannedDeliveryStatus from './components/PlannedDeliveryStatus';
import Messages from "./components/Messages";
import { useContext, useState } from "react";
import { ReportsContext } from "./context";
import FilterPopover from './FilterPopover';
import CustomerSearch from "./components/CustomerSearch";

export default function CustomerDashboard({ setTitle }) {
    setTitle("Dashboard")
    const dashReportStyle = reportStyle();
    const classes = dashHeaderStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { dashboardData, getDashboardData } = useContext(ReportsContext);

    const recentData = () => {
        let convertedData = [["country", "count"]];
        dashboardData.reportData.recentDeliveries?.map(item => {
            const count = parseInt(item.count, 10); // Convert count to a number
            const country = item.country;

            convertedData.push([country, count]);
        });

        return convertedData;
    }

    const plannedData = () => {
        let convertedData = [["country", "count"]];
        dashboardData.reportData.deliveriesPlanned?.map(item => {
            const count = parseInt(item.count, 10); // Convert count to a number
            const country = item.country;

            convertedData.push([country, count]);
        });

        return convertedData;
    }

    const statusData = () => {
        let convertedData = [["status", "count", "id"]];
        dashboardData.reportData.pendingthismonth?.map(item => {
            const count = parseInt(item.count, 10); // Convert count to a number
            const status = item.status;
            const id = item.id;

            convertedData.push([status, count, id]);
        });

        return convertedData;
    }

    const tableData = () => {
        let convertedData = [];
        dashboardData.reportData.womessages?.map(item => {
            convertedData.push(item);
        });

        return convertedData;
    }

    const handleClickFilter = (e) => {
        setIsOpenModal(!isOpenModal);
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenModal(false);
    }

    const renderDasboard = () => {
        return (
            <>
                <Grid container className={`${classes.headBox}`}>
                    <Grid container className="sidepadding headerBox">
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className="titleFirst">Dashboard</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={dashReportStyle.dashReportsWrap}>
                    <Grid container item xs={12} className={dashReportStyle.sidePadding}>
                        <Grid className="mt-2 filterwrap" container item xs={12} alignItems="center">
                            <Grid item xs={8}>
                                <Grid item xs={12}></Grid>
                            </Grid>
                            <Grid container item xs={4} className="d-flex h-100" alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <Tooltip title="Filter" arrow>
                                            <img src={filterIcon} alt="filter" width="40px"
                                                style={{ cursor: 'pointer', margin: '8px 0' }}
                                                onClick={(e) => handleClickFilter(e)}
                                            />
                                        </Tooltip>
                                    </ErrorBoundary>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box className="box">
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <RecentDelivery recentData={recentData()} />
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="box">
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <PlannedDeliveryCountry plannedData={plannedData()} />
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="box">
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <PlannedDeliveryStatus status={statusData()} />
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="box">
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <Messages tableData={tableData()} />
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="box">
                                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                        <CustomerSearch />
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderDasboard()}
            {isOpenModal &&
                <FilterPopover
                    isOpenModal={isOpenModal}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    getDashboardData={getDashboardData}
                />
            }
        </>
    )
}