import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { tableStyle } from '../common/style';

export const ExpanderButton = (props) => {
  const { open, setOpen, column, row } = props;
  const customStyle = tableStyle();
  const getStyles = () => {
    return {
      border: '1px solid #1381B9',
      width: 20,
      textAlign: 'center',
      display: 'inline-block',
      overflow: 'hidden',
      borderRadius: '50%',
      color: '#1381B9',
      height: 20,
      fontSize:10,
      marginLeft: 12,
      paddingTop: 3.5,
    }
  }

  const bgColor = () => {
    return { backgroundColor: 'transparent' }
  }

  const getChildrenCount = (column, row) => {
    if (column.isChildrenCountRequired && row.children && row.children.length) {
      return <span style={getStyles()}>{row.children.length}</span>
    }
    return '';
  }

  return <IconButton
    aria-label="expand row"
    size="small"
    disableRipple
    className={`expandBtn ${bgColor} ${customStyle.customButton}`}
    onClick={() => setOpen(!open)}>
    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
    {getChildrenCount(column, row)}

  </IconButton>
}