import { Grid, FormControl, Typography, TextField, Checkbox, Paper, makeStyles, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calenderIcon from '../../../../../assets/calendarIcon.svg';
import { forwardRef } from 'react';
import moment from "moment";
import { woStatusModal } from "../../../../../Common/constant";
import Cookies from "js-cookie";
import TrackShipDate from "../TrackShipDate";
import DocumentRepository from "../DocumentRepository";
import CustomerMessages from "../CustomerMessages";
import { downLoadShipmentFiles } from "../../../../../Common/API";
import { saveAs } from "file-saver";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        marginTop: '13px',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '-44px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '50px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}))

const WorkOrderStatus = (props) => {
    const classes = useStyles();

    const downloadFiles = (fileId, file) => {
        downLoadShipmentFiles(fileId, localStorage.getItem('userId'), "W").then(data => {
            const fileType = file.substr(file.lastIndexOf('.') + 1);
            saveAs(new Blob([data]), file.replace('.', '').replace(fileType, '') + "." + fileType);
        }).catch(error => {
            //props.amberSnackbar(error);
        });
    }

    const workoreder = () => {
        return (
            <React.Fragment>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="status">
                                        <span className={classes.mandatoryField}>*</span>
                                        Status
                                    </label>
                                </Typography>
                                <TextField
                                    id="status"
                                    color="primary"
                                    variant="outlined"
                                    value={props.woList.wo_status}
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Status"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="date">
                                        Date
                                    </label>
                                </Typography>
                                <TextField
                                    id="date"
                                    color="primary"
                                    variant="outlined"
                                    value={moment(props.woList.wo_status_date).format('yyyy-MM-DD')}
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="Date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="eta">
                                        ETA
                                    </label>
                                </Typography>
                                <TextField
                                    id="eta"
                                    color="primary"
                                    variant="outlined"
                                    value={moment(props.woList.wo_eta_date).format('yyyy-MM-DD')}
                                    className={classes.inputField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    placeholder="ETA"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }

    return (
        <>
            {workoreder()}
            <Typography variant="h6">Track Shipment</Typography>
            <br />
            <TrackShipDate trackData={props.trackData} />
            <br />
            <Typography variant="h6">Document Repository</Typography>
            <br />
            <DocumentRepository fileList={props.fileList} downloadFiles={downloadFiles}/>
            <br />
            <Typography variant="h6">Messages</Typography>
            <CustomerMessages addMessage={props.addMessage} messageList={props.messageList} custwoid={props.custwoid} />
        </>
    )
}

export default WorkOrderStatus;