import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Fallback, errorHandler } from "../Common/Fallback";
import TableView from "./TableView";
import { ErrorBoundary } from "react-error-boundary";
import { CustomerSummaryContext } from "./context/CustomerSummaryContext";
import { useState } from "react";
import AddCustomerMasterModal from "./components/AddCustomerMasterModal";
import { useSnackbar } from "react-simple-snackbar";
import { OPTIONS, OPTIONS3 } from "../Common/constant";
import { fetchContactList, saveContacts, saveCustomer, updateScan } from "../Common/API";
import { useContext } from "react";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    topItems: {
        gap: 10
    },
    filterIcon: {
        width: "40px",
        cursor: 'pointer'
    },
    topItems: {
        gap: 10
    },
    applyBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
}));

const Customer = ({ setTitle }) => {
    setTitle("Customer");
    const classes = useStyles();
    const { headCells, summaryList, customerSummary, countries } = useContext(CustomerSummaryContext);
    const [openCustomerModal, setOpenCustomerModal] = useState(false);
    const [successSnackbar] = useSnackbar(OPTIONS);
    const [amberSnackbar] = useSnackbar(OPTIONS3);
    const [country, setCountry] = useState([]);

    const saveCustomerDetail = (reqObj) => {
        saveCustomer(reqObj).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                setOpenCustomerModal(false);
            } else {
                amberSnackbar(res.message)
                setOpenCustomerModal(true);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    // const handleOpenCreate = () => {
    //     setOpenCustomerModal(!openCustomerModal);
    // }

    const addPartyScan = (reqobj) => {
        updateScan(reqobj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                customerSummary();
            } else {
                amberSnackbar(data.message)
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const renderCustomer = () => {
        return (
            <>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Summary</Typography>
                        </Grid>
                        {/* <Grid item xs={8} container className="d-flex align-center">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className={classes.topItems}>
                                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                    <Tooltip title="Create Customer" arrow>
                                        <Button variant="contained" color="primary"
                                            className={classes.applyBtn}
                                            onClick={() => handleOpenCreate()}>
                                            Create
                                        </Button>
                                    </Tooltip>
                                </ErrorBoundary>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 30px' }}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <TableView addPartyScan={addPartyScan} amberSnackbar={amberSnackbar}
                            successSnackbar={successSnackbar} headCells={headCells} summaryList={summaryList}
                            customerSummary={customerSummary} countries={countries} />
                    </ErrorBoundary>
                </Grid>
            </>
        );
    }

    return (
        <>
            {renderCustomer()}
            {openCustomerModal && (
                <AddCustomerMasterModal
                    openCustomerModal={openCustomerModal}
                    setOpenCustomerModal={setOpenCustomerModal}
                    countries={countries}
                    addCustomer={saveCustomerDetail}
                    setCountry={setCountry}
                    country={country}
                    selectedCustomer={""}
                    setSelectedCustomer={[]}
                />
            )}
        </>
    )
}

export default Customer;