import { Popover, Paper, Grid, TextField, Typography, FormControl, makeStyles, Button } from "@material-ui/core";
import { forwardRef, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../css/datepicker.styles.css'
import calenderIcon from '../../../assets/calendarIcon.svg';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    pageHeader: {
        marginBottom: 30,
    },
    pageFooter: {
        background: '#F3F3F3',
        padding: '10px',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        height: '350px',
        width: '600px',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    h6: {
        padding: '22px 0',
        color: '#2B0A3D',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}))

const FilterPopover = ({ isOpenModal, anchorEl, handleClose, selectFilter }) => {
    const imagePath = `url(${calenderIcon})`;
    const classes = useStyles();
    const To = new Date();
    const From = new Date(To.getFullYear() - 1, To.getMonth(), To.getDate());
    const [selectFromDate, setSelectFromDate] = useState(null);
    const [selectToDate, setSelectToDate] = useState(null);
    const [dashFilter, setDashFilter] = useState({
        fromDate: null,
        toDate: null,
        user: ''
    })

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 120px/ 12px no-repeat`,
                width: '162px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleClear = () => {
        setSelectFromDate(From);
        setSelectToDate(To);
    }

    const handleApplyFilter = () => {
        dashFilter.fromDate = moment(selectFromDate).format('yyyy-MM-DD');
        dashFilter.toDate = moment(selectToDate).format('yyyy-MM-DD');
        dashFilter.user = localStorage.getItem('userId');
        selectFilter(dashFilter);
        handleClose();
    }

    return (
        <Popover
            open={isOpenModal}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            <Paper component="form" className={classes.paper}>
                <Grid container wrap="wrap" className={classes.pageHeader}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">Filter</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="fromDate">
                                    From Date
                                </label>
                            </Typography>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={selectFromDate !== null ? selectFromDate : From}
                                onChange={
                                    (date) => setSelectFromDate(date)
                                }
                                customInput={<DateCustomInput />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{marginLeft: '35px'}}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="toDate">
                                    To Date
                                </label>
                            </Typography>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={selectToDate !== null ? selectToDate : To}
                                onChange={
                                    (date) => setSelectToDate(date)
                                }
                                customInput={<DateCustomInput />}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleClear()}>
                            Clear
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleApplyFilter()}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    )
}

export default FilterPopover;