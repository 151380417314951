import { Slider, withStyles } from "@material-ui/core";

export const CONTENT_TYPES = {
    LONG_TEXT : 'LONG_TEXT' , //for paragraph or text area
    TEXT : 'TEXT', //for span can be a text or number
    DATE : 'DATE',  //for date formats or calender
    SELECT : 'SELECT', //single object
    MULTI_SELECT : 'MULTI_SELECT', // array of objects [{TEXT}]
    SLIDER : 'SLIDER', // value or a number to percent or progress
    RADIO : 'RADIO', //true or false
    SWITCH: 'SWITCH', // on or off
    ICON : 'ICON', //for emoji, or Icons
    CHECK_BOX: 'CHECK_BOX'
}
export const IOSSlider = withStyles({
    root: {
        color: '#0070AD',
        height: 8,
        margin: 0,
        padding: 0,
        width: 130,
    },
    thumb: {
        '&.MuiSlider-thumb, .Mui-disabled':{
            height: 15,
            width: 15,
            backgroundColor: '#0070AD',
            marginTop: -4,
            marginLeft: -8,
            '&.Mui-disabled':{
                backgroundColor: '#769baf'
            }
        }
    },
    active: {},
    valueLabel: {
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000'
        }
    },
    track: {
        height: 8
    },
    rail: {
        height: 7,
        opacity: 0.5,
        backgroundColor: '#bfbfbf'
    },
    mark: {
        backgroundColor: '#D0D0D0',
        height: 20,
        width: 1,
        marginTop: -6
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor'
    },
    markLabel: {
        fontSize: 12,
        marginTop: '-20px',
        color: '#D0D0D0',
        textTransform: 'capitalize',
        display: 'none',
        '&[data-index="0"]':{
            marginLeft: '7px'
        },
        '&[data-index="1"]':{
            marginLeft: '-10px'
        }
    }
})(Slider);

export const MARKS = [
    {
        value: 0,
        label: '0'
    },
    {
        value: 99,
        label: '99'
    }
];