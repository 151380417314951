import { Dialog, Grid, Typography, FormControl, DialogActions, makeStyles, DialogTitle, DialogContent, TextField, Paper, Button } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import { useState, useRef } from "react";
import { Autocomplete } from "@material-ui/lab";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../../../css/datepicker.styles.css';
import calenderIcon from '../../../../../../../assets/calendarIcon.svg';
import { forwardRef } from "react";
import { receiptModal } from "../../../../../../../Common/constant";
import Cookies from "js-cookie";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    }
}))

const ReceiptPopupModal = ({ isOpenReceiptModal, setIsOpenReceiptModal, itemData, handleSaveReceipt, woid }) => {
    const formValidator = useRef(new SimpleReactValidator({}));
    const classes = useStyles();
    const [, forceUpdate] = useState();
    const [receiptData, setReceiptData] = useState([]);
    const [rctDate, setRctDate] = useState(null);
    const [selectUom, setSelectUom] = useState([]);
    const [itemCode, setItemCode] = useState([]);
    const imagePath = `url(${calenderIcon})`;

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 173px/ 12px no-repeat`,
                width: '203px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-mm-dd" />
    ));

    const handleChangeReceipt = (field, value) => {
        if(field === "receiptdate"){
            setRctDate(value);
        }else if(field === "woitemid"){
            let obj = {
                id: value.woitemid,
                woitemid: value.woitemid,
                prod_code: value.prod_code
            }
            setItemCode(obj);
            setSelectUom(itemData.find(x => x.woitemid === value?.woitemid));
        }
        setReceiptData(values => ({ ...values, [field]: value }));
    }

    const handleAddReceipt = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = {...receiptModal}
            payload.prod_code = itemCode.prod_code;
            payload.prod_qty = receiptData.prod_qty;
            payload.user = localStorage.getItem('userId');
            payload.woid = woid;
            payload.prod_uom = selectUom.uom_code;
            payload.receiptdate = rctDate ? moment(rctDate).format('yyyy-MM-DD') : null;
            payload.receiptno = receiptData.receiptno;
            payload.woitemid = itemCode.woitemid;
            handleSaveReceipt(payload);
            setIsOpenReceiptModal(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        setIsOpenReceiptModal(false);
    }

    return (
        <Dialog open={isOpenReceiptModal} maxWidth="md">
            <DialogTitle style={{ color: "#1381B9", fontFamily: 'sans-serif !important' }}>Add Receipt</DialogTitle>
            <DialogContent dividers>
                <Paper component="form" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="item_code">
                                        <span className={classes.mandatoryField}>*</span>
                                        Item Code
                                    </label>
                                </Typography>
                                <Autocomplete
                                    id="ItemCode"
                                    disableClearable
                                    options={itemData}
                                    getOptionLabel={(option) => option.prod_code}
                                    getOptionSelected={(option, value) => value?.woitemid === option?.woitemid}
                                    onChange={(e, newValue) => handleChangeReceipt("woitemid", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Item Code"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {formValidator.current.message('Item Code', itemCode, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="rct_no">
                                        <span className={classes.mandatoryField}>*</span>
                                        Receipt No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="rct_no"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeReceipt('receiptno', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Receipt No."
                                />
                                {formValidator.current.message('Receipt No.', receiptData.receiptno, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="rct_date">
                                        Receipt Date
                                    </label>
                                </Typography>
                                <DatePicker
                                    selected={rctDate}
                                    onChange={(date) => handleChangeReceipt("receiptdate", date)}
                                    customInput={<DateCustomInput />}
                                    dateFormat='yyyy-MM-dd'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="uom">
                                        UOM
                                    </label>
                                </Typography>
                                <TextField
                                    id="uom"
                                    color="primary"
                                    variant="outlined"
                                    value={selectUom.uom_code}
                                    disabled={true}
                                    className={classes.inputField}
                                    placeholder="UOM"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="qty">
                                    <span className={classes.mandatoryField}>*</span>
                                        Quantity
                                    </label>
                                </Typography>
                                <TextField
                                    id="qty"
                                    color="primary"
                                    variant="outlined"
                                    onChange={(e) => handleChangeReceipt('prod_qty', e.target.value)}
                                    className={classes.inputField}
                                    placeholder="Quantity"
                                />
                                {formValidator.current.message('Quantity', receiptData.prod_qty, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddReceipt} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReceiptPopupModal;