import { Typography, Grid, FormControl, Paper, TextField, makeStyles, Fab, Tooltip, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useRef, useState, forwardRef } from "react";
import { Add } from '@material-ui/icons';
import AddCustomerModal from "./components/AddCustomerModal";
import { saveCustomer } from "../../../../../Common/API";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ShippingModal } from "../../constants";
import moment from "moment/moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../css/datepicker.styles.css'
import Cookies from "js-cookie";
import calenderIcon from '../../../../../assets/calendarIcon.svg';

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        marginTop: '13px'
    },
    cancelButton: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    }
}))

const Shipping = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const imagePath = `url(${calenderIcon})`;
    const [invDate, setInvDate] = useState(null);
    const [shipDate, setShipDate] = useState(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(true);
    const formValidator = useRef(new SimpleReactValidator({}));

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 233px/ 12px no-repeat`,
                width: '264px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleChangeShipping = (field, value) => {
        setIsBtnDisable(false);
        if (field === "party_name") {
            props.customerDetails(value.pid);
            props.setPartyId(value.pid);
            props.getAddress(value.pid);
            let newObj = {
                pid: value.pid,
                party_name: value.party_name
            }
            props.setPartyName(newObj);
        } else if (field === "category") {
            let newObj = {
                id: value.id,
                name: value.name,
            }
            props.setCategory(newObj);
        } else if (field === "isdg") {
            props.setIsDangGood(value);
        } else if (field === "inv_date") {
            setInvDate(value);
        } else if (field === "ship_date") {
            setShipDate(value);
        }
        props.setShipping(values => ({ ...values, [field]: value }));
    }

    const handleSaveShipping = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...ShippingModal };
            payload.inv_no = props.shipping.inv_no;
            payload.inv_date = moment(props.shipping.inv_date).format("yyyy-MM-DD") ? moment(props.shipping.inv_date).format("yyyy-MM-DD") : invDate;
            payload.ship_date = moment(props.shipping.ship_date).format("yyyy-MM-DD") ? moment(props.shipping.ship_date).format("yyyy-MM-DD") : shipDate;
            payload.pid = props.partyName.pid;
            payload.user = props.userId;
            payload.category = props.category.id ? props.category.id : "";
            payload.isdg = props.isDangGood === true ? "Y" : "N";
            if (props.invid) {
                payload.invid = props.invid;
            }
            props.addComInv(payload);
            setIsBtnDisable(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCancel = () => {
        navigate('/')
    }

    const handleOpenDialog = () => {
        setIsOpenDialog(!isOpenDialog);
    }

    const addCustomer = (reqData) => {
        saveCustomer(reqData).then(res => {
            if (res.status === 1) {
                props.successSnackbar(res.message)
                setIsOpenDialog(false);
                props.fetchCustomerList();
            } else {
                props.amberSnackbar(res.message);
                setIsOpenDialog(true);
            }
        }).catch(error => {
            props.amberSnackbar(error);
        })
    }

    const renderShipping = () => {
        return (
            <React.Fragment>
                {localStorage.getItem('party_type') !== "CST" ? (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="inv_no">
                                        <span className={classes.mandatoryField}>*</span>
                                        Invoice No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="inv_no"
                                    color="primary"
                                    variant="outlined"
                                    value={props.shipping.inv_no}
                                    className={classes.inputField}
                                    onChange={(e) => handleChangeShipping("inv_no", e.target.value)}
                                    placeholder="Invoice No."
                                />
                                {formValidator.current.message('Invoice No.', props.shipping.inv_no, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="inv_date">
                                        <span className={classes.mandatoryField}>*</span>
                                        Invoice Date
                                    </label>
                                </Typography>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd'
                                    selected={invDate}
                                    value={invDate ? invDate : moment(props.shipping.inv_date).format("yyyy-MM-DD")}
                                    onChange={
                                        (date) => handleChangeShipping("inv_date", date)
                                    }
                                    customInput={<DateCustomInput />}
                                />
                                {formValidator.current.message('Invoice Date', invDate === null ? moment(props.shipping.inv_date).format("yyyy-MM-DD") : invDate, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ship_date">
                                        <span className={classes.mandatoryField}>*</span>
                                        Shipment Date
                                    </label>
                                </Typography>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd'
                                    selected={shipDate}
                                    value={shipDate ? shipDate : moment(props.shipping.ship_date).format("yyyy-MM-DD")}
                                    onChange={
                                        (date) => handleChangeShipping("ship_date", date)
                                    }
                                    style={{ width: '160px' }}
                                    customInput={<DateCustomInput />}
                                />
                                {formValidator.current.message('Shipment Date', shipDate ? shipDate : moment(props.shipping.shipDate).format("yyyy-MM-DD"), 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="export_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Customer
                                    </label>
                                </Typography>
                                <Autocomplete
                                    id="exporter-select"
                                    disableClearable
                                    value={props.partyName}
                                    options={props.customers}
                                    getOptionLabel={(option) => option.party_name}
                                    getOptionSelected={(option, value) => value?.pid === option?.pid}
                                    onChange={(e, newValue) => handleChangeShipping("party_name", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Exporter"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                                {formValidator.current.message('Exporter', props.partyName, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Fab size="small" color="primary" aria-label="add" style={{ marginTop: '35px', width: '35px', height: '5px' }}>
                                <Tooltip title="Add Customer" arrow>
                                    <Add onClick={() => handleOpenDialog()} />
                                </Tooltip>
                            </Fab>
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: "45px" }}>
                            <FormControl fullWidth>
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dangerousGood">
                                        Dangerous Good
                                    </label>
                                </Typography>
                                <Checkbox
                                    onChange={(e) => handleChangeShipping("isdg", e.target.checked)}
                                    color="primary"
                                    checked={props.isDangGood}
                                    style={{ marginTop: '-27px', marginLeft: '73px', background: "none" }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dg_category">
                                        Dangerous Good Categories
                                    </label>
                                </Typography>
                                <Autocomplete
                                    id="dg-select"
                                    disableClearable
                                    value={props.category}
                                    options={(props.shipping.isdg === "Y" || props.isDangGood === true) ? props.dangerousGoods : []}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionSelected={(option, value) => value?.id === option?.id}
                                    onChange={(e, newValue) => handleChangeShipping("category", newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            placeholder="Dangerous Goods"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="inv_no">
                                        <span className={classes.mandatoryField}>*</span>
                                        Invoice No.
                                    </label>
                                </Typography>
                                <TextField
                                    id="inv_no"
                                    color="primary"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={props.shipping.inv_no}
                                    className={classes.inputField}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="inv_date">
                                        <span className={classes.mandatoryField}>*</span>
                                        Invoice Date
                                    </label>
                                </Typography>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd'
                                    value={moment(props.shipping.inv_date).format("yyyy-MM-DD")}
                                    readOnly
                                    customInput={<DateCustomInput />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="ship_date">
                                        <span className={classes.mandatoryField}>*</span>
                                        Shipment Date
                                    </label>
                                </Typography>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd'
                                    value={moment(props.shipping.ship_date).format("yyyy-MM-DD")}
                                    style={{ width: '160px' }}
                                    readOnly
                                    customInput={<DateCustomInput />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="export_name">
                                        <span className={classes.mandatoryField}>*</span>
                                        Customer
                                    </label>
                                </Typography>
                                <TextField
                                    id="customer"
                                    color="primary"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={props.shipping.party_name}
                                    className={classes.inputField}
                                />
                                {formValidator.current.message('Exporter', props.partyName, 'required', { className: classes.validateStyle })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Fab size="small" color="primary" aria-label="add" style={{ marginTop: '35px', width: '35px', height: '5px' }}>
                                <Tooltip title="Add Customer" arrow>
                                    <Add onClick={() => handleOpenDialog()} />
                                </Tooltip>
                            </Fab>
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: "45px" }}>
                            <FormControl fullWidth>
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dangerousGood">
                                        Dangerous Good
                                    </label>
                                </Typography>
                                <Checkbox
                                    readOnly
                                    color="primary"
                                    checked={props.shipping.isdg === "Y" ? true : false}
                                    style={{ marginTop: '-27px', marginLeft: '73px', background: "none" }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <Typography className={classes.accordianExpandedTitle}>
                                    <label htmlFor="dg_category">
                                        Dangerous Good Categories
                                    </label>
                                </Typography>
                                <TextField
                                    id="dg"
                                    color="primary"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={props.shipping.dgcategory}
                                    className={classes.inputField}
                                    placeholder="Dangerous Good"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}

            </React.Fragment>
        )
    }

    return (
        <>
            <Paper className={classes.paper}>
                {renderShipping()}
                {localStorage.getItem('party_type') !== "CST" ? (
                    <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                disabled={isBtnDisable}
                                onClick={() => handleSaveShipping()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : null}
            </Paper>

            {isOpenDialog &&
                <AddCustomerModal
                    setIsOpenDialog={setIsOpenDialog}
                    isOpenDialog={isOpenDialog}
                    countries={props.countries}
                    addCustomer={addCustomer}
                />
            }
        </>
    )

}

export default Shipping;