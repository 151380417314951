import React, { useState } from "react";
import { Table, TableBody, TableHead, makeStyles, TableCell, TextField, Typography, Tooltip, Grid, TableContainer, Paper, TableRow, Button, ButtonGroup } from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import Delete from '../../../../../assets/DeleteIcon.svg';
import DeleteConfirmationModal from "../../../../../Common/DeleteConfirmationModal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../css/datepicker.styles.css';
import calenderIcon from '../../../../../assets/calendarIcon.svg';
import { forwardRef } from "react";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'sans-serif',
        boxShadow: 'none'
    },
    tableWrapper: {
        maxHeight: 499
    },
    tableRow: {
        cursor: 'pointer'
    },
    textToTop: {
        verticalAlign: 'top'
    },
    tableCellActions: {
        minWidth: 190
    },
    tableCellAlignment: {
        textAlign: "center",
    },
    pageNumber: {
        color: '#828282',
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-23px 20px 20px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    paginationUl: {
        '& ul': {
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-page.Mui-selected': {
                    color: theme.palette.primary.dark,
                    background: 'transparent',
                }
            }
        }
    },
    buttonRoot: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        border: 0,
        margin: `` + theme.spacing(.5) + `px 0 ` + theme.spacing(.5) + `px ` + theme.spacing(.5) + `px!important`,
        position: 'relative',
        padding: '0 2px',
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#0070AD',
        '&::after': {
            position: 'absolute',
            right: -15,
            content: '""',
            top: 0,
            height: 24,
            textDecoration: 'none'
        },
        '&:hover': {
            background: 'none',
            color: '#2B0A3D',
            fontWeight: 600,
            border: 0,
            textDecoration: 'none'
        },
        '&.active': {
            color: '#2B0A3D',
            fontWeight: 600,
            textDecoration: 'none',
            '&:before': {
                backgroundColor: '#2B0A3D',
                left: 0,
                content: '""',
                bottom: -5,
                height: 2,
                position: 'absolute',
                width: '100%',
                textDecoration: 'none'
            }
        }
    },
    paginationFont: {
        color: '#707070DE',
        fontSize: '16px',
        paddingTop: '5px'
    },
    stickyPagination: {
        position: 'sticky',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        bottom: 0,
        marginTop: theme.spacing(1.5),
        background: '#F3F3F3'
    },
    tableCellTextTransform: {
        textTransform: 'capitalize'
    },
    uploadTable: {
        '& .MuiTableCell-head, .MuiTableCell-body': {
            maxWidth: 220,
            fontFamily: "sans-serif",
            padding: '8px, 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 150,
            '&.setMinWidth': {
                minWidth: 180,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1 / 4),
            },
            '&.manageSpace': {
                minWidth: '700px'
            }
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& tbody tr': {
            '&.errorCell': {
                verticalAlign: 'top',
            },
            '&:nth-child(even), .even.even': {
                background: theme.palette.grey[100],
            },
            '&.expandedRow': {
                background: 'transparent!important',
                '&>td': { padding: '0 0 20px' }
            },
        },
        '& .counterBx': {
            border: `1px solid ` + theme.palette.primary.main,
            width: '22px',
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '50%',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input::-webkit-calendar-picker-indicator': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230070AD' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`,
        },
    },
    muteText: {
        fontFamily: 'sans-serif',
        color: '#1381B9',
        fontWeight: 'bold',
        fontSize: 15
    },
    inputField: {
        marginTop: '13px',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        background: "#F3F3F3",
        padding: '15px',
        marginBottom: '5px',
        textAlign: 'right',
        right: 0,
        bottom: 0,
        marginTop: '35px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    }
}));

const TrackShipmentDate = (props) => {
    const classes = useStyles();
    const [activeSeletion, setActiveSeletion] = useState(2);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [trackShipDateId, setTrackShipDateId] = useState();
    const [newTrackShipDate, setNewTrackShipDate] = useState([]);
    const [selectStatus, setSelectStatus] = useState([]);
    const [trackShipDate, setTrackShipDate] = useState(props.trackShipDateData);
    const imagePath = `url(${calenderIcon})`;

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 160px/ 12px no-repeat`,
                width: '188px'
            }}
            className={classes.inputField}
            inputProps={{ style: { padding: "10.5px 14px" } }}
            onClick={onClick}
            onChange={onChange}
            ref={ref} placeholder="yyyy-mm-dd" />
    ));

    const statusData = [
        { id: "Pending", trackstatus: "Pending" },
        { id: "Completed", trackstatus: "Completed" },
        { id: "Work in progress", trackstatus: "Work in progress" },
        { id: "Hold", trackstatus: "Hold" },
    ];
    const filteredData = props.trackShipDateData.filter(row => {
        return row;
    })

    // useEffect(() => {
    //     filteredData.map((data, i) => {
    //         setSelectStatus(statusData.find(x => x.label === data.trackstatus));
    //     })
    // }, [])

    const ROWS_PER_PAGE = [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: filteredData.length }
    ];
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage, setrecordsPerPage] = useState(ROWS_PER_PAGE[0].value)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0].value);

    const handleChangeRowsPerPage = (value) => {
        setrecordsPerPage(value);
        setCurrentPage(1)
        setActiveSeletion(1)
        setRowsPerPage(value);
        setPage(1)
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setActiveSeletion(2)
        setPage(newPage);
    }

    const TotalRecords = filteredData.length
    const Pagecount = Math.ceil(TotalRecords / recordsPerPage)

    const validate = (index) => {
        if (activeSeletion === 1) {
            // user clicks on show option recently
            return rowsPerPage > index;
        }
        if (activeSeletion === 2) {
            // user clicks on pagination numbers recently
            return (page === 1 ? 0 : (rowsPerPage * page) - rowsPerPage) <= index && index < (page === 1 ? 25 : (((page * rowsPerPage) - rowsPerPage) + rowsPerPage));
        }
    }

    const handleAddRow = () => {
        const trackShipDateRow = {
            trackactivity: "",
            trackremarks: "",
            trackstatus: "",
            trackplandate: "",
            trackactdate: ""
        }
        setNewTrackShipDate([...newTrackShipDate, trackShipDateRow]);
    }

    const handleUpdateTrackShipmentDate = (field, value, trackId) => {
        setTrackShipDate([
            ...trackShipDate.map(trackData => {
                if (trackData.trackactid === trackId) trackData[field] = value;
                return trackData;
            }),
        ]);
    }

    const handleChangeTrackShipmentDate = (field, value, index) => {
        setNewTrackShipDate([
            ...newTrackShipDate.map((trackData, i) => {
                if (index === i) trackData[field] = value
                return trackData
            })
        ])
    }

    const handleDeleteRow = (index) => {
        const data = newTrackShipDate.filter((_, i) => i !== index)
        setNewTrackShipDate(data);
    }

    const handleDeleteTrackShipmentDate = (trackid) => {
        setTrackShipDateId(trackid)
        setOpenDeleteModal(!openDeleteModal);
    }

    const handleModalClose = () => {
        setOpenDeleteModal(false);
    }

    const handleDelete = () => {
        props.deleteTrackShipmentDate(trackShipDateId);
        setOpenDeleteModal(false);
    }

    const newresult = newTrackShipDate.map(trackItem => {
        return {
            id: 0,
            trackActivity: trackItem.trackactivity,
            trackPlanDate: trackItem.trackplandate ? moment(trackItem.trackplandate).format('yyyy-MM-DD') : "",
            trackActDate: trackItem.trackactdate ? moment(trackItem.trackactdate).format('yyyy-MM-DD') : "",
            trackStatus: trackItem.trackstatus ? trackItem.trackstatus : "",
            trackRemarks: trackItem.trackremarks,
            woid: props.woid
        }
    });

    const updatedResult = trackShipDate.map(trackItem => {
        return {
            id: trackItem.trackactid,
            trackActivity: trackItem.trackactivity,
            trackPlanDate: trackItem.trackplandate ? moment(trackItem.trackplandate).format('yyyy-MM-DD') : "",
            trackActDate: trackItem.trackactdate ? moment(trackItem.trackactdate).format('yyyy-MM-DD') : "",
            trackStatus: trackItem.trackstatus ? trackItem.trackstatus : "",
            trackRemarks: trackItem.trackremarks,
            woid: props.woid
        }
    })

    const handleSaveTrackShipmentDate = () => {
        const reqObj = [...newresult, ...updatedResult]
        props.saveTrackShipDate(reqObj);
        setNewTrackShipDate([])
    }

    const handleCancel = () => {

    }

    const renderTrackShipment = () => {
        return (
            <React.Fragment>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="primary"
                        className={classes.selectBtn}
                        onClick={() => handleAddRow()}>
                        + Row
                    </Button>
                </Grid>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer className={classes.tableWrapper}>
                        <Table stickyHeader size="small" aria-label="Upload table" className={classes.uploadTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Activity</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Plan Date</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Actual Date</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Status</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Remarks</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.muteText}>Action</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newTrackShipDate.map((row, index) => (
                                    <>
                                        {
                                            validate(index) && <TableRow key={row.trackactid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        placeholder="Activity"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={classes.inputField}
                                                        inputProps={{ style: { padding: "10.5px 14px" } }}
                                                        onChange={(e) => handleChangeTrackShipmentDate("trackactivity", e.target.value, index)}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <DatePicker
                                                        selected={row.trackplandate}
                                                        onChange={(date) => handleChangeTrackShipmentDate("trackplandate", date, index)}
                                                        customInput={<DateCustomInput />}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <DatePicker
                                                        selected={row.trackactdate}
                                                        onChange={(date) => handleChangeTrackShipmentDate("trackactdate", date, index)}
                                                        customInput={<DateCustomInput />}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row" width="220px">
                                                    <Autocomplete
                                                        id="ItemCode"
                                                        disableClearable
                                                        options={statusData}
                                                        getOptionLabel={(option) => option.trackstatus}
                                                        getOptionSelected={(option, value) => value?.id === option?.id}
                                                        onChange={(e, newValue) => handleChangeTrackShipmentDate("trackstatus", newValue, index)}
                                                        inputProps={{ style: { padding: "1.5px 14px", width: "185px" } }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                required
                                                                {...params}
                                                                placeholder="Status"
                                                                variant="outlined"
                                                                margin="normal"
                                                                className={classes.inputField}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        id="desc"
                                                        color="primary"
                                                        variant="outlined"
                                                        multiline
                                                        minRows="3"
                                                        placeholder="Remarks"
                                                        className={classes.inputField}
                                                        inputProps={{ style: { height: "37px" } }}
                                                        onChange={(e) => handleChangeTrackShipmentDate("trackremarks", e.target.value, index)}
                                                    />
                                                </TableCell>
                                                <Tooltip title="Delete" arrow>
                                                    <TableCell component="th" scope="row">
                                                        <img
                                                            src={Delete}
                                                            alt='delete'
                                                            style={{ cursor: 'pointer', marginLeft: "21px", marginTop: "21px" }}
                                                            onClick={() => handleDeleteRow(index)}
                                                        />
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                                {filteredData.map((row, index) => (
                                    <>
                                        {
                                            validate(index) && <TableRow key={row.trackactid} className={`${classes.tableRow} ${classes.textToTop}`} hover>
                                                <TableCell component="th" scope="row">
                                                    {row.trackactivity}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <DatePicker
                                                        value={row.trackplandate === null ? "" : moment(row.trackplandate).format("yyyy-MM-DD")}
                                                        onChange={(date) => handleUpdateTrackShipmentDate("trackplandate", date, row.trackactid)}
                                                        customInput={<DateCustomInput />}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <DatePicker
                                                        value={row.trackactdate === null ? "" : moment(row.trackactdate).format("yyyy-MM-DD")}
                                                        onChange={(date) => handleUpdateTrackShipmentDate("trackactdate", date, row.trackactid)}
                                                        customInput={<DateCustomInput />}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row" width="220px">
                                                    <Autocomplete
                                                        id="status"
                                                        disableClearable
                                                        value={row}
                                                        options={statusData}
                                                        getOptionLabel={(option) => option.trackstatus}
                                                        getOptionSelected={(option, value) => value.id === option.id}
                                                        onChange={(e, newValue) => handleUpdateTrackShipmentDate("trackstatus", newValue.trackstatus, row.trackactid)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                required
                                                                {...params}
                                                                placeholder="Status"
                                                                variant="outlined"
                                                                margin="normal"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        id="desc"
                                                        color="primary"
                                                        variant="outlined"
                                                        multiline
                                                        minRows="3"
                                                        placeholder="Remarks"
                                                        value={row.trackremarks}
                                                        className={classes.inputField}
                                                        inputProps={{ style: { height: "37px" } }}
                                                        onChange={(e) => handleUpdateTrackShipmentDate("trackremarks", e.target.value, row.trackactid)}
                                                    />
                                                </TableCell>
                                                <Tooltip title="Delete" arrow>
                                                    <TableCell component="th" scope="row">
                                                        <img
                                                            src={Delete}
                                                            alt='delete'
                                                            style={{ cursor: 'pointer', marginLeft: "21px", marginTop: "21px" }}
                                                            onClick={() => handleDeleteTrackShipmentDate(row.trackactid)}
                                                        />
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid container boxShadow={3} className={classes.stickyPagination} >
                            <Grid item xs={3}>
                                <Typography className={classes.paginationFont} style={{ marginLeft: '10px' }}> Page {currentPage} of {Pagecount} </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Pagination className={[classes.paginationUl, { root: classes.pageNumber }]} count={Pagecount} showFirstButton showLastButton onChange={handleChangePage} />
                            </Grid>
                            <Grid container item xs={4} justifyContent='center'>
                                <Typography className={classes.paginationFont}>Show</Typography>
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    {ROWS_PER_PAGE.map(({ label, value }) => (
                                        <Button
                                            key={value}
                                            className={recordsPerPage === value && 'active'}
                                            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                                            onClick={() => handleChangeRowsPerPage(value)}>
                                            {label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </TableContainer>
                </Paper>
                <Grid container wrap="wrap" className={classes.pageFooter}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary"
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.applyBtn}
                            onClick={() => handleSaveTrackShipmentDate()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderTrackShipment()}
            {openDeleteModal && <DeleteConfirmationModal
                handleModalClose={handleModalClose}
                openDeleteModal={openDeleteModal}
                handleDelete={handleDelete}
                type="activity"
            />}
        </>
    )
}

export default TrackShipmentDate;