import { Grid, Paper, Typography, makeStyles, Button, InputBase, IconButton } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import AddUser from "./AddUser";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import { useState } from "react";
import { addUserDetails, fetchAllUsers, fetchCustomer } from "../Common/API";
import { OPTIONS, OPTIONS3 } from "../Common/constant";
import { useSnackbar } from "react-simple-snackbar";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    searchBar: {
        border: 'solid 1px #0070AD', 
        borderRadius: 20, 
        marginLeft: "3%", 
        padding: '0 10px 0 30px',
        margin: `${theme.spacing(2)}px 0`
    }
}))

const UserManagement = ({ setTitle }) => {
    setTitle('Customer User');
    const classes = useStyles();
    const [amberSnackbar] = useSnackbar(OPTIONS3);
    const [successSnackbar] = useSnackbar(OPTIONS);
    const [userData, setUserData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [newUserAdd, setNewUserAdd] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchCustomerList();
        getUser();
    }, []);

    const userDataList = useMemo(() => {
        if (!search) return userData;
        return userData.filter(
            (value) => value.username.toLowerCase().includes(search.toLowerCase())
        );
    })

    const fetchCustomerList = async () => {
        try {
            fetchCustomer().then(data => {
                setPartyData(data);
            })
        } catch (error) {
            alert(error);
        }
    }

    const getUser = async () => {
        try {
            fetchAllUsers().then(res => {
                setUserData(res);
            })
        } catch (error) {
            alert(error);
        }
    }

    const saveUser = async (reqObj) => {
        try {
            addUserDetails(reqObj).then(res => {
                if (res.status === 1) {
                    successSnackbar(res.message);
                    getUser();
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        } catch (error) {
            alert(error);
        }
    }

    const handleAddRow = () => {
        const addUser = {
            username: "",
            useremail: "",
            partyname: "",
            userrole: "",
            userstatus: ""
        }
        setNewUserAdd([...newUserAdd, addUser]);
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                    <Grid item xs={4} className="d-flex align-center">
                        <Typography variant="h6" className={classes.title}>Customer User</Typography>
                    </Grid>
                    <Grid item xs={4} className="d-flex align-center">
                        <div className={classes.searchBar}>
                            <InputBase
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end" className="d-flex align-center">
                        <Button variant="contained" color="primary" size="medium"
                            onClick={handleAddRow} className={classes.applyBtn}>
                            Add User
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ padding: '0 31px' }}>
                <Paper component="form" className={classes.paper}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <AddUser userData={userDataList} partyData={partyData} saveUser={saveUser}
                            newUserAdd={newUserAdd} setNewUserAdd={setNewUserAdd} setUserData={setUserData} />
                    </ErrorBoundary>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default UserManagement;