import { Typography } from "@material-ui/core";

const BulkUpload = ({setTitle}) => {
    setTitle('Admin Activities');

    return (
        <Typography>In Progress...</Typography>
    )
}

export default BulkUpload;