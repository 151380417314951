import { Typography, Grid, Button, Tab, Box, AppBar, Tabs, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { errorHandler, Fallback } from "../../../Common/Fallback";
import CustomerDetails from "./components/CustomerDetails";
import { fetchCustomer, fetchCountry, fetchUOMDetails, fetchCustomerAddress, fetchPOEditData, fetchAvailableItemQty, saveCustomer, updatePurchaseOrder, deletePoItem, updatePoItems, saveDeliveryPlan, deleteAllPo, savePurchaseOrder, savePOItem, updateScan, saveContacts, fetchContactList, updateLocationContact, saveUpdatePoContact, saveUpdateWoContact } from '../../../Common/API';
import PODetails from "./components/PODetails";
import ItemDetails from "./components/ItemDetails";
import { useNavigate, useParams } from "react-router-dom";
import DeliveryPlan from "./components/DeliveryPlan";
import { useSnackbar } from "react-simple-snackbar";
import { OPTIONS, OPTIONS3, purchaseOrderStyle } from "../../../Common/constant";
import DeleteReasonPopupModal from "../../../Common/DeleteReasonPopupModal";
import pIcon from '../../../assets/picon.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labeledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box p={index === 3 ? 0 : 3}>
                    <Typography component="div">
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    )
}

const CreatePO = ({ setTitle }) => {
    const classes = purchaseOrderStyle();
    setTitle('Purchase Order')
    const { poid } = useParams();
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [tabIndex, setTabIndex] = useState(0);
    const [successSnackbar] = useSnackbar(OPTIONS);
    const [amberSnackbar] = useSnackbar(OPTIONS3);
    const [poListObj, setPOListObj] = useState([]);
    const [country, setCountry] = useState([]);
    const [itemEditList, setItemEditList] = useState([]);
    const [delPlanData, setDelPlanList] = useState([]);
    const [balanceQty, setBalanceQty] = useState([]);
    const [customerId, setCustomerId] = useState();
    const [prodUOM, setProdUOM] = useState([]);
    const [selectPoid, setSelectPoid] = useState();
    const [contactName, setContactName] = useState([]);
    const [partyPaid, setPartyPaid] = useState();
    const [contacts, setContacts] = useState([]);
    const [workOrder, setWorkOrder] = useState([]);
    const [isType, setIsType] = useState();
    const [itemList, setItemList] = useState(itemEditList.length > 0 ? [] : [{
        id: 0,
        prod_code: "",
        prod_name: "",
        prod_qty: "",
        prod_qty_uom: "",
        prod_unit_price: "",
        total: "",
    }]);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [allFormData, setAllFormData] = useState({
        customerList: [],
        countryList: [],
        uomList: [],
        addrList: [],
        poList: [],
        delPlanList: [],
        balenceQty: [],
        itemList: []
    })

    useEffect(() => {
        fetchCustomerList();
        fetchPOData();
    }, [])

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const fetchCustomerList = () => {
        fetchCustomer().then(data => {
            setAllFormData((prevState) => {
                return {
                    ...prevState,
                    customerList: data
                }
            })
        }).catch(error => {
            amberSnackbar(error);
        });

        fetchCountry().then(data => {
            setAllFormData((prevState) => {
                return {
                    ...prevState,
                    countryList: data
                }
            })
        }).catch(error => {
            amberSnackbar(error);
        })

        fetchUOMDetails().then(data => {
            setAllFormData((prevState) => {
                return {
                    ...prevState,
                    uomList: data
                }
            })
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const fetchPOData = () => {
        (async () => {
            if (poid) {
                await fetchPOEditData(poid).then(data => {
                getBalanceItemQty(poid);
                contactNameList(data.customer[0].pid, "", data.po[0].po_pcid);
                if (data) {
                    getAddress(data.customer[0].pid);
                    setCountry(data.po.country);
                    setPOListObj(data.po[0]);
                    setItemEditList(data.items);
                    setCustomerId(data.customer[0].pid);
                    setWorkOrder(data.wo);
                    setContacts(data.pocontacts);
                    setAllFormData((prevState) => {
                        return {
                            ...prevState,
                            poList: data.po[0],
                            customerList: data.customer[0],
                            delPlanList: data.wo,
                        }
                    })
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        }
      })();
    }

    const getBalanceItemQty = (poid) => {
        fetchAvailableItemQty(poid, userId).then(data => {
            setBalanceQty(data.items);
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const getAddress = (party_id) => {
        fetchCustomerAddress(party_id).then(data => {
            setAllFormData((prevState) => {
                return {
                    ...prevState,
                    addrList: data
                }
            })
        }).catch(error => {
            amberSnackbar(error);
        });
    }

    const saveCustomerDetail = (reqObj) => {
        saveCustomer(reqObj).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                setCustomerId(res.pid);
            } else {
                amberSnackbar(res.message)
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const editPurchaseOrder = (reqObj) => {
        updatePurchaseOrder(reqObj).then((data) => {
            if (data.status === 1) {
                successSnackbar(data.message);
                fetchPOData();
            } else {
                amberSnackbar(data.message);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const handleCancelPO = () => {
        setIsOpenDelete(!isOpenDelete)
        setIsType('C');
    }

    const handleDeletePO = () => {
        setIsOpenDelete(!isOpenDelete)
        setIsType('D');
    }

    const purchaseOrderDelete = (reason, type) => {
        deleteAllPo(poid, localStorage.getItem('userId'), reason, type)
            .then(data => {
                if (data.status === 1) {
                    successSnackbar(data.message);
                    setIsOpenDelete(false);
                    navigate("/purchase-order/summary");
                } else {
                    amberSnackbar(data.message);
                    setIsOpenDelete(false);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
    }

    const poItemDelete = (po_item_id) => {
        deletePoItem(po_item_id, userId).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                fetchPOData();
            } else {
                amberSnackbar(res.message);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const editPOItem = (itemObj) => {
        updatePoItems(itemObj).then((res) => {
            if (res.status === 1) {
                successSnackbar(res.message);
                setItemEditList([]);
                setItemList([]);
                fetchPOData();
            } else {
                amberSnackbar(res.message);
            }
        }).catch((error) => {
            amberSnackbar(error);
        })
    }

    const addDeliveryPlan = (reqObj) => {
        if(balanceQty === undefined){
            amberSnackbar("No items found !, Please add items to save delivery plan.")
        }else {
            saveDeliveryPlan(reqObj).then(res => {
                if (res.status === 1) {
                    successSnackbar(res.message);
                    fetchPOData();
                    setPartyPaid(res.paid)
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                amberSnackbar(error);
            })
        }  
    }

    const addPO = (payload) => {
        savePurchaseOrder(payload).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                setSelectPoid(data.newid);
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const addPOItems = (payload) => {
        savePOItem(payload).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                navigate(`/edit-purchase-order/${selectPoid}`)
                fetchPOEditData(selectPoid).then(data => {
                    getBalanceItemQty(selectPoid)
                    if (data) {
                        getAddress(data.customer[0].pid);
                        setCountry(data.po.country);
                        setPOListObj(data.po[0]);
                        setItemEditList(data.items);
                        setCustomerId(data.customer[0].pid);
                        setAllFormData((prevState) => {
                            return {
                                ...prevState,
                                poList: data.po[0],
                                customerList: data.customer[0],
                                delPlanList: data.wo
                            }
                        })
                    }
                }).catch(error => {
                    amberSnackbar(error);
                })
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const addPartyScan = (reqobj) => {
        updateScan(reqobj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
            } else {
                amberSnackbar(data.message)
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const updatecontact = (reqObj) => {
        updateLocationContact(reqObj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const addContact = (reqObj) => {
        saveContacts(reqObj).then(data => {
            if (data.status === 1) {
                successSnackbar(data.message);
                const obj = {
                    pa_pcid: data.newid,
                    paid: reqObj.paid,
                    user: reqObj.user
                }
                updatecontact(obj);
                contactNameList("", reqObj.paid);
            } else {
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const contactNameList = (pid, paid) => {
        fetchContactList(pid, paid, userId).then(data => {
            setContactName(data)
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const savePoContact = (reqobj) => {
        saveUpdatePoContact(reqobj).then(data => {
            if(data.status === 1) {
                successSnackbar(data.message);
            }else{
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const saveMultipleContact = (reqobj) => {
        saveUpdateWoContact(reqobj).then(data => {
            if(data.status === 1){
                successSnackbar(data.message);
            }else{
                amberSnackbar(data.message);
            }
        }).catch(error => {
            amberSnackbar(error);
        })
    }

    const renderPO = () => {
        return (
            <>
                <Grid container item xs={12}>
                    {poid ? <Grid item xs={2}>
                        <Box ml={3} me={2}>
                            <Paper elevation={10} className={classes.leftPaper}>
                                <Grid item>
                                    <img src={pIcon} className={classes.profileIcon} />
                                </Grid>
                                <Grid item container xs={12} spacing={4} className={classes.topP}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.disabled}>Purchase Order No.</Typography>
                                        <Typography>{allFormData.poList.po_number}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.disabled}>Date</Typography>
                                        <Typography>{allFormData.poList.po_date}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.disabled}>Party Name</Typography>
                                        <Typography>{allFormData.customerList.party_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.disabled}>Country</Typography>
                                        <Typography>{allFormData.customerList.party_country}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Box ml={3}>
                                    <Button data-testid="delete" fullWidth variant="contained" color="primary"
                                        onClick={() => handleDeletePO()} style={{ textTransform: "none" }} >
                                        Delete Purchase Order
                                    </Button>
                                    <Button data-testid="delete" fullWidth variant="contained" color="primary"
                                        onClick={() => handleCancelPO()} style={{ textTransform: "none", marginTop: '10px' }} >
                                        Cancel Purchase Order
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid> : null}
                    <Grid item xs={poid ? 10 : 12}>
                        <Box ml={2} mr={3}>
                            <Paper elevation={10} className={classes.paper}>
                                <AppBar position="static" color="default" className={classes.topPadding}>
                                    <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable"
                                        scrollButtons="auto">
                                        {poid ? null : <Tab label="Customer Details" style={{ textTransform: "none" }} />}
                                        <Tab label="Purchase Order" style={{ textTransform: "none" }} />
                                        <Tab label="Item Details" style={{ textTransform: "none" }} />
                                        {poid ? <Tab label="Delivery Plan" style={{ textTransform: "none" }} /> : null}
                                    </Tabs>
                                </AppBar>
                                <Box className={classes.topP}>
                                    <TabPanel value={tabIndex} index={0}>
                                        {poid ? null : <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                            <CustomerDetails userId={userId} customers={allFormData.customerList}
                                                countries={allFormData.countryList} saveCustomerDetail={saveCustomerDetail}
                                                setCustomerId={setCustomerId} customerId={customerId} addPartyScan={addPartyScan}
                                                addContact={addContact} contactNameList={contactNameList} />
                                        </ErrorBoundary>}
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={poid ? 0 : 1}>
                                        <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                            {(customerId || poid) ? <PODetails countries={allFormData.countryList} poData={allFormData.poList}
                                                poListObj={poListObj} setPOListObj={setPOListObj} poid={poid}
                                                editPurchaseOrder={editPurchaseOrder} addPO={addPO} prodUOM={prodUOM}
                                                setCountry={setCountry} country={country} setProdUOM={setProdUOM}
                                                customerId={customerId} addPartyScan={addPartyScan} addContact={addContact}
                                                contactName={contactName} amberSnackbar={amberSnackbar} 
                                                savePoContact={savePoContact} selectPoid={selectPoid} 
                                                contacts={contacts} setContacts={setContacts} /> :
                                                <Typography>Please select/save Customer section First...</Typography>}
                                        </ErrorBoundary>
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={poid ? 1 : 2}>
                                        <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                            {(selectPoid || poid) ? <ItemDetails uomData={allFormData.uomList} setItemEditList={setItemEditList}
                                                poid={poid} poItemDelete={poItemDelete} itemEditList={itemEditList} editPOItem={editPOItem}
                                                itemList={itemList} setItemList={setItemList} addPOItems={addPOItems}
                                                selectPoid={selectPoid} /> :
                                                <Typography>Please save Purchase Order section First...</Typography>}

                                        </ErrorBoundary>
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={poid ? 2 : 3}>
                                        {poid ? <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                                            <DeliveryPlan addrData={allFormData.addrList} countries={allFormData.countryList}
                                                delPlanData={delPlanData} setDelPlanList={setDelPlanList}
                                                balanceQty={balanceQty} setBalanceQty={setBalanceQty} poid={poid}
                                                addDeliveryPlan={addDeliveryPlan} po_no={poListObj.po_number}
                                                po_date={poListObj.po_date} currency={poListObj.currency}
                                                partyId={customerId} itemEditList={itemEditList} 
                                                contactNameList={contactNameList} addContact={addContact}
                                                contactName={contactName} partyPaid={partyPaid} workOrder={workOrder}
                                                saveMultipleContact={saveMultipleContact} />
                                        </ErrorBoundary> : null}
                                    </TabPanel>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >
                </Grid>

            </>
        )
    }

    return (
        <>
            {renderPO()}
            {isOpenDelete && <DeleteReasonPopupModal
                isOpenDelete={isOpenDelete}
                setIsOpenDelete={setIsOpenDelete}
                orderDelete={purchaseOrderDelete}
                isType={isType}
            />}
        </>
    )
}

export default CreatePO;