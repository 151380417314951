import { Popover, Paper, Grid, TextField, Typography, FormControl, makeStyles, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Cookies from "js-cookie";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../css/datepicker.styles.css'
import {
    fetchOriginList, fetchPOList,
    fetchCustomerAddress,
    fetchDestination,
    fetchFreightForward,
    fetchUserList,
    fetchCustomer,
    fetchSalesCustomer,
    fetchWOStatus
} from '../../../Common/API';
import calenderIcon from '../../../assets/calendarIcon.svg';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    pageHeader: {
        marginBottom: 30,
    },
    pageFooter: {
        background: '#F3F3F3',
        padding: '10px',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20
    },
    paper: {
        background: 'none',
        height: '600px',
        width: '752px',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            fontFamily: 'Ubuntu, sans-serif',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    h6: {
        padding: '22px 0',
        color: '#2B0A3D',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}))

const FilterPopover = ({ isOpenModal, anchorEl, handleClose, filterWOSummary, setWoFilter, woFilter }) => {
    const imagePath = `url(${calenderIcon})`;
    const userId = localStorage.getItem('userId');
    const classes = useStyles();
    const To = new Date();
    const From = new Date(To.getFullYear() - 1, To.getMonth(), To.getDate());
    const [poList, setPoList] = useState([]);
    const [originList, setOriginList] = useState([]);
    const [selectFromDate, setSelectFromDate] = useState(null);
    const [selectToDate, setSelectToDate] = useState(null);
    const [destinationList, setDestinationList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [ffList, setFFList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [woList, setWoList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [salesCustList, setSalesCustList] = useState([]);

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 120px/ 12px no-repeat`,
                width: '162px'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    useEffect(() => {
        fetchSalesCustomer(localStorage.getItem('userId')).then(data => {
            setSalesCustList(data);
        }).catch((error) => {

        });
        fetchCustomer().then(data => {
            setCustomerList(data);
        }).catch((error) => {

        });
        fetchWOStatus().then(data => {
            setWoList(data);
        }).catch((error) => {

        });
        fetchFreightForward().then(data => {
            setFFList(data);
        }).catch((error) => {

        });
        fetchUserList().then(data => {
            setUserList(data);
        }).catch((error) => {

        });
        if (localStorage.getItem('party_type') === "CST") {
            pageLoad();
        }
    }, []);

    const pageLoad = () => {
        fetchOriginList(localStorage.getItem('party_id')).then((res) => {
            if (res) {
                setOriginList(res);
            }
        }).catch((error) => {

        });
        fetchDestination(localStorage.getItem('party_id')).then((data) => {
            if (data) {
                setDestinationList(data);
            }
        }).catch(error => {

        })
        fetchCustomerAddress(localStorage.getItem('party_id')).then((data) => {
            if (data) {
                setAddressList(data);
            }
        }).catch(error => {

        })
        fetchPOList(localStorage.getItem('party_id')).then((res) => {
            if (res) {
                setPoList(res);
            }
        }).catch((error) => {

        });
    }

const handleChangeFilter = (field, value) => {
    if (field === "party_id" && value) {
        fetchOriginList(value).then((res) => {
            if (res) {
                setOriginList(res);
            }
        }).catch((error) => {

        });
        fetchDestination(value).then((data) => {
            if (data) {
                setDestinationList(data);
            }
        }).catch(error => {

        })
        fetchCustomerAddress(value).then((data) => {
            if (data) {
                setAddressList(data);
            }
        }).catch(error => {

        })
        fetchPOList(value).then((res) => {
            if (res) {
                setPoList(res);
            }
        }).catch((error) => {

        });
    }
    setWoFilter(values => ({ ...values, [field]: value }));
}
const handleClear = () => {

}

const handleApplyFilter = () => {
    if (localStorage.getItem('party_type') === 'CST') {
        woFilter['fromdate'] = selectFromDate !== null ? moment(selectFromDate).format('yyyy-MM-DD') : moment(From).format('yyyy-MM-DD');
        woFilter['todate'] = selectToDate !== null ? moment(selectToDate).format('yyyy-MM-DD') : moment(To).format('yyyy-MM-DD');
        woFilter['party_id'] = localStorage.getItem('party_id');
        filterWOSummary(woFilter);
    } else {
        woFilter['fromdate'] = selectFromDate !== null ? moment(selectFromDate).format('yyyy-MM-DD') : moment(From).format('yyyy-MM-DD');
        woFilter['todate'] = selectToDate !== null ? moment(selectToDate).format('yyyy-MM-DD') : moment(To).format('yyyy-MM-DD');
        woFilter['user'] = userId;
        filterWOSummary(woFilter);
    }
    handleClose();
}

return (
    <Popover
        open={isOpenModal}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
    >
        <Paper component="form" className={classes.paper}>
            <Grid container wrap="wrap" className={classes.pageHeader}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary" style={{ fontFamily: 'Ubuntu, sans-serif' }}>Filter</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
            {localStorage.getItem('party_type') === "SLS" ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="party_name">
                                    Customer
                                </label>
                            </Typography>
                            <Autocomplete
                                disableClearable
                                id="customer-select"
                                options={salesCustList}
                                getOptionLabel={(option) => option.party_name}
                                getOptionSelected={(option, value) => value.pid === option.pid}
                                onChange={(e, newValue) => handleChangeFilter("party_id", newValue?.pid)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        placeholder="Customer"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.inputField}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                ) : null }
                {localStorage.getItem('party_type') !== "CST" && localStorage.getItem('party_type') !== "SLS" ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="party_name">
                                    Customer
                                </label>
                            </Typography>
                            <Autocomplete
                                disableClearable
                                id="customer-select"
                                options={customerList}
                                getOptionLabel={(option) => option.party_name}
                                getOptionSelected={(option, value) => value.pid === option.pid}
                                onChange={(e, newValue) => handleChangeFilter("party_id", newValue?.pid)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        placeholder="Customer"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.inputField}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {localStorage.getItem('party_type') === "CST" ? <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="wo-status">
                                WO Status
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="Status-select"
                            options={woList}
                            getOptionLabel={(option) => option.wostatus}
                            getOptionSelected={(option, value) => value.status_code === option.status_code}
                            onChange={(e, newValue) => handleChangeFilter("wostatus", newValue?.status_code)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="WO Status"
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid> : null}
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="bno">
                                Search BO#
                            </label>
                        </Typography>
                        <TextField
                            id="pono"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("bno", e.target.value)}
                            className={classes.inputField}
                            placeholder="PO No."
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="mawbno">
                                Search MAWB#
                            </label>
                        </Typography>
                        <TextField
                            id="pono"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("mawbno", e.target.value)}
                            className={classes.inputField}
                            placeholder="PO No."
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="hawbno">
                                Search HAWB#
                            </label>
                        </Typography>
                        <TextField
                            id="pono"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("hawbno", e.target.value)}
                            className={classes.inputField}
                            placeholder="PO No."
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="origin">
                                Origin
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="origin-select"
                            options={originList}
                            getOptionLabel={(option) => option.ctrycname}
                            getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                            onChange={(e, newValue) => handleChangeFilter("origin", newValue?.ctrycode)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="Origin"
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="destination">
                                Destination
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="destination-select"
                            options={destinationList}
                            getOptionLabel={(option) => option.ctrycname}
                            getOptionSelected={(option, value) => value.ctrycode === option.ctrycode}
                            onChange={(e, newValue) => handleChangeFilter("destination", newValue?.ctrycode)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="Destination"
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="del-location">
                                Delivery Location
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="location-select"
                            options={addressList}
                            getOptionLabel={(option) => option.party_name}
                            getOptionSelected={(option, value) => value.paid === option.paid}
                            onChange={(e, newValue) => handleChangeFilter("dellocation", newValue?.paid)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="Delivery Location"
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                {localStorage.getItem('party_type') !== "CST" && (
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="wo-status">
                                    WO Status
                                </label>
                            </Typography>
                            <Autocomplete
                                disableClearable
                                id="Status-select"
                                options={woList}
                                getOptionLabel={(option) => option.wostatus}
                                getOptionSelected={(option, value) => value.status_code === option.status_code}
                                onChange={(e, newValue) => handleChangeFilter("wostatus", newValue?.status_code)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        placeholder="WO Status"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.inputField}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="select-po">
                                Select PO#
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="po-select"
                            options={poList}
                            getOptionLabel={(option) => option.po_number}
                            getOptionSelected={(option, value) => value.poid === option.poid}
                            onChange={(e, newValue) => handleChangeFilter("pono", newValue?.poid)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="PO No."
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="pono">
                                Search PO#
                            </label>
                        </Typography>
                        <TextField
                            id="pono"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("posearch", e.target.value)}
                            className={classes.inputField}
                            placeholder="PO No."
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="fromDate">
                                From Date
                            </label>
                        </Typography>
                        <DatePicker
                            dateFormat='yyyy-MM-dd'
                            selected={selectFromDate !== null ? selectFromDate : From}
                            onChange={
                                (date) => setSelectFromDate(date)
                            }
                            customInput={<DateCustomInput />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="toDate">
                                To Date
                            </label>
                        </Typography>
                        <DatePicker
                            dateFormat='yyyy-MM-dd'
                            selected={selectToDate !== null ? selectToDate : To}
                            onChange={
                                (date) => setSelectToDate(date)
                            }
                            customInput={<DateCustomInput />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="brand">
                                Brand
                            </label>
                        </Typography>
                        <TextField
                            id="brand"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("po_party_brand", e.target.value)}
                            className={classes.inputField}
                            placeholder="Brand"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="ff">
                                Frieght Forwarder
                            </label>
                        </Typography>
                        <Autocomplete
                            disableClearable
                            id="ff-select"
                            options={ffList}
                            getOptionLabel={(option) => option.party_name}
                            getOptionSelected={(option, value) => value.pid === option.pid}
                            onChange={(e, newValue) => handleChangeFilter("ffpid", newValue?.pid)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    placeholder="Freight Forwarder"
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.inputField}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                {localStorage.getItem('party_type') !== "CST" && (
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Typography className={classes.accordianExpandedTitle}>
                                <label htmlFor="contact_name">
                                    User
                                </label>
                            </Typography>
                            <Autocomplete
                                disableClearable
                                id="user-select"
                                options={userList ? userList : []}
                                getOptionLabel={(option) => option.user_name}
                                getOptionSelected={(option, value) => value.uid === option.uid}
                                onChange={(e, newValue) => handleChangeFilter("pouid", newValue?.uid)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        placeholder="User"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.inputField}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                        <Typography className={classes.accordianExpandedTitle}>
                            <label htmlFor="item">
                                Item
                            </label>
                        </Typography>
                        <TextField
                            id="item"
                            color="primary"
                            variant="outlined"
                            onChange={(e) => handleChangeFilter("items", e.target.value)}
                            className={classes.inputField}
                            placeholder="Item"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container wrap="wrap" className={classes.pageFooter}>
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary"
                        className={classes.cancelButton}
                        onClick={() => handleClear()}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary"
                        className={classes.applyBtn}
                        onClick={() => handleApplyFilter()}>
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Popover>
)
}

export default FilterPopover;