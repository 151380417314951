import { Typography, Grid, FormControl, IconButton, TextField, makeStyles, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { forwardRef, useRef, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calenderIcon from '../../../../../assets/calendarIcon.svg';
import '../../../../../css/datepicker.styles.css';
import { poContact, poListModal } from "../../../../../Common/constant";
import SimpleReactValidator from "simple-react-validator";
import { fecthBlacklistCustomer, updateScan } from "../../../../../Common/API";
import CreateIcon from '../../../../../assets/CreateActive.svg';
import AddContactPopupModal from "../../../../../Common/AddContactPopupModal";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ScanPartyPopupModal from "../../../../../Common/ScanPartyPopupModal";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        fontFamily: 'Ubuntu, sans-serif',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '32px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    pageFooter: {
        marginBottom: '-16px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-52px 20px 10px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },

    scanbtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-20px 20px 10px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
}))

const PODetails = (props) => {
    const classes = useStyles();
    const imagePath = `url(${calenderIcon})`;
    const [poDate, setPoDate] = useState(null);
    const [, forceUpdate] = useState();
    const [isEnable, setIsEnable] = useState(true);
    const [isDisable, setIsDisable] = useState(true);
    const [isOpenContactModal, setIsOpenContactModal] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [matchData, setMatchData] = useState([]);
    const [noOfMatch, setNoOfMatch] = useState("");
    const formValidator = useRef(new SimpleReactValidator({}));
    const validateUpdate = useRef(new SimpleReactValidator({}));

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        props.poid ?
            <TextField value={value}
                variant="outlined"
                style={{
                    background: `${imagePath} 232px/ 12px no-repeat`,
                    width: '273px'
                }}
                onClick={onClick}
                onChange={onChange}
                className={classes.inputField}
                ref={ref} placeholder="yyyy-mm-dd" /> :

            <TextField value={value}
                variant="outlined"
                style={{
                    background: `${imagePath} 301px/ 12px no-repeat`,
                    width: '337px'
                }}
                onClick={onClick}
                onChange={onChange}
                className={classes.inputField}
                ref={ref} placeholder="yyyy-mm-dd" />
    ));

    const handleChangePO = (field, value) => {
        setIsEnable(false);
        setIsDisable(false);
        if (field === "po_party_country") {
            let obj = {
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            props.setCountry(obj);
        } else if (field === "po_date") {
            setPoDate(value);
        }
        props.setPOListObj(values => ({ ...values, [field]: value, user: localStorage.getItem('userId') }))
    }

    const handleUpdatePO = () => {
        const isValid = validateUpdate.current.allValid();
        if (isValid) {
            const payload = { ...poListModal }
            payload.po_number = props.poListObj.po_number;
            payload.po_date = props.poListObj.po_date;
            payload.po_party_name = props.poListObj.po_party_name;
            payload.po_party_addr1 = props.poListObj.po_party_addr1 ? props.poListObj.po_party_addr1 : "";
            payload.po_party_addr2 = props.poListObj.po_party_addr2 ? props.poListObj.po_party_addr2 : "";
            payload.po_party_country = props.country.ctrycode;
            payload.po_party_city = props.poListObj.po_party_city ? props.poListObj.po_party_city : "";
            payload.po_party_state = props.poListObj.po_party_state ? props.poListObj.po_party_state : "";
            payload.po_party_brand = props.poListObj.po_party_brand ? props.poListObj.po_party_brand : "";
            payload.po_party_zip = props.poListObj.po_party_zip ? props.poListObj.po_party_zip : "";
            payload.currency = props.poListObj.currency ? props.poListObj.currency : "";
            payload.po_contact_email = props.poListObj.po_contact_email ? props.poListObj.po_contact_email : "";
            payload.user = localStorage.getItem('userId');
            payload.poid = props.poid;
            props.editPurchaseOrder(payload);
            setIsDisable(true);
        } else {
            validateUpdate.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleChangeEditPO = (field, value) => {
        setIsDisable(false);
        if (field === "po_party_country") {
            let obj = {
                id: value.ctrycode,
                ctrycode: value.ctrycode,
                ctrycname: value.ctrycname
            }
            props.setCountry(obj);
        } else if (field === "po_date") {
            setPoDate(value);
        } else if (field === "party_contact") {
            props.setContacts(value);
        }
        props.setPOListObj(values => ({ ...values, [field]: value }))
    }

    const handleSavePo = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            const payload = { ...poListModal }
            payload.po_number = props.poListObj.po_number;
            payload.po_date = props.poListObj.po_date;
            payload.po_party_name = props.poListObj.po_party_name;
            payload.po_party_addr1 = props.poListObj.po_party_addr1 ? props.poListObj.po_party_addr1 : "";
            payload.po_party_addr2 = props.poListObj.po_party_addr2 ? props.poListObj.po_party_addr2 : "";
            payload.po_party_country = props.country.ctrycode;
            payload.po_party_city = props.poListObj.po_party_city ? props.poListObj.po_party_city : "";
            payload.po_party_state = props.poListObj.po_party_state ? props.poListObj.po_party_state : "";
            payload.po_party_brand = props.poListObj.po_party_brand ? props.poListObj.po_party_brand : "";
            payload.po_party_zip = props.poListObj.po_party_zip ? props.poListObj.po_party_zip : "";
            payload.currency = props.poListObj.currency ? props.poListObj.currency : "";
            payload.user = localStorage.getItem('userId');
            payload.cust_id = props.customerId;
            payload.po_contact_email = props.poListObj.po_contact_email ? props.poListObj.po_contact_email : "";
            payload.po_order = props.poListObj.po_order;
            props.addPO(payload)
            setIsDisable(true);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleAddContact = () => {
        setIsOpenContactModal(!isOpenContactModal);
    }

    const getScanDetails = () => {
        const payload = {
            "search": {
                "securekey": `${process.env.REACT_APP_DPL_GURU_KEY}`,
                "filters": {
                    "fuzzylogic": "false",
                    "dplguruforensic": "false",
                    "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
                    "exclusionwords": "pvt",
                    "redflag": "military"
                },
                "itemstosearch": [
                    {
                        "name": props.poListObj.po_party_name,
                        "address": props.poListObj.po_party_addr1 + ' ' + props.poListObj.po_party_addr2 ,
                        "country_code": props.country.ctrycode
                    }
                ]
            }
        }

        fecthBlacklistCustomer(payload).then(data => {
            setNoOfMatch(data.summary.matches)
            if (data.summary.matches === "0") {
                setMatchData([]);
                setIsLoading(false);
                updateScan(props.poListObj.po_party_name ? props.poListObj.po_party_name : "", props.poListObj.po_party_addr1 + ' ' + props.poListObj.po_party_addr2, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                })
            } else {
                updateScan(props.poListObj.po_party_name ? props.poListObj.po_party_name : "", props.poListObj.po_party_addr1 + ' ' + props.poListObj.po_party_addr2, data.records, data.summary.matches, 0, localStorage.getItem('userId'), data.status.statuscode).then(res => {
                    console.log('successful');
                })
                setMatchData(data.records);
                setIsLoading(false);
            }
        }).catch(error => {
            props.amberSnackbar(error);
        })
    }

    const handleCheckBlackList = () => {
        setIsPopupOpen(!isPopupOpen);
        getScanDetails();
    }

    const handleSaveUpdatePOContact = () => {
        const payload = { ...poContact }
        payload.user = localStorage.getItem('userId');
        payload.poid = props.selectPoid ? props.selectPoid : props.poid;
        payload.party_contact = props.contacts
        props.savePoContact(payload);
    }

    const renderPO = () => {
        return (
            <React.Fragment>
                {props.poid ? (
                    <>
                        <Grid container item xs={12} justifyContent="flex-end">
                            <Button variant="contained" color="primary"
                                className={classes.selectBtn}
                                onClick={() => handleCheckBlackList()}>
                                Scan Blacklist Customer
                            </Button>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="po_no">
                                            <span className={classes.mandatoryField}>*</span>
                                            PO No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="po_no"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_number}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeEditPO("po_number", e.target.value)}
                                        placeholder="PO No."
                                    />
                                    {validateUpdate.current.message('PO No.', props.poListObj?.po_number, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="po_date">
                                            <span className={classes.mandatoryField}>*</span>
                                            PO Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        selected={poDate}
                                        value={props.poListObj?.po_date}
                                        onChange={(date) => handleChangeEditPO("po_date", date)}
                                        customInput={<DateCustomInput />}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                    {validateUpdate.current.message('PO Date', props.poListObj?.po_date, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="currency">
                                            Currency
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="currency"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.currency}
                                        onChange={(e) => handleChangeEditPO("currency", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="Currency"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="party_name"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_name}
                                        onChange={(e) => handleChangeEditPO("po_party_name", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="Party Name"
                                    />
                                    {validateUpdate.current.message('Party Name', props.poListObj?.po_party_name, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_addr1}
                                        className={classes.inputField}
                                        onChange={(e) => handleChangeEditPO("po_party_addr1", e.target.value)}
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_addr2}
                                        onChange={(e) => handleChangeEditPO("po_party_addr2", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_city}
                                        onChange={(e) => handleChangeEditPO("po_party_city", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_state}
                                        onChange={(e) => handleChangeEditPO("po_party_state", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="State"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_zip}
                                        onChange={(e) => handleChangeEditPO("po_party_zip", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="Zip"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            <span className={classes.mandatoryField}>*</span>
                                            Country
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        id="country-select"
                                        disableClearable
                                        value={props.country}
                                        options={props.countries}
                                        getOptionLabel={(option) => option.ctrycname || ""}
                                        getOptionSelected={(option, value) => value?.ctrycode === option?.ctrycode}
                                        onChange={(e, newValue) => handleChangeEditPO("po_party_country", newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Country"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {validateUpdate.current.message('country', props.country, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="brand">
                                            Brand
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="brand"
                                        color="primary"
                                        variant="outlined"
                                        value={props.poListObj?.po_party_brand}
                                        onChange={(e) => handleChangeEditPO("po_party_brand", e.target.value)}
                                        className={classes.inputField}
                                        placeholder="Brand"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    disabled={isDisable}
                                    onClick={() => handleUpdatePO()}>
                                    Update
                                </Button>
                            </Grid>
                            <Grid item xs={12}><hr /></Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Customer Contact Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        limitTags={2}
                                        id="party-contact-select"
                                        disableClearable
                                        multiple
                                        value={props.contacts}
                                        options={props.contactName}
                                        getOptionLabel={(option) => option.contact_name}
                                        getOptionSelected={(option, value) => value.pa_pcid === option.pa_pcid}
                                        onChange={(e, newValue) => props.setContacts(newValue)}
                                        renderOption={(option, { selected }) => (
                                            <Grid container item justifyContent="space-between">
                                                <Grid>
                                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#0070AD' }} checked={selected} />
                                                    {option.contact_name}
                                                </Grid>
                                            </Grid>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Contact Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton edge="end" aria-label="add contact" onClick={() => handleAddContact()}>
                                    <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    //disabled={props.contacts}
                                    onClick={() => handleSaveUpdatePOContact()}>
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                ) :
                    <>
                        <Grid container item xs={12} justifyContent="flex-end">
                            <Button variant="contained" color="primary"
                                className={classes.scanbtn}
                                disabled={isEnable}
                                onClick={() => handleCheckBlackList()}>
                                Scan Blacklist Customer
                            </Button>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="po_no">
                                            <span className={classes.mandatoryField}>*</span>
                                            PO No.
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="po_no"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_order", e.target.value)}
                                        placeholder="PO No."
                                    />
                                    {formValidator.current.message('PO No.', props.poListObj.po_order, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="po_date">
                                            <span className={classes.mandatoryField}>*</span>
                                            PO Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        selected={poDate}
                                        onChange={(date) => handleChangePO("po_date", date)}
                                        customInput={<DateCustomInput />}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                    {formValidator.current.message('PO Date', props.poListObj.po_date, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="currency">
                                            Currency
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="currency"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("currency", e.target.value)}
                                        placeholder="Currency"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            <span className={classes.mandatoryField}>*</span>
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="party_name"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_name", e.target.value)}
                                        placeholder="Party Name"
                                    />
                                    {formValidator.current.message('Party Name', props.poListObj.po_party_name, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add1">
                                            Address 1
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add1"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_addr1", e.target.value)}
                                        placeholder="Address 1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="add2">
                                            Address 2
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="add2"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_addr2", e.target.value)}
                                        placeholder="Address 2"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="city">
                                            City
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="city"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_city", e.target.value)}
                                        placeholder="City"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="state">
                                            State
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="state"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_state", e.target.value)}
                                        placeholder="State"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="zip">
                                            Zip
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="zip"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_zip", e.target.value)}
                                        placeholder="Zip"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="country">
                                            <span className={classes.mandatoryField}>*</span>
                                            Country
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        disableClearable
                                        id="country-select"
                                        value={props.country}
                                        options={props.countries}
                                        getOptionLabel={(option) => option.ctrycname || ""}
                                        getOptionSelected={(option, value) => value?.ctrycode === option.ctrycode}
                                        onChange={(e, newValue) => handleChangePO("po_party_country", newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Country"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                    {formValidator.current.message('country', props.country, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="brand">
                                            Brand
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="brand"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => handleChangePO("po_party_brand", e.target.value)}
                                        placeholder="Brand"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    disabled={isDisable}
                                    onClick={() => handleSavePo()}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={12}><hr /></Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="contact_name">
                                            Customer Contact Name
                                        </label>
                                    </Typography>
                                    <Autocomplete
                                        limitTags={2}
                                        id="party-contact-select"
                                        disableClearable
                                        multiple
                                        options={props.contactName}
                                        getOptionLabel={(option) => option.contact_name}
                                        getOptionSelected={(option, value) => value.pa_pcid === option.pa_pcid}
                                        onChange={(e, newValue) => props.setContacts(newValue)}
                                        renderOption={(option, { selected }) => (
                                            <Grid container item justifyContent="space-between">
                                                <Grid>
                                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#0070AD' }} checked={selected} />
                                                    {option.contact_name}
                                                </Grid>
                                            </Grid>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                placeholder="Contact Name"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.inputField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton edge="end" aria-label="add contact" onClick={() => handleAddContact()}>
                                    <img src={CreateIcon} alt="add" width="16px" style={{ marginTop: "33px" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    className={classes.applyBtn}
                                    onClick={() => handleSaveUpdatePOContact()}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </>}
            </React.Fragment>
        )
    }

    return (
        <>
            {renderPO()}
            {isOpenContactModal && (
                <AddContactPopupModal
                    isOpenContactModal={isOpenContactModal}
                    setIsOpenContactModal={setIsOpenContactModal}
                    pid={props.customerId}
                    saveContact={props.addContact}
                    paid={0} />
            )}
            {isPopupOpen && (
                <ScanPartyPopupModal
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                    scanDetails={matchData}
                    isLoading={isLoading}
                    matches={noOfMatch} />
            )}
        </>
    )

}

export default PODetails;