import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button } from "@material-ui/core";
import { useContext, useRef } from "react";
import React, { forwardRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { SearchHistoryContext } from "./components/Context";
import TableView from './components/TableView';
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import { SpinnerCircularFixed } from "spinners-react";
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/datepicker.styles.css'
import calenderIcon from '../assets/calendarIcon.svg';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    validateStyle: {
        color: '#f44336',
        fontSize: '12px',
        fontWeight: '100',
        marginTop: 20,
        fontFamily: 'sans-serif'
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    mandatoryField: {
        color: '#FF304C',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        paddingRight: '2px'
    },
    saveBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '35px 20px 5px 17px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}))

const SeacrhHistory = ({ setTitle }) => {
    setTitle('Search History');
    const imagePath = `url(${calenderIcon})`;
    const classes = useStyles();
    const [selectDate, setSelectDate] = useState(null);
    const [selectToDate, setSelectToDate] = useState(null);
    const [, forceUpdate] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isBtnTrue, setIsBtnTrue] = useState(true);
    const formValidator = useRef(new SimpleReactValidator({}));
    const { headCells, summaryList, searchHistoryParty } = useContext(SearchHistoryContext);

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            variant="outlined"
            style={{
                background: `${imagePath} 85% / 12px no-repeat`,
                width: '100%'
            }}
            onClick={onClick}
            onChange={onChange}
            className={classes.inputField}
            ref={ref} placeholder="yyyy-MM-dd" />
    ));

    const handleSearch = () => {
        const isValid = formValidator.current.allValid();
        if (isValid) {
            searchHistoryParty(moment(selectDate).format('yyyy-MM-DD'), moment(selectToDate).format('yyyy-MM-DD'), localStorage.getItem('userId'));
            setIsOpen(true);
            setIsBtnTrue(false);
        } else {
            formValidator.current.showMessages();
            forceUpdate(1);
            setIsBtnTrue(true);
        }
    }

    const handleExportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(summaryList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheetname" || 'Sheet1');
        XLSX.writeFile(wb, "dplguru.xlsx" || 'excel-export.xlsx');
    }

    const renderParty = () => {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Search History</Typography>
                        </Grid>
                        <Grid item xs={8} container justifyContent="flex-end" className="d-flex align-center">
                            <Button variant="contained" color="primary" size="medium"
                                onClick={handleExportToExcel} className={classes.applyBtn} disabled={isBtnTrue}>
                                Export to Excel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <Paper component="form" className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="Date">
                                            <span className={classes.mandatoryField}>*</span>
                                            From Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd'
                                        selected={selectDate}
                                        onChange={
                                            (date) => setSelectDate(date)
                                        }
                                        customInput={<DateCustomInput />}
                                    />
                                    {formValidator.current.message('From Date', selectDate, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="Date">
                                            <span className={classes.mandatoryField}>*</span>
                                            To Date
                                        </label>
                                    </Typography>
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd'
                                        selected={selectToDate}
                                        onChange={
                                            (date) => setSelectToDate(date)
                                        }
                                        customInput={<DateCustomInput />}
                                    />
                                    {formValidator.current.message('To Date', selectDate, 'required', { className: classes.validateStyle })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" size="medium"
                                    onClick={handleSearch} className={classes.saveBtn}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderParty()}
            {(isOpen && summaryList !== undefined) ?
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <TableView headCells={headCells} summaryList={summaryList} />
                </ErrorBoundary>
                : <SpinnerCircularFixed style={{ marginLeft: '46%' }} enabled={isOpen} />}
        </>
    )
}

export default SeacrhHistory;