/** @format */

import React, { createContext, useState, useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { HEAD_CELLS } from '../Constants/index';
import { fetchCountry, fetchCustomer } from '../../Common/API';

const initialState = {
  loading: true,
  surveyList: [],
  error: null,
  hasError: false,
  headCells: HEAD_CELLS,
  order: 'asc',
  orderBy: '',
  pageId: null,
};

const spinnerStyle = {
  marginLeft: '50%',
  marginTop: '2%',
};

export const CustomerSummaryContext = createContext(initialState);

export const CustomerSummaryContextProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [allCustomerContextData, setAllCustomerContextData] = useState(initialState);
  const { summaryList, loading, headCells, orderBy, order } = allCustomerContextData;

  useEffect(() => {
    customerSummary();
    getCountry();
  }, []);

  const getCountry = () => {
    fetchCountry().then(res => {
      setCountries(res);
    }).catch(error => {
      
    })
  }
  
  const customerSummary = () => {
    fetchCustomer()
      .then((data) => {
        const summaryData = data.map((data, index) => {
          return data;
        });
        setAllCustomerContextData((prevState) => {
          return {
            ...prevState,
            summaryList: summaryData,
            loading: false,
            error: null,
            hasError: false,
            searchTerm: '',
          }
        });
      })
      .catch((error) => {
        setAllCustomerContextData({
          ...allCustomerContextData,
          loading: false,
          error: error,
          hasError: true,
        });
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setAllCustomerContextData({
      ...allCustomerContextData,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  function descendingComparator(a, b, orderByVal) {
    const orderByArray = orderByVal.split('.');
    if (
      orderByArray &&
      orderByArray.length &&
      b[orderByArray[0]] &&
      b[orderByArray[0]][orderByArray[1]] &&
      a[orderByArray[0]] &&
      a[orderByArray[0]][orderByArray[1]]
    ) {
      if (
        b[orderByArray[0]][orderByArray[1]] <
        a[orderByArray[0]][orderByArray[1]]
      ) {
        return -1;
      }
      return 1;
    } else if (b[orderByVal] && a[orderByVal]) {
      const isNumber = typeof a[orderByVal] === 'number';
      const filedValueB = isNumber
        ? b[orderByVal]
        : b[orderByVal].toUpperCase();
      const filedValueA = isNumber
        ? a[orderByVal]
        : a[orderByVal].toUpperCase();
      if (filedValueB < filedValueA) {
        return -1;
      }
      return 1;
    }
    return 0;
  }

  function getComparator(orderVal, orderByVal) {
    return orderVal === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByVal)
      : (a, b) => -descendingComparator(a, b, orderByVal);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orders = comparator(a[0], b[0]);
      if (orders !== 0) return orders;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const contextValues = {
    ...allCustomerContextData,
    summaryList,
    headCells,
    countries,
    customerSummary,
    handleRequestSort,
    getComparator,
    stableSort,
  };

  return (
    <CustomerSummaryContext.Provider value={contextValues}>
      {loading ? (
        <div style={spinnerStyle}>
          {' '}
          <SpinnerCircularFixed
            color='#0070AD'
            enabled={loading}
          />{' '}
        </div>
      ) : (
        children
      )}
    </CustomerSummaryContext.Provider>
  );
};
