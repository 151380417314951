import { Grid, makeStyles, Paper, Button, Card, CardContent, CardActions, Typography } from "@material-ui/core";
import React from "react";
import fededLogo from '../../../../../assets/FedexLabelIcons/fedexLogo.png';
import uspsLogo from '../../../../../assets/FedexLabelIcons/uspsLogo.png';
import upsLogo from '../../../../../assets/FedexLabelIcons/upsLogo.png';
import FedexRates from "./components/FedexRates";
import { useState } from "react";
import TrackShipment from "./components/TrackShipment";
import PageLoader from "../../../../../Common/PageLoader";
import { SpinnerCircularFixed } from "spinners-react";

const useStyles = makeStyles(theme => ({
    h5: {
        fontSize: '18px',
        fontFamily: 'sans-serif'
    },
    fedexImg: {
        width: "7rem",
        height: "7rem",
        borderRadius: "9999px",
        border: "2px solid #fff",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
    },
    cancelButton: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            textDecoration: 'none'
        }
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    pageFooter: {
        marginBottom: '-26px',
        textAlign: 'right',
        right: 0,
        bottom: 0
    },
    selectBtn: {
        fontFamily: 'sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '-20px 20px 20px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    disbleImg: {
        pointerEvents: "none",
        opacity: 0.5
    }
}))

const ChooseShipment = (props) => {
    const classes = useStyles();
    const [serviceRates, setServiceRates] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const handleGenerateRates = () => {
        props.generateFedexRates();
    }

    const handleCancel = () => {

    }

    const handleSaveFedexRates = () => {
        props.validateLabel(props.invoiceId, serviceRates.Service);
    }

    const handleDeleteShipment = () => {
        props.shipemntDelete();
    }

    const handleTrackShipment = () => {
        props.shipmentTrack();
    }

    const handleChangeCheck = (e, index) => {
        setIsChecked(e.target.checked[index]);
        props.fedexRates?.map((rate, key) => {
            if (key === index) {
                setServiceRates(rate);
            }
            return rate;
        });
    }

    const renderFedex = () => {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={8} className="d-flex align-center">
                        {props.shipmentMsg.trackno !== undefined && props.shipmentMsg.result !== undefined && (
                            <Typography style={{ fontFamily: 'sans-serif', color: 'crimson' }}>{props.shipmentMsg.trackno + " - " + props.shipmentMsg.result}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={4} container className="d-flex align-center">
                        <Grid container direction="row" justifyContent="flex-end">
                            <Button variant="contained" color="primary"
                                className={classes.selectBtn}
                                disabled={props.trackShip.length > 0 ? false : true}
                                onClick={() => handleTrackShipment()}>
                                Track Shipment
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.selectBtn}
                                disabled={props.trackShip.length > 0 ? false : true}
                                onClick={() => handleDeleteShipment()}>
                                Delete Shipment
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {props.trackShip.length > 0 ?
                    <Grid item xs={12} >
                        <TrackShipment trackList={props.trackShip} />
                    </Grid>
                    : <>
                        <Grid container spacing={3} style={{ textAlign: "center" }}>
                            <Grid item xs={4}>
                                <img src={fededLogo} alt="fedexLogo" onClick={() => handleGenerateRates()} className={classes.fedexImg} style={{ cursor: 'pointer' }} />
                            </Grid>
                            <Grid item xs={4}>
                                <img src={uspsLogo} alt="fedexLogo" className={`${classes.fedexImg} ${classes.disbleImg}`} />
                            </Grid>
                            <Grid item xs={4}>
                                <img src={upsLogo} alt="fedexLogo" className={`${classes.fedexImg} ${classes.disbleImg}`} />
                            </Grid>
                            {props.fedexRates.length > 0 && props.fedexRates.map((row, index) => (
                                <Grid item xs={3}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <FedexRates ratesFedex={row} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h7" component="div">
                                                {row.Service}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                ${row.Rate}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <input type="radio"
                                                style={{ width: '20px', margin: "0 auto 20px" }}
                                                checked={isChecked}
                                                onClick={(e) => handleChangeCheck(e, index)}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}

                        </Grid>
                    </>}
            </React.Fragment>
        )
    }

    return (
        <>
            <Paper component="form" className={classes.paper}>
                {renderFedex()}
                {serviceRates.length !== 0 && (
                    <Grid container wrap="wrap" className={classes.pageFooter}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary"
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.applyBtn}
                                onClick={() => handleSaveFedexRates()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    )

}

export default ChooseShipment;