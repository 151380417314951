import { useState } from "react"
import { fetchCustomerGraph } from "../../Common/API";
import { useEffect } from "react";
import { createContext } from "react";
import Cookies from "js-cookie";
import moment from "moment";
import { useLocation } from "react-router-dom";

const initialState = {
    dashboardData: { isLoad: false, dashboardList: [] }
}

const reportInitialState = {
    dashboardData: { isLoad: false, reportData: [] }
}

export const ReportsContext = createContext(initialState);

export const ReportProvider = ({ children }) => {
    const To = new Date();
    const From = new Date(To.getFullYear() - 1, To.getMonth(), To.getDate());
    const payload = {
        "fromDate": moment(From).format('yyyy-MM-DD'),
        "toDate": moment(To).format('yyyy-MM-DD'),
        "party_id": localStorage.getItem('party_id'),
    }
    const [allReportResponse, setAllReportResponse] = useState(reportInitialState);

    const getDashboardData = async (reqObj) => {
        const fetchReport = await fetchCustomerGraph(reqObj);
        setAllReportResponse({ ...allReportResponse, dashboardData: { isLoad: true, reportData: fetchReport } })
    }

    useEffect(() => {
        getDashboardData(payload);
    }, []);

    const contextValues = {
        ...allReportResponse,
        getDashboardData
    }

    return (<ReportsContext.Provider value={contextValues}>
        {children}
    </ReportsContext.Provider>)
}