import { Box, Grid, Paper, Tab, Tabs, Typography, AppBar, Button } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../../../Common/Fallback";
import { getDefaultWoInfo, workOrderStyle } from "../../../Common/constant";
import WorkOrderStatus from "./components/WorkOrderStatus";
import { deleteFFReciept, deleteTrackShipDate, deleteWOLoadEvent, fetchFreightForward, deleteWORepository, deletebillNo, fetchCountry, fetchCustomerAddress, fetchWOStatus, fetchWoDetails, saveFFCartons, saveRcvdFFReceipt, saveTrackShipmentDate, saveWOMessage, saveWOStatus, saveWoBillNo, saveWoRepository, updateDelPlan, saveFF, updateFFR, deleteWorkOrder, updateScan, saveDeliveryAgent, fetchDeliveryAgent, updateDelAgent, saveContacts, fetchContactList, saveUpdateWoContact } from "../../../Common/API";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import wIcon from '../../../assets/w-icon.png';
import DeliveryLocation from "./components/DeliveryLocation";
import FreightForwarder from "./components/FreightForwarder";
import TrackShipmentDate from "./components/TrackShipmentDate";
import AWBNOs from "./components/AWBNOs";
import ReceivedFreightForwarder from "./components/ReceivedFreightForwarder";
import LoadEvents from "./components/LoadEvents";
import Repository from "./components/Repository";
import WOMessages from "./components/WOMessages";
import Cookies from "js-cookie";
import { useSnackbar, withSnackbar } from "react-simple-snackbar";
import { OPTIONS, OPTIONS3 } from "../../../Common/constant";
import DeleteReasonPopupModal from "../../../Common/DeleteReasonPopupModal";
import DeliveryAgent from './components/DeliveryAgent';
import WOItemDetails from "./components/WOItemDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labeledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={index === 3 ? 0 : 3}>
          <Typography component="div">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  )
}

const EditWorkOrder = ({ setTitle }) => {
  setTitle('Work Order');
  const classes = workOrderStyle();
  const { woid } = useParams();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [woStatus, setWoStatus] = useState([]);
  const [woInfo, setWoInfo] = useState(getDefaultWoInfo);
  const [location, setLocation] = useState([]);
  const [successSnackbar] = useSnackbar(OPTIONS);
  const [amberSnackbar] = useSnackbar(OPTIONS3);
  const [selectWOStatus, setSelectWOStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [fforwarder, setFForwarder] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [partyid, setPartyId] = useState();
  const [agent, setAgent] = useState([]);
  const [contactName, setContactName] = useState([]);
  const formValidator = useRef(new SimpleReactValidator({}));

  useEffect(() => {
    refreshWO();
    fetchWOStatus().then(data => {
      setWoStatus(data);
    }).catch(error => {
      amberSnackbar(error);
    });

    fetchCountry().then((res) => {
      setCountries(res);
    }).catch(error => {
      amberSnackbar(error);
    });
    fetchFreightForward().then(data => {
      setFForwarder(data);
    }).catch(error => {
      amberSnackbar(error);
    })
    fetchDeliveryAgent().then(data => {
      setAgent(data);
    }).catch(error => {
      amberSnackbar(error);
    })
  }, [])

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const refreshWO = () => {
    (async () => {
      await fetchWoDetails(woid).then((res) => {
        contactNameList("", res.dellocation[0].paid)
        let newObj = {
          id: res.order[0].status_code,
          status_code: res.order[0].status_code,
          wostatus: res.order[0].wostatus,
        }
        setSelectWOStatus(newObj);
        setWoInfo((prevState) => {
          return {
            ...prevState,
            woList: res.order[0],
            deliveryLocation: res.dellocation[0],
            trackShipment: res.tracking,
            container: res.containers,
            trackShipmentDate: res.trackdates,
            freightForward: res.forwarding,
            message: res.msgdetails,
            repository: res.repodetails,
            partyFFR: res.partyffr[0],
            itemDetails: res.items,
            woda: res.partyda[0],
            contacts: res.wocontacts
          }
        });
        fetchCustomerAddress(res.party[0].pid).then(data => {
          setLocation(data);
        }).catch(error => {
          amberSnackbar(error);
        })
      }).catch(error => {
        amberSnackbar(error);
      });
    })();
  }

  const addMessage = (reqObj) => {
    saveWOMessage(reqObj).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
        refreshWO();
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const saveFile = (documentUpload, discription) => {
    const formData = new FormData();
    formData.append('myfile', documentUpload);
    formData.append('repo_desc', (discription !== undefined || discription !== '') ? discription : '');
    formData.append('woid', woid);
    formData.append('user', localStorage.getItem('userId'));
    saveWoRepository(formData).then((res) => {
      if (res.status === 1) {
        successSnackbar(res.message);
        refreshWO();
      } else {
        amberSnackbar(res.message);
      }
    }).catch((error) => {
      amberSnackbar(error);
    })
  }

  const handleFileDelete = (repoid) => {
    deleteWORepository(repoid, woid, localStorage.getItem('userId')).then((res) => {
      if (res.status === 1) {
        successSnackbar(res.message);
        refreshWO();
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const handleDeleteLoadEvent = (containerId) => {
    deleteWOLoadEvent(containerId, localStorage.getItem('userId'), woid).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
        refreshWO();
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error)
    })
  }

  const handleDeleteRecieveAtFF = (ffid) => {
    deleteFFReciept(ffid, localStorage.getItem('userId'), woid).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
        refreshWO();
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error)
    })
  }

  const handleSaveFFReceipt = (reqObj) => {
    saveRcvdFFReceipt(reqObj).then((res) => {
      if (res.status === 1) {
        successSnackbar(res.message);
        refreshWO();
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const addCartons = (reqObj) => {
    saveFFCartons(reqObj).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
        refreshWO();
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    });
  }

  const saveAWBNo = (reqObj) => {
    saveWoBillNo(reqObj, localStorage.getItem('userId'), woid).then((res) => {
      if (res.status === 1) {
        successSnackbar(res.message);
        refreshWO();
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const deleteAWBNo = (awbnoid) => {
    deletebillNo(awbnoid, localStorage.getItem('userId'), woid).then((res) => {
      if (res.status === 1) {
        refreshWO();
        successSnackbar(res.message);
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const saveTrackShipDate = (reqObj) => {
    saveTrackShipmentDate(reqObj).then((res) => {
      if (res.status === 1) {
        successSnackbar(res.message);
        refreshWO();
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const deleteTrackShipmentDate = (tractdateId) => {
    deleteTrackShipDate(tractdateId, localStorage.getItem('userId'), woid).then((res) => {
      if (res.status === 1) {
        refreshWO();
        successSnackbar(res.message);
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const addWOStatus = (reqObj) => {
    saveWOStatus(reqObj).then((res) => {
      if (res.status === 1) {
        refreshWO();
        successSnackbar(res.message);
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const addDeliveryLocation = (reqObj) => {
    updateDelPlan(reqObj).then(res => {
      if (res.status === 1) {
        refreshWO();
        successSnackbar(res.message);
      } else {
        amberSnackbar(res.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const handleWODelete = () => {
    setIsOpenDelete(!isOpenDelete);
  }

  const addFreightForwarder = (reqObj) => {
    saveFF(reqObj).then(data => {
      if (data.status === 1) {
        refreshWO();
        setPartyId(data.party_id);
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const addDeliveryAgent = (reqObj) => {
    saveDeliveryAgent(reqObj).then(data => {
      if (data.status === 1) {
        refreshWO();
        setPartyId(data.party_id);
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const ffrUpdate = (reqObj) => {
    updateFFR(reqObj).then(data => {
      if (data.status === 1) {
        refreshWO();
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const workOrderDelete = (reason) => {
    deleteWorkOrder(woid, localStorage.getItem('userId'), reason, 'D')
      .then(data => {
        if (data.status === 1) {
          navigate("/work-order/summary");
          successSnackbar(data.message);
        } else {
          amberSnackbar(data.message);
        }
      }).catch(error => {
        amberSnackbar("Failed");
      })
  }

  const addScanParty = (reqobj) => {
    updateScan(reqobj).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const updateAgent = (reqObj) => {
    updateDelAgent(reqObj).then(data => {
      if (data.status === 1) {
        refreshWO();
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const addContact = (reqObj) => {
    saveContacts(reqObj).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
        contactNameList("", reqObj.paid);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const contactNameList = (pid, paid) => {
    fetchContactList(pid, paid, localStorage.getItem('userId')).then(data => {
      setContactName(data)
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const saveUpdateContact = (reqObj) => {
    saveUpdateWoContact(reqObj).then(data => {
      if (data.status === 1) {
        successSnackbar(data.message);
      } else {
        amberSnackbar(data.message);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }

  const renderWorkOrder = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={2}>
          <Box ml={3} me={2}>
            <Paper elevation={10} className={classes.leftPaper}>
              <Grid item>
                <img src={wIcon} alt='woIcon' className={classes.profileIcon} />
              </Grid>
              <Grid item container xs={12} spacing={4} className={classes.topP}>
                <Grid item xs={12}>
                  <Typography className={classes.disabled}>Work Order No.</Typography>
                  <Typography>{woInfo.woList?.workorderno}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.disabled}>Date</Typography>
                  <Typography>{woInfo.woList?.workorderdate}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.disabled}>Origin</Typography>
                  <Typography>{woInfo.woList?.Origin}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.disabled}>Destination</Typography>
                  <Typography>{woInfo.woList?.deldestination}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box ml={3}>
                <Button data-testid="delete" fullWidth variant="contained" color="primary"
                  onClick={() => handleWODelete()} style={{ textTransform: "none" }}
                  disabled={woInfo.woList.wostatus !== "New" ? true : false} >
                  Delete Work Order
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10}>
          <Box ml={2} mr={3}>
            <Paper elevation={10} className={classes.paper}>
              <AppBar position="static" color="default" className={classes.topPadding}>
                <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable"
                  scrollButtons="auto">
                  <Tab label="WO Status" style={{ textTransform: "none" }} />
                  <Tab label="Delivery Location" style={{ textTransform: "none" }} />
                  <Tab label="Delivery Agent" style={{ textTransform: "none" }} />
                  <Tab label="Freight Forwarder" style={{ textTransform: "none" }} />
                  <Tab label="Item Details" style={{ textTransform: "none" }} />
                  <Tab label="Track Shipment Date" style={{ textTransform: "none" }} />
                  <Tab label="AWB NOs" style={{ textTransform: "none" }} />
                  <Tab label="Recieved @FF" style={{ textTransform: "none" }} />
                  <Tab label="Load Events" style={{ textTransform: "none" }} />
                  <Tab label="Repository" style={{ textTransform: "none" }} />
                  <Tab label="Message" style={{ textTransform: "none" }} />
                </Tabs>
              </AppBar>
              <Box className={classes.topP}>
                <TabPanel value={tabIndex} index={0}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <WorkOrderStatus woStatus={woStatus} formValidator={formValidator} woStatusList={woInfo.woList}
                      addWOStatus={addWOStatus} selectWOStatus={selectWOStatus} setSelectWOStatus={setSelectWOStatus} woid={woid} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <DeliveryLocation deliveryLocation={woInfo.deliveryLocation} setSelectedLocation={setSelectedLocation}
                      formValidator={formValidator} location={location} countries={countries} selectedLocation={selectedLocation}
                      addDeliveryLocation={addDeliveryLocation} woid={woid} contactNameList={contactNameList}
                      addContact={addContact} contactName={contactName} saveUpdateContact={saveUpdateContact}
                      wocontacts={woInfo.contacts} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <DeliveryAgent woid={woid} addScanParty={addScanParty} addDeliveryAgent={addDeliveryAgent}
                      updateAgent={updateAgent} countries={countries} agent={agent} partyid={partyid} setPartyId={setPartyId}
                      delagent={woInfo.woda} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <FreightForwarder ffData={woInfo.partyFFR} countries={countries} fforwarder={fforwarder} addScanParty={addScanParty}
                      addFreightForwarder={addFreightForwarder} woid={woid} ffrUpdate={ffrUpdate} partyid={partyid}
                      setPartyId={setPartyId} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <WOItemDetails itemData={woInfo.itemDetails} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <TrackShipmentDate trackShipDateData={woInfo.trackShipmentDate} saveTrackShipDate={saveTrackShipDate}
                      deleteTrackShipmentDate={deleteTrackShipmentDate} woid={woid} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <AWBNOs awbList={woInfo.trackShipment} saveAWBNo={saveAWBNo} deleteAWBNo={deleteAWBNo} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={7}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <ReceivedFreightForwarder ffList={woInfo.freightForward} itemList={woInfo.itemDetails} woid={woid}
                      handleDeleteRecieveAtFF={handleDeleteRecieveAtFF} handleSaveFFReceipt={handleSaveFFReceipt}
                      addCartons={addCartons} containerList={woInfo.container} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={8}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <LoadEvents loadEventList={woInfo.container} handleDeleteLoadEvent={handleDeleteLoadEvent} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={9}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <Repository repository={woInfo.repository} saveFile={saveFile} handleFileDelete={handleFileDelete} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel value={tabIndex} index={10}>
                  <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <WOMessages addMessage={addMessage} formValidator={formValidator} messageList={woInfo.message} woid={woid} />
                  </ErrorBoundary>
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Grid >
      </Grid >
    );
  }

  return (
    <>
      {renderWorkOrder()}
      {isOpenDelete && (
        <DeleteReasonPopupModal
          isOpenDelete={isOpenDelete}
          setIsOpenDelete={setIsOpenDelete}
          orderDelete={workOrderDelete} />
      )}
    </>
  )
}

export default withSnackbar(EditWorkOrder);