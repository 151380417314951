import { Grid, List, Typography, ListItem, IconButton } from "@material-ui/core";
import {
    AccumulationChartComponent, AccumulationAnnotation, AccumulationAnnotationsDirective, AccumulationAnnotationDirective,
    AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel,
} from "@syncfusion/ej2-react-charts";
import { useEffect, useRef, useState } from "react";
import { LEGEND_CATEGORIES_COLORS_IMPACT } from "../../../Common/constant";

const DonutReport = ({ title, pieTitle, dataSource, totalCount, palettes, noOfLegend, legendMultiRow, graphClick }) => {
    const [legendCollections, setLegendCollections] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const ejChart = useRef();
    const noOfLegends = noOfLegend || 5;

    const pointClick = (args) => {
        graphClick(args.point.x)
    };

    const legendRender = (args) => {
        if (!legendCollections.length && ejChart.current && ejChart.current.accumulationLegendModule.legendCollections.length) setLegendCollections(ejChart.current.accumulationLegendModule.legendCollections);
    }

    const returnPaginationData = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const updatePagination = (array, expectPage) => {
        if (!((expectPage <= 0) || (expectPage > Math.ceil(array.length / noOfLegends)))) setCurrentPage(expectPage)
    }
    const redrawLegendShape = () => {
        if (legendCollections.length) {
            legendCollections.map((legend, indx) => {
                const targetId = ejChart.current.element.getAttribute('id');
                const targetPath = document.querySelector(`#${targetId} #${targetId}_chart_legend_shape_${indx}`);
                if (targetPath) targetPath.outerHTML = targetPath.outerHTML.replaceAll(/path/g, "span")
            })
        }
    }

    useEffect(() => {
        redrawLegendShape()
    }, [legendCollections, ejChart]);

    useEffect(() => {
        if (ejChart.current && !legendCollections.length) {
            setLegendCollections(ejChart.current.accumulationLegendModule.legendCollections)
        }
    });

    const toggleClick = (indx, text) => {
        // const targetId = ejChart.current.element.getAttribute('id');
        // const targetPath = document.querySelector(`#${targetId} #${targetId}_chart_legend_shape_${indx}`)
        // if (targetPath) targetPath.click();
        graphClick(text);
    }

    const onResizeGraph = () => {
        setTimeout(() => redrawLegendShape(), 1000)
    }

    return (
        <>
            <Grid item xs={legendMultiRow ? 4 : 6}>
                <AccumulationChartComponent
                    ref={ejChart}
                    width='100%'
                    height='50%'
                    tooltip={{ enable: true }}
                    resized={onResizeGraph}
                    legendSettings={{
                        visible: true,
                        position: 'Bottom',
                        width: '1000',
                        height: '5',
                    }}
                    legendRender={legendRender}
                    pointClick={(args) => { pointClick(args) }}
                    style={{cursor: "pointer"}}
                >
                    <Inject services={[AccumulationAnnotation, AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel, AccumulationAnnotation]} />
                    <AccumulationAnnotationsDirective>
                        <AccumulationAnnotationDirective
                            content={`<div style="font-size:25px;text-align:center;font-weight:bold">${totalCount}</div><div style="font-family: sans-serif; font-size:11px;width:90px; text-align:center; word-wrap: break-word;">${pieTitle}</div>`}
                            region='Series'
                            coordinateUnits='Pixel'
                            x='50%'
                            y='47%'>
                        </AccumulationAnnotationDirective>
                    </AccumulationAnnotationsDirective>
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective
                            dataSource={dataSource}
                            xName='x'
                            yName='y'
                            palettes={palettes || LEGEND_CATEGORIES_COLORS_IMPACT}
                            innerRadius="60%"
                            legendShape='Rectangle'
                        >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </Grid>
            <Grid item xs={legendMultiRow ? 8 : 6} className={`cstmLegend ${legendMultiRow ? 'multiRowLegend' : ''}`}>
                <Typography className="icapBoxBoldTxt" variant="h6">{title}</Typography>
                <List>
                    {returnPaginationData(legendCollections, noOfLegends, currentPage).map((legend, indx) => (
                        <ListItem key={legend.text} onClick={() => toggleClick(indx, legend.text)} title={legend.text}><div className='ellipsis'><span className="legendColor" style={{ background: legend.fill }}></span> {legend.text} </div> <span className="legendCount">{dataSource[legend.pointIndex].y}</span></ListItem>
                    ))}
                </List>
                <Grid container item alignItems='center' justifyContent='flex-end' className="ejCstmPagination d-flex">
                    <IconButton size='small' onClick={() => updatePagination(legendCollections, currentPage - 1)}>ᐸ</IconButton>
                    <span>{currentPage}</span>
                    <span>/</span>
                    <span>{Math.ceil(legendCollections.length / noOfLegends)}</span>
                    <IconButton size='small' onClick={() => updatePagination(legendCollections, currentPage + 1)}>ᐳ</IconButton>
                </Grid>
            </Grid>
        </>
    )
}

export default DonutReport;