import { Grid, makeStyles, Paper, TextField, Typography, FormControl, Button } from "@material-ui/core";
import { useContext } from "react";
import React, { useState } from "react";
import { SearchPartyContext } from "./components/Context";
import TableView from './components/TableView';
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import { SpinnerCircularFixed } from "spinners-react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    accordianExpandedTitle: {
        fontSize: '12px',
        lineHeight: '13px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '600',
        letterSpacing: '0px',
        color: '#2B0A3D',
        opacity: 1
    },
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    applyBtn: {
        fontFamily: 'Ubuntu, sans-serif',
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '5px 20px 5px 0',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    },
    inputField: {
        "&::placeholder": {
            fontSize: '12px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '400',
            letterSpacing: '0px',
            color: '#828282',
            opacity: 1
        },
        marginTop: '13px',
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: '400',
        letterSpacing: '0px',
        color: '#828282',
        opacity: 1
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    saveBtn: {
        borderRadius: '30px',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '35px 20px 5px 17px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0070AD',
            textDecoration: 'none',
            boxShadow: 'none',
            color: '#fff'
        }
    }
}))

const SeacrhParty = ({ setTitle }) => {
    setTitle('Search Party');
    const classes = useStyles();
    const navigate = useNavigate();
    const [partyName, setPartyName] = useState();
    const [address, setAddress] = useState();
    const [country, setCountry] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const { headCells, summaryList, noOfMatches, searchParty } = useContext(SearchPartyContext);

    const handleSearch = () => {
        const payload = {
            "search": {
                "securekey": `${process.env.REACT_APP_DPL_GURU_KEY}`,
                "filters": {
                    "fuzzylogic": "false",
                    "dplguruforensic": "false",
                    "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
                    "exclusionwords": "pvt",
                    "redflag": "military"
                },
                "itemstosearch": [
                    {
                        "name": partyName ? partyName : "",
                        "address": address ? address : "",
                        "country_code": country ? country : ""
                    }
                ]
            }
        }
        searchParty(payload, partyName ? partyName : "", address ? address : "");
        setIsOpen(true);
    }

    const handleSearchHistory = () => {
        navigate('/search-history');
    }

    const renderParty = () => {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                        <Grid item xs={4} className="d-flex align-center">
                            <Typography variant="h6" className={classes.title}>Search Customer</Typography>
                        </Grid>
                        <Grid item xs={8} container justifyContent="flex-end" className="d-flex align-center">
                            <Button variant="contained" color="primary" size="medium"
                                onClick={handleSearchHistory} className={classes.applyBtn}>
                                Search History
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ padding: '0 31px' }}>
                    <Paper component="form" className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="party_name">
                                            Party Name
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="party_name"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => setPartyName(e.target.value)}
                                        placeholder="Party Name"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="addr">
                                            Address
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="addr"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder="Address"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined">
                                    <Typography className={classes.accordianExpandedTitle}>
                                        <label htmlFor="ctry">
                                            Country
                                        </label>
                                    </Typography>
                                    <TextField
                                        id="ctry"
                                        color="primary"
                                        variant="outlined"
                                        className={classes.inputField}
                                        onChange={(e) => setCountry(e.target.value)}
                                        placeholder="Country"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" size="medium"
                                    onClick={handleSearch} className={classes.saveBtn}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <>
            {renderParty()}
            {(isOpen && summaryList !== undefined) ?
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <Typography style={{ textAlign: "-webkit-center", fontWeight: 700, lineHeight: 3, fontSize: 'x-large' }}>{noOfMatches ? noOfMatches : 'No'} matches found for <mark>{partyName}</mark></Typography>
                    <TableView headCells={headCells} summaryList={summaryList} />
                </ErrorBoundary>
                : <SpinnerCircularFixed style={{ marginLeft: '46%' }} enabled={isOpen} />}
        </>
    )
}

export default SeacrhParty;